<template>
  <v-row class="style-guide--buttons">
    <v-col cols="12" lg="6" xxl="5">
      <div>
        <h3>Primary</h3>
        <v-btn>Primary</v-btn>
        <v-btn disabled>Primary Disabled</v-btn>
      </div>
      <div>
        <h3>Secondary</h3>
        <v-btn class="secondary">Secondary</v-btn>
        <v-btn class="secondary" disabled>Secondary Disabled</v-btn>
      </div>
      <div>
        <h3>Tertiary</h3>
        <v-btn class="tertiary">Tertiary</v-btn>
        <v-btn class="tertiary" disabled>Disabled</v-btn>
      </div>
      <div class="dark-background">
        <h3>Secondary Dark</h3>
        <p>Secondary button over dark background</p>
        <v-btn class="secondary-light">Secondary (Dark)</v-btn>
        <v-btn class="secondary-light" disabled>Secondary (Dark) Disabled</v-btn>
      </div>
      <div class="blue-toggle-buttons">
        <h3>Big Blue Buttons</h3>
        <p>
          <strong>TODO</strong>: this should be a "Journey Selector" (recently added to Design
          System 2.0) and moved to "Forms & Inputs"
        </p>
        <h6 class="mt-4">Just Text</h6>
        <v-btn class="big-blue">By myself</v-btn>
        <h6 class="mt-8">Icon and Text</h6>
        <v-btn class="big-blue" prepend-icon="ep-icon-coapplicant" stacked>With co-applicant</v-btn>
      </div>
    </v-col>
    <v-col cols="12" lg="6" xxl="5" offset-xxl="2">
      <div class="grouped-buttons">
        <h3>Grouped Buttons</h3>
        <p>
          Adding a wrapper element with class <code>.button-group</code> will make the buttons
          50/50. When putting a <code>.primary</code> and <code>.secondary</code> button next to
          each other, always put the <code>.secondary</code> button first
        </p>
        <div class="button-group">
          <v-btn class="secondary">Secondary (Dark)</v-btn>
          <v-btn>Secondary (Dark) Disabled</v-btn>
        </div>
      </div>
      <div>
        <h3>Maxiumum Limits</h3>
        <p>
          Buttons have a maximum width of 336px. Any text that does not fit on a single line within
          that limit should be clipped by an ellipsis.
        </p>
        <v-btn>Primary with more words than fit in the button</v-btn>
        <v-btn class="secondary">Secondary with more words than fit in the button</v-btn>
        <v-btn class="tertiary">Tertiary with more words than fit in the button</v-btn>
      </div>
      <div>
        <h3>Loading States</h3>
        <p>
          This is an example of how to add a loading state to a button when clicked. This example
          just runs a timer function for 2 seconds. In a real-world application you could run a form
          handler or send an API request.
        </p>
        <v-btn :loading="loading.primary" @click="load('primary')" @keyup.enter="load('primary')">
          Click Me
        </v-btn>
        <v-btn
          class="secondary"
          :loading="loading.secondary"
          @click="load('secondary')"
          @keyup.enter="load('secondary')"
        >
          Click Me
        </v-btn>
        <v-btn
          class="tertiary"
          :loading="loading.tertiary"
          @click="load('tertiary')"
          @keyup.enter="load('tertiary')"
        >
          Click Me
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'StyleGuide--Buttons',
  components: {},
  data() {
    return {
      loading: {
        primary: false,
        secondary: false,
        tertiary: false,
      },
    };
  },
  methods: {
    load(variant) {
      this.loading[variant] = true;
      setTimeout(() => (this.loading[variant] = false), 2000);
    },
  },
});
</script>

<style lang="scss">
.style-guide--buttons {
  h3 {
    margin: 0 auto;
  }
  .v-col-12 > div + div {
    margin-top: $spacing_stack-md;
  }
  .v-btn {
    display: block;
    margin: 20px 0;
  }
  .dark-background {
    background: $park-gray-10;
    border-radius: $border-radius-lg;
    box-shadow: $shadow-level-4;
    padding: $spacing_inset-lg;
    margin-top: 40px;
    margin-bottom: 30px;

    h3,
    p {
      color: $base-white-100;
    }
  }
  .grouped-buttons {
    code {
      display: inline-block;
    }
  }
}
</style>

<template>
  <div class="sca-header" data-testid="sca-header">
    <div class="branding">
      <a href="#" @click.prevent="handleLogoClick" data-testid="sca-logo">
        <sc-image :media="logo" />
      </a>
      <h4>{{ title }}</h4>
    </div>
    <div class="contact" data-testid="sca-phone">
      <sc-image class="sca-store-location-icon mr-1" :media="phoneIcon" />
      <a :href="phoneHref">{{ phone }}</a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Field, Image } from '@sitecore-jss/sitecore-jss-vue';
import { FieldsPropType } from '@/lib/FieldTypes';

export default defineComponent({
  name: 'StandaloneCreditAppHeader',
  props: {
    fields: {
      type: Object as PropType<FieldsPropType>,
      default: () => ({}),
    },
  },
  components: {
    ScImage: Image,
  },
  data() {
    return {
      title: this.fields['header-label']?.value,
      logo: this.fields['header-logo'] as Field,
      phoneIcon: this.fields['phoneIcon'] as Field,
      phone: this.$jss?.sitecoreContext()?.location?.nearestStore?.phoneNumber,
    };
  },
  methods: {
    handleLogoClick() {
      this.emitter.emit('standalone-credit-app:exit');
    },
  },
  computed: {
    phoneHref() {
      if (this.phone) {
        return `tel:${this.phone.replace(/\D/g, '')}`;
      } else {
        return '#';
      }
    },
  },
});
</script>

<style lang="scss">
@use '@/assets/styles/variables' as *;

.sca-header {
  position: relative;
  z-index: 99;
  background: $base-white-100;
  border-bottom: $border-width-hairline solid $park-gray-90;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing_squish-xs;

  @include media-breakpoint-up(sm) {
    padding: ($spacing_stack-nano * 1.5) $spacing_inline-xxs;
  }

  .branding {
    display: flex;
    align-items: center;
    gap: $spacing_inline-xxxs;

    img {
      display: block;

      @include media-breakpoint-up(sm) {
        width: 108px;
        height: 36px;
      }
    }

    h4 {
      display: none;

      @include media-breakpoint-up(sm) {
        display: block;
        line-height: 60px;
      }
    }
  }
  .contact {
    color: $system-alert-blue-60;
    display: flex;
    gap: $spacing_inset-quarck;
    align-items: center;
    line-height: $line-height-default;
    font-weight: $font-weight-medium;
  }
}
</style>

<template>
  <transition name="slide">
    <!-- This is where the 'shop by store' dealerships / stores are -->
    <div class="facetFilter">
      <div class="filterLabel">
        <span class="filterLabel">{{ label }}</span>
      </div>
      <div class="divFacetList w-100">
        <div
          v-for="dealership in getFirstDealerships(numberOfDealershipsToDisplay)"
          :key="`dealership-row-${dealership.dealerId}`"
          @click="handleDealershipSelect(dealership.dealerId)"
        >
          <!-- Business requirement that at least 1 store must be always checked -->
          <FacetListItem
            :item="{ name: dealership.storeName || '', value: 0 }"
            :isSelected="selectedStores.includes(dealership.dealerId || '')"
            :isCheckboxDisabled="isCheckboxDisabled(dealership.dealerId)"
            :data-action-select-filter="dealership.storeName"
          >
            <template v-slot:leftContent>
              <span>{{ dealership.storeName }}</span>
            </template>
            <template v-slot:rightContent>
              <span>{{ Math.round(dealership.distance || 0).toLocaleString() }} mi</span>
            </template>
          </FacetListItem>
        </div>
      </div>
      <div v-if="displayMoreDealershipsButton" class="facetOption border-0">
        <a
          href="javascript:void(0)"
          @click="showMoreDealerships()"
          class="showMore mx-auto"
          :data-action="showMoreText"
        >
          <span>{{ showMoreText }}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="8"
            viewBox="0 0 13 8"
            fill="none"
          >
            <path
              d="M6.49974 5.05937L11.4497 0.109375L12.8637 1.52338L6.49974 7.88737L0.135742 1.52338L1.54974 0.109375L6.49974 5.05937Z"
              fill="#006FA6"
            />
          </svg>
        </a>
      </div>
      <div v-if="displayLessDealershipsButton" class="facetOption border-0">
        <a
          href="javascript:void(0)"
          @click="showLessDealerships()"
          class="showMore mx-auto"
          :data-action="showLessText"
        >
          <span>{{ showLessText }}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="8"
            viewBox="0 0 14 8"
            fill="none"
          >
            <path
              d="M7.00026 2.94062L2.05026 7.89062L0.636258 6.47662L7.00026 0.112625L13.3643 6.47662L11.9503 7.89062L7.00026 2.94062Z"
              fill="#006FA6"
            />
          </svg>
        </a>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { fetchDealerships } from '@services/dealershipSearchService';
import FacetListItem from './FacetListItem.vue';
import { resolveUrl } from '@util/routeBuilder';
import { facetMetaData } from '@util/facetHelper';
import { Dealership } from '@/types/ServiceModels';
import { mapActions, mapState } from 'pinia';
import { useCommonStore } from '@/stores/common';
import { useVehicleSearchStore } from '@/stores/vehicleSearch';

export default defineComponent({
  name: 'FacetDealershipPicker',
  data() {
    return {
      numberOfDealershipsToDisplay: 3 as number | string,
      isZipEdit: false,
    };
  },
  props: {
    label: String,
    showMoreText: String,
    showLessText: String,
  },
  components: {
    FacetListItem,
  },
  computed: {
    ...mapState(useCommonStore, ['dealerships', 'zipCode', 'nearestDealership']),
    ...mapState(useVehicleSearchStore, [
      'includedDealerships',
      'shipToStore',
      'radius',
      'queryParams',
      'sortType',
      'facetFilters',
      'shopByStore',
      'remainingParams',
    ]),

    displayMoreDealershipsButton() {
      return (
        // @ts-ignore // TODO-pinia existing error
        this.dealerships != null && this.numberOfDealershipsToDisplay < this.dealerships.length
      );
    },
    displayLessDealershipsButton() {
      return this.dealerships != null && this.numberOfDealershipsToDisplay == 'all';
    },
    layoutPath() {
      return this.$jss?.sitecoreContext()?.layoutPath;
    },
    selectedStores: {
      get() {
        return this.includedDealerships;
      },
      set(value) {
        this.setIncludedDealerships(value);
        if (this.layoutPath) {
          const params = {
            facetFilters: this.facetFilters,
            queryParams: this.queryParams,
            includedDealerships: this.includedDealerships,
            ship: this.shipToStore,
            sortType: this.sortType,
            itemPath: this.layoutPath, // set item path to layout path on reroute
            layoutPath: null,
            radius: this.radius,
            shopByStore: this.shopByStore,
            remainingParams: this.remainingParams,
            root: this.$root,
          };

          resolveUrl(this.$pinia, this.$router, params);
          window.location.reload();
        }
        this.emitter.emit('fetch-cars-srp');
      },
    },
  },
  async mounted() {
    if (this.dealerships == null) {
      let dealershipResults = (await fetchDealerships(this.zipCode)) || ([] as Dealership[]);
      if (
        dealershipResults != null &&
        dealershipResults.length > 0 &&
        dealershipResults[0].dealerId
      ) {
        this.selectedStores = [dealershipResults[0].dealerId];
      }
      this.setDealerships(dealershipResults);
      //If the nearest store is outside the currently set radius for the new zip - then set it to Nationwide and fetch cars
      if (
        this.radius > 0 &&
        this.nearestDealership.distance !== undefined &&
        this.nearestDealership.distance > this.radius
      ) {
        this.setRadius(facetMetaData['radius'].radiusMap.nationwide.value);
      }
    }
  },
  methods: {
    ...mapActions(useVehicleSearchStore, ['setIncludedDealerships', 'setRadius']),
    ...mapActions(useCommonStore, ['setDealerships']),
    getFirstDealerships: function (count) {
      if (count == 'all') {
        count = this.dealerships.length;
      }
      if (this.dealerships == null) {
        return [];
      }
      let result: Dealership[] = [];
      for (let i = 0; i < count && this.dealerships.length >= count; i++) {
        result.push(this.dealerships[i]);
      }
      return result;
    },
    showMoreDealerships: function () {
      this.numberOfDealershipsToDisplay = 'all';
      return false;
    },
    showLessDealerships: function () {
      this.numberOfDealershipsToDisplay = 3;
      return false;
    },
    isCheckboxDisabled: function (value) {
      // checkbox is disabled if there is only 1 selectedStore and this is that store
      return this.selectedStores.length === 1 && this.selectedStores.includes(value);
    },
    handleDealershipSelect: function (dealership) {
      // isSelecting if dealership is already present in selectedStores
      const isSelecting = !this.selectedStores.includes(dealership);
      if (isSelecting) {
        // selecting
        this.selectedStores = this.selectedStores.concat([dealership]);
      } else {
        // unselecting
        // don't allow unselecting the last store
        if (this.selectedStores.length > 1) {
          this.selectedStores = this.selectedStores.filter((item) => item !== dealership);
        }
      }
    },
  },
});
</script>

<style lang="scss">
.facetFilter {
  color: $park-gray-0;
  font-family: Roboto, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  max-height: 300px;

  .divFacetList {
    display: inline-block;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .facetOption {
    padding: 16px;
    border-top: 1px solid var(--base-park-gray-90, #e8e9eb);
    border-bottom: 1px solid var(--base-park-gray-90, #e8e9eb);
    background: var(--base-white, #fff);
    display: flex;
    justify-content: space-between;
  }

  .storeList {
    overflow: hidden;
    position: relative;
    height: 100%;
  }

  .scrollable-content {
    width: 100%;
    height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0;
      /* Hide the scrollbar in WebKit browsers (e.g., Chrome, Safari) */
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      /* Hide the scrollbar thumb in WebKit browsers */
    }
  }

  .showMore {
    color: $system-alert-blue-60;
    font-family: Roboto, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;

    span {
      margin-right: 4px;
    }
  }

  .filterLabel {
    font-family: Roboto, serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  div.filterLabel {
    padding-left: 24px;
    padding-bottom: 6px;
    border-bottom: 1px solid var(--shadow-white);
  }
}
</style>

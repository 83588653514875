<template>
  <div class="style-guide--forms-and-inputs">
    <v-row>
      <v-col cols="12" md="6">
        <h3>Checkboxes</h3>
        <v-checkbox :model-value="false" label="Unchecked"></v-checkbox>
        <v-checkbox :model-value="true" label="Checked"></v-checkbox>
        <v-checkbox :model-value="false" label="Disabled [Unchecked]" disabled></v-checkbox>
        <v-checkbox :model-value="true" label="Disabled [Checked]" disabled></v-checkbox>
      </v-col>
      <v-col cols="12" md="6">
        <h3>Radios</h3>
        <v-radio-group v-model="radios">
          <v-radio label="Option 01 [Selected by default]" value="one"></v-radio>
          <v-radio label="Option 02" value="two"></v-radio>
          <v-radio label="Option 03" value="three"></v-radio>
        </v-radio-group>
        <v-radio-group v-model="disabledRadios">
          <v-radio label="Option 04 [Disabled, Unselected]" value="four" disabled></v-radio>
          <v-radio label="Option 05 [Disabled, Selected]" value="five" disabled></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <h3>Toggles / Switches</h3>
        <v-switch label="Default"></v-switch>
        <v-switch label="Selected" :model-value="true"></v-switch>
        <v-switch label="Disabled" disabled></v-switch>
        <v-switch label="Disabled [Selected]" :model-value="true" disabled></v-switch>
      </v-col>
      <v-col cols="12" md="6">
        <h3>Sliders</h3>
        <v-slider v-model="slider" strict>
          <template v-slot:prepend>
            <v-label>Lower Bound</v-label>
          </template>
          <template v-slot:append>
            <v-label>Upper Bound</v-label>
          </template>
        </v-slider>
        <v-range-slider v-model="sliderRange" strict>
          <template v-slot:prepend>
            <v-label>Lower Bound</v-label>
          </template>
          <template v-slot:append>
            <v-label>Upper Bound</v-label>
          </template>
        </v-range-slider>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-row class="ma-0" no-gutters>
          <v-col cols="12">
            <h3>Box Inputs</h3>
          </v-col>
          <v-col cols="12" class="mb-8">
            <h4 class="mb-4">Extra Small</h4>
            <v-btn-toggle v-model="boxInputXs" class="box-input box-input-xs">
              <v-btn>Label</v-btn>
              <v-btn>Label</v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col cols="12" class="mb-8">
            <h4 class="mb-4">Small</h4>
            <v-btn-toggle v-model="boxInputSm" class="box-input box-input-sm">
              <v-btn>Label</v-btn>
              <v-btn>Label</v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col cols="12" class="mb-8">
            <h4 class="mb-4">Medium</h4>
            <v-btn-toggle v-model="boxInputMd" class="box-input box-input-md">
              <v-btn>
                <div class="label-start">Label</div>
                <div class="label-end">Side label</div>
              </v-btn>
              <v-btn>
                <div class="label-start">Label</div>
                <div class="label-end">Side label</div>
              </v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col cols="12" class="mb-8">
            <h4 class="mb-4">Large</h4>
            <v-btn-toggle v-model="boxInputLg" class="box-input box-input-lg">
              <v-btn>
                <div class="label-start">Label</div>
                <div class="label-end">Side label</div>
              </v-btn>
              <v-btn>
                <div class="label-start">Label</div>
                <div class="label-end">Side label</div>
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <v-row class="ma-0" no-gutters>
          <v-col cols="12">
            <h3>Segmented Controls</h3>
          </v-col>
          <v-col cols="12" class="mb-8">
            <p class="note mb-6">
              The grey/white version of this component found in the calculator is
              <strong>deprecated</strong>. Do not use the grey/white version, but instead replace
              with this updated green/white version.
            </p>
            <v-btn-toggle v-model="segmentedControl" class="segmented-control">
              <v-btn>Label</v-btn>
              <v-btn>Label</v-btn>
              <v-btn>Label</v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col cols="12">
            <h3>Logic Selectors</h3>
            <code>.logic-selector</code>
            <p class="mt-1 mb-4">
              Use the <strong>logic-selector</strong> class to properly format "label" text above a
              Logic Selector component
            </p>
          </v-col>
          <v-col cols="12" class="mb-8">
            <p class="logic-selector-label">Logic Selector Label</p>
            <v-btn-toggle v-model="toggleOne" class="logic-selector">
              <v-btn>one</v-btn>
              <v-btn>two</v-btn>
              <v-btn>three</v-btn>
              <v-btn>four</v-btn>
              <v-btn>five</v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col cols="12">
            <h3>Box Selectors</h3>
          </v-col>
          <v-col cols="12" class="mb-8">
            <v-btn-toggle v-model="boxSelector" class="box-selector">
              <v-btn>Label</v-btn>
              <v-btn>Label</v-btn>
              <v-btn>Label</v-btn>
              <v-btn>Label</v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <h3>Text Fields</h3>
        <v-text-field :rules="[rules.required]" label="First name"></v-text-field>
        <v-text-field :rules="[rules.required]" label="Last name"></v-text-field>
        <v-text-field
          label="Email address"
          placeholder="johndoe@gmail.com"
          type="email"
        ></v-text-field>
        <v-text-field label="Disabled Input" model-value="Filled out" disabled></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <h3>Selects</h3>
        <v-select
          label="Select a number"
          :items="[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]"
          :rules="[rules.required]"
        ></v-select>
        <v-select
          label="State"
          :items="['California', 'Colorado', 'Florida', 'Georgia', 'Texas', 'Wyoming']"
          :rules="[rules.required]"
        ></v-select>
        <v-select
          label="Disabled Select"
          :items="['California', 'Colorado', 'Florida', 'Georgia', 'Texas', 'Wyoming']"
          disabled
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <h3>Textareas</h3>
        <v-textarea label="Please provide your comments" class="mb-4"></v-textarea>
        <!--        <v-textarea label="This textarea is disabled" disabled></v-textarea>-->
      </v-col>
      <v-col cols="12" md="6">
        <h3>&nbsp;</h3>
        <div class="mb-4">
          <v-textarea
            :rules="[rules.required]"
            label="This textarea is required"
            model-value="This is pre-filled"
          ></v-textarea>
        </div>
        <div class="mb-4">
          <v-textarea :rules="[rules.required]" label="This textarea is required"></v-textarea>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'StyleGuide--Colors',
  components: {},
  data() {
    return {
      boxInputXs: null,
      boxInputSm: null,
      boxInputMd: null,
      boxInputLg: null,
      boxSelector: 1,
      disabledRadios: 'five',
      radios: 'one',
      rules: {
        required: (value) => !!value || 'Field is required',
      },
      segmentedControl: 1,
      slider: [35],
      sliderRange: [20, 70],
      toggleOne: 2,
    };
  },
});
</script>

<style lang="scss">
.style-guide--forms-and-inputs {
  h3 {
    margin-bottom: 20px;
  }
  .box-input-small {
    display: flex;
    justify-content: space-between;
    gap: 8px;

    .v-btn {
      width: calc(50% - 4px) !important;
    }
  }
}
</style>

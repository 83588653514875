<template>
  <div class="style-guide--colors">
    <v-row>
      <v-col cols="12">
        <h3>Base Colors</h3>
      </v-col>
      <v-col cols="12">
        <h4>Primary</h4>
      </v-col>
      <v-col cols="12">
        <div class="box base-color-primary-pure"></div>
        <code>$base-color-primary-pure</code>
      </v-col>
      <v-col cols="12">
        <v-row class="color-variants" justify="start">
          <v-col>
            <div class="box base-color-primary-lt"></div>
            <code>$base-color-primary-lt</code>
          </v-col>
          <v-col>
            <div class="box base-color-primary-xmed"></div>
            <code>$base-color-primary-xmed</code>
          </v-col>
          <v-col>
            <div class="box base-color-primary-med"></div>
            <code>$base-color-primary-med</code>
          </v-col>
          <v-col>
            <div class="box base-color-primary-dark"></div>
            <code>$base-color-primary-dark</code>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <h4>Secondary</h4>
      </v-col>
      <v-col cols="12">
        <div class="box base-color-secondary-pure"></div>
        <code>$base-color-secondary-pure</code>
      </v-col>
      <v-col cols="12">
        <v-row class="color-variants" justify="start">
          <v-col>
            <div class="box base-color-secondary-med"></div>
            <code>$base-color-secondary-med</code>
          </v-col>
          <v-col>
            <div class="box base-color-secondary-dark"></div>
            <code>$base-color-secondary-dark</code>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h3>Accent Colors</h3>
      </v-col>
      <v-col cols="12">
        <div class="box accent-color-pure"></div>
        <code>$accent-color-pure</code>
      </v-col>
      <v-col cols="12">
        <v-row class="color-variants" justify="start">
          <v-col>
            <div class="box accent-color-lt"></div>
            <code>$accent-color-lt</code>
          </v-col>
          <v-col>
            <div class="box accent-color-med"></div>
            <code>$accent-color-med</code>
          </v-col>
          <v-col>
            <div class="box accent-color-dark"></div>
            <code>$accent-color-dark</code>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h3>Neutral Colors</h3>
      </v-col>
      <v-col cols="12">
        <h4>Low</h4>
      </v-col>
      <v-col cols="12">
        <div class="box neutral-color-low-pure"></div>
        <code>$neutral-color-low-pure</code>
      </v-col>
      <v-col cols="12">
        <v-row class="color-variants" justify="start">
          <v-col>
            <div class="box neutral-color-low-lt"></div>
            <code>$neutral-color-low-lt</code>
          </v-col>
          <v-col>
            <div class="box neutral-color-low-med"></div>
            <code>$neutral-color-low-med</code>
          </v-col>
          <v-col>
            <div class="box neutral-color-low-dark"></div>
            <code>$neutral-color-low-dark</code>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <h4>High</h4>
      </v-col>
      <v-col cols="12">
        <div class="box neutral-color-high-pure"></div>
        <code>$neutral-color-high-pure</code>
      </v-col>
      <v-col cols="12">
        <v-row class="color-variants" justify="start">
          <v-col>
            <div class="box neutral-color-high-xlt"></div>
            <code>$neutral-color-high-xlt</code>
          </v-col>
          <v-col>
            <div class="box neutral-color-high-lt"></div>
            <code>$neutral-color-high-lt</code>
          </v-col>
          <v-col>
            <div class="box neutral-color-high-med"></div>
            <code>$neutral-color-high-med</code>
          </v-col>
          <v-col>
            <div class="box neutral-color-high-dark"></div>
            <code>$neutral-color-high-dark</code>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h3>Background Colors</h3>
      </v-col>
      <v-col cols="12">
        <h4>Low</h4>
      </v-col>
      <v-col cols="12">
        <div class="box bg-color-low-pure"></div>
        <code>$bg-color-low-pure</code>
      </v-col>
      <v-col cols="12">
        <v-row class="color-variants" justify="start">
          <v-col>
            <div class="box bg-color-low-xlt"></div>
            <code>$bg-color-low-xlt</code>
          </v-col>
          <v-col>
            <div class="box bg-color-low-lt"></div>
            <code>$bg-color-low-lt</code>
          </v-col>
          <v-col>
            <div class="box bg-color-low-xmed"></div>
            <code>$bg-color-low-xmed</code>
          </v-col>
          <v-col>
            <div class="box bg-color-low-med"></div>
            <code>$bg-color-low-med</code>
          </v-col>
          <v-col>
            <div class="box bg-color-low-dark"></div>
            <code>$bg-color-low-dark</code>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <h4>High</h4>
      </v-col>
      <v-col cols="12">
        <div class="box bg-color-high-pure"></div>
        <code>$bg-color-high-pure</code>
      </v-col>
      <v-col cols="12">
        <v-row class="color-variants" justify="start">
          <v-col>
            <div class="box bg-color-high-xlt"></div>
            <code>$bg-color-high-xlt</code>
          </v-col>
          <v-col>
            <div class="box bg-color-high-lt"></div>
            <code>$bg-color-high-lt</code>
          </v-col>
          <v-col>
            <div class="box bg-color-high-med"></div>
            <code>$bg-color-high-med</code>
          </v-col>
          <v-col>
            <div class="box bg-color-high-dark"></div>
            <code>$bg-color-high-dark</code>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h3>Feedback Colors</h3>
      </v-col>
      <v-col cols="12">
        <h4>Warning</h4>
      </v-col>
      <v-col cols="12">
        <div class="box feedback-color-warning-pure"></div>
        <code>$feedback-color-warning-pure</code>
      </v-col>
      <v-col cols="12">
        <v-row class="color-variants" justify="start">
          <v-col>
            <div class="box feedback-color-warning-lt"></div>
            <code>$feedback-color-warning-lt</code>
          </v-col>
          <v-col>
            <div class="box feedback-color-warning-dark"></div>
            <code>$feedback-color-warning-dark</code>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <h4>Alert</h4>
      </v-col>
      <v-col cols="12">
        <div class="box feedback-color-alert-pure"></div>
        <code>$feedback-color-alert-pure</code>
      </v-col>
      <v-col cols="12">
        <v-row class="color-variants" justify="start">
          <v-col>
            <div class="box feedback-color-alert-lt"></div>
            <code>$feedback-color-alert-lt</code>
          </v-col>
          <v-col>
            <div class="box feedback-color-alert-med"></div>
            <code>$feedback-color-alert-med</code>
          </v-col>
          <v-col>
            <div class="box feedback-color-alert-dark"></div>
            <code>$feedback-color-alert-dark</code>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'StyleGuide--Colors',
  components: {},
  data() {
    return {};
  },
});
</script>

<style lang="scss">
.style-guide--colors {
  .v-row.color-variants {
    margin: 0;

    & > .v-col {
      padding-top: 0;
      padding-right: 0;
      padding-left: 0;
      flex-grow: unset;
      flex-basis: 100%;

      @include media-breakpoint-up(md) {
        flex-basis: auto;
        width: 249px !important;
      }
    }
  }

  .box {
    width: 100% !important;

    @include media-breakpoint-up(md) {
      width: 249px !important;
    }
  }

  // base primary
  .base-color-primary-pure {
    background: $green-70;
  }
  .base-color-primary-lt {
    background: $green-90;
  }
  .base-color-primary-xmed {
    background: $green-60;
  }
  .base-color-primary-med {
    background: $green-50;
  }
  .base-color-primary-dark {
    background: $green-40;
  }

  // base secondary
  .base-color-secondary-pure {
    background: $park-gray-10;
  }
  .base-color-secondary-med {
    background: $park-gray-20;
  }
  .base-color-secondary-dark {
    background: $park-gray-0;
  }

  // accent
  .accent-color-pure {
    background: $system-blue-80;
  }
  .accent-color-lt {
    background: $system-alert-blue-90;
  }
  .accent-color-med {
    background: $system-alert-blue-60;
  }
  .accent-color-dark {
    background: $supplementary-blue-50;
  }

  // neutral low
  .neutral-color-low-pure {
    background: $neutral-color-low-pure;
  }
  .neutral-color-low-lt {
    background: $park-gray-60;
  }
  .neutral-color-low-med {
    background: $neutral-color-low-med;
  }
  .neutral-color-low-dark {
    background: $neutral-color-low-dark;
  }

  // neutral high
  .neutral-color-high-pure {
    background: $base-white-100;
  }
  .neutral-color-high-xlt {
    background: $park-gray-100;
  }
  .neutral-color-high-lt {
    background: $park-gray-90;
  }
  .neutral-color-high-med {
    background: $park-gray-80;
  }
  .neutral-color-high-dark {
    background: $park-gray-70;
  }

  // background low
  .bg-color-low-pure {
    background: $accent-warm-gray-60;
  }
  .bg-color-low-xlt {
    background: $accent-warm-gray-100;
  }
  .bg-color-low-lt {
    background: $accent-warm-gray-90;
  }
  .bg-color-low-xmed {
    background: $accent-warm-gray-80;
  }
  .bg-color-low-med {
    background: $accent-warm-gray-70;
  }
  .bg-color-low-dark {
    background: $accent-warm-gray-50;
  }

  // background high
  .bg-color-high-pure {
    background: $accent-tan-60;
  }
  .bg-color-high-xlt {
    background: $accent-tan-90;
  }
  .bg-color-high-lt {
    background: $accent-tan-80;
  }
  .bg-color-high-med {
    background: $accent-tan-70;
  }
  .bg-color-high-dark {
    background: $accent-tan-50;
  }

  // feedback warning
  .feedback-color-warning-pure {
    background: $supplementary-red-60;
  }
  .feedback-color-warning-lt {
    background: $supplementary-red-90;
  }
  .feedback-color-warning-dark {
    background: $supplementary-red-50;
  }

  // feedback alert
  .feedback-color-alert-pure {
    background: $feedback-color-alert-pure;
  }
  .feedback-color-alert-lt {
    background: $supplementary-yellow-90;
  }
  .feedback-color-alert-med {
    background: $supplementary-yellow-60;
  }
  .feedback-color-alert-dark {
    background: $supplementary-yellow-50;
  }
}
</style>

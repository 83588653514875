<template>
  <v-row class="style-guide--opacity-levels">
    <v-col cols="12" md="6" lg="4" xl="3" xxl="2">
      <div class="boxes">
        <div class="box green top"></div>
        <div class="box black middle opacity-level-semiopaque"></div>
        <div class="box green bottom"></div>
      </div>
      <code>.opacity-level-semiopaque</code>
      <code>$opacity-level-semiopaque</code>
    </v-col>
    <v-col cols="12" md="6" lg="4" xl="3" xxl="2">
      <div class="boxes">
        <div class="box green top"></div>
        <div class="box black middle opacity-level-intense"></div>
        <div class="box green bottom"></div>
      </div>
      <code>.opacity-level-intense</code>
      <code>$opacity-level-intense</code>
    </v-col>
    <v-col cols="12" md="6" lg="4" xl="3" xxl="2">
      <div class="boxes">
        <div class="box green top"></div>
        <div class="box black middle opacity-level-medium"></div>
        <div class="box green bottom"></div>
      </div>
      <code>.opacity-level-medium</code>
      <code>$opacity-level-medium</code>
    </v-col>
    <v-col cols="12" md="6" lg="4" xl="3" xxl="2">
      <div class="boxes">
        <div class="box green top"></div>
        <div class="box black middle opacity-level-light"></div>
        <div class="box green bottom"></div>
      </div>
      <code>.opacity-level-light</code>
      <code>$opacity-level-light</code>
    </v-col>
    <v-col cols="12" md="6" lg="4" xl="3" xxl="2">
      <div class="boxes">
        <div class="box green top"></div>
        <div class="box black middle opacity-level-semitransparent"></div>
        <div class="box green bottom"></div>
      </div>
      <code>.opacity-level-semitransparent</code>
      <code>$opacity-level-semitransparent</code>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'StyleGuide--OpacityLevels',
  components: {},
  data() {
    return {};
  },
});
</script>

<style lang="scss">
.style-guide--opacity-levels {
  .boxes {
    position: relative;
    height: 106px;
  }
  .box {
    &.green {
      background: $green-70;
    }
    &.top {
      position: absolute;
      top: 0;
      left: 0;
    }
    &.middle {
      position: absolute;
      top: 16px;
      left: 16px;
      z-index: 99;
    }
    &.bottom {
      position: absolute;
      top: 32px;
      left: 32px;
    }
  }
  code {
    white-space: nowrap;
  }
}
</style>

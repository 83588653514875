<template>
  <div @click="$emit('click')" @keyup.enter="$emit('click')" class="filter-list-item" tabindex="0">
    <label
      class="facet-name"
      :class="{ 'selected-filter': isSelected }"
      :data-action-select-filter="title"
      >{{ title }}</label
    >
    <button class="btn btn-sm px-4" tabindex="-1">
      <img
        src="@icons/Path_filtermain.svg"
        class="arrow-icon"
        :alt="$t('srp.common.rightArrowIcon')"
      />
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    isSelected: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
  },
  emits: ['click'],
  name: 'FilterMenuLineItem',
});
</script>

<style lang="scss">
.filter-list-item {
  color: $park-gray-0;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  padding: 16px 6px 15px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dee2e6;

  &:hover {
    transition: 0.3s;
    background-color: rgb(242, 242, 242);
  }
  &:focus-visible {
    outline-offset: -3px;
  }
  .btn {
    margin: -10px 0;
    padding: 11px 14px !important;
  }
  .arrow-icon {
    fill: var(--park-gray-0);
    height: 14px;
  }
  .facet-name {
    cursor: pointer;
  }
  .selected-filter {
    font-weight: bold;
  }
}
</style>

<template>
  <div class="sca-form-header">
    <v-row no-gutters>
      <v-col cols="12">
        <h6 class="sca-form-title">{{ title }}</h6>
      </v-col>
    </v-row>
    <v-row v-if="note || $slots.note" no-gutters>
      <v-col cols="12">
        <slot name="note">
          <sc-rich-text :field="note" class="sca-header-note" />
        </slot>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import RichText from '@components-vue/Foundation/Common/RichText';
import { TextField } from '@/lib/FieldTypes';

export default defineComponent({
  name: 'FormHeader',
  props: {
    title: {
      type: String,
      required: true,
    },
    note: {
      type: Object as PropType<TextField>,
    },
  },
  components: {
    ScRichText: RichText,
  },
});
</script>

<style lang="scss">
@use '@/assets/styles/variables' as *;

.sca-form-header {
  .sca-form-header {
    margin-bottom: $spacing_stack-xxs;
  }
  .sca-form-title {
    color: $park-gray-0;
    line-height: $line-height-default;
  }
  .sca-header-note {
    font-size: $font-size-xxxs;
    margin-top: $spacing_stack-nano;
  }
}
</style>

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PersonalDetailsForm = _resolveComponent("PersonalDetailsForm")!

  return (_openBlock(), _createBlock(_component_PersonalDetailsForm, {
    class: "sca-co-applicant-personal-details",
    fields: _ctx.fields,
    ref: "formComponent",
    showCoApplicantCheckbox: true,
    isCobuyer: true
  }, null, 8, ["fields"]))
}
<template>
  <div class="panel-content">
    <v-row>
      <v-col cols="12">
        <p class="store-header">{{ storeName }}</p>
        <p class="small">
          {{ store?.address }}<br />
          {{ store?.city }}, {{ store?.state }} {{ store?.zip }}
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useCommonStore } from '@/stores/common';
import { useStandaloneCreditAppStore } from '@/stores/standaloneCreditApp';
import { mapState } from 'pinia';

export default defineComponent({
  name: 'Submit--Store',
  components: {},
  methods: {},
  computed: {
    ...mapState(useCommonStore, ['dealerships']),
    ...mapState(useStandaloneCreditAppStore, ['selectedDealership']),
    storeName() {
      return this.store ? 'Echo Park ' + this.store.storeName : 'Echo Park';
    },
    store() {
      return (
        this.dealerships.find(
          (dealership) => dealership.storeId === this.selectedDealership?.storeId
        ) || null
      );
    },
  },
});
</script>

<style lang="scss"></style>

<template>
  <v-container>
    <v-row>
      <v-col>
        <v-table>
          <thead>
            <tr>
              <th>ContactUsTitle</th>
              <th>VisitLocationLink</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><sc-text :field="fields.ContactUsTitle" /></td>
              <td><sc-link :field="fields.VisitLocationLink" /></td>
            </tr>
          </tbody>
        </v-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Text, Link, ComponentRendering } from '@sitecore-jss/sitecore-jss-vue';
import { FieldsPropType } from '@/lib/FieldTypes';

export default defineComponent({
  name: 'ContactUsOverlay',
  components: {
    ScText: Text,
    ScLink: Link,
  },
  props: {
    fields: {
      type: Object as PropType<FieldsPropType>,
      default: () => ({}),
    },
    rendering: {
      type: Object as PropType<ComponentRendering>,
      default: () => ({}),
    },
    params: {
      type: Object,
      default: () => ({}),
    },
    errorComponent: {
      type: Object,
      default: () => ({}),
    },
  },
});
</script>

<style lang="scss"></style>

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "fixed-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_placeholder = _resolveComponent("placeholder")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, {
    class: "two-col-layout pa-0",
    fluid: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { "no-gutters": "" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            md: "6",
            lg: "5",
            class: _normalizeClass([_ctx.layoutClasses[0], "left-col"])
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_placeholder, {
                  name: "left-content",
                  rendering: _ctx.rendering
                }, null, 8, ["rendering"])
              ])
            ]),
            _: 1
          }, 8, ["class"]),
          _createVNode(_component_v_col, {
            cols: "12",
            md: "6",
            lg: "7",
            class: _normalizeClass([_ctx.layoutClasses[1], "right-col"])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_placeholder, {
                name: "right-content",
                rendering: _ctx.rendering
              }, null, 8, ["rendering"])
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
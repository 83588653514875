import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_not_found = _resolveComponent("not-found")!
  const _component_layout = _resolveComponent("layout")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      (_ctx.notFound && !_ctx.loading && !_ctx.languageIsChanging)
        ? (_openBlock(), _createBlock(_component_not_found, {
            key: 0,
            context: _ctx.appState.sitecoreContext
          }, null, 8, ["context"]))
        : (_openBlock(), _createBlock(_component_layout, {
            key: _ctx.layoutKey,
            route: _ctx.layoutData
          }, null, 8, ["route"]))
    ]),
    _: 1
  }))
}
<template>
  <v-row class="style-guide--breakpoints">
    <v-col>
      <v-table>
        <thead>
          <tr>
            <th>Breakpoint</th>
            <th>Min</th>
            <th>Max</th>
            <th>Can Use in Media Query</th>
            <th>
              Can Use in
              <a href="https://vuetifyjs.com/en/components/grids/#usage" target="_blank">Grid</a>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>xs</td>
            <td>0</td>
            <td>374px</td>
            <td><v-icon icon="ep-icon-check" color="success" /></td>
            <td><v-icon icon="ep-icon-check" color="success" /></td>
          </tr>
          <tr>
            <td>375</td>
            <td>375px</td>
            <td>575px</td>
            <td><v-icon icon="ep-icon-check" color="success" /></td>
            <td><v-icon icon="ep-icon-close" color="error" /></td>
          </tr>
          <tr>
            <td>sm</td>
            <td>576px</td>
            <td>624px</td>
            <td><v-icon icon="ep-icon-check" color="success" /></td>
            <td><v-icon icon="ep-icon-check" color="success" /></td>
          </tr>
          <tr>
            <td>625</td>
            <td>625px</td>
            <td>767px</td>
            <td><v-icon icon="ep-icon-check" color="success" /></td>
            <td><v-icon icon="ep-icon-close" color="error" /></td>
          </tr>
          <tr>
            <td>md</td>
            <td>768px</td>
            <td>1023px</td>
            <td><v-icon icon="ep-icon-check" color="success" /></td>
            <td><v-icon icon="ep-icon-check" color="success" /></td>
          </tr>
          <tr>
            <td>lg</td>
            <td>1024px</td>
            <td>1239px</td>
            <td><v-icon icon="ep-icon-check" color="success" /></td>
            <td><v-icon icon="ep-icon-check" color="success" /></td>
          </tr>
          <tr>
            <td>xl</td>
            <td>1240px</td>
            <td>1439px</td>
            <td><v-icon icon="ep-icon-check" color="success" /></td>
            <td><v-icon icon="ep-icon-check" color="success" /></td>
          </tr>
          <tr>
            <td>xxl</td>
            <td>1440px</td>
            <td>1919px</td>
            <td><v-icon icon="ep-icon-check" color="success" /></td>
            <td><v-icon icon="ep-icon-check" color="success" /></td>
          </tr>
          <tr>
            <td>1920</td>
            <td>1920px</td>
            <td>&infin;</td>
            <td><v-icon icon="ep-icon-check" color="success" /></td>
            <td><v-icon icon="ep-icon-close" color="error" /></td>
          </tr>
        </tbody>
      </v-table>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'StyleGuide--Breakpoints',
  components: {},
  data() {
    return {};
  },
});
</script>

<style lang="scss">
@use '@/assets/styles/variables' as *;

.style-guide--breakpoints {
  .v-table {
    border-radius: $border-radius-md;
    box-shadow: $shadow-level-1;
    max-width: 600px;
  }
  thead {
    background: $base-black-100;
    color: $base-white-100;
  }
  th {
    text-align: center !important;

    a {
      color: $base-white-100;
      text-decoration: underline;
      font-weight: $font-weight-bold;
    }
  }
  td {
    text-align: center;

    &:first-of-type {
      font-weight: $font-weight-bold;
    }
  }
}
</style>

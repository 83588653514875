<template>
  <StandaloneCreditAppController>
    <div class="sca-container">
      <div class="sca-sub-nav">
        <div class="head-links" :class="{ 'justify-space-between': isFormStep }">
          <RouterLink v-if="isFormStep" :to="prevStep" class="sca-back" data-testid="sca-back">
            <img src="@icons/Path_filtermain.svg" class="arrow-back-icon" />
            Back
          </RouterLink>
          <a
            href="#"
            data-testid="sca-exit"
            @click.prevent="emitter.emit('standalone-credit-app:exit')"
            >Exit</a
          >
        </div>
        <div v-if="isFormStep" class="sca-title-wrap">
          <h2 class="title-sm">{{ headerText }}</h2>
        </div>
        <div class="sca-progress-wrap" v-if="isFormStep">
          <v-progress-circular
            :model-value="stepPercent"
            size="90"
            color="#62A60A"
            width="7"
            class="sca-stepper"
            data-testid="sca-progress"
            ><span class="current-step" data-testid="sca-current-step">{{ currentStepCount }}</span
            ><span class="px-1">of</span>
            <span class="total-steps" data-testid="sca-total-steps">{{
              totalStepCount
            }}</span></v-progress-circular
          >
          <div class="sca-stepper-title">
            <h2 class="sca-current-step-header">{{ currentStepDetails?.formTitle }}</h2>
            <h3 class="sca-current-step-next" v-if="!isLastFormStep">
              {{ nextStepTemplate }}
            </h3>
          </div>
        </div>
      </div>

      <v-alert
        v-if="(isStartStep || isFormStep) && stepErrors.length > 0"
        class="sca-error-style"
        data-testid="sca-error-alert"
      >
        <template v-slot:prepend>
          <v-icon size="24">mdi-alert</v-icon>
        </template>
        {{ errorDisplayTemplate }}
      </v-alert>

      <placeholder
        name="standalone-credit-app-form"
        :rendering="rendering"
        ref="scaFormPlaceholder"
      />

      <div class="sca-sticky-foot-cta" v-if="isFormStep && hasStickyFoot">
        <div class="sca-sticky-btn-container">
          <v-btn
            data-testid="sca-continue-sticky-btn"
            block
            @click.prevent="emitter.emit('sca-validate', navigateForward)"
          >
            {{ $t('continue-button') }}<v-icon class="ml-2">mdi-arrow-right</v-icon>
          </v-btn>
        </div>
      </div>
      <div class="sca-foot-cta" v-else-if="isFormStep">
        <v-btn
          data-testid="sca-continue-btn"
          @click.prevent="emitter.emit('sca-validate', navigateForward)"
          class="mt-3"
          :loading="isLoading"
        >
          {{ $t('continue-button') }}<v-icon class="ml-2">mdi-arrow-right</v-icon>
        </v-btn>
      </div>

      <div class="sca-foot-cta mt-3" v-if="isDebugging && isFormStep">
        <v-btn @click.prevent="navigateForward" variant="outlined">
          Bypass <v-icon class="ml-2">mdi-arrow-right</v-icon>
        </v-btn>
      </div>
    </div>
  </StandaloneCreditAppController>
</template>

<script lang="ts">
import StandaloneCreditAppController from '@components-vue/Listeners/StandaloneCreditAppController.vue';
import { defineComponent, PropType } from 'vue';
import { ComponentRendering, Placeholder, Field } from '@sitecore-jss/sitecore-jss-vue';
import { mapState, mapActions } from 'pinia';
import { useStandaloneCreditAppStore } from '@/stores/standaloneCreditApp';
import { fetchOverlayRouteData } from '@/util/overlayUtils';
import { FieldsPropType } from '@/lib/FieldTypes';

export default defineComponent({
  name: 'StandaloneCreditAppContainer',
  props: {
    fields: {
      type: Object as PropType<FieldsPropType>,
      default: () => ({}),
    },
    rendering: {
      type: Object as PropType<ComponentRendering>,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  components: {
    Placeholder,
    StandaloneCreditAppController,
  },
  computed: {
    ...mapState(useStandaloneCreditAppStore, [
      'nextStep',
      'prevStep',
      'isFormStep',
      'currentStepCount',
      'totalStepCount',
      'currentStepDetails',
      'stepPercent',
      'stepErrors',
      'isStoreStep',
      'isStartStep',
      'isLoading',
      'isDebugging',
    ]),
    hasStickyFoot() {
      return this.isStoreStep;
    },
    isLastFormStep() {
      return this.currentStepCount === this.totalStepCount;
    },
    nextStepTemplate() {
      const template = this.routeFields['nextStep']?.value ?? '';
      return template.replace('##stepName##', this.currentStepDetails?.nextStep ?? '');
    },
    errorDisplayTemplate() {
      const template = this.isFormStep
        ? (this.routeFields['errorMessaging']?.value ?? '')
        : '##errors##';

      const errorDisplay = this.stepErrors.join(', ');
      return template.replace('##errors##', errorDisplay);
    },
    routeFields() {
      const fields = this.$jss.store.state.routeData.fields['StartPage']?.fields as Array<Field>;
      return fields;
    },
    headerText() {
      const headerPlaceholder =
        this.$jss.store.state.routeData.placeholders['jss-header'][0]?.fields?.['header-label']
          ?.value ?? '';
      return headerPlaceholder;
    },
  },
  methods: {
    ...mapActions(useStandaloneCreditAppStore, ['clearErrors', 'setLoading']),
    fetchOverlayRouteData,
    async handleExitClick() {
      const exitOverlayName = 'StandaloneCreditApp';
      // if a form step, show the modal, otherwise no modal and the link exits directly
      if (this.isFormStep) {
        this.emitter.emit('overlay-handler:open', exitOverlayName);
      } else {
        // in this case, we need to fetch the exit redirect path from the exit overlay
        const routeData = await this.fetchOverlayRouteData(exitOverlayName);
        const exitCtaPath =
          routeData?.['placeholders']?.['overlays']?.[0]?.['fields']?.['CtaLink']?.value?.['href'];

        // here we need to get the cta path and redirect there
        window.location.href = exitCtaPath;
      }
    },
    handleExitRedirect(path: string) {
      if (path) {
        window.location.href = path;
      }
    },
    async navigateForward(): Promise<void> {
      if (this.nextStep) {
        await this.$router.push(this.nextStep);
        this.setLoading(false);
      }
    },
  },
  mounted() {
    this.emitter.on('exit-overlay:cta-click', this.handleExitRedirect);
    this.emitter.on('standalone-credit-app:exit', this.handleExitClick);

    this.clearErrors();
  },
  unmounted() {
    this.emitter.off('standalone-credit-app:exit', this.handleExitClick);
    this.emitter.off('exit-overlay:cta-click', this.handleExitRedirect);
  },
});
</script>
<style lang="scss">
@use '@/assets/styles/variables' as *;
@use 'sass:math';

.sca-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .sca-error-style {
    background-color: $supplementary-red-90;
    color: $park-gray-0;
    border: $border-width-thin solid $supplementary-red-50;
    margin-bottom: $spacing_stack-xxs;
    border-radius: $border-radius-lg;

    .v-icon {
      color: $supplementary-red-50;
    }
    .v-alert__prepend {
      margin-inline-end: 8px;
    }
  }

  .sca-sub-nav {
    .sca-title-wrap {
      @include media-breakpoint-up(md) {
        display: none;
      }

      .title-sm {
        color: $park-gray-0;
        font-size: $font-size-lg;
        font-weight: $font-weight-light;
        text-align: center;
      }
    }

    .head-links {
      display: flex;
      justify-content: end;
      margin-bottom: 8px;

      .sca-back {
        display: inline-flex;
        align-items: center;
      }

      a {
        color: $system-alert-blue-60;
        font-size: $font-size-xxxs;
        font-weight: $font-weight-medium;
      }

      .arrow-back-icon {
        filter: brightness(0) saturate(100%) invert(18%) sepia(91%) saturate(3047%)
          hue-rotate(184deg) brightness(99%) contrast(102%);
        height: 14px;
        transform: rotate(180deg);
        margin-right: 8px;
      }
    }

    .sca-progress-wrap {
      display: flex;
      margin-top: $spacing_stack-xxs;
      margin-bottom: $spacing_stack-xxs;
    }

    @include media-breakpoint-up(md) {
      .sca-progress-wrap {
        margin-top: $spacing_stack-xxxs;
        margin-bottom: 26px;
      }
    }

    .sca-current-step-header {
      font-size: $font-size-md;
      font-weight: $font-weight-bold;
      color: $park-gray-0;
    }

    .sca-current-step-next {
      font-size: $font-size-xxxs;
      font-weight: $font-weight-regular;
      color: $park-gray-20;
    }

    .sca-stepper-title {
      display: flex;
      flex-direction: column;
      align-self: center;
      margin-left: 16px;
    }
  }

  .sca-sticky-foot-cta {
    position: fixed;
    width: 100%;
    bottom: 0;
    padding: 24px;
    background: $base-white-100;
    box-shadow: 0px -4px 6px 0px rgba(36, 44, 51, 0.12);
    margin-left: -24px;
    margin-right: -24px;

    @include media-breakpoint-up(md) {
      width: 50%;
      margin-left: -$spacing_inline-xxs;
      margin-right: -$spacing_inline-xxs;
      display: flex;
      justify-content: flex-end;

      .sca-sticky-btn-container {
        width: fit-content;
      }
    }
    @include media-breakpoint-up(lg) {
      width: math.div(100%, 12) * 7;
      margin-left: -$spacing_inline-sm;
      margin-right: -$spacing_inline-sm;
    }
    @include media-breakpoint-up(xl) {
      width: 800px;
    }
  }

  .sca-foot-cta {
    display: flex;
    justify-content: flex-end;

    .v-btn {
      width: 100%;

      @include media-breakpoint-up(sm) {
        width: auto;
      }
    }
  }
  .sca-stepper {
    .v-progress-circular__content {
      color: $park-gray-0;
      align-items: baseline;
    }

    .current-step {
      font-size: $font-size-md;
      font-weight: $font-weight-bold;
    }

    .total-steps {
      font-size: $font-size-xxxs;
      font-weight: $font-weight-regular;
    }
  }
}
</style>

<template>
  <div></div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'StandaloneCreditAppSchemaForm',
  props: {},
  components: {},
  computed: {},
  methods: {},
  data() {
    return {};
  },
});
</script>

<style lang="scss"></style>

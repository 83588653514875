import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lord_icon = _resolveComponent("lord-icon")!

  return (_openBlock(), _createBlock(_component_lord_icon, {
    src: "https://cdn.lordicon.com/utwelszo.json",
    trigger: "loop",
    delay: "1000",
    colors: "primary:#3e8500,secondary:#f42434,tertiary:#666b70,quaternary:#f4f5f7",
    style: {"width":"144px","height":"144px"}
  }))
}
<template>
  <!-- There are two variations of this page, the intro or the form variation
   The intro has a single image centered at the bottom. The form variation has an image and text centered x and y -->
  <div class="sca-fullpage-illustration">
    <sc-image :media="fields.image_file" />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { StartImage } from '@/lib/CommonDatasourceModels';
import { Image } from '@sitecore-jss/sitecore-jss-vue';

export default defineComponent({
  name: 'FullPageIllustration',
  props: {
    fields: {
      type: Object as PropType<StartImage>,
      default: () => ({}),
    },
  },
  components: {
    ScImage: Image,
  },
});
</script>

<style lang="scss">
.sca-fullpage-illustration {
  background-color: $park-gray-100;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;

  img {
    width: 100%;
  }
}
</style>

<template>
  <div class="chat-box chat-pos">
    <button
      type="button"
      role="button"
      tabindex="0"
      class="cn-b13-btn cn-b13-chat"
      data-name="Chat Icon Button"
      title="Chat"
      aria-label="Chat"
      @click="toggleChat"
    >
      <sc-image :media="fields.ChatIconImage" height="48" width="48" />
    </button>
    <button
      type="button"
      role="button"
      tabindex="0"
      class="cn-b13-btn cn-b13-hide cn-b13-x cn-b13-x-chat"
      title="Close"
      aria-label="Close"
      @click="toggleChat"
    >
      <sc-image :media="fields.ChatCloseIconImage" />
    </button>
  </div>
</template>

<script lang="ts">
import { Image } from '@sitecore-jss/sitecore-jss-vue';
import { defineComponent, PropType } from 'vue';
import { FieldsPropType } from '@/lib/FieldTypes';

export default defineComponent({
  name: 'Chat',
  props: {
    fields: {
      type: Object as PropType<FieldsPropType>,
      default: () => ({}),
    },
  },
  components: {
    ScImage: Image,
  },
  mounted() {
    let self = this;
    this.$nextTick(() => {
      (function (d, s, id) {
        let js,
          cnjs = d.getElementsByTagName('head')[0];

        if (d.getElementById(id)) return;

        js = d.createElement(s);
        js.id = id;
        js.src = self.fields.CarNowKey.value;
        js.async = true;

        // @ts-ignore
        cnjs.appendChild(js, cnjs);

        js.onload = function () {
          // @ts-ignore
          CarNowPlugin.init();
        };
      })(document, 'script', 'com-carnow-plugin');
    });
  },
  methods: {
    toggleChat: function (el: any) {
      // @ts-ignore
      if (typeof CNPC != 'undefined') {
        // @ts-ignore
        CNPC.toggleChat(el);
      } else {
        console.error('CNPC [carnow-plugin] is not loaded.');
      }

      return false;
    },
  },
});
</script>

<style lang="scss">
.cn-wid-buttons {
  display: none;
}
.live-chat {
  width: 48px;
  height: 48px;
  display: block;
  border: 0;
  background: 0;
  position: relative;
}

.live-chat img {
  position: relative;
  z-index: 1;
  width: 100%;
}

.live-chat::before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: 0 2px 6px 0 rgba(110, 80, 80, 0.5);
  border-radius: 100%;
}

.cn-b13-container .cn-b13-btn {
  width: 48px !important;
  height: 48px !important;
  background-color: var(--supplementary-blue-blue-60);
  border-radius: 100% !important;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2) !important;
}

.cn-b13-container .cn-b13-btn:hover {
  animation: none !important;
}

.chat-box {
  width: 48px;
  height: 48px;
  position: relative;
}

.chat-box .cn-b13-btn {
  display: block !important;
  width: 48px !important;
  height: 48px !important;
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 0 !important;
  border-radius: 0 !important;
  border: none !important;
  margin: 0;
}

.chat-box .cn-b13-btn:hover {
  animation: none !important;
}

.chat-box .cn-b13-btn.cn-b13-hide {
  display: none !important;
}

.chat-pos {
  position: fixed;
  right: 8px;
  bottom: 8px;
  z-index: 7;
}

@media (min-width: 992px) {
  .chat-pos {
    right: 16px;
    bottom: 16px;
  }
}
</style>

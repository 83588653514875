<template>
  <!-- horizontal spacing -->
  <v-row id="horizontal-spacing" class="style-guide--spacing-horizontal">
    <v-col cols="12">
      <h2 class="style-guide-heading">Horizontal Spacing</h2>
    </v-col>
    <v-col>
      <div class="vertical-display">
        <div class="box spacing_inline-giant"></div>
        <code>$spacing_inline-giant</code>
      </div>
      <div class="vertical-display">
        <div class="box spacing_inline-huge"></div>
        <code>$spacing_inline-huge</code>
      </div>
      <div class="vertical-display">
        <div class="box spacing_inline-xxxl"></div>
        <code>$spacing_inline-xxxl</code>
      </div>
      <div class="vertical-display">
        <div class="box spacing_inline-xxl"></div>
        <code>$spacing_inline-xxl</code>
      </div>
      <div class="vertical-display">
        <div class="box spacing_inline-xl"></div>
        <code>$spacing_inline-xl</code>
      </div>
      <div class="vertical-display">
        <div class="box spacing_inline-lg"></div>
        <code>$spacing_inline-lg</code>
      </div>
      <div class="vertical-display">
        <div class="box spacing_inline-md"></div>
        <code>$spacing_inline-md</code>
      </div>
      <div class="vertical-display">
        <div class="box spacing_inline-sm"></div>
        <code>$spacing_inline-sm</code>
      </div>
      <div class="vertical-display">
        <div class="box spacing_inline-xs"></div>
        <code>$spacing_inline-xs</code>
      </div>
      <div class="vertical-display">
        <div class="box spacing_inline-xxs"></div>
        <code>$spacing_inline-xxs</code>
      </div>
      <div class="vertical-display">
        <div class="box spacing_inline-xxxs"></div>
        <code>$spacing_inline-xxxs</code>
      </div>
      <div class="vertical-display">
        <div class="box spacing_inline-nano"></div>
        <code>$spacing_inline-nano</code>
      </div>
      <div class="vertical-display">
        <div class="box spacing_inline-quark"></div>
        <code>$spacing_inline-quark</code>
      </div>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'StyleGuide--SpacingHorizontal',
  components: {},
  data() {
    return {};
  },
});
</script>

<style lang="scss">
.style-guide--spacing-horizontal {
  .v-col {
    @include media-breakpoint-up(xxl) {
      display: flex;
      gap: 16px;
    }
  }
  .vertical-display {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
    width: 100%;

    @include media-breakpoint-up(xxl) {
      flex-direction: column;
      gap: unset;

      code {
        width: fit-content;
        margin-top: 20px;
        writing-mode: vertical-lr;
      }
    }
  }
  .box {
    height: 300px;
    background: rgba(#ff7101, 0.32);
    border: 1px solid #ff7101;
    margin: 0;

    &.spacing_inline-quark {
      width: $spacing_inline-quarck;
    }
    &.spacing_inline-nano {
      width: $spacing_inline-nano;
    }
    &.spacing_inline-xxxs {
      width: $spacing_inline-xxxs;
    }
    &.spacing_inline-xxs {
      width: $spacing_inline-xxs;
    }
    &.spacing_inline-xs {
      width: $spacing_inline-xs;
    }
    &.spacing_inline-sm {
      width: $spacing_inline-sm;
    }
    &.spacing_inline-md {
      width: $spacing_inline-md;
    }
    &.spacing_inline-lg {
      width: $spacing_inline-lg;
    }
    &.spacing_inline-xl {
      width: $spacing_inline-xl;
    }
    &.spacing_inline-xxl {
      width: $spacing_inline-xxl;
    }
    &.spacing_inline-xxxl {
      width: $spacing_inline-xxxl;
    }
    &.spacing_inline-huge {
      width: $spacing_inline-huge;
    }
    &.spacing_inline-giant {
      width: $spacing_inline-giant;
    }
  }
}
</style>

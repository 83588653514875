<template>
  <PersonalDetailsForm :fields="fields" ref="formComponent" />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { useStandaloneCreditAppStore } from '@/stores/standaloneCreditApp';
import { mapActions } from 'pinia';
import PersonalDetailsForm from './Forms/PersonalDetailsForm.vue';
import { accumulateChildFields } from '@/util/schema/schemaValidatorUtils';
import standaloneCreditAppFormStep from '@/mixins/standaloneCreditAppFormStep';
import { FieldsPropType } from '@/lib/FieldTypes';

export default defineComponent({
  name: 'StandaloneCreditApp--PersonalDetails',
  components: {
    PersonalDetailsForm,
  },
  mixins: [standaloneCreditAppFormStep],
  props: {
    fields: {
      type: Object as PropType<FieldsPropType>,
      default: () => ({}),
    },
  },
  methods: {
    ...mapActions(useStandaloneCreditAppStore, ['generateValidation']),
    getSubmitHandler(): () => Promise<boolean> {
      return (this.$refs.formComponent as any).submitHandler;
    },
  },
  created() {
    this.generateValidation(accumulateChildFields(this.fields));
  },
});
</script>

<template>
  <div class="vehicle-result-heading-container" v-html="resultHeading"></div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { MMTFacetValue } from '@/types/VehicleSearch/Facets';
import { useVehicleSearchStore } from '@/stores/vehicleSearch';
import { mapState } from 'pinia';
import { useCommonStore } from '@/stores/common';
import { FieldsPropType } from '@/lib/FieldTypes';

export default defineComponent({
  name: 'VehicleResultHeading',
  props: {
    fields: {
      type: Object as PropType<FieldsPropType>,
      default: () => ({}),
    },
  },
  components: {},
  computed: {
    ...mapState(useCommonStore, ['dealerships']), // TODO-pinia wasn't working before because dealerships doesn't exist in searchResults state
    ...mapState(useVehicleSearchStore, [
      'facetFilters',
      'includedDealerships',
      'formattedVehicleAmount',
    ]),
    /*
        Controls the results heading text, e.g. "123 used cars at EchoPark" that dynamically
        updates as filters are changed Please refer to
        https://sonicautomotive.atlassian.net/browse/SAECHO-6010 for the full requirements

        There are 5 "priority" facets that change the text (6 with the location facet)
        In order: Color, year, make, model, body style
        When multiple priority facets are selected, must sort then based on the priority order

        Selecting other non-priority facets has no impact on the heading
      */
    vlpLocation() {
      if (this.$jss?.sitecoreContext()?.locationDisplayName) {
        return this.$jss?.sitecoreContext()?.locationDisplayName.value;
      }
      return null;
    },
    // eslint-disable-next-line
    resultHeading() {
      const priorityFacetStateNames = ['extcolor', 'year', 'mmt', 'bodystyle'];

      /* Add more pluralization logic as requested by the business */
      const pluralize = (item) => {
        // TODO: CANNOT BE LOCALIZED, WE NEED TO FIX THIS
        return item + 's';
      };

      /* add location onto the end of the results */
      const addLocation = (heading) => {
        // TODO: FIX HARDCODED CONTENT
        let location = 'EchoPark'; // default
        if (this.includedDealerships && this.includedDealerships.length > 0 && this.dealerships) {
          // for each included dealership, lookup the "nice" name
          location = this.vlpLocation
            ? this.vlpLocation
            : `${location} ` +
              this.includedDealerships
                .map((name) => {
                  return this.dealerships.find((dealership) => dealership.dealerId === name)
                    ?.storeName;
                })
                .join(', ');
        }
        return heading.replace('##location##', location);
      };

      /* Add the count and format the title accordingly */
      /* <div>1,758</div><h1>used 2005-2021s for sale at EchoPark</h1> */
      /* It is a business requirement to not have the vehicle count in the h1 */
      const addCount = (heading) => {
        return (
          heading.replace(
            '##vehicleCount##',
            `<div class="title" data-testid='inventoryCount'>${this.formattedVehicleAmount}</div><h1 class="title">`
          ) + '</h1>'
        );
      };

      /* given a list of selected facets, sort them into the order above */
      /* sortPriorityFacets(["bodystyle", "year", "mmt"]) --> ["year", "mmt", "bodystyle"] */
      const sortPriorityFacets = (facets) => {
        // Create an array of indices for the original array
        const indices = facets.map((_, i) => i);

        // Sort the indices based on the reference array
        indices.sort((a, b) => {
          return (
            priorityFacetStateNames.indexOf(facets[a]) - priorityFacetStateNames.indexOf(facets[b])
          );
        });

        // Rearrange the original array based on the sorted indices
        return indices.map((index) => facets[index]);
      };

      let baseTemplate = this.fields.default_facet_header.value;
      let selectedFacetsCount = Object.keys(this.facetFilters || {}).length; // excludes location
      let priorityFacets = Object.keys(this.facetFilters || {}).filter((facetName) =>
        priorityFacetStateNames.includes(facetName)
      );

      if (this.formattedVehicleAmount) {
        // @ts-ignore // TODO-pinia uncovered existing type error
        if (this.facetFilters && this.facetFilters.extcolor && selectedFacetsCount == 1) {
          // special requirement for when only the color facet is selected
          baseTemplate = this.fields.color_facet_header.value;
          // @ts-ignore // TODO-pinia uncovered existing type error
          let selectedColors = this.facetFilters.extcolor.facetValue.join(', ');

          return addCount(addLocation(baseTemplate.replace('##color##', selectedColors)));
        } else if (priorityFacets.length > 0) {
          // when >0 priority facets are selected (but not color)
          baseTemplate = this.fields.priority_facet_header.value;

          // ["mmt", "year"] --> ["Ford", "Toyota", "2010-2020"]
          let expandedFacets = sortPriorityFacets(priorityFacets)
            .map((facetType) => {
              if (facetType === 'extcolor') {
                // @ts-ignore // TODO-pinia uncovered existing type error
                return this.facetFilters.extcolor.facetValue;
              } else if (facetType === 'year') {
                // @ts-ignore // TODO-pinia uncovered existing type error

                return `${this.facetFilters.year.min}-${this.facetFilters.year.max}`;
              } else if (facetType === 'mmt') {
                // grab makes, grab models, ignore trims
                // Note: sort Makes before Models at this step, since earlier it wasn't possible
                // Available at this.facetFilters.mmt.facetValue.{make}.{model}
                // @ts-ignore // TODO-pinia uncovered existing type error
                const makes: (string | string[])[] = Object.keys(this.facetFilters.mmt.facetValue);
                // @ts-ignore // TODO-pinia uncovered existing type error
                const models: string[][] = Object.values(this.facetFilters.mmt.facetValue).map(
                  (facetValue) => {
                    return Object.keys(facetValue as MMTFacetValue).filter(
                      (facetValue) => facetValue != 'selected'
                    );
                  }
                );

                // combine makes+models
                return makes.concat(models);
              } else if (facetType === 'bodystyle') {
                // @ts-ignore // TODO-pinia uncovered existing type error

                return this.facetFilters.bodystyle.facetValue;
              }
            })
            .flat(2)
            .filter((e) => e && e.length);

          // pluralize the last priority facet
          expandedFacets[expandedFacets.length - 1] = pluralize(
            expandedFacets[expandedFacets.length - 1]
          );

          return addCount(
            addLocation(baseTemplate.replace('##priorityFacets##', expandedFacets.join(', ')))
          );
        } else {
          // no priority facets applied
          return addCount(addLocation(baseTemplate));
        }
      }
    },
  },
  methods: {},
});
</script>

<style lang="scss">
.vehicle-result-heading-container {
  text-align: center;
  margin: 24px;

  @media only screen and (min-width: 1240px) {
    text-align: left;
    margin: -32px 240px 24px 24px;
  }

  .title {
    color: $park-gray-0;
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
    display: inline;
    letter-spacing: 0;
  }
}
</style>

import * as Yup from 'yup';

/**
 * The available flow options for the application.
 * - 'single': A single applicant flow.
 * - 'coApplicant': A co-applicant flow.
 */
export type FlowOption = 'single' | 'coApplicant';

/**
 * Represents a Yup schema for validating a single form (e.g., the "buyer" form).
 */
export type ValidationSchema = Yup.ObjectSchema<any>;

/**
 * Represents a collection of Yup schemas, where each key corresponds to a form name
 * (e.g., "buyerName", "address") and the value is its respective validation schema.
 */
export type ValidationSchemas = Record<string, Yup.ObjectSchema<any>>;

/**
 * The result of a Yup schema validation.
 * - `null` indicates no validation errors.
 * - A record object contains field names and corresponding error messages.
 */
export type ValidationResult = Record<string, string> | null;

/**
 * Represents the schema definitions for the forms combined from Route One and Sitecore.
 * Each form has a set of fields, and each field has its own definition.
 */
export type SchemaDefinitions = Record<string, Record<string, SchemaFieldDefinition>>;

/**
 * Represents multiple Route One schema definitions (e.g., 'buyer', 'address').
 */
export type AbstractSchemaDefinitions = Record<string, AbstractSchemaDefinition>;

/**
 * Defines the structure of a field within the final schema object.
 * - `label`: The display name of the field.
 * - `type`: The data type of the field (e.g., 'string', 'number', 'boolean').
 * - `required`: Whether the field is required for form submission.
 * - `format`: An optional format for the field (e.g., 'email', 'date').
 * - `pattern`: A regex pattern that the field value must match.
 * - `rules`: Additional validation rules (e.g., minimum and maximum values).
 */
export interface SchemaFieldDefinition {
  label?: string;
  type: string;
  required?: boolean;
  format?: string;
  pattern?: string;
  validationMessage?: string;
  $ref?: string;
  rules?: {
    minimum?: number;
    maximum?: number;
    pattern?: string;
  };
  minLength?: number;
  maxLength?: number;
}

/**
 * Represents how a field is defined in the Route One schema.
 * - `type`: The data type of the field.
 * - `description`: An optional description of the field.
 * - `enum`: A list of allowed values for the field.
 * - `properties`: Nested fields (if the field is an object).
 * - `pattern`: A regex pattern the field must match.
 * - `required`: A list of required nested fields.
 */
export interface AbstractSchemaField {
  type?: string;
  description?: string;
  format?: string;
  enum?: string[];
  properties?: Record<string, AbstractSchemaField>;
  pattern?: string;
  $ref?: string;
  required?: string[];
  minLength?: number;
  maxLength?: number;
}

/**
 * Represents a complete Route One schema definition (e.g., 'buyer', 'address').
 * - `properties`: Fields within the schema.
 * - `required`: A list of required fields.
 */
export interface AbstractSchemaDefinition {
  properties: Record<string, AbstractSchemaField>;
  required?: string[];
  $defs?: Record<string, Record<string, any>>;
}

/**
 * Represents a field definition in the Sitecore schema.
 * - `FieldName`: The name of the field.
 * - `Label`: The display name of the field.
 * - `Required`: Whether the field is required.
 * - `ValidationMessage`: A custom validation message.
 */
export interface SitecoreField {
  FieldName: { value: string };
  Label?: { value: string };
  Required?: { value: boolean };
  ValidationMessage?: { value: string };
  FormName?: { value: string }; // For fields like 'buyer-form'
  [key: string]: any; // Allow additional fields
}

/**
 * Represents the Sitecore schema.
 * - `fields`: Contains an array of Sitecore schema items.
 */
export interface SitecoreSchema {
  fields: {
    children: SitecoreSchemaItem[];
  };
}

/**
 * Represents an item within the Sitecore schema.
 * - `fields`: The main field definitions.
 */
export interface SitecoreSchemaItem {
  id: string;
  url: string;
  name: string;
  displayName: string;
  fields: SitecoreField; // The main fields object
  [key: string]: any; // Allow extra properties
}

/**
 * Represents the structure of the entire credit application.
 */
export interface CreditApplication {
  routeOneDealerId: string;
  testPropNotNeeded?: string;
  primaryBuyer: Buyer;
  coBuyer?: Buyer;
  dealJacketVehicles: DealJacketVehicle[];
  finance?: Finance;
  additionalDetails: AdditionalDetails;
}

/**
 * Represents the handler function for form submission.
 */
export interface FormComponentType {
  submitHandler: () => Promise<Record<string, string>>;
}

/**
 * Navigation structure for the credit application.
 * Each path corresponds to an array of flow types (e.g., 'single' or 'coApplicant') that the path belongs to.
 */
export interface CreditAppNav {
  [path: string]: string[];
}

/**
 * Contains details about the current step in the application.
 * - `componentName`: The name of the Vue component for the current step.
 * - `formTitle`: An optional title for the form being displayed on this step.
 * - `nextStep`: An optional path to the next step in the flow.
 */
export interface CurrentStepDetails {
  componentName: string;
  formTitle?: string;
  nextStep?: string;
}

/**
 * Represents a buyer's details, including identity, contact information, and address.
 */
export interface Buyer {
  customerTypeCode: 'D' | undefined;
  identity: BuyerIdentity;
  contactInfo: ContactInfo;
  personalDetails?: PersonalDetails;
  address: Address;
  employmentDetails?: EmploymentDetails;
}

/**
 * Represents the identity information of a buyer.
 */
export interface BuyerIdentity {
  firstName: string;
  middleName?: string;
  lastName: string;
  suffix?: 'JR' | 'SR' | 'I' | 'II' | 'III' | 'IV' | 'V' | 'None' | '' | undefined;
  displayName: string;
  dateOfBirth: string;
  dateOfBirthDefined: boolean;
}

/**
 * Represents a buyer's contact information.
 */
export interface ContactInfo {
  phone: string;
  phoneType: 'HOME' | 'WORK' | 'MOBILE';
  email: string;
  altPhone?: string;
  altPhoneType?: 'HOME' | 'WORK' | 'MOBILE';
}

/**
 * Represents additional details.
 */
export interface AdditionalDetails {
  storeId: string;
  source?: string;
  comments?: {
    GClid?: string;
    FBClids?: string;
    MSClids?: string;
    GAClientID?: string;
    timestamp?: string;
    nearestLocation?: string;
    zipCode?: string;
    notes?: string;
    trade?: any;
  }[];
}

/**
 * Represents a buyer's personal details.
 */
export interface PersonalDetails {
  ssn?: string;
  ssnDefined?: boolean;
}

/**
 * Represents a buyer's address information.
 */
export interface Address {
  addressLine1: string;
  addressLine2?: string;
  zipCode: string;
  city: string;
  state: string;
  county?: string;
  countryCode: 'US' | 'CA';
  moveInMonth?: number;
  moveInYear?: number;
  monthsAtAddress: number;
  residenceTypeCode: string;
  residenceTypeCodeText?: string;
  rentMortgagePaymentAmountString?: string;
  rentMortgagePaymentAmount?: number;
  previousAddressLine1?: string;
  previousAddressLine2?: string;
  previousZipCode?: string;
  previousCity?: string;
  previousState?: string;
  previousCounty?: string;
  previousCountryCode?: 'US' | 'CA' | '';
  previousMoveInMonth?: number;
  previousMoveInYear?: number;
  previousMonthsAtAddress?: number;
  previousResidenceTypeCode?: string;
  previousResidenceTypeCodeText?: string;
  previousRentMortgagePaymentAmountString?: string;
  previousRentMortgagePaymentAmount?: number;
}

/**
 * Represents a buyer's employment and income details.
 */
export interface EmploymentDetails {
  employmentTypeCode?: string;
  employmentStatusCode?: string;
  employmentTitle?: string;
  employerName?: string;
  employerPhone?: string;
  employerStartMonth?: number; // Not on schema
  employerStartYear?: number; // Not on schema
  monthsOnJob?: number;

  incomeTypeCode?: 'Hourly' | 'Salary' | ''; // not on schema
  hourlyPayRate?: number; // not on schema
  hourlyPayRateString?: string; // not on schema
  avgWeeklyHours?: number; // not on schema
  avgWeeklyHoursString?: string; // not on schema
  hasBonusPay?: 'Yes' | 'No' | ''; // not on schema
  monthlyBonusPay?: number; // not on schema
  monthlyBonusPayString?: string; // not on schema
  annualOrMonthly?: 'Annual' | 'Monthly' | ''; // not on schema
  annualIncome?: number; // not on schema
  annualIncomeString?: string; // not on schema
  monthlyIncome?: number | ''; // not on schema
  monthlyIncomeString?: string | ''; // not on schema

  incomeAmount?: number;
  incomeIntervalCode?: 'WK' | 'BiWK' | 'SmMO' | 'MO' | 'YR' | '';
  currencyTypeCode?: 'USD' | 'CAD' | '';
  hasExtraIncome?: 'Yes' | 'No' | '';

  otherIncomeSourceCode?: string;
  otherIncomeSourceDescription?: string;
  otherIncomeAmount?: number;
  otherIncomeAmountString?: string;
  otherIncomeIntervalCode?: 'WK' | 'BiWK' | 'SmMO' | 'MO' | 'YR' | '';
  otherCurrencyTypeCode?: 'USD' | 'CAD' | '';
  educationLevelCode?: string;

  previousEmploymentTypeCode?: string; // Not on schema
  previousEmploymentStatusCode?: string; // Not on schema
  previousEmploymentTitle?: string; // Not on schema
  previousEmployerName?: string; // Not on schema
  previousEmployerPhone?: string; // Not on schema
  previousEmployerStartMonth?: number; // Not on schema
  previousEmployerStartYear?: number; // Not on schema
  previousMonthsOnJob?: number; // Not on schema

  previousIncomeTypeCode?: 'Hourly' | 'Salary' | ''; // not on schema
  previousHourlyPayRate?: number; // not on schema
  previousHourlyPayRateString?: string; // not on schema
  previousAvgWeeklyHours?: number; // not on schema
  previousAvgWeeklyHoursString?: string; // not on schema
  previousHasBonusPay?: 'Yes' | 'No' | ''; // not on schema
  previousMonthlyBonusPay?: number; // not on schema
  previousMonthlyBonusPayString?: string; // not on schema
  previousAnnualOrMonthly?: 'Annual' | 'Monthly' | ''; // not on schema
  previousAnnualIncome?: number; // not on schema
  previousAnnualIncomeString?: string; // not on schema
  previousMonthlyIncome?: number | ''; // not on schema
  previousMonthlyIncomeString?: string | ''; // not on schema

  previousIncomeAmount?: number; // Not on schema
  previousIncomeIntervalCode?: 'WK' | 'BiWK' | 'SmMO' | 'MO' | 'YR' | ''; // Not on schema
  previousCurrencyTypeCode?: 'USD' | 'CAD' | ''; // Not on schema
  previousEducationLevelCode?: string; // Not on schema
}

/**
 * Represents a vehicle within the deal jacket.
 */
export interface DealJacketVehicle {
  vehicle: Vehicle;
  intendedUse:
    | 'BUSINESS'
    | 'AGRICULTURAL'
    | 'HAZARDOUS'
    | 'LOCAL'
    | 'INTERSTATE'
    | 'INTERMEDIATE'
    | 'PERSONAL';
  tradeInVehicleIndicator?: boolean;
}

/**
 * Represents a vehicle's details.
 */
export interface Vehicle {
  vin: string;
  make?: string;
  model?: string;
  year?: number;
  style?: string;
  color?: string;
  condition?: 'NEW' | 'USED' | 'CERTIFIED_USED' | 'AUCTION' | 'DEMO';
  stockNumber?: string;
  collateralType?: 'AUTO' | 'LIGHT_TRUCK' | 'MEDIUM_TRUCK' | 'CONVERSION_VAN' | 'OTHER';
  mileage?: number;
  msrp?: number;
  wholesaleAmount?: number;
}

/**
 * Represents the financial details of a credit application.
 */
export interface Finance {
  transactionType: 'RETAIL' | 'LEASE' | 'BALLOON' | 'CASH' | 'PAYMENT_CALL';
  vehicleCashPrice?: number;
  cashDownPayment?: number;
  cashDownPaymentString?: string;
}

export type MonthOption = { text: string; value: number | string };

export const monthOptions: MonthOption[] = [
  { text: 'January', value: 1 },
  { text: 'February', value: 2 },
  { text: 'March', value: 3 },
  { text: 'April', value: 4 },
  { text: 'May', value: 5 },
  { text: 'June', value: 6 },
  { text: 'July', value: 7 },
  { text: 'August', value: 8 },
  { text: 'September', value: 9 },
  { text: 'October', value: 10 },
  { text: 'November', value: 11 },
  { text: 'December', value: 12 },
];

export type StateCode =
  | 'AL'
  | 'AK'
  | 'AZ'
  | 'AR'
  | 'CA'
  | 'CO'
  | 'CT'
  | 'DC'
  | 'DE'
  | 'FL'
  | 'GA'
  | 'HI'
  | 'ID'
  | 'IL'
  | 'IN'
  | 'IA'
  | 'KS'
  | 'KY'
  | 'LA'
  | 'ME'
  | 'MD'
  | 'MA'
  | 'MI'
  | 'MN'
  | 'MS'
  | 'MO'
  | 'MT'
  | 'NE'
  | 'NV'
  | 'NH'
  | 'NJ'
  | 'NM'
  | 'NY'
  | 'NC'
  | 'ND'
  | 'OH'
  | 'OK'
  | 'OR'
  | 'PA'
  | 'RI'
  | 'SC'
  | 'SD'
  | 'TN'
  | 'TX'
  | 'UT'
  | 'VT'
  | 'VA'
  | 'WA'
  | 'WV'
  | 'WI'
  | 'WY'
  | 'AA'
  | 'AE'
  | 'AP'; // Military Addresses

export const stateOptions: StateCode[] = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
  'AA',
  'AE',
  'AP',
];

export interface EmploymentConfig {
  showEmployerName: boolean;
  showEmployerPhone: boolean;
  showEmploymentTitle: boolean;
  showIncome: boolean;
}

const employmentConfigOptions = (
  showEmployerName: boolean,
  showEmployerPhone: boolean,
  showEmploymentTitle: boolean,
  showIncome: boolean
): EmploymentConfig => ({
  showEmployerName,
  showEmployerPhone,
  showEmploymentTitle,
  showIncome,
});

export interface ResidenceTypeOption {
  text: string;
  value: string; // Keeping it as a string to match your schema
  showPayment: boolean;
  showOther: boolean;
}

/* 
  Current UI mapping is:
  (UI Left, API right)

  Employed: Full Time
  Military: Military
  Retired: Retired
  Unemployed: Not Applicable
  Other: Self-Employed

  Additional mapping:
  Employed + (Hourly && < 32 hours) : Part Time
  */

/* Below defines a central location to
   manage API employment statuses
 
 Part time, contract, seasonal, and temporary
 are not implemented by the UI as of 3/6/2025 NP
 */
export const EmploymentStatuses = {
  FULL_TIME: 'Full Time',
  MILITARY: 'Military',
  RETIRED: 'Retired',
  NOT_APPLICABLE: 'Not Applicable',
  SELF_EMPLOYED: 'Self-Employed',
  PART_TIME: 'Part Time',
  // CONTRACT: 'Contract',
  // SEASONAL: 'Seasonal',
  // TEMPORARY: 'Temporary',
} as const;

export type EmploymentStatus = (typeof EmploymentStatuses)[keyof typeof EmploymentStatuses];

export const employmentConfig: Record<EmploymentStatus, EmploymentConfig> = {
  [EmploymentStatuses.FULL_TIME]: employmentConfigOptions(true, true, true, true),
  [EmploymentStatuses.PART_TIME]: employmentConfigOptions(true, true, true, true),
  [EmploymentStatuses.MILITARY]: employmentConfigOptions(false, true, true, true),
  [EmploymentStatuses.RETIRED]: employmentConfigOptions(false, false, false, true),
  [EmploymentStatuses.NOT_APPLICABLE]: employmentConfigOptions(false, false, false, true),
  [EmploymentStatuses.SELF_EMPLOYED]: employmentConfigOptions(true, true, true, true),
};

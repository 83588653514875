<template>
  <Flyout :isOpen="commonStore.zipFlyout" @close="commonStore.setZipFlyout(false)">
    <div class="zip-flyout">
      <div
        class="zipLocation_flyout newZipLocation"
        aria-hidden="true"
        tabindex="-1"
        :style="commonStore.zipFlyout ? 'right: 0' : ''"
      >
        <div class="zipLocation_flyout_title">
          <ZipLocationEdit :fields="fields" :showClose="true" v-model="zipCode" />
        </div>
        <div class="zip-flyout-locations">
          <ZipNearestStoresDisplay
            :fields="fields"
            :dealerships="commonStore.dealerships"
            :closestDealership="commonStore.nearestDealership"
            :loading="loading"
          />
        </div>
      </div>
    </div>
  </Flyout>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import ZipLocationEdit from '@components-vue/Feature/ZipFlyout/ZipLocationEdit.vue';
import ZipNearestStoresDisplay from '@components-vue/Feature/ZipFlyout/ZipNearestStoresDisplay.vue';
import Flyout from '@components-vue/Foundation/Common/Overlay/Flyout.vue';
import { mapStores } from 'pinia';
import { useCommonStore } from '@/stores/common';
import { FieldsPropType } from '@/lib/FieldTypes';

export default defineComponent({
  name: 'ZipFlyout',
  methods: {},
  components: {
    Flyout,
    ZipLocationEdit,
    ZipNearestStoresDisplay,
  },
  computed: {
    ...mapStores(useCommonStore),
    zipCode: {
      get() {
        return this.commonStore.zipCode;
      },
      set(value) {
        this.commonStore.setZipCode(value);
        this.emitter.emit('filter-updated-srp');
      },
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  props: {
    fields: {
      type: Object as PropType<FieldsPropType>,
      default: () => ({}),
    },
  },
});
</script>

<style lang="scss">
.zip-flyout-locations {
  margin: 32px -24px;
}
</style>

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sca-text-illustration" }
const _hoisted_2 = { class: "inner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sc_image = _resolveComponent("sc-image")!
  const _component_sc_rich_text = _resolveComponent("sc-rich-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_sc_image, {
        media: _ctx.fields.image_file,
        height: "320",
        width: "320"
      }, null, 8, ["media"]),
      _createVNode(_component_sc_rich_text, { field: _ctx.imageText }, null, 8, ["field"])
    ])
  ]))
}
<template>
  <v-row>
    <v-col cols="12" class="pb-6">
      <FormHeader
        :title="fieldsMap.get(coPrefix + 'current-address.Label')?.value"
        :note="fieldsMap.get(coPrefix + 'current-address.Description')"
      />
    </v-col>
  </v-row>
  <v-row v-if="isCobuyer">
    <v-col cols="12" class="same-address-checkbox">
      <v-checkbox-btn
        v-model="currentAddress.coApplicantSameAddress"
        :label="
          fieldsMap.get('co-applicant-current-address.same-address-as-applicant.Label')?.value
        "
        class="mt-4"
        data-testid="cobuyer-same-address"
      />
    </v-col>
  </v-row>
  <BuyerAddressDetails
    :fields="fields"
    :fieldsMap="fieldsMap"
    :isCobuyer="isCobuyer"
    v-model:formValues="currentAddress"
    :buyerAddressSchema="buyerAddressSchema"
    :stateOptions="stateOptions"
    :residenceTypeOptions="residenceTypeOptions"
    :hasError="hasError"
  />
  <v-row v-if="includePreviousAddresss">
    <v-col cols="12" class="pb-6">
      <FormHeader
        :title="fieldsMap.get(coPrefix + 'previous-address.Label')?.value"
        :note="fieldsMap.get(coPrefix + 'current-address.Description')"
      />
    </v-col>
  </v-row>
  <BuyerAddressDetails
    v-if="includePreviousAddresss"
    :fields="fields"
    :fieldsMap="fieldsMap"
    :isCobuyer="isCobuyer"
    v-model:formValues="previousAddress"
    :buyerAddressSchema="buyerAddressSchema"
    :stateOptions="stateOptions"
    :residenceTypeOptions="residenceTypeOptionsPrevious"
    :hasError="hasError"
    :isPrevious="true"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { useStandaloneCreditAppStore } from '@/stores/standaloneCreditApp';
import { useCommonStore } from '@/stores/common';
import { mapState, mapActions } from 'pinia';
import { validate } from '@/util/schema/schemaValidator';
import {
  stateOptions,
  ResidenceTypeOption,
} from '@/types/StandaloneCreditApp/StandaloneCreditAppTypes';
import { ensureNumericValues } from '@util/commonUtils';
import FormHeader from '../Includes/FormHeader.vue';
import BuyerAddressDetails from './BuyerAddressDetails.vue';
import { FieldsPropType } from '@/lib/FieldTypes';

export default defineComponent({
  name: 'BuyerAddress',
  props: {
    fields: {
      type: Object as PropType<FieldsPropType>,
      default: () => ({}),
    },
    fieldsMap: {
      type: Map as PropType<Map<String, any>>,
      default: () => ({}),
    },
    isCobuyer: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FormHeader,
    BuyerAddressDetails,
  },
  data() {
    return {
      errors: {} as Record<string, string>,
      currentAddress: {
        addressLine1: '',
        addressLine2: '',
        zipCode: '',
        city: '',
        state: undefined as string | undefined,
        county: '', // not asked for
        moveInMonth: undefined as number | undefined, // not on schema
        moveInYear: undefined as number | undefined, // not on schema
        monthsAtAddress: 0,
        residenceTypeCode: '0', // default to own home to match figma
        residenceTypeCodeText: '',
        rentMortgagePaymentAmountString: '',
        rentMortgagePaymentAmount: 0,
        countryCode: 'US' as 'US' | 'CA', // not asked for, hardcode for now
        coApplicantSameAddress: false,
      },
      previousAddress: {
        previousAddressLine1: '',
        previousAddressLine2: '',
        previousZipCode: '',
        previousCity: '',
        previousState: '',
        previousCounty: '', // not asked for
        previousCountryCode: 'US' as 'US' | 'CA', // not asked for, hardcode for now
        previousMoveInMonth: undefined as number | undefined, // not on schema
        previousMoveInYear: undefined as number | undefined, // not on schema
        previousMonthsAtAddress: 0,
        previousResidenceTypeCode: '0', // default to own home  to match figma
        previousResidenceTypeCodeText: '',
        previousRentMortgagePaymentAmountString: '',
        previousRentMortgagePaymentAmount: 0,
      },
      showCoApplicantCheckbox: false,
      stateOptions,
    };
  },
  computed: {
    ...mapState(useStandaloneCreditAppStore, [
      'buyerAddressValidator',
      'buyerAddressSchema',
      'primaryBuyerAddress',
      'primaryBuyer',
      'coBuyer',
    ]),
    calculatedMonthsAtAddress(): number {
      return this.calculateMonthsDifference(
        this.currentAddress.moveInYear,
        this.currentAddress.moveInMonth
      );
    },
    calculatedPreviousMonthsAtAddress(): number {
      return this.calculateMonthsDifference(
        this.previousAddress.previousMoveInYear,
        this.previousAddress.previousMoveInMonth
      );
    },
    includePreviousAddresss() {
      return (
        this.calculatedMonthsAtAddress < 24 &&
        this.currentAddress.moveInMonth != 0 &&
        this.currentAddress.moveInMonth != null &&
        this.currentAddress.moveInYear != 0 &&
        this.currentAddress.moveInYear != null
      );
    },
    residenceTypeOptions(): ResidenceTypeOption[] {
      return this.generateResidenceTypeOptions(this.coPrefix + 'current-address');
    },
    residenceTypeOptionsPrevious(): ResidenceTypeOption[] {
      return this.generateResidenceTypeOptions(this.coPrefix + 'previous-address');
    },
    coPrefix() {
      return this.isCobuyer ? 'co-applicant-' : '';
    },
  },
  methods: {
    ...mapActions(useStandaloneCreditAppStore, ['setAddress']),
    ...mapActions(useCommonStore, ['setZipCode']),
    async submitHandler(): Promise<Record<string, string>> {
      this.setAddressDurations();
      this.handleCoApplicantAddress();
      this.ensureNumericFields();

      this.errors = (await this.validateForm()) || {};
      if (!this.hasErrors()) {
        this.updateAddressInStore();
      }
      return this.errors;
    },
    async validateForm(): Promise<Record<string, string>> {
      const addressErrors = await validate([this.buyerAddressValidator], {
        ...this.currentAddress,
        ...this.previousAddress,
      });

      return { ...addressErrors };
    },
    getResidenceTypeText(code: string, options: any[]): string {
      return options.find((option) => option.value === code)?.text || '';
    },
    updateAddressInStore(): void {
      this.ensureValidResidenceType(this.currentAddress, 'residenceTypeCode');
      this.ensureValidResidenceType(this.previousAddress, 'previousResidenceTypeCode');

      this.currentAddress.residenceTypeCodeText = this.getResidenceTypeText(
        this.currentAddress.residenceTypeCode,
        this.residenceTypeOptions
      );
      this.previousAddress.previousResidenceTypeCodeText = this.getResidenceTypeText(
        this.previousAddress.previousResidenceTypeCode,
        this.residenceTypeOptionsPrevious
      );

      const addressData = {
        ...this.currentAddress,
        ...this.previousAddress,
      };

      this.setAddress(addressData, this.isCobuyer);
      this.setZipCode(this.currentAddress.zipCode);
    },
    calculateMonthsDifference(year?: number, month?: number): number {
      if (!year || !month) return 0;
      const now = new Date();
      const moveInDate = new Date(year, month - 1);
      const diffInMonths =
        (now.getFullYear() - moveInDate.getFullYear()) * 12 +
        (now.getMonth() - moveInDate.getMonth());
      return Math.max(diffInMonths, 0);
    },
    hasError(key: string): boolean {
      return !!this.errors[key];
    },
    hasErrors() {
      return Object.keys(this.errors).length;
    },
    // Number values must be as they are (1, 2, 3, 4, 6) to match api options NP 3/6/2025
    generateResidenceTypeOptions(prefix: string): ResidenceTypeOption[] {
      return [
        { key: 'residence_type_own', value: '1', showPayment: false, showOther: false },
        { key: 'residence_type_mortage', value: '2', showPayment: true, showOther: false },
        { key: 'residence_type_family', value: '3', showPayment: true, showOther: false },
        { key: 'residence_type_rent', value: '4', showPayment: true, showOther: false },
        { key: 'residence_type_other', value: '6', showPayment: true, showOther: false },
      ].map(({ key, value, showPayment, showOther }) => ({
        text: this.fieldsMap.get(`${prefix}.residenceTypeCode.${key}.Label`)?.value,
        value,
        showPayment,
        showOther,
      }));
    },
    copyPrimaryBuyerAddress(): void {
      if (!this.primaryBuyerAddress) return;
      const { addressLine1, addressLine2, city, state, zipCode } = this.primaryBuyerAddress;
      Object.assign(this.currentAddress, { addressLine1, addressLine2, city, state, zipCode });
    },
    setAddressDurations() {
      this.currentAddress.monthsAtAddress = this.calculatedMonthsAtAddress;
      this.previousAddress.previousMonthsAtAddress = this.calculatedPreviousMonthsAtAddress;
    },
    handleCoApplicantAddress() {
      if (this.currentAddress.coApplicantSameAddress) {
        this.copyPrimaryBuyerAddress();
      }
    },
    ensureValidResidenceType(address: Record<string, any>, key: string): void {
      if (!address[key] || address[key] === '0') {
        address[key] = '1';
      }
    },
    ensureNumericFields() {
      ensureNumericValues(['rentMortgagePaymentAmount'], this.currentAddress);
      ensureNumericValues(['previousRentMortgagePaymentAmount'], this.previousAddress);
    },
  },
  mounted() {
    const buyer = this.isCobuyer ? this.coBuyer : this.primaryBuyer;

    this.currentAddress = {
      addressLine1: buyer?.address?.addressLine1 || '',
      addressLine2: buyer?.address?.addressLine2 || '',
      zipCode: buyer?.address?.zipCode || '',
      city: buyer?.address?.city || '',
      state: buyer?.address?.state || undefined,
      county: buyer?.address?.county || '',
      moveInMonth: buyer?.address?.moveInMonth ?? undefined,
      moveInYear: buyer?.address?.moveInYear ?? undefined,
      monthsAtAddress: buyer?.address?.monthsAtAddress || 0,
      residenceTypeCode: buyer?.address?.residenceTypeCode || '0',
      residenceTypeCodeText: buyer?.address?.residenceTypeCodeText || '',
      rentMortgagePaymentAmountString: buyer?.address?.rentMortgagePaymentAmountString ?? '',
      rentMortgagePaymentAmount: buyer?.address?.rentMortgagePaymentAmount ?? 0,
      countryCode: buyer?.address?.countryCode || 'US',
      coApplicantSameAddress: false, // default to false
    };

    this.previousAddress = {
      previousAddressLine1: buyer?.address?.previousAddressLine1 || '',
      previousAddressLine2: buyer?.address?.previousAddressLine2 || '',
      previousZipCode: buyer?.address?.previousZipCode || '',
      previousCity: buyer?.address?.previousCity || '',
      previousState: buyer?.address?.previousState || '',
      previousCounty: buyer?.address?.previousCounty || '',
      previousCountryCode: buyer?.address?.previousCountryCode || 'US',
      previousMoveInMonth: buyer?.address?.previousMoveInMonth ?? undefined,
      previousMoveInYear: buyer?.address?.previousMoveInYear ?? undefined,
      previousMonthsAtAddress: buyer?.address?.previousMonthsAtAddress || 0,
      previousResidenceTypeCode: buyer?.address?.previousResidenceTypeCode || '0',
      previousResidenceTypeCodeText: buyer?.address?.previousResidenceTypeCodeText || '',
      previousRentMortgagePaymentAmountString:
        buyer?.address?.previousRentMortgagePaymentAmountString ?? '',
      previousRentMortgagePaymentAmount: buyer?.address?.previousRentMortgagePaymentAmount ?? 0,
    };
  },
});
</script>

<style lang="scss">
@use '@/assets/styles/variables' as *;

.same-address-checkbox {
  padding-top: 0;
  padding-bottom: $spacing_stack-nano;
  margin-top: -$spacing_stack-xxxs;
}
</style>

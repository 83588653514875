<template>
  <div class="sca-store">
    <div class="sca-store-inner">
      <v-row justify="space-between" align="center" dense class="header-wrap flex-nowrap">
        <h6 class="sca-store-header">
          {{ fieldsMap.get('Select Location.Label')?.value }}
        </h6>
        <div class="d-flex flex-column align-end">
          <span class="sca-store-tip-content">{{
            fieldsMap.get('nearest-to.fieldNotes')?.value
          }}</span>
          <div class="d-flex align-end">
            <div class="d-flex align-center">
              <sc-image
                class="sca-store-location-icon mr-1"
                :media="fieldsMap.get('Select Location.option-icon')?.value"
              />
              <span class="sca-store-zip">{{ zipCode }}</span>
            </div>
          </div>
        </div>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <sc-rich-text
            class="sca-store-body-text"
            :field="fieldsMap.get('Select Location.notes')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="sca-store-selector">
          <v-btn-toggle
            v-model="selectedDealership"
            v-for="(dealership, index) in dealerships"
            :key="index"
            class="box-input box-input-md"
          >
            <v-btn
              @click="setDealership(dealership)"
              @keyup.enter="setDealership(dealership)"
              :class="selectedDealership === dealership ? 'active' : ''"
              :data-testid="`sca-dealership-${index}`"
            >
              <div class="label-start">
                {{ dealership.storeName }}
              </div>
              <div class="label-end">
                {{ Math.round(dealership.distance || 0).toLocaleString() }} mi
              </div>
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { fetchDealerships } from '@services/dealershipSearchService';
import RichText from '@components-vue/Foundation/Common/RichText';
import { useCommonStore } from '@/stores/common';
import { Image } from '@sitecore-jss/sitecore-jss-vue';
import { mapActions, mapState } from 'pinia';
import { Dealership } from '@/types/ServiceModels';
import { validate } from '@/util/schema/schemaValidator';
import { useStandaloneCreditAppStore } from '@/stores/standaloneCreditApp';
import { buildMap } from '@/util/standaloneCreditAppUtils';
import standaloneCreditAppFormStep from '@/mixins/standaloneCreditAppFormStep';
import { FieldsPropType } from '@/lib/FieldTypes';

export default defineComponent({
  name: 'StandaloneCreditApp--Store',
  props: {
    fields: {
      type: Object as PropType<FieldsPropType>,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedDealership: {} as Dealership,
      fieldsMap: new Map(),
    };
  },
  mixins: [standaloneCreditAppFormStep],
  components: {
    ScRichText: RichText,
    ScImage: Image,
  },
  computed: {
    ...mapState(useCommonStore, ['dealerships', 'zipCode']),
    ...mapState(useStandaloneCreditAppStore, {
      storeDealership: 'selectedDealership',
      buyerStoreValidator: 'buyerStoreValidator',
      additionalDetails: 'additionalDetails',
    }),
  },
  methods: {
    ...mapActions(useCommonStore, ['setDealerships']),
    ...mapActions(useStandaloneCreditAppStore, [
      'addError',
      'clearErrors',
      'setSelectedDealership',
      'generateValidation',
    ]),
    getSubmitHandler(): () => Promise<boolean> {
      const isValid: () => Promise<boolean> = async () => {
        const errors = (await this.validateForm()) || {};
        if (Object.keys(errors).length > 0) {
          const errorMessage = `${Object.values(errors).join(', ')}`;
          this.addError(errorMessage);
          return false;
        } else {
          this.setSelectedDealership(this.selectedDealership);
          return true;
        }
      };

      return isValid.bind(this);
    },
    setDealership(dealership: Dealership) {
      this.clearErrors();
      this.selectedDealership = dealership;
    },
    async validateForm(): Promise<Record<string, string>> {
      const errors = await validate([this.buyerStoreValidator], {
        storeId: this.selectedDealership?.routeOneDealerId,
      });
      return { ...errors };
    },
    buildFieldsMap() {
      this.fieldsMap = buildMap(this.fields);
    },
  },
  async mounted() {
    this.buildFieldsMap();
    this.selectedDealership = this.storeDealership || {};
    if (!this.dealerships || (Array.isArray(this.dealerships) && this.dealerships.length === 0)) {
      let dealershipResults = (await fetchDealerships(this.zipCode)) || ([] as Dealership[]);
      this.setDealerships(dealershipResults);
    }
  },
  created() {
    const fields = { ...this.fields };
    const fieldsObject = { fields };
    this.generateValidation(fieldsObject);
  },
});
</script>
<style lang="scss">
@use '@/assets/styles/variables' as *;

.sca-store {
  margin: 0 auto 72px;
  max-width: 432px;

  .sca-store-inner {
    margin-bottom: 48px;

    @include media-breakpoint-up(lg) {
      width: 432px;
      margin-bottom: 23px;
    }

    .header-wrap {
      margin-bottom: 8px;
    }
    .sca-store-header {
      color: $park-gray-0;
      line-height: 1;
    }
    .sca-store-tip-content {
      font-size: $font-size-xxxxs;
      line-height: $line-height-xs;
      color: $park-gray-20;
      font-weight: $font-weight-regular;
    }
    .sca-store-zip {
      font-size: $font-size-xxs;
      line-height: $line-height-xs;
      color: $neutral-color-low-dark;
      font-weight: $font-weight-medium;
    }
    .sca-store-body-text {
      font-size: $font-size-xxxs;
      line-height: $line-height-md;
      color: $base-black-100;
      font-weight: $font-weight-regular;
      text-align: left;
    }
    .sca-store-location-icon {
      width: 13.25px;
      height: 16px;
    }
  }
  .sca-store-selector {
    display: flex;
    flex-direction: column;
    gap: $spacing_inset-xs;

    .v-btn-toggle.box-input {
      .v-btn {
        height: 74px !important;
        padding: $spacing_inset-sm;

        &.active,
        &.v-btn--active {
          padding: ($spacing_inset-sm - 1) !important;
        }
      }
    }
  }
}
</style>

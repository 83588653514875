import zipService from '@services/zipService';
import CheckAvailabilityService from '@services/checkAvailabilityService';
import {
  generateCapiEventId,
  getFpbFromCookie,
  getFbClidFromUrl,
  generateUserDataSrp,
  generateCapiModelSrp,
  sendFacebookCapiEvent,
  sendTikTokCapiEvent,
} from '@assets/js/capiFunctions';
import emitter from '@util/eventBus';

export const validateFormAskAboutThisCar = async () => {
  const firstname = (document.getElementById('inputFirstNameAboutThisCar') as HTMLInputElement)
    ?.value;
  const lastname = (document.getElementById('inputLastNameAboutThisCar') as HTMLInputElement)
    ?.value;
  const phone = (document.getElementById('inputPhoneNumberAskAboutThisCar') as HTMLInputElement)
    ?.value;
  const email = (document.getElementById('inputEmailAskAboutThisCar') as HTMLInputElement)?.value;
  const zipcodeField = document.getElementById('inputZipCodeAboutThisCar') as HTMLInputElement;
  const zipcode = zipcodeField.value;
  const isEmailPhone = (document.getElementById('isEmailPhone') as HTMLInputElement)?.value;

  let isvalidForm = true;
  isvalidForm = verifyEmailAndPhoneNumberValidation(isEmailPhone, email, phone);

  const regexEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  if (firstname == '') {
    changeDisplayError('error_FirstName', true);
    (document.getElementById('inputFirstNameAboutThisCar') as HTMLInputElement).classList.add(
      'error-necessaryFields'
    );
    isvalidForm = false;
  } else {
    changeDisplayError('error_FirstName', false);
    (document.getElementById('inputFirstNameAboutThisCar') as HTMLInputElement).classList.remove(
      'error-necessaryFields'
    );
  }

  if (lastname == '') {
    changeDisplayError('error_LastName', true);
    (document.getElementById('inputLastNameAboutThisCar') as HTMLInputElement).classList.add(
      'error-necessaryFields'
    );
    isvalidForm = false;
  } else {
    changeDisplayError('error_LastName', false);
    (document.getElementById('inputLastNameAboutThisCar') as HTMLInputElement).classList.remove(
      'error-necessaryFields'
    );
  }

  if (phone.length > 0 && phone.length != 14) {
    changeDisplayError('error_PhoneNumber', true);
    isvalidForm = false;
  } else {
    changeDisplayError('error_PhoneNumber', false);
  }

  if (email.length > 0) {
    const is_email = regexEmail.test(email);
    if (!is_email) {
      changeDisplayError('error_Email', true);
      isvalidForm = false;
    } else {
      changeDisplayError('error_Email', false);
    }
  }

  const isValidZip = await zipService.isValidZip(zipcode);
  if (isValidZip) {
    if (zipcodeField.classList.contains('error-zip')) {
      zipcodeField.classList.remove('error-zip');
    }
    changeDisplayError('error-zip', false);
  } else {
    isvalidForm = false;
    if (!zipcodeField.classList.contains('error-zip')) {
      zipcodeField.classList.add('error-zip');
    }
    changeDisplayError('error-zip', true);
  }

  return isvalidForm;
};

function changeDisplayError(errorElementId, display) {
  if (display) {
    if (
      (document.getElementById(errorElementId) as HTMLInputElement).classList.contains('hide-text')
    ) {
      (document.getElementById(errorElementId) as HTMLInputElement).classList.remove('hide-text');
    }
  } else {
    if (
      !(document.getElementById(errorElementId) as HTMLInputElement).classList.contains('hide-text')
    ) {
      (document.getElementById(errorElementId) as HTMLInputElement).classList.add('hide-text');
    }
  }
}

function verifyEmailAndPhoneNumberValidation(featureFlag, email, phone) {
  if (featureFlag === '1') {
    if (email == '' || phone == '') {
      changeDisplayError('Error_btn', true);
      return false;
    }
  } else {
    if (email == '' && phone == '') {
      changeDisplayError('Error_btn', true);
      return false;
    }
  }
  return true;
}

export const onInputFocus = (event: FocusEvent) => {
  const input = event.target as HTMLInputElement;
  const parent = input?.parentElement;
  if (!parent) {
    return;
  }
  if (!parent.classList.contains('focused')) {
    parent.classList.add('focused');
  }
};

export const onInputBlur = (event: FocusEvent) => {
  const input = event.target as HTMLInputElement;
  const parent = input?.parentElement;
  if (!parent) {
    return;
  }
  if (parent.classList.contains('focused')) {
    parent.classList.remove('focused');
  }
  if (input.value.length > 0) {
    parent.classList.add('filled');
  } else if (parent.classList.contains('filled')) {
    parent.classList.remove('filled');
  }
};

export const TextAreaFocus = (event: FocusEvent) => {
  const input = event.target as HTMLTextAreaElement;
  const parent = input?.parentElement;
  if (!parent) {
    return;
  }
  if (!parent.classList.contains('focused')) {
    parent.classList.add('focused');
    (parent.querySelector('.hintTextArea') as HTMLElement).classList.remove('hide-text');
    input.placeholder = '';
  }
};

export const TextAreaFocusOut = (event: FocusEvent) => {
  const input = event.target as HTMLTextAreaElement;
  const parent = input?.parentElement;
  if (!parent) {
    return;
  }
  if (input.value.length > 0) {
    parent.classList.remove('focused');
    parent.classList.add('filled');
    input.placeholder = '';
  } else {
    parent.classList.remove('focused');
    const hintTextArea = parent.querySelector('.hintTextArea') as HTMLElement;
    hintTextArea.classList.add('hide-text');
    input.placeholder = hintTextArea.textContent ?? '';
  }
};

export const CheckAvailability = () => {
  const FirstName = (document.getElementById('inputFirstNameAboutThisCar') as HTMLInputElement)
    .value;
  const LastName = (document.getElementById('inputLastNameAboutThisCar') as HTMLInputElement).value;
  const PhoneNumber = (
    document.getElementById('inputPhoneNumberAskAboutThisCar') as HTMLInputElement
  ).value;
  const Email = (document.getElementById('inputEmailAskAboutThisCar') as HTMLInputElement).value;
  const ZipCode = (document.getElementById('inputZipCodeAboutThisCar') as HTMLInputElement).value;
  const comments = (document.getElementById('comments') as HTMLInputElement).value;
  const vin = (document.getElementById('selectedVehicleVin') as HTMLInputElement).value;
  const make = (document.getElementById('selectedVehicleMake') as HTMLInputElement).value;
  const model = (document.getElementById('selectedVehicleModel') as HTMLInputElement).value;
  const year = (document.getElementById('selectedVehicleYear') as HTMLInputElement).value;

  CheckAvailabilityService.CheckAvailability(
    FirstName,
    LastName,
    Email,
    PhoneNumber,
    comments,
    ZipCode,
    vin
  );

  const eventName = 'Lead';
  const contentName = 'EchoPark.com SRP Check Availability';
  const capiEventId = generateCapiEventId('EchoPark.com SRP Check Availability');
  const requestUrl = window.location.href;
  const baseUrl = window.location.origin;
  const contentCategory = 'Web Form Lead Sent';
  const value = '1';
  const search = '';
  const street = '';
  const city = '';
  const state = '';
  const userAgent = navigator.userAgent;
  const fbp = getFpbFromCookie();
  const fbclid = getFbClidFromUrl();

  const obj = {
    event: 'Lead Sent',
    Source: 'EchoPark.com SRP Check Availability',
    Subsource: 'Form Submitted',
    Location: ZipCode,
    VOI_VIN: vin,
    VOI_Make: make,
    VOI_Model: model,
    VOI_Year: year,
    CAPI_event_id: capiEventId,
  };

  const userData = generateUserDataSrp(
    FirstName,
    LastName,
    street,
    city,
    state,
    ZipCode,
    Email,
    PhoneNumber,
    userAgent
  );
  const capiModel = generateCapiModelSrp(
    eventName,
    contentName,
    capiEventId,
    requestUrl,
    baseUrl,
    vin,
    userData,
    contentCategory,
    value,
    search,
    fbp,
    fbclid
  );

  sendFacebookCapiEvent(capiModel);
  sendTikTokCapiEvent(capiModel, 'Contact');
  emitter.emit('analytics', obj);
};

/* NOTE: most of facetHelper can/should be converted to fully typescript types, but waiting until the vue3 upgrade is stable */
import * as facetHelper from './facetHelper';

export function addPageQueryParam(qParams, queryParams) {
  const pageKey = facetHelper.facetMetaData['page'].key;
  if (pageKey && queryParams[pageKey] && queryParams[pageKey] > 0) {
    qParams.push(
      `${facetHelper.facetMetaData[pageKey as string]?.urlPrefix ?? ''}${queryParams[pageKey]}`
    );
  }
}

export function addShipQueryParam(qParams: string[], ship: boolean) {
  const shipKey = facetHelper.facetMetaData['ship'].key;
  if (!ship) {
    // ship=true should never show in url (its the default)
    qParams.push(`${facetHelper.facetMetaData[shipKey]?.urlPrefix ?? ''}${ship}`);
  }
}

export function addSortQueryParam(qParams, sortType) {
  const sortKey: string = facetHelper.facetMetaData['sort']['key'];
  if (
    sortType.sortBy &&
    sortType.sortBy !== facetHelper.facetMetaData['sort']['sortByMap']['distance']
  ) {
    // sort by distance should not show in url (its the default)
    const prefix = facetHelper.facetMetaData[sortKey]?.urlPrefix ?? '';
    const sortValue = facetHelper.facetMetaData['sort']['sortByMap'][sortType.sortBy];
    const sortPath =
      sortValue === facetHelper.facetMetaData['sort']['sortByMap'].dateinstockticks
        ? sortValue
        : `${sortValue}-${facetHelper.facetMetaData['sort']['sortDirMap'][sortType.sortDirection]}`;
    qParams.push(`${prefix}${sortPath}`);
  }
}

export function addRemainingQueryParam(qParams: string[], remainingParams) {
  Object.keys(remainingParams).forEach((key) => {
    const value = remainingParams[key];
    qParams.push(`${key}=${value}`);
  });
}

export function addDealershipsQueryParam(qParams, includedDealerships, layoutPath) {
  if (includedDealerships && includedDealerships.length > 0 && !layoutPath) {
    // dealerships dont show when on an seo item (layoutPath is present)
    qParams.push(
      `${facetHelper.facetMetaData['dealer'].urlPrefix ?? ''}${includedDealerships.join('|')}`
    );
  }
}

export function addRadiusQueryParam(qParams, radius, shopByStore) {
  const radiusKey: string = facetHelper.facetMetaData['radius'].key;
  if (radius && !shopByStore) {
    // only show radius when on radius toggle
    const prefix = facetHelper.facetMetaData[radiusKey]?.urlPrefix ?? '';
    const radiusPath =
      radius === facetHelper.facetMetaData['radius'].radiusMap.nationwide.value
        ? facetHelper.facetMetaData['radius'].radiusMap.nationwide.key
        : radius;
    qParams.push(`${prefix}${radiusPath}`);
  }
}

export function addNumericQueryParams(qParams, facetFilters) {
  const numericValues = filterFacetKeys(
    Object.keys(facetHelper.orderedFacets(facetFilters, facetHelper.urlSortOrder)),
    facetHelper.getNumericKeys()
  );
  numericValues.forEach((facetName) => {
    qParams.push(getNumericPath(facetName, facetFilters));
  });
}

export function addMmtPathParam(paramsList, facetFilters, isCanonical) {
  const mmtKey = facetHelper.facetMetaData['mmt'].key;
  const getPath = isCanonical ? getCanonicalMmtPath : getMmtPath;

  if (facetFilters[mmtKey]) {
    paramsList.push(getPath(facetFilters[mmtKey]));
  }
}

export function addStringPathParams(paramsList, facetFilters, isCanonical) {
  const facetKeys = Object.keys(facetHelper.orderedFacets(facetFilters, facetHelper.urlSortOrder));
  const stringValues = filterFacetKeys(facetKeys, facetHelper.getStringKeys());
  const getPath = isCanonical ? getCanonicalStringPath : getStringPath;

  stringValues.forEach((facetName) => {
    if (facetFilters[facetName]) {
      paramsList.push(getPath(facetName, facetFilters));
    }
  });
}

// Canonical url only includes first selection of mmt
export function getCanonicalMmtPath(mmtFilter) {
  return facetHelper
    .getCanonicalMmtKeys(mmtFilter)
    .map((item: string) => item.toLowerCase().replace(/[\s/]+/g, '-'))
    .join('/');
}

export function getMmtPath(mmtFilter) {
  return facetHelper
    .getMmtKeys(mmtFilter)
    .map((item: string) => item.toLowerCase().replace(/[\s/]+/g, '-'))
    .join('/');
}

export function getStringPath(facetName: string, facetFilters) {
  const prefix = facetHelper.facetMetaData[facetName as string]?.urlPrefix ?? '';
  return facetFilters[facetName].facetValue
    .map((item) => `${prefix}${item.replace(' / ', '-').toLowerCase().replace(/[ /]/g, '-')}`) // .replace(" / ", "-") is to handle edge case "Leather / leatherette seats" -> "leather-leatherette-seats"
    .join('/');
}

/* Canonical facet selections only include the first of any 
   (i.e. if brown,blue,black selected only include brown) */
export function getCanonicalStringPath(facetName, facetFilters) {
  const prefix = facetHelper.facetMetaData[facetName as string]?.urlPrefix ?? '';
  return `${prefix}${facetFilters[facetName].facetValue[0].replace(' / ', '-').toLowerCase().replace(/[ /]/g, '-')}`;
}

export function getNumericPath(facetName, facetFilters) {
  const prefix = facetHelper.facetMetaData[facetName as string]?.urlPrefix ?? '';
  return `${prefix}${facetFilters[facetName].min}-${facetFilters[facetName].max}`;
}

export function filterFacetKeys(facetKeys, filterFunction) {
  return facetKeys.filter((facetFilter) => filterFunction.includes(facetFilter));
}

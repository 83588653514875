import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_placeholder = _resolveComponent("placeholder")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_ctx.overlayRoute?.placeholders)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.dialog,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dialog) = $event)),
          class: _normalizeClass(["overlay-handler", _ctx.overlayRoute.fields.ClassName.value as string]),
          persistent: (_ctx.overlayRoute.fields.IsPersistent.value as boolean) || false,
          id: _ctx.overlayRoute.fields.ClassName.value as string
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                (_ctx.overlayRoute.fields.Title.value as string)
                  ? (_openBlock(), _createBlock(_component_v_card_title, { key: 0 }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.overlayRoute.fields.Title.value) + " ", 1),
                        ((_ctx.overlayRoute?.fields?.CloseX?.value as boolean) || false)
                          ? (_openBlock(), _createBlock(_component_v_btn, {
                              key: 0,
                              icon: "ep-icon-close",
                              variant: "text",
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dialog = false)),
                              class: "modal-close"
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.overlayRoute.fields.SubTitle.value as string)
                  ? (_openBlock(), _createBlock(_component_v_card_subtitle, { key: 1 }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.overlayRoute.fields.SubTitle.value), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_placeholder, {
                      name: "overlays",
                      onDismiss: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dialog = false)),
                      rendering: _ctx.overlayRoute
                    }, null, 8, ["rendering"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue", "persistent", "class", "id"])
      ]))
    : _createCommentVNode("", true)
}
/**
 * Schema Validation Library for Standalone Credit Application
 *
 * This library is responsible for managing form validation logic in the Standalone Credit App.
 * It defines the process of creating a unified schema, generating validation schemas, and submitting form data.
 *
 * 1. **Schema Generation (Source of Truth)**:
 *    - The `createSchemaFields` function combines the Route One schema and Sitecore schema overrides.
 *    - This unified schema structure acts as the source of truth for form fields, containing metadata such as
 *      validation rules, labels, types, formats, and enumerations for each field.
 *    - The schema supports nested structures and recursively processes object-type fields.
 *
 * 2. **Validation Schema Generation**:
 *    - The `createValidationSchemas` function generates Yup validation schemas from the unified schema definitions.
 *    - These validation schemas enforce field rules, such as required fields, data types, and custom formats.
 *    - Sub-schemas are extracted and named hierarchically using a dot-separated notation (e.g., `name.contact`).
 *
 * 3. **Form Submission and Validation**:
 *    - The `validate` function is responsible for validating form data against the generated Yup schemas.
 *    - It filters out empty or non-relevant values from the form and iterates through each schema to perform validation.
 *    - If errors are encountered during validation, they are extracted and returned. If no errors are found, the function returns `null`.
 *
 * Functions and Utilities:
 * - `createSchemaFields`: Generates the unified schema structure from Route One and Sitecore.
 * - `createValidationSchemas`: Creates Yup validation schemas from the unified schema.
 * - `validate`: Validates form data against the Yup validation schemas.
 * - Utility functions (imported from `@util/schemaValidatorUtils`) handle tasks such as mapping Sitecore fields, filtering values, and extracting validation errors.
 *
 */

import * as Yup from 'yup';
import * as utils from '@/util/schema/schemaValidatorUtils';
import {
  SitecoreSchema,
  ValidationSchema,
  ValidationResult,
  SchemaDefinitions,
  ValidationSchemas,
  AbstractSchemaDefinitions,
  AbstractSchemaDefinition,
} from '@/types/StandaloneCreditApp/StandaloneCreditAppTypes';
import { enhanceAddressValidator } from '@/util/schema/schemaEnhancers/addressSchemaEnhancer';
import { enhanceEmploymentValidator } from '@/util/schema/schemaEnhancers/employmentSchemaEnhancer';

/**
 * Generate a unified schema structure with validation rules, labels, types, formats, and enums for each schema.
 * Supports nested schemas by recursively processing object-type fields.
 *
 * @param {AbstractSchemaDefinition} schema - The application schema object.
 * @param {SitecoreSchema} sitecoreSchema - The Sitecore schema object.
 * @param {string} schemaType - The type of schema being processed (e.g., 'SCA', 'OtherType').
 * @param {Object} formOptions - An object containing form-specific parameters.
 * @param {string} [formOptions.subsetName] - Specifies the child-level schema to place extra fields in.
 * @param {string} [formOptions.formName] - Specifies the top-level schema to place extra fields in.
 * @returns {Record<string, Record<string, ProcessedField>>} - An object mapping schema names to processed fields.
 */
export const createSchemaFields = (
  schema: AbstractSchemaDefinition,
  sitecoreSchema: SitecoreSchema,
  schemaType: string,
  formOptions: { subsetName?: string; formName?: string } = {}
): Record<string, any> => {
  const { subsetName, formName } = formOptions;
  const schemaStructure: Record<string, any> = {};
  const sitecoreFieldsMap = utils.mapSitecoreFields(sitecoreSchema);
  const matchedFields = new Set<string>(); // Tracks which sitecore fields are matched

  /*
   * Process inner "$defs" object- each one becomes a unified object in the schema structure, ie.
   * finance $def becomes a finance object in schemaStructure, integrating sitecore overrides
   */
  Object.entries(schema?.$defs as AbstractSchemaDefinitions).forEach(([schemaName, schema]) => {
    if (!schema?.properties) return;
    const requiredFieldsSet = new Set(schema.required || []);
    schemaStructure[schemaName] = utils.processNestedFields(
      schema.properties,
      requiredFieldsSet,
      sitecoreFieldsMap,
      matchedFields
    );
  });

  /* Any sitecore fields left that didnt have a matching Schema field name, gets put into the schema according
  to the subset of that sitecore field that comes in off the sitecore properties 

  Example: employerStartMonth has no corresponding API schema field of the same name, so check the sitecore 
  field subset, see "address", and put it in "address" object if it exists */
  if (Object.keys(sitecoreFieldsMap).length > 0) {
    Object.entries(sitecoreFieldsMap).forEach(([fieldName, fieldDef]) => {
      const subsetKey = subsetName || fieldDef.subset || 'sitecoreFields';

      if (formName) {
        schemaStructure[formName] = schemaStructure[formName] || {};
        schemaStructure[formName][subsetKey] = schemaStructure[formName][subsetKey] || {};

        schemaStructure[formName][subsetKey][fieldName] = {
          label: fieldDef.label,
          required: fieldDef.required || false,
          validationMessage: fieldDef.validationMessage || 'No validation message',
        };
      } else {
        schemaStructure[subsetKey] = schemaStructure[subsetKey] || {};

        schemaStructure[subsetKey][fieldName] = {
          label: fieldDef.label,
          required: fieldDef.required || false,
          validationMessage: fieldDef.validationMessage || 'No validation message',
        };
      }
    });
  }

  if (schemaType === 'SCA') {
    /* if SCA app, process outer layer (routeOne layer) */
    const requiredFieldsSet: Set<string> = new Set((schema?.required as string[]) || []);
    schemaStructure['routeOne'] = utils.processNestedFields(
      schema?.properties,
      requiredFieldsSet,
      {},
      new Set<string>()
    );
  }

  return schemaStructure;
};

/**
 * Generate Yup validation schemas from schema definitions.
 * @param {SchemaDefinitions} schemaDefinitions - The application schema definitions (combined from schema and Sitecore).
 * @param {string} schemaType - The type of schema being processed (e.g., 'SCA', 'OtherType').
 * @returns {ValidationSchemas} - An object containing Yup validation schemas.
 */
export const createValidationSchemas = (
  schemaDefinitions: SchemaDefinitions,
  schemaType: string
): ValidationSchemas => {
  const validationSchemas: ValidationSchemas = {};

  Object.keys(schemaDefinitions).forEach((schemaName) => {
    const schema = schemaDefinitions[schemaName];
    if (!schema) return;

    validationSchemas[schemaName] = Yup.object().shape(
      utils.buildAndExtractSubSchemas(schema, validationSchemas, schemaName)
    );
  });

  if (schemaType === 'SCA') {
    enhanceAddressValidator(validationSchemas, schemaDefinitions);
    enhanceEmploymentValidator(validationSchemas, schemaDefinitions);
  }

  return validationSchemas;
};

/**
 * Submit form data by validating against multiple schemas.
 * @param {ValidationSchema[]} schemas - Array of Yup schemas to validate.
 * @param {Record<string, any>} values - The form values to validate.
 * @returns {Promise<ValidationResult>} - A promise resolving to errors or null if no errors.
 */
export const validate = async (
  schemas: ValidationSchema[],
  values: Record<string, any>
): Promise<ValidationResult> => {
  try {
    const filteredValues = utils.filterNonEmptyValues(values);
    for (const schema of schemas) {
      await schema.validate(filteredValues, { abortEarly: false });
    }
    return null; // No errors found
  } catch (error) {
    if (error instanceof Yup.ValidationError) {
      return utils.extractValidationErrors(error);
    }
  }
  return null;
};

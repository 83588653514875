<template>
  <div class="vehicle-and-location" v-if="view !== 'confirmation'">
    <div class="date" v-if="day && time && view === 'contactInfo'">
      <p class="sub-head">
        <span data-testid="test-drive-dayAndTimeLabel">
          <v-icon icon="ep-icon-calendar"></v-icon>
          {{ fields.dateAndTimeLabel.value }}
        </span>
        <span
          class="edit-link"
          @click="editDayAndTime"
          @keyup.enter="editDayAndTime"
          data-testid="test-drive-editLabel"
        >
          <v-icon icon="ep-icon-edit"></v-icon>
          {{ editLabel }}
        </span>
      </p>
      <p>{{ getLocaleDay(day) }}</p>
      <p>{{ time }}</p>
    </div>
    <div
      class="location"
      v-if="lgAndUp || view === 'dayAndTime' || (view === 'contactInfo' && showDetails)"
    >
      <p class="sub-head" data-testid="test-drive-locationLabel">
        <v-icon icon="ep-icon-location-building"></v-icon>
        {{ fields.locationLabel.value }}
      </p>
      <p>{{ formatDealerId(dealership?.dealerId ?? '') }} ({{ vehicle?.distanceToUser }} mi)</p>
      <p class="location-address">
        {{ dealership.address }}<br />
        {{ dealership.city }}, {{ dealership.state }} {{ dealership.zip }}
      </p>
    </div>
    <div
      class="vehicle"
      v-if="lgAndUp || view === 'dayAndTime' || (view === 'contactInfo' && showDetails)"
    >
      <p class="sub-head" data-testid="test-drive-vehicleLabel">
        <v-icon icon="ep-icon-car"></v-icon>
        {{ fields.vehicleLabel.value }}
      </p>
      <ScheduleTestDriveVehicleInfo />
    </div>
    <div
      class="show-details"
      v-if="mdAndDown && view === 'contactInfo'"
      @click="toggleShowDetails"
      @keyup.enter="toggleShowDetails"
    >
      <span>
        <template v-if="showDetails">
          {{ fields.hideDetailsLabel.value }} <v-icon icon="ep-icon-chevron-up"></v-icon>
        </template>
        <template v-else>
          {{ fields.showDetailsLabel.value }} <v-icon icon="ep-icon-chevron-down"></v-icon>
        </template>
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useDisplay } from 'vuetify';
import ScheduleTestDriveVehicleInfo from '@components/Page/ScheduleTestDrive/ScheduleTestDrive--VehicleInfo.vue';
import { formatDealerId } from '@/util/scheduleTestDriveUtil';
const { mdAndDown, lgAndUp } = useDisplay();
</script>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { mapActions, mapState } from 'pinia';
import { useScheduleTestDriveStore } from '@/stores/scheduleTestDrive';
import { FieldsPropType } from '@/lib/FieldTypes';

export default defineComponent({
  name: 'ScheduleTestDrive--VehicleAndLocation',
  components: {},
  computed: {
    ...mapState(useScheduleTestDriveStore, ['day', 'time', 'view', 'vehicle', 'dealership']),
  },
  props: {
    fields: {
      type: Object as PropType<FieldsPropType>,
      default: () => ({}),
    },
    rendering: {
      type: Object,
      default: () => ({}),
    },
    params: {
      type: Object,
      default: () => ({}),
    },
    errorComponent: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dateAndTimeLabel: 'Date & time',
      editLabel: 'Edit',
      location: {},
      hideDetailsLabel: 'Hide details',
      locationLabel: 'Location',
      showDetails: false,
      showDetailsLabel: 'Show details',
      vehicleLabel: 'Car',
    };
  },
  methods: {
    ...mapActions(useScheduleTestDriveStore, ['setTestDriveView']),
    editDayAndTime() {
      this.showDetails = false;
      this.setTestDriveView('dayAndTime');
    },
    getLocaleDay(day: string) {
      const normalizedDay = day.replaceAll('-', '/');
      const date = new Date(`${normalizedDay} GMT-12:00`); // we don't want timezone to be a factor in the simple date conversion
      return date.toLocaleDateString('en-US', {
        weekday: 'long',
        year: undefined,
        month: 'long',
        day: 'numeric',
      });
    },
    toggleShowDetails() {
      this.showDetails = !this.showDetails;
    },
  },
});
</script>

<style lang="scss">
.vehicle-and-location {
  background: $park-gray-100;
  padding: $spacing_inset-sm;
  padding-bottom: 16px;

  @include media-breakpoint-up(lg) {
    flex: none;
    width: (300px - $spacing_inline-xxs);
    height: fit-content;
    border-radius: $border-radius-lg;
    padding: $spacing_stretch-xs;
    margin-left: $spacing_inline-xxs;
    margin-bottom: $spacing_stack-xxs;
  }
}

.sub-head {
  margin-bottom: $spacing_stack-nano;

  &,
  span {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }
  .v-icon {
    font-size: $font-size-xxs;
  }
  .date & {
    justify-content: space-between;
  }
}

.date + .location {
  margin-top: $spacing_stack-xxs;
}

.location-address {
  font-size: $font-size-xxxs;
  font-weight: $font-weight-light;
}

.vehicle {
  margin-top: $spacing_stack-xxs;
}

.vehicle-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: $spacing_inline-nano;

  img {
    width: 64px;
    height: 64px;
    border-radius: $border-radius-md;
    object-fit: cover;
    gap: 8px;
  }
}

.details p {
  width: 100%;
}

.year-miles-price {
  font-size: $font-size-xxxs;
  font-weight: $font-weight-light;

  span + span:before {
    display: inline-block;
    content: '|';
    color: $park-gray-70;
    margin: 0 $spacing_inline-nano;
  }
}

.edit-link,
.show-details {
  color: $system-alert-blue-60;
  font-weight: $font-weight-medium;
  font-size: $font-size-xxxs;
  line-height: $line-height-xs;
  letter-spacing: 0.2px;
  cursor: pointer;

  span {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
}

.show-details {
  margin-top: $spacing_stack-xxxs;

  span {
    align-items: center;
  }
  i {
    margin-left: 2px;
  }
  .vehicle + & {
    margin-top: $spacing_stack-xxs;
  }
}
</style>

import { createApp as createVueApp, h, createSSRApp } from 'vue';
import { SitecoreJssPlaceholderPlugin } from '@sitecore-jss/sitecore-jss-vue';
import { DefaultApolloClient } from '@vue/apollo-composable';
import AppRoot from './AppRoot.vue';
import { createRouter } from './router';
import SitecoreJssStorePlugin from './lib/SitecoreJssStorePlugin';
import GraphQLClientFactory from './lib/GraphQLClientFactory';
import config from '@/appConfig';
import componentFactory from './temp/componentFactory';
import { createHead, VueHeadMixin } from '@unhead/vue';
import vuetify from '@/plugins/vuetify';
import emitter from '@/util/eventBus';
import { mergeDeep } from './util/commonUtils';
import { createPinia } from 'pinia';
import { useCommonStore } from '@/stores/common';
import { useVehicleSearchStore } from '@/stores/vehicleSearch';
import { useScheduleTestDriveStore } from '@/stores/scheduleTestDrive';
import { useStandaloneCreditAppStore } from '@/stores/standaloneCreditApp';

// createApp is invoked by both the main and SSR entry points, so the two entry points can use the same app creation process.
export function createApp(initialSitecoreState: any, i18n: any, piniaState: any, isSSR: boolean) {
  const router = createRouter(isSSR);
  const graphQLProvider = createGraphQLProvider(initialSitecoreState);

  const vueOptions = {
    router,
    render: () => h(AppRoot),
    i18n,
  };

  const app = isSSR ? createSSRApp(vueOptions) : createVueApp(vueOptions);

  app.provide(DefaultApolloClient, graphQLProvider);

  const store = createPinia();
  app.use(store);

  // For SSR to work correctly, we need to initialize the known stores when creating the pinia instance
  // Otherwise, the returned pinia instance isn't  fully aware of the stores it should be tracking
  useCommonStore();
  useVehicleSearchStore();
  useScheduleTestDriveStore();
  useStandaloneCreditAppStore();

  if (piniaState) {
    store.state.value = mergeDeep(store.state.value, piniaState);
  }

  app.use(router);
  app.use(SitecoreJssStorePlugin);
  app.use(SitecoreJssPlaceholderPlugin, { componentFactory });
  app.use(i18n);
  app.use(vuetify);

  const head = createHead();
  app.use(head);
  app.mixin(VueHeadMixin);

  // if there is an initial state defined, push it into the store, where it can be referenced by interested components.
  if (initialSitecoreState) {
    app.config.globalProperties.$jss.store.setSitecoreData(initialSitecoreState);
  }

  // Add emitter to the global instance, so it can be accessed with this.emitter in components
  // Note: the emitter can be imported in non-components directly using "import emitter from '@/util/eventBus"
  app.config.globalProperties.emitter = emitter;

  return { app, router, graphQLProvider, metaInfo: head };
}

export function createGraphQLProvider(initialState: any) {
  const client = initialState?.APOLLO_STATE
    ? GraphQLClientFactory(config.graphQLEndpoint, false, initialState.APOLLO_STATE)
    : GraphQLClientFactory(config.graphQLEndpoint, true);

  return client;
}

<template>
  <template v-if="isCobuyer">
    <v-row>
      <v-col cols="12" lg="3">
        <p>{{ $t('employment-status-label') }}</p>
        <p class="small">
          {{
            isPrevious
              ? buyer?.employmentDetails.previousEmploymentStatusCode
              : buyer?.employmentDetails.employmentStatusCode
          }}
        </p>
      </v-col>
      <v-col cols="12" lg="3" v-if="employmentConfigSelected.showEmployerName">
        <p>{{ $t('employer-name-label') }}</p>
        <p class="small">
          {{
            isPrevious
              ? buyer?.employmentDetails.previousEmployerName
              : buyer?.employmentDetails.employerName
          }}
        </p>
      </v-col>
      <v-col cols="12" lg="3" v-if="employmentConfigSelected.showEmployerPhone">
        <p>{{ $t('employer-phone-label') }}</p>
        <p class="small">
          {{
            isPrevious
              ? displayPhoneNumber(buyer?.employmentDetails.previousEmployerPhone)
              : displayPhoneNumber(buyer?.employmentDetails.employerPhone)
          }}
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" lg="3" v-if="employmentConfigSelected.showEmploymentTitle">
        <p>{{ $t('job-title-label') }}</p>
        <p class="small">
          {{
            isPrevious
              ? buyer?.employmentDetails.previousEmploymentTitle
              : buyer?.employmentDetails.employmentTitle
          }}
        </p>
      </v-col>
      <v-col cols="12" lg="3">
        <p>{{ $t('start-date-label') }}</p>
        <p class="small">
          {{ isPrevious ? previousFormattedMonthAndYear : formattedMonthAndYear }}
        </p>
      </v-col>
      <v-col cols="12" lg="3">
        <p>{{ $t('annual-income-label') }}</p>
        <p class="small">{{ formatCurrency(convertIncomeToAnnual(isPrevious)) }}</p>
      </v-col>
    </v-row>
  </template>

  <template v-else>
    <v-row>
      <v-col cols="12" class="pb-0">
        <p class="large light">
          {{ $t(isPrevious ? 'previous-job-label' : 'current-employment-label') }}
        </p>
      </v-col>
      <v-col cols="12" lg="3">
        <p>{{ $t('employment-status-label') }}</p>
        <p class="small">
          {{
            isPrevious
              ? buyer?.employmentDetails.previousEmploymentStatusCode
              : buyer?.employmentDetails.employmentStatusCode
          }}
        </p>
      </v-col>
      <v-col cols="12" lg="3" v-if="employmentConfigSelected.showEmployerName">
        <p>{{ $t('employer-name-label') }}</p>
        <p class="small">
          {{
            isPrevious
              ? buyer?.employmentDetails.previousEmployerName
              : buyer?.employmentDetails.employerName
          }}
        </p>
      </v-col>
      <v-col cols="12" lg="3" v-if="employmentConfigSelected.showEmployerPhone">
        <p>{{ $t('employer-phone-label') }}</p>
        <p class="small">
          {{
            isPrevious
              ? displayPhoneNumber(buyer?.employmentDetails.previousEmployerPhone)
              : displayPhoneNumber(buyer?.employmentDetails.employerPhone)
          }}
        </p>
      </v-col>
      <v-col cols="12" lg="3" v-if="employmentConfigSelected.showEmploymentTitle">
        <p>{{ $t('job-title-label') }}</p>
        <p class="small">
          {{
            isPrevious
              ? buyer?.employmentDetails.previousEmploymentTitle
              : buyer?.employmentDetails.employmentTitle
          }}
        </p>
      </v-col>
      <v-col cols="12" lg="3">
        <p>{{ $t('start-date-label') }}</p>
        <p class="small">
          {{ isPrevious ? previousFormattedMonthAndYear : formattedMonthAndYear }}
        </p>
      </v-col>
      <v-col cols="12" lg="3">
        <p>{{ $t('annual-income-label') }}</p>
        <p class="small">
          {{ formatCurrency(convertIncomeToAnnual(isPrevious)) }}
        </p>
      </v-col>
    </v-row>
  </template>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { useStandaloneCreditAppStore } from '@/stores/standaloneCreditApp';
import { mapActions } from 'pinia';
import {
  EmploymentConfig,
  employmentConfig,
} from '@/types/StandaloneCreditApp/StandaloneCreditAppTypes';

export default defineComponent({
  name: 'EmploymentSection',
  props: {
    buyer: {
      type: Object as PropType<Record<string, any> | undefined>,
      required: true,
    },
    isPrevious: {
      type: Boolean,
      default: false,
    },
    isCobuyer: {
      type: Boolean,
      default: false,
    },
    formattedMonthAndYear: {
      type: String as PropType<string | undefined>,
      required: true,
    },
    previousFormattedMonthAndYear: {
      type: String as PropType<string | undefined>,
      required: true,
    },
  },
  methods: {
    ...mapActions(useStandaloneCreditAppStore, ['formatCurrency', 'displayPhoneNumber']),
    convertIncomeToAnnual(isPrevious?: boolean): number {
      const employmentDetails = this.buyer?.employmentDetails;
      if (!employmentDetails) return 0;

      if (isPrevious) {
        return employmentDetails.previousIncomeIntervalCode === 'MO'
          ? (employmentDetails.previousIncomeAmount || 0) * 12
          : employmentDetails.previousIncomeAmount || 0;
      }

      return employmentDetails.incomeIntervalCode === 'MO'
        ? (employmentDetails.incomeAmount || 0) * 12
        : employmentDetails.incomeAmount || 0;
    },
  },
  computed: {
    employmentConfigSelected(): EmploymentConfig {
      const employmentStatusCode = this.isPrevious
        ? this.buyer?.employmentDetails?.previousEmploymentStatusCode
        : this.buyer?.employmentDetails?.employmentStatusCode;
      return (
        employmentConfig?.[employmentStatusCode] ?? {
          showEmployerName: false,
          showEmployerPhone: false,
          showEmploymentTitle: false,
          showIncome: false,
        }
      );
    },
  },
});
</script>

<style lang="scss"></style>

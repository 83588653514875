import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sca-form-header" }
const _hoisted_2 = { class: "sca-form-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_sc_rich_text = _resolveComponent("sc-rich-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_row, { "no-gutters": "" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, { cols: "12" }, {
          default: _withCtx(() => [
            _createElementVNode("h6", _hoisted_2, _toDisplayString(_ctx.title), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.note || _ctx.$slots.note)
      ? (_openBlock(), _createBlock(_component_v_row, {
          key: 0,
          "no-gutters": ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { cols: "12" }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "note", {}, () => [
                  _createVNode(_component_sc_rich_text, {
                    field: _ctx.note,
                    class: "sca-header-note"
                  }, null, 8, ["field"])
                ])
              ]),
              _: 3
            })
          ]),
          _: 3
        }))
      : _createCommentVNode("", true)
  ]))
}
<template>
  <v-row class="style-guide--spacing-inset">
    <v-col cols="12" lg="4">
      <h3>Inset</h3>
      <v-row class="align-center">
        <v-col cols="4" lg="6">
          <div class="box spacing_inset-quark"></div>
        </v-col>
        <v-col cols="8" lg="6">
          <code>$spacing_inset-quark</code>
        </v-col>
      </v-row>
      <v-row class="align-center">
        <v-col cols="4" lg="6">
          <div class="box spacing_inset-nano"></div>
        </v-col>
        <v-col cols="8" lg="6">
          <code>$spacing_inset-nano</code>
        </v-col>
      </v-row>
      <v-row class="align-center">
        <v-col cols="4" lg="6">
          <div class="box spacing_inset-xs"></div>
        </v-col>
        <v-col cols="8" lg="6">
          <code>$spacing_inset-xs</code>
        </v-col>
      </v-row>
      <v-row class="align-center">
        <v-col cols="4" lg="6">
          <div class="box spacing_inset-sm"></div>
        </v-col>
        <v-col cols="8" lg="6">
          <code>$spacing_inset-sm</code>
        </v-col>
      </v-row>
      <v-row class="align-center">
        <v-col cols="4" lg="6">
          <div class="box spacing_inset-md"></div>
        </v-col>
        <v-col cols="8" lg="6">
          <code>$spacing_inset-md</code>
        </v-col>
      </v-row>
      <v-row class="align-center">
        <v-col cols="4" lg="6">
          <div class="box spacing_inset-lg"></div>
        </v-col>
        <v-col cols="8" lg="6">
          <code>$spacing_inset-lg</code>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" lg="4">
      <h3>Inset Squish</h3>
      <v-row class="align-center">
        <v-col cols="4" lg="6">
          <div class="box spacing_squish-quark"></div>
        </v-col>
        <v-col cols="8" lg="6">
          <code>$spacing_squish-quark</code>
        </v-col>
      </v-row>
      <v-row class="align-center">
        <v-col cols="4" lg="6">
          <div class="box spacing_squish-nano"></div>
        </v-col>
        <v-col cols="8" lg="6">
          <code>$spacing_squish-nano</code>
        </v-col>
      </v-row>
      <v-row class="align-center">
        <v-col cols="4" lg="6">
          <div class="box spacing_squish-xs"></div>
        </v-col>
        <v-col cols="8" lg="6">
          <code>$spacing_squish-xs</code>
        </v-col>
      </v-row>
      <v-row class="align-center">
        <v-col cols="4" lg="6">
          <div class="box spacing_squish-sm"></div>
        </v-col>
        <v-col cols="8" lg="6">
          <code>$spacing_squish-sm</code>
        </v-col>
      </v-row>
      <v-row class="align-center">
        <v-col cols="4" lg="6">
          <div class="box spacing_squish-md"></div>
        </v-col>
        <v-col cols="8" lg="6">
          <code>$spacing_squish-md</code>
        </v-col>
      </v-row>
      <v-row class="align-center">
        <v-col cols="4" lg="6">
          <div class="box spacing_squish-lg"></div>
        </v-col>
        <v-col cols="8" lg="6">
          <code>$spacing_squish-lg</code>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" lg="4">
      <h3>Inset Stretch</h3>
      <v-row class="align-center">
        <v-col cols="4" lg="6">
          <div class="box spacing_stretch-quark"></div>
        </v-col>
        <v-col cols="8" lg="6">
          <code>$spacing_stretch-quark</code>
        </v-col>
      </v-row>
      <v-row class="align-center">
        <v-col cols="4" lg="6">
          <div class="box spacing_stretch-nano"></div>
        </v-col>
        <v-col cols="8" lg="6">
          <code>$spacing_stretch-nano</code>
        </v-col>
      </v-row>
      <v-row class="align-center">
        <v-col cols="4" lg="6">
          <div class="box spacing_stretch-xs"></div>
        </v-col>
        <v-col cols="8" lg="6">
          <code>$spacing_stretch-xs</code>
        </v-col>
      </v-row>
      <v-row class="align-center">
        <v-col cols="4" lg="6">
          <div class="box spacing_stretch-sm"></div>
        </v-col>
        <v-col cols="8" lg="6">
          <code>$spacing_stretch-sm</code>
        </v-col>
      </v-row>
      <v-row class="align-center">
        <v-col cols="4" lg="6">
          <div class="box spacing_stretch-md"></div>
        </v-col>
        <v-col cols="8" lg="6">
          <code>$spacing_stretch-md</code>
        </v-col>
      </v-row>
      <v-row class="align-center">
        <v-col cols="4" lg="6">
          <div class="box spacing_stretch-lg"></div>
        </v-col>
        <v-col cols="8" lg="6">
          <code>$spacing_stretch-lg</code>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'StyleGuide--SpacingInset',
  components: {},
  data() {
    return {};
  },
});
</script>

<style lang="scss">
.style-guide--spacing-inset {
  &.v-row {
    margin: 0 -24px;

    h3 {
      padding: 12px;
    }
  }

  .box {
    width: 100% !important;
    max-width: 120px !important;
    height: 100px !important;
    background: $base-white-100;
    border: 0 solid #c4e0b7;

    // inset
    &.spacing_inset-quark {
      border-width: $spacing_inset-quarck;
    }
    &.spacing_inset-nano {
      border-width: $spacing_inset-nano;
    }
    &.spacing_inset-xs {
      border-width: $spacing_inset-xs;
    }
    &.spacing_inset-sm {
      border-width: $spacing_inset-sm;
    }
    &.spacing_inset-md {
      border-width: $spacing_inset-md;
    }
    &.spacing_inset-lg {
      border-width: $spacing_inset-lg;
    }

    // squish
    &.spacing_squish-quark {
      border-width: $spacing_squish-quarck;
    }
    &.spacing_squish-nano {
      border-width: $spacing_squish-nano;
    }
    &.spacing_squish-xs {
      border-width: $spacing_squish-xs;
    }
    &.spacing_squish-sm {
      border-width: $spacing_squish-sm;
    }
    &.spacing_squish-md {
      border-width: $spacing_squish-md;
    }
    &.spacing_squish-lg {
      border-width: $spacing_squish-lg;
    }

    // stretch
    &.spacing_stretch-quark {
      border-width: $spacing_stretch-quarck;
    }
    &.spacing_stretch-nano {
      border-width: $spacing_stretch-nano;
    }
    &.spacing_stretch-xs {
      border-width: $spacing_stretch-xs;
    }
    &.spacing_stretch-sm {
      border-width: $spacing_stretch-sm;
    }
    &.spacing_stretch-md {
      border-width: $spacing_stretch-md;
    }
    &.spacing_stretch-lg {
      border-width: $spacing_stretch-lg;
    }
  }
}
</style>

import { ScheduleTestDriveContact } from '@/types/ScheduleTestDrive';
import axios from 'axios';
import {
  generateCapiEventId,
  getFpbFromCookie,
  getFbClidFromUrl,
  generateUserDataSrp,
  generateCapiModelSrp,
  sendFacebookCapiEvent,
  sendTikTokCapiEvent,
} from '@assets/js/capiFunctions';
import emitter from '@util/eventBus';

const API_URL = '/api/sitecore/VehicleDetails/SubmitForScheduleTestDriveToELeadCRM';
const HEADERS = {
  'Content-Type': 'application/json',
};

interface SubmitParams {
  vin: string;
  firstName: string;
  lastName: string;
  phoneNum: string;
  email: string;
  dueDate: string;
  endTime: string;
  startTime: string;
  location: string;
}

export async function submitForScheduleTestDriveToELeadCRM(
  contact: ScheduleTestDriveContact,
  endTime: string,
  startTime: string,
  dueDate: string,
  location: string,
  vin: string,
  setTestDriveView: (view: string) => void,
  setLoadingState: (loading: boolean) => void,
  showError: (message: string) => void
) {
  const params: SubmitParams = {
    vin,
    firstName: contact.first,
    lastName: contact.last,
    phoneNum: contact.mobile,
    email: contact.email,
    dueDate,
    endTime,
    startTime,
    location,
  };

  try {
    setLoadingState(true);

    const response = await axios.post(API_URL, params, { headers: HEADERS });

    if (response.data && response.data.Status === 1) {
      setTestDriveView('confirmation');
      handleSuccess(response.data, params);
    } else {
      showError(response.data?.ErrorMessage || 'An error occurred');
    }
  } catch (error) {
    console.error('Error in submitForScheduleTestDriveToELeadCRM:', error);
    showError('An unexpected error occurred during submission.');
  } finally {
    setLoadingState(false);
  }
}

function handleSuccess(data: any, params: SubmitParams) {
  const vin = params.vin;
  const userData = generateUserDataSrp(
    params.firstName,
    params.lastName,
    '',
    '',
    '',
    '',
    params.email,
    params.phoneNum,
    navigator.userAgent
  );

  const contentCategory = 'Web Form Lead Sent';
  const eventName = 'Lead';
  const contentName = 'Schedule Test Drive';
  //const opportunityId = data.OpportunityId;
  const requestUrl = window.location.href;
  const fbp = getFpbFromCookie();
  const fbclid = getFbClidFromUrl();
  const capiEventId = generateCapiEventId('Schedule Test Drive');

  const capiModel = generateCapiModelSrp(
    eventName,
    contentName,
    capiEventId,
    requestUrl,
    window.location.origin,
    vin,
    userData,
    contentCategory,
    '1',
    '',
    fbp,
    fbclid
  );

  const eventObject = {
    event: 'Lead Sent',
    Source: 'Schedule Test Drive',
    Subsource: '',
    Location: params.location,
    Status: '',
    SubStatus: '',
    CAPI_event_id: capiEventId,
  };

  emitter.emit('analytics', eventObject);
  sendFacebookCapiEvent(capiModel);
  sendTikTokCapiEvent(capiModel, 'SubmitForm');
}

export const formatDealerId = (dealerId: string): string => {
  const pattern = /^EchoPark([A-Z][a-z]+)/;
  if (pattern.test(dealerId)) {
    return dealerId.replace(pattern, 'EchoPark $1');
  }
  return dealerId;
};

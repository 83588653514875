import config from './temp/config';

const apiKey = process.env.SITECORE_API_KEY || config.sitecoreApiKey;
let apiHost = process.env.SITECORE_API_HOST || config.sitecoreApiHost;
let sitecoreApiHost;

if (typeof window !== 'undefined' && config.deployedEnv) {
  apiHost = '/';
  sitecoreApiHost = `${window.location.protocol}//${window.location.hostname}/`;
} else if (!apiHost.endsWith('/')) {
  apiHost = apiHost + '/';
  sitecoreApiHost = apiHost;
}
const DEALERSHIP_API_URL = `${apiHost}api/dealership-search`;
const VEHICLE_API_URL = `${apiHost}api/vehicle-inventory-search`;
const STORES_API_URL = `${apiHost}api/sitecore/Stores/IsUSAZipcode`;
const LOGIN_API_URL = `${apiHost}api/cxa/EchoParkLogin/LoginUser`;
const REGISTRATION_API_URL = `${apiHost}api/cxa/Registration/RegisterUser`;
const COUNT_API_URL = `${apiHost}api/facet-counts`;
//const ROUTE_ONE_BASE_URL = 'https://7z56m2ew7c.execute-api.us-east-1.amazonaws.com/api';
const ROUTE_ONE_BASE_URL = `/api/credit-app`;
const DEFAULT_ROUTE_ONE_API_KEY = 'yjXES9j7mP1iRacklg1wA8v3h7HUOyLG8BZGR9FY';

export default {
  sitecoreApiKey: apiKey,
  srpTemplateId: '64b1a7b6-e070-4835-aa5f-9b3b539058cc',
  sitecoreApiHost: sitecoreApiHost || '/',
  jssAppName: config.jssAppName || 'echopark-jss-app',
  site: config.jssAppName,
  defaultLanguage: config.defaultLanguage || 'en',
  graphQLEndpointPath: !config.deployedEnv ? config.graphQLEndpointPath : '/api/echopark-jss-app',
  graphQLEndpoint: !config.deployedEnv
    ? config.graphQLEndpoint
    : `${apiHost}api/echopark-jss-app?sc_apikey=${apiKey}`,
  dealershipApiEndpoint: DEALERSHIP_API_URL,
  vehicleApiEndpoint: VEHICLE_API_URL,
  storesApiEndpoint: STORES_API_URL,
  loginApiEndpoint: LOGIN_API_URL,
  registrationApiEndpoint: REGISTRATION_API_URL,
  countApiEndpoint: COUNT_API_URL,
  routeOne: {
    baseEndpoint: ROUTE_ONE_BASE_URL,
    defaultApiKey: DEFAULT_ROUTE_ONE_API_KEY,
  },
  layoutServiceConfigurationName: config.layoutServiceConfigurationName,
};

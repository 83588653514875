<template>
  <visitor-identification />
  <OverlayHandler />
  <div :class="scrollDirection" class="sc-wrapper">
    <placeholder v-if="route" name="jss-header" :rendering="route" />
    <placeholder v-if="route" name="jss-main" :rendering="route" class="jss-main" />
    <placeholder v-if="route" name="jss-footer" :rendering="route" class="jss-footer" />
  </div>
</template>
<script lang="ts">
import { PropType, defineComponent } from 'vue';
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';
import VisitorIdentification from './VisitorIdentification.vue';
import OverlayHandler from './OverlayHandler.vue';
import { HeaderData } from './types/SEOMetaData';
import { mapActions, mapState } from 'pinia';
import { useCommonStore } from '@/stores/common';

export default defineComponent({
  name: 'Layout',
  data() {
    return {
      lastScrollTop: 0,
      scrollDirection: 'scrollDown',
    };
  },
  props: {
    route: {
      type: Object as PropType<any>,
      default: () => {},
    },
  },
  components: {
    Placeholder,
    VisitorIdentification,
    OverlayHandler,
  },
  head() {
    const canonical = this.headerData?.link?.find((m) => m.rel == 'canonical');
    if (canonical && canonical.href == '') {
      const index = this.headerData.link.indexOf(canonical);
      this.headerData.link.splice(index, 1);
    }
    return this.headerData;
  },
  methods: {
    ...mapActions(useCommonStore, ['setHeaderData']),
    handleScroll() {
      const currentScrollTop = Math.abs(document.body.getBoundingClientRect().top);
      if (currentScrollTop >= this.lastScrollTop) {
        // scrolling down
        this.scrollDirection = 'scrollDown';
      } else {
        if (currentScrollTop < 100) {
          // scrolling up
          // this ensures the scrollUp style stops 100 px from the top, so that the search bar reverts to its normal state
          this.scrollDirection = '';
        } else {
          this.scrollDirection = 'scrollUp';
        }
      }
      this.lastScrollTop = currentScrollTop;
    },
  },
  computed: {
    ...mapState(useCommonStore, ['headerData']),
  },
  created() {
    const initialHeaderData = {
      title: this.route?.fields?.['Meta Title']?.value ?? 'Page',
      meta: [
        {
          name: 'description',
          content: this.route?.fields?.['Meta Description']?.value ?? '',
        },
        {
          name: 'keywords',
          content: this.route?.fields?.['Meta Keywords']?.value ?? '',
        },
        { name: 'og:title', content: this.route?.fields?.['Meta Title']?.value ?? '' },
        {
          name: 'og:image',
          content: this.route?.fields?.['OG Image']?.value?.src ?? '',
        },
      ],
      link: [{ rel: 'canonical', href: this.$jss.sitecoreContext()?.canonicalUrl ?? '' }],
      ogImage: null,
    };

    this.setHeaderData(initialHeaderData);

    this.emitter.on('header-data-changed', (eventData: HeaderData) => {
      let currentHeaderData = this.headerData;

      if (eventData.description) {
        currentHeaderData.meta.find((m) => m.name == 'description').content = eventData.description;
      }

      if (eventData.canonical) {
        currentHeaderData.link.find((m) => m.rel == 'canonical').href = eventData.canonical;
      }

      if (eventData.title) {
        currentHeaderData.title = eventData.title;
      }

      this.setHeaderData(currentHeaderData);
    });
  },
  mounted() {
    // NOTE: this is an expensive listener, and a debouncer should be added in the future
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
});
</script>

<template>
  <div id="sa_modal_exit" class="modal-overlay">
    <div class="modal-body">
      <div class="modal-content">
        <div class="modal-icon-wrapper">
          <sc-image :media="fields.Icon" class="modal-icon" />
        </div>
        <div class="modal-text-wrapper">
          <h3 class="modal-title">{{ fields.ContentTitle.value }}</h3>
          <p class="modal-subtitle">{{ fields.NotSavedContent.value }}</p>
        </div>
      </div>
      <div class="modal-buttons">
        <v-btn
          type="button"
          @click="emitter.emit('exit-overlay:cta-click', fields?.['CtaLink']?.value?.['href'])"
          :aria-description="ariaAccept"
        >
          {{ confirmCTA }}
        </v-btn>

        <a
          href="#"
          class="exit-cta-dismiss"
          @click.prevent="$emit('dismiss') /* OverlayHandler handles all dismiss events */"
          :aria-description="ariaCancel"
        >
          {{ cancelCTA }}
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Image } from '@sitecore-jss/sitecore-jss-vue';
import { FieldsPropType } from '@/lib/FieldTypes';

export default defineComponent({
  name: 'ExitOverlay',
  components: {
    ScImage: Image,
  },
  props: {
    fields: {
      type: Object as PropType<FieldsPropType>,
      default: () => ({}),
    },
  },
  data() {
    return {
      confirmCTA: '',
      cancelCTA: '',
      ariaAccept: '',
      ariaCancel: '',
    };
  },
  mounted() {
    this.confirmCTA = this.fields['Confirm CTA']?.value;
    this.cancelCTA = this.fields['Cancel CTA']?.value;
    this.ariaAccept = this.fields['Aria Description Accept']?.value;
    this.ariaCancel = this.fields['Aria Description Cancel']?.value;
  },
  methods: {},
});
</script>

<style lang="scss">
.exit-modal {
  height: 100%;

  .v-overlay__content {
    @include media-breakpoint-down(lg) {
      width: 100vw;
      max-width: 100vw;
      height: 100%;
      max-height: 100%;
      margin: 0;

      .v-card {
        border-radius: 0;
        width: 100vw;
        height: 100%;
      }
    }
    @include media-breakpoint-up(lg) {
      max-width: 450px;
      .v-card {
        border-radius: $border-radius-lg;
      }
    }
  }

  .modal-overlay {
    display: flex;
    justify-content: center;
  }

  .modal-body {
    max-width: 400px;
    margin: 0px 24px 24px 24px;
  }

  .modal-icon-wrapper {
    text-align: center;
  }

  .modal-text-wrapper {
    text-align: left;
  }

  .modal-icon {
    margin: 0 auto 16px;
  }

  .modal-title {
    font-size: $font-size-xxs;
    font-weight: $font-weight-bold;
    margin-bottom: 8px;
    color: $park-gray-0;
  }

  .modal-subtitle {
    margin-bottom: 24px;
    font-size: $font-size-xxs;
    color: $park-gray-0;
  }

  .modal-buttons {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .exit-cta-dismiss {
    color: $system-alert-blue-60;
    text-align: center;
    font-size: 16px;
    font-weight: $font-weight-bold;
    line-height: 24px; /* 150% */
    padding: 0;
    margin: 0 auto;
  }
}
</style>

<template>
  <div v-if="hasResults" class="pageNavigationBox">
    <v-tooltip :text="`Previous ${take}`" location="top" class="top">
      <template v-slot:activator="{ props }">
        <NavigationButton
          id="navButtonLeft"
          direction="left"
          :disabled="isFirstPage"
          :handleButtonClick="goToPreviousPage"
          v-bind="props"
        />
      </template>
    </v-tooltip>
    <div id="SrpPaginationText">
      <span>{{ startCarIndex }}-{{ lastCarIndex }} of {{ formattedVehicleAmount }} </span>
    </div>
    <v-tooltip :text="`Next ${take}`" location="top" class="top">
      <template v-slot:activator="{ props }">
        <NavigationButton
          id="navButtonRight"
          direction="right"
          :disabled="isLastPage"
          :handleButtonClick="goToNextPage"
          v-bind="props"
        />
      </template>
    </v-tooltip>
  </div>
</template>

<script lang="ts">
import NavigationButton from './NavigationButton.vue';
import { defineComponent } from 'vue';
// import eventBus from '@assets/js/eventBus';
import { mapActions, mapState } from 'pinia';
import { useVehicleSearchStore } from '@/stores/vehicleSearch';

export default defineComponent({
  data() {
    return {};
  },
  name: 'PageNavigation',
  computed: {
    ...mapState(useVehicleSearchStore, ['queryParams', 'take', 'srpVehiclesData', 'loading']),
    ...mapState(useVehicleSearchStore, ['formattedVehicleAmount', 'vehicleCount']),
    isFirstPage() {
      // @ts-ignore // TODO-pinia
      return this.queryParams?.page === 0;
    },
    isLastPage() {
      // @ts-ignore // TODO-pinia
      return this.queryParams?.page * this.take + this.take >= this.vehicleCount;
    },
    startCarIndex() {
      // @ts-ignore // TODO-pinia
      return (this.queryParams?.page * this.take + 1).toLocaleString().replace('.', ',');
    },
    lastCarIndex() {
      // @ts-ignore // TODO-pinia
      return Math.min(this.queryParams?.page * this.take + this.take, this.vehicleCount)
        .toLocaleString()
        .replace('.', ',');
    },
    resultCount() {
      if (!this.srpVehiclesData) return -1;
      return this.srpVehiclesData.resultCount;
    },
    hasResults() {
      return !this.loading && this.resultCount > 0;
    },
  },
  methods: {
    ...mapActions(useVehicleSearchStore, ['setPageIncrement']),
    goToNextPage() {
      if (!this.isLastPage) {
        this.setPageIncrement(1);
        this.emitter.emit('pagination-fetch-srp');
      }
      let obj = {
        elementTitle: 'next button',
        'page number': document.getElementById('SrpPaginationText')?.innerText.trim(),
      };
      this.emitter.emit('analytics', obj);
    },
    goToPreviousPage() {
      if (!this.isFirstPage) {
        this.setPageIncrement(-1);
        this.emitter.emit('pagination-fetch-srp');
      }
      let obj = {
        elementTitle: 'prev button',
        'page number': document.getElementById('SrpPaginationText')?.innerText.trim(),
      };
      this.emitter.emit('analytics', obj);
    },
  },
  components: { NavigationButton },
});
</script>

<style lang="scss">
.pageNavigationBox {
  order: 6;
  width: 100%;
  margin: 24px 0;
  display: flex;
  justify-content: center;
  justify-items: center;
}
#SrpPaginationText {
  max-width: 246px;
  flex: 1;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: var(--park-gray-0);
  text-align: center;
  align-self: center;
}
</style>

// @ts-nocheck
// Mixins have poor typescript support at the moment
import { useStandaloneCreditAppStore } from '@/stores/standaloneCreditApp';
import { mapActions } from 'pinia';

export default {
  methods: {
    ...mapActions(useStandaloneCreditAppStore, ['setLoading', 'clearErrors']),
    getSubmitHandler(): () => Promise<boolean> {
      return () =>
        Promise.reject(new Error(`Missing getSubmitHandler definition for ${this.$options.name}`));
    },
    /* Centralized logic for handling each form step submission */
    async submitHandler(navigateForward: () => Promise<void>) {
      this.setLoading(true);
      this.clearErrors();
      const isValid = await this.getSubmitHandler()();
      if (isValid) {
        navigateForward();
      } else {
        this.setLoading(false);
      }
    },
  },
  mounted() {
    this.emitter.on('sca-validate', this.submitHandler);
  },
  beforeUnmount() {
    this.emitter.off('sca-validate', this.submitHandler);
  },
};

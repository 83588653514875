import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "sca-header",
  "data-testid": "sca-header"
}
const _hoisted_2 = { class: "branding" }
const _hoisted_3 = {
  class: "contact",
  "data-testid": "sca-phone"
}
const _hoisted_4 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sc_image = _resolveComponent("sc-image")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        href: "#",
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleLogoClick && _ctx.handleLogoClick(...args)), ["prevent"])),
        "data-testid": "sca-logo"
      }, [
        _createVNode(_component_sc_image, { media: _ctx.logo }, null, 8, ["media"])
      ]),
      _createElementVNode("h4", null, _toDisplayString(_ctx.title), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_sc_image, {
        class: "sca-store-location-icon mr-1",
        media: _ctx.phoneIcon
      }, null, 8, ["media"]),
      _createElementVNode("a", { href: _ctx.phoneHref }, _toDisplayString(_ctx.phone), 9, _hoisted_4)
    ])
  ]))
}
<template>
  <v-container class="sca-personal-details">
    <v-form ref="form">
      <v-row>
        <v-col cols="12" class="pb-6">
          <FormHeader
            :title="
              fieldsMap.get(isCobuyer ? 'co-applicant-details.Label' : 'personal-details.Label')
                ?.value
            "
            :note="
              fieldsMap.get(
                isCobuyer ? 'co-applicant-details.Description' : 'personal-details.Description'
              )
            "
          />
        </v-col>
      </v-row>
      <BuyerIdentity ref="buyerIdentityComponent" :isCobuyer="isCobuyer"></BuyerIdentity>
      <BuyerAddress
        ref="buyerAddressComponent"
        :fields="fields"
        :fieldsMap="fieldsMap"
        :isCobuyer="isCobuyer"
      ></BuyerAddress>
      <SocialSecurity
        v-if="isCobuyer"
        :fields="fields"
        :title="fieldsMap.get('verify-co-applicant-identity.Label')?.value"
        :subtitle="fieldsMap.get('verify-co-applicant-identity.Description')"
        :footnote="fieldsMap.get('verify-co-applicant-identity.co-applicant-ssn.notes')"
        v-model:personalDetailsValues="personalDetailsValues"
        :buyerPersonalDetailsSchema="buyerPersonalDetailsSchema"
        :hasError="hasError"
      />
    </v-form>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { mapState, mapActions } from 'pinia';
import { FormComponentType } from '@/types/StandaloneCreditApp/StandaloneCreditAppTypes';
import { useStandaloneCreditAppStore } from '@/stores/standaloneCreditApp';
import { validate } from '@/util/schema/schemaValidator';
import BuyerIdentity from './BuyerIdentity.vue';
import BuyerAddress from './BuyerAddress.vue';
import FormHeader from '../Includes/FormHeader.vue';
import SocialSecurity from './SocialSecurity.vue';
import { buildMap } from '@/util/standaloneCreditAppUtils';
import { FieldsPropType } from '@/lib/FieldTypes';

export default defineComponent({
  name: 'PersonalDetailsForm',
  props: {
    fields: {
      type: Object as PropType<FieldsPropType>,
      default: () => ({}),
    },
    isCobuyer: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BuyerIdentity,
    BuyerAddress,
    FormHeader,
    SocialSecurity,
  },
  data() {
    return {
      personalDetailsValues: {
        ssn: '',
        ssnDefined: false,
      },
      errors: {} as Record<string, string>,
      fieldsMap: new Map(),
    };
  },
  computed: {
    ...mapState(useStandaloneCreditAppStore, [
      'buyerPersonalDetailsValidator',
      'buyerPersonalDetailsSchema',
      'coBuyer',
    ]),
  },
  methods: {
    ...mapActions(useStandaloneCreditAppStore, ['addErrors', 'setPersonalDetails']),
    async submitHandler() {
      const formComponents = [
        this.$refs.buyerIdentityComponent,
        this.$refs.buyerAddressComponent,
      ] as FormComponentType[];

      const errorsArray = await Promise.all(
        formComponents.map((component) => component.submitHandler())
      );

      let personalDetailsErrors = {};
      if (this.isCobuyer) {
        personalDetailsErrors = await this.validateForm();
      }

      this.errors = Object.assign({}, ...errorsArray, personalDetailsErrors);
      if (this.errors && Object.keys(this.errors).length > 0) {
        this.addErrors(Object.values(this.errors) as string[]);
        return false;
      } else {
        this.setPersonalDetails(
          {
            ...this.personalDetailsValues,
            ssnDefined: !!this.personalDetailsValues.ssn.trim(),
          },
          true
        );
        return true;
      }
    },
    async validateForm(): Promise<Record<string, string>> {
      const personalDetailsErrors = await validate([this.buyerPersonalDetailsValidator], {
        ...this.personalDetailsValues,
      });

      return personalDetailsErrors || {};
    },
    hasError(key: string): boolean {
      return !!this.errors[key];
    },
    buildFieldsMap() {
      this.fieldsMap = buildMap(this.fields);
    },
  },
  mounted() {
    this.buildFieldsMap();
    this.emitter.on('sca-validate', this.submitHandler);

    // Coapplicant buyer social only here
    this.personalDetailsValues = {
      ssn: this.coBuyer?.personalDetails?.ssn || '',
      ssnDefined: !!this.coBuyer?.personalDetails?.ssn?.trim(),
    };
  },
  unmounted() {
    this.emitter.off('sca-validate', this.submitHandler);
  },
});
</script>

<style lang="scss">
@use '@/assets/styles/variables' as *;

.sca-personal-details {
  padding: 0;
  margin-bottom: $spacing_stack-nano;
}
</style>

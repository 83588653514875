import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormHeader = _resolveComponent("FormHeader")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_BuyerIdentity = _resolveComponent("BuyerIdentity")!
  const _component_BuyerAddress = _resolveComponent("BuyerAddress")!
  const _component_SocialSecurity = _resolveComponent("SocialSecurity")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "sca-personal-details" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_form, { ref: "form" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                class: "pb-6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_FormHeader, {
                    title: 
              _ctx.fieldsMap.get(_ctx.isCobuyer ? 'co-applicant-details.Label' : 'personal-details.Label')
                ?.value
            ,
                    note: 
              _ctx.fieldsMap.get(
                _ctx.isCobuyer ? 'co-applicant-details.Description' : 'personal-details.Description'
              )
            
                  }, null, 8, ["title", "note"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_BuyerIdentity, {
            ref: "buyerIdentityComponent",
            isCobuyer: _ctx.isCobuyer
          }, null, 8, ["isCobuyer"]),
          _createVNode(_component_BuyerAddress, {
            ref: "buyerAddressComponent",
            fields: _ctx.fields,
            fieldsMap: _ctx.fieldsMap,
            isCobuyer: _ctx.isCobuyer
          }, null, 8, ["fields", "fieldsMap", "isCobuyer"]),
          (_ctx.isCobuyer)
            ? (_openBlock(), _createBlock(_component_SocialSecurity, {
                key: 0,
                fields: _ctx.fields,
                title: _ctx.fieldsMap.get('verify-co-applicant-identity.Label')?.value,
                subtitle: _ctx.fieldsMap.get('verify-co-applicant-identity.Description'),
                footnote: _ctx.fieldsMap.get('verify-co-applicant-identity.co-applicant-ssn.notes'),
                personalDetailsValues: _ctx.personalDetailsValues,
                "onUpdate:personalDetailsValues": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.personalDetailsValues) = $event)),
                buyerPersonalDetailsSchema: _ctx.buyerPersonalDetailsSchema,
                hasError: _ctx.hasError
              }, null, 8, ["fields", "title", "subtitle", "footnote", "personalDetailsValues", "buyerPersonalDetailsSchema", "hasError"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 512)
    ]),
    _: 1
  }))
}
<template>
  <v-container :class="['sca-submit', { 'is-submitting': isLoading }]">
    <v-expansion-panels multiple>
      <v-expansion-panel value="primary">
        <v-expansion-panel-title>
          <div class="h6">{{ getLabel('primary-applicant-header') }}</div>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <panel-section labelKey="personal-details-label" :enableEdit="enableEdit">
            <ApplicantDetails
              :buyer="primaryBuyer"
              :formattedAddress="formattedAddress(false, false)"
              :previousFormattedAddress="formattedAddress(true, false)"
              :formattedMonthAndYear="formattedMonthAndYear(false, false, 'address')"
              :previousFormattedMonthAndYear="formattedMonthAndYear(true, false, 'address')"
            />
          </panel-section>
          <panel-section labelKey="store-label" :enableEdit="enableEdit">
            <Store />
          </panel-section>
          <panel-section labelKey="employment-label" :enableEdit="enableEdit">
            <Employment
              :buyer="primaryBuyer"
              :formattedMonthAndYear="formattedMonthAndYear(false, false, 'employment')"
              :previousFormattedMonthAndYear="formattedMonthAndYear(true, false, 'employment')"
            />
          </panel-section>
          <panel-section labelKey="additional-info-label" :enableEdit="enableEdit">
            <AdditionalDetails :additionalDetails="additionalDetailsValues" />
          </panel-section>
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel value="co-applicant" v-if="flow === 'coApplicant'">
        <v-expansion-panel-title>
          <div class="h6">{{ $t('co-applicant-header') }}</div>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <panel-section labelKey="co-applicant-details-label" :enableEdit="enableEdit">
            <ApplicantDetails
              :buyer="coBuyer"
              :formattedAddress="formattedAddress(false, true)"
              :previousFormattedAddress="formattedAddress(true, true)"
              :formattedMonthAndYear="formattedMonthAndYear(false, true, 'address')"
              :previousFormattedMonthAndYear="formattedMonthAndYear(true, true, 'address')"
            />
          </panel-section>
          <panel-section labelKey="co-applicant-employment-label" :enableEdit="enableEdit">
            <Employment
              :buyer="coBuyer"
              :isCobuyer="true"
              :formattedMonthAndYear="formattedMonthAndYear(false, true, 'employment')"
              :previousFormattedMonthAndYear="formattedMonthAndYear(true, true, 'employment')"
            />
          </panel-section>
          <a
            href="#"
            class="remove-co-applicant"
            data-testid="sca-remove-co-applicant"
            v-if="false /* Remove co-applicant is not in the initial release */"
          >
            <v-icon icon="ep-icon-delete" />
            <span class="link-text">{{ $t('remove-co-app-label') }}</span>
          </a>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-form ref="form" @submit.prevent="submitForm">
      <v-row>
        <v-col>
          <h3 v-if="fields?.['form-title']?.value">{{ fields?.['title']?.value }}</h3>
          <div class="content">
            <sc-rich-text
              :field="fieldsMap.get('confirmation-message.fieldNotes')"
              data-testid="sca-confirmation-message-notes"
            />
          </div>
          <div class="agreement-checkbox">
            <v-checkbox v-model="authorized" data-testid="sca-authorization-checkbox">
              <template v-slot:label>
                <sc-rich-text :field="fieldsMap.get('authorization-legal.authorization')" />
              </template>
            </v-checkbox>
          </div>
          <div class="disclaimer">
            <sc-rich-text
              :field="fieldsMap.get('authorization-legal.legal')"
              data-testid="sca-authorization-disclaimer"
            />
          </div>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { mapActions, mapState } from 'pinia';
import RichText from '@components-vue/Foundation/Common/RichText';
import { useStandaloneCreditAppStore } from '@/stores/standaloneCreditApp';
import { submitCreditApp } from '@/services/routeOneService';
import { validate } from '@/util/schema/schemaValidator';
import ApplicantDetails from './Includes/Submit--ApplicantDetails.vue';
import AdditionalDetails from './Includes/Submit--AdditionalDetails.vue';
import Store from './Includes/Submit--Store.vue';
import Employment from './Includes/Submit--EmploymentDetails.vue';
import PanelSection from './Includes/Submit--PanelHeader.vue';
import { buildMap } from '@/util/standaloneCreditAppUtils';
import standaloneCreditAppFormStep from '@/mixins/standaloneCreditAppFormStep';
import { FieldsPropType } from '@/lib/FieldTypes';
import { removeCookie } from '@util/commonUtils';

export default defineComponent({
  name: 'StandaloneCreditApp--Submit',
  props: {
    fields: {
      type: Object as PropType<FieldsPropType>,
      default: () => ({}),
    },
  },
  mixins: [standaloneCreditAppFormStep],
  components: {
    ScRichText: RichText,
    ApplicantDetails,
    Store,
    Employment,
    AdditionalDetails,
    PanelSection,
  },
  data() {
    return {
      errors: {} as Record<string, string>,
      toggleOption: null,
      authorized: '',
      loading: false,
      enableEdit: false /* edit not in the current scope */,
      fieldsMap: new Map(),
    };
  },
  computed: {
    ...mapState(useStandaloneCreditAppStore, [
      'flow',
      'routeOneApplication',
      'primaryBuyer',
      'additionalDetails',
      'finance',
      'trade',
      'coBuyer',
      'routeOneValidator',
      'buyerStoreValidator',
      'buyerPersonalDetailsValidator',
      'buyerFinanceValidator',
      'buyerAddressValidator',
      'buyerIdentityValidator',
      'buyerContactValidator',
      'buyerEmploymentValidator',
      'isLoading',
      'isDebugging',
    ]),
    additionalDetailsValues() {
      return {
        downPayment: this.finance?.cashDownPayment || 0,
        ssn: this.formatSsn(this.primaryBuyer?.personalDetails?.ssn) || '',
        trade: this.trade || '',
      };
    },
  },
  methods: {
    getLabel(labelName) {
      return this.$t(labelName);
    },
    ...mapActions(useStandaloneCreditAppStore, [
      'addError',
      'formattedAddress',
      'formatSsn',
      'formattedMonthAndYear',
      'addErrors',
      'clearErrors',
      'setLoading',
    ]),
    getSubmitHandler(): () => Promise<boolean> {
      return this.submitForm;
    },
    async submitForm() {
      if (!this.authorized) {
        this.addError('Please agree to terms and conditions.'); // todo- content manage
        return false;
      }

      if (!this.isDebugging) {
        this.errors = (await this.validateForm()) || {};
        if (Object.keys(this.errors).length) {
          this.addErrors(Object.values(this.errors) as string[]);
          return false;
        }
      }

      if (this.isDebugging) {
        await new Promise((resolve) => setTimeout(resolve, 15 * 1000)); // Simulated delay for debugging
      }

      this.clearErrors();

      try {
        await submitCreditApp(this.routeOneApplication);
        // Remove the vehicle of intrest cookie to avoid it showing up in subsequent submissions.
        removeCookie('ep_vehicleOfInterest');
        // TODO we may need to check for unsuccessful non-error responses that don't trigger a catch
        return true;
      } catch (error) {
        console.error(error);
        this.addError('Error submitting the application'); // TODO: content manage this
        return false;
      }
    },
    async validateForm(): Promise<Record<string, string>> {
      let errors = await this.validateAllData();
      if (Object.keys(errors).length > 0) {
        return { ...errors };
      }

      const routeOneErrors = await validate([this.routeOneValidator], {
        ...this.routeOneApplication,
      });

      return { ...routeOneErrors };
    },
    async validateAllData() {
      let errors = {
        ...(await validate([this.buyerStoreValidator], {
          ...this.routeOneApplication?.additionalDetails,
        })),
        ...(await validate([this.buyerPersonalDetailsValidator], {
          ...this.routeOneApplication?.primaryBuyer?.personalDetails,
        })),
        ...(await validate([this.buyerFinanceValidator], {
          ...this.routeOneApplication?.finance,
        })),
        ...(await validate([this.buyerAddressValidator], {
          ...this.routeOneApplication?.primaryBuyer?.address,
        })),
        ...(await validate([this.buyerIdentityValidator], {
          ...this.routeOneApplication?.primaryBuyer.identity,
        })),
        ...(await validate([this.buyerContactValidator], {
          ...this.routeOneApplication?.primaryBuyer.contactInfo,
        })),
        ...(await validate([this.buyerEmploymentValidator], {
          ...this.routeOneApplication?.primaryBuyer.employmentDetails,
        })),
      };

      return errors;
    },
    buildFieldsMap() {
      this.fieldsMap = buildMap(this.fields);
    },
  },
  mounted() {
    this.buildFieldsMap();
  },
});
</script>
<style lang="scss">
@use '@/assets/styles/variables' as *;

.sca-submit {
  font-size: $font-size-xxxs;
  line-height: $line-height-xl;
  padding: 0;

  &.is-submitting {
    opacity: $opacity-level-medium;
  }

  .content p {
    margin-bottom: $spacing_stack-xxxs;
  }

  .v-expansion-panels {
    width: calc(100% + ($spacing_inline-xxs * 2));
    margin: 0 #{-$spacing_inline-xxs} $spacing_stack-xxs;
    border-top: 1px solid $park-gray-90;
    border-bottom: 1px solid $park-gray-90;

    @include media-breakpoint-up(lg) {
      width: calc(100% + ($spacing_inline-sm * 2));
      margin: 0 #{-$spacing_inline-sm} $spacing_stack-xxs;
    }
  }

  .v-expansion-panel {
    &:after {
      display: none;
    }

    & + .v-expansion-panel {
      border-top: 1px solid $park-gray-90;
    }
  }

  .v-expansion-panel--active:not(:first-child),
  .v-expansion-panel--active + .v-expansion-panel {
    margin: 0;
  }

  .v-expansion-panel-title,
  .v-expansion-panel-text {
    padding: $spacing_stack-xs $spacing_inline-xxs;

    @include media-breakpoint-up(lg) {
      padding-left: $spacing_inline-sm;
      padding-right: $spacing_inline-sm;
    }

    .h6 {
      margin: 0;
    }
  }

  .v-expansion-panel-text {
    padding-top: 0;
  }

  .v-expansion-panel-text__wrapper {
    padding: 0;
  }

  .panel-section {
    border: 1px solid $park-gray-90;
    border-radius: $border-radius-lg;
    padding: $spacing_inset-sm;

    & + .panel-section {
      margin-top: $spacing_stack-xs;
    }
  }

  .panel-heading {
    display: flex;
    justify-content: space-between;

    p {
      margin-bottom: 0;
    }

    .v-icon {
      font-size: $font-size-xxs;
    }
  }

  .panel-content {
    margin-top: $spacing_stack-xs;

    .v-row + .v-row {
      margin-top: $spacing_stack-xxs;
    }

    p {
      margin-bottom: $spacing_stack-nano;

      &.small {
        color: $park-gray-20;
      }
    }
  }

  .store-header {
    color: $park-gray-20;
    font-weight: $font-weight-bold;
  }

  .remove-co-applicant {
    margin: $spacing_stack-xs auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;

    span {
      font-weight: $font-weight-medium;
      margin: 1px 0 0 $spacing_inline-nano;
    }

    &:hover {
      .v-icon {
        opacity: 0.8;
      }

      span {
        text-decoration: underline;
      }
    }
  }

  .agreement-checkbox {
    background-color: $accent-warm-gray-100;
    margin: $spacing_stack-xs auto;
    padding: $spacing_inset-sm;
    border-radius: $border-radius-lg;

    .v-checkbox-btn {
      margin-bottom: 0;
      align-items: flex-start;
    }

    .v-label {
      font-size: $font-size-xxxs;
      line-height: $line-height-xl;
    }
  }

  .disclaimer {
    text-align: center;
    font-size: $font-size-xxxxs;
    line-height: $line-height-md;
    color: $park-gray-20;
    margin: $spacing_stack-xs auto $spacing_stack-xxs;
  }
}
</style>

<template>
  <div class="sca-finish">
    <div class="mx-auto text-center">
      <v-row no-gutters>
        <v-col cols="12">
          <sc-image
            :media="fieldsMap.get('body-image.pageImage')"
            height="140"
            width="140"
            class=""
          />
        </v-col>
      </v-row>
      <v-row class="my-0">
        <v-col cols="12" class="py-0">
          <h5 class="heading" data-testid="sca-finish-header">
            {{ headerText }}
          </h5>
          <div class="helper-text">
            {{ fieldsMap.get('body-image.helperText')?.value }}
          </div>
          <div class="content" v-html="mainText"></div>

          <div class="finish-cta">
            <v-btn
              :href="fieldsMap.get('appointment-cta.cta-link')?.value?.['url']"
              data-testid="sca-appointment-cta"
              >{{ fieldsMap.get('appointment-cta.cta-text')?.value }}</v-btn
            >
            <v-btn variant="plain" data-testid="sca-shop-cta" :href="shopAtStoreHref">{{
              shopAtStoreCta
            }}</v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Image } from '@sitecore-jss/sitecore-jss-vue';
import { mapState } from 'pinia';
import { useStandaloneCreditAppStore } from '@/stores/standaloneCreditApp';
import { buildMap } from '@/util/standaloneCreditAppUtils';
import { useCommonStore } from '@/stores/common';
import { FieldsPropType } from '@/lib/FieldTypes';

export default defineComponent({
  name: 'StandaloneCreditApp--Finish',
  props: {
    fields: {
      type: Object as PropType<FieldsPropType>,
      default: () => ({}),
    },
  },
  components: {
    ScImage: Image,
  },
  computed: {
    ...mapState(useStandaloneCreditAppStore, [
      'singleApplicantNavSteps',
      'coApplicantNavSteps',
      'nextStep',
      'primaryBuyer',
      'additionalDetails',
      'selectedDealership',
    ]),
    ...mapState(useCommonStore, ['dealerships']),
    shopAtStoreCta() {
      const contentItem = this.fieldsMap.get('shop-cta.cta-text')?.value as string;
      return contentItem.replace('##storeName##', this.storeName);
    },
    shopAtStoreHref() {
      const href = this.fieldsMap.get('shop-cta.cta-link')?.value?.['href'] as string;
      const dealerId = this.selectedDealership.dealerId ?? '';
      const separator = href.includes('?') ? '&' : '?';
      return `${href}${separator}dealer=${encodeURIComponent(dealerId)}`;
    },
    headerText() {
      const contentItem = this.fieldsMap.get('body-image.informationText')?.value as string;
      const name = this.primaryBuyer.identity.firstName;
      return contentItem.replace('##name##', name);
    },
    mainText() {
      const contentItem = this.fieldsMap.get('appointment-notes.fieldNotes')?.value as string;
      return contentItem.replace('##storeName##', this.storeName);
    },
    storeName() {
      return this.selectedDealership?.storeName ?? '';
    },
  },
  methods: {},
  mounted() {},
  data() {
    return {
      fieldsMap: buildMap(this.fields),
    };
  },
});
</script>

<style lang="scss">
@use '@/assets/styles/variables' as *;

.sca-finish {
  .helper-text {
    color: $park-gray-0;
    font-size: $font-size-xxs;
    line-height: $line-height-lg; /* 150% */
    margin-bottom: $spacing_stack-xs;
  }

  .heading {
    color: $park-gray-0;
    font-size: $font-size-md;
    line-height: $line-height-sm;
  }

  .content {
    color: $park-gray-0;
    margin-bottom: $spacing_stack-xs;
  }

  .finish-cta {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>

<template>
  <div class="zipLocation text-right" id="zipLocation" @click="handleZipLocationClick">
    <div class="d-flex flex-column">
      <span class="spShoppingLocationsLabel d-none d-lg-block" id="spShoppingLocationsLabel">{{
        $t('Picking up closest to')
      }}</span>
      <a class="zipLocation--code" id="ancHeaderZipcode" role="link" href="javascript:void(0);">
        <p id="ZipcodeInformationHeader">{{ zipcode }}</p>
      </a>
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fill-rule="evenodd">
        <g>
          <g>
            <g>
              <path
                d="M0 0L24 0 24 24 0 24z"
                transform="translate(-1138 -24) translate(1017 20) translate(121 4)"
              />
              <path
                fill="#242C33"
                fill-rule="nonzero"
                d="M18.364 16.364L12 22.728l-6.364-6.364c-3.515-3.515-3.515-9.213 0-12.728 3.515-3.515 9.213-3.515 12.728 0 3.515 3.515 3.515 9.213 0 12.728zM12 12c1.105 0 2-.896 2-2 0-1.105-.895-2-2-2s-2 .895-2 2c0 1.104.895 2 2 2z"
                transform="translate(-1138 -24) translate(1017 20) translate(121 4)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script lang="ts">
import { mapActions } from 'pinia';
import { useCommonStore } from '@/stores/common';

export default {
  name: 'ZipCode',
  props: {
    zipcode: {
      type: String,
      default: '00000',
    },
  },
  methods: {
    ...mapActions(useCommonStore, ['setZipFlyout']),
    handleZipLocationClick() {
      this.setZipFlyout(true);
    },
  },
};
</script>

<style lang="scss"></style>

export function buildMap(obj: any): Map<string, any> {
  const recurseObj = {
    fields: obj,
  };

  function build(map: Map<string, any>, prefix: string, obj: any) {
    for (const fieldName in obj.fields) {
      if (fieldName === 'children') {
        obj.fields[fieldName].forEach((child) => {
          obj.name ? build(map, `${prefix}${obj.name}.`, child) : build(map, `${prefix}`, child);
        });
      } else {
        obj.name
          ? map.set(`${prefix}${obj.name}.${fieldName}`, obj.fields[fieldName])
          : map.set(`${prefix}${fieldName}`, obj.fields[fieldName]);
      }
    }
  }

  const map = new Map<string, any>();
  build(map, '', recurseObj);
  return map;
}

import VehicleSearchClient from '@/clients/vehicleSearchClient';
import CountSearchClient from '@/clients/countSearchClient';
import conf from '@/appConfig';
import axios from 'axios';
import { useVehicleSearchStore } from '@/stores/vehicleSearch';
import { Pinia } from 'pinia';
import { useCommonStore } from '@/stores/common';

async function executeClientRequest(store: Pinia, client, setDataCallback, setLoading = true) {
  let data = null;
  const vehicleSearchStore = useVehicleSearchStore(store);

  if (setLoading) {
    vehicleSearchStore.setLoading(true);
  }

  try {
    const response = await client.execute();
    data = response.data;
    setDataCallback(data);
  } catch (error) {
    console.error(error);
  }

  if (setLoading) {
    vehicleSearchStore.setLoading(false);
  }
}

function addCommonClientParameters(store: Pinia, client) {
  const vehicleSearchStore = useVehicleSearchStore(store);
  const commonStore = useCommonStore(store);

  const zip = commonStore.zipCode;
  const shopByStore = vehicleSearchStore.shopByStore;
  const shipToStore = vehicleSearchStore.shipToStore;

  client.addZip(zip);

  if (shopByStore) {
    const includedDealerships = vehicleSearchStore.includedDealerships;
    client.addDealers(includedDealerships);
    client.setShippable(shipToStore);
  } else {
    const radius = vehicleSearchStore.radius;
    client.addRadius(radius);
    client.setShippable(shipToStore ?? true);
  }
}

export async function fetchSrpVehicles(store: Pinia) {
  const vehicleSearchStore = useVehicleSearchStore(store);

  const client = VehicleSearchClient(axios, conf.vehicleApiEndpoint);

  const facetFilters = vehicleSearchStore.facetFilters;

  Object.keys(facetFilters).forEach((facetFilter) => {
    client.addFacetFilter(facetFilter, facetFilters[facetFilter]);
  });

  const take = vehicleSearchStore.take;
  const page = vehicleSearchStore.page;
  client.setPageSize(take);
  client.setPageNumber(page);

  addCommonClientParameters(store, client);

  const sortType = vehicleSearchStore.sortType;
  if (sortType.sortBy !== null && sortType.sortDirection !== null) {
    client.setSortBy(sortType.sortBy);
    client.setSortDirection(sortType.sortDirection);
  }

  await executeClientRequest(store, client, (data) => {
    if (data) {
      vehicleSearchStore.setSrpVehiclesData(data);
    }
  });
}

export async function fetchFacetCounts(store: Pinia, facetToExclude: string) {
  const vehicleSearchStore = useVehicleSearchStore(store);

  const facetFilters = vehicleSearchStore.facetFilters;

  const client = CountSearchClient(axios, conf.countApiEndpoint);

  Object.keys(facetFilters).forEach((facetFilter) => {
    if (facetFilter !== facetToExclude) {
      client.addFacetFilter(facetFilter, facetFilters[facetFilter]);
    }
  });

  addCommonClientParameters(store, client);

  await executeClientRequest(
    store,
    client,
    (data) => {
      if (data?.facets) {
        vehicleSearchStore.setFacetCounts(data.facets);
      }
    },
    false
  );
}

export function scrollToTop() {
  if (typeof window !== 'undefined') {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }
}

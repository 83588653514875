<template>
  <v-container class="style-guide">
    <!-- overview -->
    <v-row class="overview">
      <v-col cols="12">
        <h1>Style Guide</h1>
        <p>
          This page provides an overview of the style guide based on the Component Library created
          by the XD team. This project is based on Vue 3 and the
          <a href="https://vuetifyjs.com/en/" target="_blank">Vuetify Component Framework</a>. The
          Vuetify base styles have been overridden utilizing Sass to match the
          <a
            href="https://www.figma.com/design/dwePPWv7hJ4RjB2oyoFVwN/EchoPark-Design-System-2.0?node-id=155-7598&t=2RtiXbZET3I1pf02-0"
            target="_blank"
            >Figma</a
          >
          provided by the XD team.
        </p>
      </v-col>
      <v-col cols="12">
        <h2>Sass Files</h2>
        <p class="mb-4">
          This project utilized Sass to write and compile CSS more efficiently and effectively. The
          Sass files can be found at <code>/src/Project/EchoPark/client/src/assets/styles</code>
        </p>
        <p class="mb-4">
          <span class="sub-head">main.scss</span><br />
          This is the main Sass file where other Sass files are included.
        </p>
        <p class="mb-4">
          <span class="sub-head">settings.scss</span><br />
          This file contains explicit overrides to the default Vuetify Sass variables
        </p>
        <p class="mb-4">
          <span class="sub-head">_variables.scss</span><br />
          This file contains Sass variables that define Echo Park styles
        </p>
        <p class="mb-4">
          <span class="sub-head">legacy.scss</span><br />
          This file contains legacy CSS from the original codebase. As components are created in the
          Vue 3 codebase, be sure to remove unnecessary and unused rules from this file so we're
          lean and clean.
        </p>
        <p class="mb-4">
          <span class="sub-head">global.scss</span><br />
          This file contains new global styling rules specific to the new Vue 3 codebase.
        </p>
        <p class="mb-4">
          <span class="sub-head">Other Sass Partials</span><br />
          When creating reusable component styles, use Sass partials so it's not generated into CSS
          unless it's included in another Sass file. If you want to include a partial in another
          <code>*.scss</code> file, include it like<code>@use 'buttons'</code> (be sure the path is
          correct). If you want to include a partial in a <code>*.vue</code> file, include it
          like<code>@use '@/assets/styles/variables' as *;</code> (again, making sure the path is
          correct). Adding the <code>as *</code> will allow you to not need to use the variables
          like <code>color: variables.$park-gray-0;</code>
        </p>
      </v-col>
      <v-col cols="12">
        <h2>Sections</h2>
        <ul>
          <li><a href="#border-radius">Border Radius</a></li>
          <li><a href="#border-width">Border Width</a></li>
          <li><a href="#breakpoints">Breakpoints</a></li>
          <li><a href="#buttons">Buttons</a></li>
          <li><a href="#colors">Colors</a></li>
          <li><a href="#forms-and-inputs">Forms & Inputs</a></li>
          <li><a href="#opacity-levels">Opacity Levels</a></li>
          <li><a href="#shadows">Shadows</a></li>
          <li><a href="#spacing-horizontal">Spacing [Horizontal]</a></li>
          <li><a href="#spacing-inset">Spacing [Inset]</a></li>
          <li><a href="#spacing-vertical">Spacing [Vertical]</a></li>
          <li><a href="#tooltips">Tooltips</a></li>
          <li><a href="#typography">Typography</a></li>
        </ul>
      </v-col>
    </v-row>

    <v-expansion-panels multiple>
      <!-- border radius -->
      <v-expansion-panel id="border-radius">
        <v-expansion-panel-title collapse-icon="mdi-minus" expand-icon="mdi-plus">
          Border Radius
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <StyleGuideBorderRadius />
        </v-expansion-panel-text>
      </v-expansion-panel>

      <!-- border width -->
      <v-expansion-panel id="border-width">
        <v-expansion-panel-title collapse-icon="mdi-minus" expand-icon="mdi-plus">
          Border Width
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <StyleGuideBorderWidth />
        </v-expansion-panel-text>
      </v-expansion-panel>

      <!-- breakpoints -->
      <v-expansion-panel id="breakpoints">
        <v-expansion-panel-title collapse-icon="mdi-minus" expand-icon="mdi-plus">
          Breakpoints
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <StyleGuideBreakpoints />
        </v-expansion-panel-text>
      </v-expansion-panel>

      <!-- buttons -->
      <v-expansion-panel id="buttons">
        <v-expansion-panel-title collapse-icon="mdi-minus" expand-icon="mdi-plus">
          Buttons
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <StyleGuideButtons />
        </v-expansion-panel-text>
      </v-expansion-panel>

      <!-- colors -->
      <v-expansion-panel id="colors">
        <v-expansion-panel-title collapse-icon="mdi-minus" expand-icon="mdi-plus">
          Colors
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <StyleGuideColors />
        </v-expansion-panel-text>
      </v-expansion-panel>

      <!-- forms & inputs -->
      <v-expansion-panel id="forms-and-inputs">
        <v-expansion-panel-title collapse-icon="mdi-minus" expand-icon="mdi-plus">
          Forms & Inputs
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <StyleGuideFormsAndInputs />
        </v-expansion-panel-text>
      </v-expansion-panel>

      <!-- opacity levels -->
      <v-expansion-panel id="opacity-levels">
        <v-expansion-panel-title collapse-icon="mdi-minus" expand-icon="mdi-plus">
          Opacity Levels
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <StyleGuideOpacityLevels />
        </v-expansion-panel-text>
      </v-expansion-panel>

      <!-- shadows -->
      <v-expansion-panel id="shadows">
        <v-expansion-panel-title collapse-icon="mdi-minus" expand-icon="mdi-plus">
          Shadows
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <StyleGuideShadows />
        </v-expansion-panel-text>
      </v-expansion-panel>

      <!-- spacing [horizontal] -->
      <v-expansion-panel id="spacing-horizontal">
        <v-expansion-panel-title collapse-icon="mdi-minus" expand-icon="mdi-plus">
          Spacing [Horizontal]
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <StyleGuideSpacingHorizontal />
        </v-expansion-panel-text>
      </v-expansion-panel>

      <!-- spacing [inset] -->
      <v-expansion-panel id="spacing-inset">
        <v-expansion-panel-title collapse-icon="mdi-minus" expand-icon="mdi-plus">
          Spacing [Inset]
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <StyleGuideSpacingInset />
        </v-expansion-panel-text>
      </v-expansion-panel>

      <!-- spacing [vertical] -->
      <v-expansion-panel id="spacing-vertical">
        <v-expansion-panel-title collapse-icon="mdi-minus" expand-icon="mdi-plus">
          Spacing [Vertical]
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <StyleGuideSpacingVertical />
        </v-expansion-panel-text>
      </v-expansion-panel>

      <!-- tooltips -->
      <v-expansion-panel id="tooltips">
        <v-expansion-panel-title collapse-icon="mdi-minus" expand-icon="mdi-plus">
          Tooltips
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <StyleGuideTooltips />
        </v-expansion-panel-text>
      </v-expansion-panel>

      <!-- typography -->
      <v-expansion-panel id="typography">
        <v-expansion-panel-title collapse-icon="mdi-minus" expand-icon="mdi-plus">
          Typography
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <StyleGuideTypography />
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import StyleGuideBorderRadius from '@components/Page/StyleGuide/StyleGuide--BorderRadius.vue';
import StyleGuideBorderWidth from '@components/Page/StyleGuide/StyleGuide--BorderWidth.vue';
import StyleGuideBreakpoints from '@components/Page/StyleGuide/StyleGuide--Breakpoints.vue';
import StyleGuideButtons from '@components/Page/StyleGuide/StyleGuide--Buttons.vue';
import StyleGuideColors from '@components/Page/StyleGuide/StyleGuide--Colors.vue';
import StyleGuideFormsAndInputs from '@components/Page/StyleGuide/StyleGuide--FormsAndInputs.vue';
import StyleGuideOpacityLevels from '@components/Page/StyleGuide/StyleGuide--OpacityLevels.vue';
import StyleGuideShadows from '@components/Page/StyleGuide/StyleGuide--Shadows.vue';
import StyleGuideSpacingHorizontal from '@components/Page/StyleGuide/StyleGuide--SpacingHorizontal.vue';
import StyleGuideSpacingInset from '@components/Page/StyleGuide/StyleGuide--SpacingInset.vue';
import StyleGuideSpacingVertical from '@components/Page/StyleGuide/StyleGuide--SpacingVertical.vue';
import StyleGuideTypography from '@components/Page/StyleGuide/StyleGuide--Typography.vue';
import StyleGuideTooltips from '@components/Page/StyleGuide/StyleGuide--Tooltips.vue';

export default defineComponent({
  name: 'StyleGuide',
  components: {
    StyleGuideTooltips,
    StyleGuideBorderRadius,
    StyleGuideBorderWidth,
    StyleGuideBreakpoints,
    StyleGuideButtons,
    StyleGuideColors,
    StyleGuideFormsAndInputs,
    StyleGuideSpacingHorizontal,
    StyleGuideSpacingInset,
    StyleGuideOpacityLevels,
    StyleGuideShadows,
    StyleGuideSpacingVertical,
    StyleGuideTypography,
  },
  data() {
    return {};
  },
});
</script>

<style lang="scss">
.style-guide {
  margin-bottom: 100px;

  .v-row {
    margin: 20px auto;

    @include media-breakpoint-up(md) {
      margin: 60px auto;
    }
  }
  .v-expansion-panel {
    border-radius: 0;
  }
  .v-expansion-panel-title {
    font-size: $font-size-xl;
    font-weight: $font-weight-regular;
    border-bottom: 2px solid darken($supplementary-blue-50, 6);
    padding-right: 4px;

    i:before {
      font-size: $font-size-lg;
    }
  }
  code {
    display: block;
    color: $supplementary-red-60;
    font-weight: $font-weight-bold;
  }
  .overview code {
    display: inline-block;
    padding: 2px 6px;
    background: $park-gray-100;
    color: $base-black-100;
    font-weight: $font-weight-regular;
  }
  .box {
    width: 64px;
    height: 64px;
    margin-bottom: 10px;

    &.black {
      background: $base-black-100;
    }
  }
}
</style>

<template>
  <div
    v-if="toggleComponentVisible"
    class="shop-by-store-toggle"
    data-role="toggle-shopping-options"
  >
    <div class="center-options">
      <TextToggle :options="toggleOptions" v-model="shopByStore" />
    </div>
    <div class="shoppingRadius w-100">
      <div v-if="!shopByStore" class="dropdown">
        <div class="mb-6 mx-6">
          <label class="shopByRadiusLabel">{{ $t('Shopping Radius') }}</label>
          <Dropdown
            class="custom-select mt-0"
            :options="radiusOptions"
            v-model="selectedRadiusModel"
            data-action="toggle-shop-by-radius"
          />
        </div>
        <div class="storesInRadiusLabel text-center" data-testid="storesInRadiusLabel">
          {{ getText() }}
        </div>
      </div>
      <FacetDealershipPicker
        v-if="shopByStore"
        label="Select your stores"
        showMoreText="Show more"
        showLessText="Show less"
        data-action="select-dealership"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import FacetDealershipPicker from './FacetDealershipPicker.vue';
import TextToggle from './TextToggle.vue';
import Dropdown from './Dropdown.vue';
import { mapActions, mapState, mapWritableState } from 'pinia';
import { useVehicleSearchStore } from '@/stores/vehicleSearch';
import { useCommonStore } from '@/stores/common';
import { FieldsPropType } from '@/lib/FieldTypes';

export default defineComponent({
  name: 'ShopByStoreToggle',
  props: {
    fields: {
      type: Object as PropType<FieldsPropType>,
      default: () => ({}),
    },
  },
  components: {
    FacetDealershipPicker,
    TextToggle,
    Dropdown,
  },
  data() {
    return {
      shopOptions: [
        this.$t('Distance Shipping Toggle Text Left'),
        this.$t('Distance Shipping Toggle Text Right'),
      ],
      currentShopOptionIndex: 1,
      toggleComponentVisible: true,
      IsShowZipFlyout: false,
      storesInRadius: 0,
    };
  },
  computed: {
    ...mapWritableState(useVehicleSearchStore, ['shopByStore']),
    ...mapState(useCommonStore, ['nearestDealership', 'dealerships', 'zipCode']),
    ...mapState(useVehicleSearchStore, ['radius', 'shipToStoreRadius']),
    selectedRadiusModel: {
      get() {
        return String(this.radius);
      },
      set(value) {
        let radius = parseInt(value);
        this.setRadius(radius);
        this.emitter.emit('filter-updated-srp');
      },
    },
    radiusOptions() {
      // @ts-ignore TS migration - fix when possible
      return this.fields?.zipRadiusOptions?.map((x) => {
        const zipRadiusValue = String(x.fields?.RadiusValue?.value);
        const zipRadiusDisplay = String(x.fields?.DisplayText?.value);
        return { label: zipRadiusDisplay, value: zipRadiusValue };
      });
    },
    storeCount() {
      return this.radius == -1
        ? this.dealerships.length
        : // @ts-ignore // TODO-pinia
          this.dealerships.filter((dealership) => dealership.distance <= this.radius).length;
    },
    toggleOptions: function () {
      return [
        { text: this.$t('Distance Shipping Toggle Text Left'), value: false },
        { text: this.$t('Distance Shipping Toggle Text Right'), value: true },
      ];
    },
  },
  methods: {
    ...mapActions(useVehicleSearchStore, [
      'setIncludedDealerships',
      'setShipToStore',
      'setShopByStore',
      'setRadius',
    ]),
    getText() {
      if (this.storeCount == 1 && this.radius != -1) {
        return this.replaceText(
          this.$t('There Is Store Within Miles'),
          [';xMiles;'],
          [this.radius]
        );
      } else if (this.storeCount > 1 && this.radius != -1) {
        return this.replaceText(
          this.$t('There Are Stores Within Miles'),
          [';nStores;', ';xMiles;'],
          [this.storeCount, this.radius]
        );
      } else if (this.radius == -1) {
        return this.replaceText(
          this.$t('There Are Stores Nationwide'),
          [';nStores;'],
          [this.storeCount]
        );
      } else if (this.storeCount == 0) {
        return 'There are 0 stores within ' + this.radius + ' mi';
      }
    },
    replaceText(text, search, replacements) {
      let modifiedText = text;
      for (let i = 0; i < replacements.length; i++) {
        modifiedText = modifiedText.replace(search[i], replacements[i]);
      }
      return modifiedText;
    },
  },
  watch: {
    shopByStore: function (selected) {
      if (selected && this?.nearestDealership?.dealerId) {
        this.setIncludedDealerships([this.nearestDealership.dealerId]);
      } else {
        this.setIncludedDealerships([]);
      }
      this.emitter.emit('filter-updated-srp');
    },
  },
});
</script>

<style lang="scss">
.shop-by-store-toggle {
  .shopByRadiusLabel {
    color: #a2a6ab;
    font-size: 12px;
    font-family: Roboto, serif;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.2px;
    word-wrap: break-word;
  }

  .storesInRadiusLabel {
    color: $park-gray-0;
    font-size: 12px;
    font-family: Roboto, serif;
    font-weight: 700;
    line-height: 16px;
  }

  .center-options {
    padding: 0 0 16px;
  }

  .dropdown {
    position: relative;
    display: inline-block;
    margin-top: 10px;
    width: 100%;

    &:hover .dropdown-menu {
      display: block;
    }
  }

  .dropdown-menu {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      padding: 10px;

      a {
        color: #333;
        text-decoration: none;
        display: block;

        &:hover {
          background-color: #e0e0e0;
        }
      }
    }
  }

  .radiusDropdown {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
}
</style>

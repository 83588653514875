<template>
  <div class="SrpButton">
    <button v-if="onlyIcon" tabindex="0" class="default-button calculator-button">
      <img
        v-if="calculator"
        src="@icons/calculator-hover.svg"
        class="onhover"
        alt="calculator icon"
        height="20"
        width="20"
      />
      <img
        v-if="calculator"
        src="@icons/calculator.svg"
        class="initial"
        alt="calculator icon"
        height="20"
        width="20"
      />
    </button>
    <button
      v-else
      @click="navigateToLinkClick"
      tabindex="0"
      class="wideButton"
      :class="{ 'carousel-button': carousel, 'default-button': !carousel }"
    >
      <img
        v-if="steeringWheel"
        class="onhover"
        src="@icons/steeringWheel-hover.svg"
        alt="steering wheel icon"
      />
      <img
        v-if="steeringWheel"
        class="initial"
        src="@icons/steeringWheel.svg"
        alt="steering wheel icon"
      />
      <img v-if="subtract" class="onhover" src="@icons/subtract-hover.svg" alt="subtract icon" />
      <img v-if="subtract" class="initial" src="@icons/subtract.svg" alt="subtract icon" />
      <span class="SrpButtonText">{{ buttonText }}</span>
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { FieldsPropType } from '@/lib/FieldTypes';

export default defineComponent({
  name: 'SrpButton',
  props: {
    fields: {
      type: Object as PropType<FieldsPropType>,
      default: () => ({}),
    },
    calculator: Boolean,
    testDrive: Boolean,
    carousel: Boolean,
    steeringWheel: Boolean,
    subtract: Boolean,
    buttonText: String,
    onlyIcon: Boolean,
    linkClick: String,
  },
  methods: {
    navigateToLinkClick(e) {
      if (this.linkClick) {
        e.preventDefault();
        e.stopPropagation();
        window.location.href = this.linkClick;
      }
    },
  },
});
</script>

<style lang="scss">
.SrpButton {
  display: flex;
  justify-content: flex-start;

  button {
    border-radius: 28px;
    background-color: transparent;
    min-width: 40px;
    height: 40px;
    white-space: nowrap;
    gap: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.wideButton {
      width: 180px;
    }
    &:not(.wideButton) {
      width: 40px;
    }
    &.carousel-button {
      border: 2px solid var(--park-gray-90);
      color: var(--park-gray-90);

      .SrpButtonText {
        font-weight: 500;
      }
    }
    &.default-button {
      border: 2px solid var(--system-alert-blue-60);
      color: var(--system-alert-blue-60);

      &:hover:not(:active) {
        transition: 0.3s;
        border: 2px solid var(--supplementary-blue-50);
        color: var(--supplementary-blue-50);
      }
      &:hover:enabled:not(:active) {
        .onhover {
          display: initial;
        }
        .initial {
          display: none;
        }
      }
    }
  }
  a {
    text-decoration: none;
  }
  .onhover {
    display: none;
  }
}
.SrpButtonText {
  text-align: center;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: 'Roboto Bold', 'roboto medium', roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
}
</style>

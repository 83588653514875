import { constants, GraphQLLayoutService, RestLayoutService } from '@sitecore-jss/sitecore-jss-vue';

import { default as appConfig } from '@/appConfig';

interface FetchParams {
  [param: string]: string | number | boolean;
  sc_apikey: string;
  sc_site: string;
  sc_lang: string;
  tracking: boolean;
}

/* The purpose of this extension is to allow for params to be appended into 
the layout request URL because this is not supported in the default JSS package */
class EchoParkRestLayoutService extends RestLayoutService {
  constructor(private config: any) {
    super(config);
  }
  protected getFetchParams = (language?: string): FetchParams => {
    let params = {};
    if (typeof window !== 'undefined' && window.location?.href) {
      try {
        const currentUrl = new URL(window.location.href);
        params = Object.fromEntries(currentUrl.searchParams.entries()) ?? {};
      } catch (err) {
        console.error(err);
      }
    }

    return {
      ...params,
      sc_apikey: this.config.apiKey,
      sc_site: this.config.siteName,
      sc_lang: language ?? '',
      tracking: true,
    };
  };
}

function getApiHost(): string {
  const sitecoreApiHost: string = appConfig.sitecoreApiHost;
  return sitecoreApiHost.length > 0 && sitecoreApiHost.endsWith('/')
    ? sitecoreApiHost.substring(0, sitecoreApiHost.length - 1)
    : sitecoreApiHost;
}

export class LayoutServiceFactory {
  create() {
    return process.env.VUE_APP_FETCH_WITH === constants.FETCH_WITH.GRAPHQL
      ? new GraphQLLayoutService({
          endpoint: appConfig.graphQLEndpoint,
          apiKey: appConfig.sitecoreApiKey,
          siteName: appConfig.jssAppName,
        })
      : new EchoParkRestLayoutService({
          apiHost: getApiHost(),
          apiKey: appConfig.sitecoreApiKey,
          siteName: 'echopark', // TODO: make JSS app name dynamic
          configurationName: appConfig.layoutServiceConfigurationName,
        });
  }
}

export const layoutServiceFactory = new LayoutServiceFactory();

import { AxiosInstance, AxiosStatic, AxiosResponse } from 'axios';

const createRouteOneClient = (
  axios: AxiosStatic,
  baseUrl: string,
  apiKey: string
): AxiosInstance => {
  const client = axios.create({
    baseURL: baseUrl,
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': apiKey,
    },
    timeout: 20 * 1000, // Timeout in milliseconds
  });

  // Add response interceptor to validate JSON response
  client.interceptors.response.use(
    (response: AxiosResponse) => {
      const contentType = response.headers['content-type'] || '';
      if (!contentType.includes('application/json')) {
        return Promise.reject(
          new Error(`Invalid response format: expected JSON but received ${contentType}`)
        );
      }
      return response; // Continue with the valid response
    },
    (error) => {
      return Promise.reject(error); // Pass through other errors
    }
  );

  return client;
};

export { createRouteOneClient };

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "zip-flyout" }
const _hoisted_2 = { class: "zipLocation_flyout_title" }
const _hoisted_3 = { class: "zip-flyout-locations" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ZipLocationEdit = _resolveComponent("ZipLocationEdit")!
  const _component_ZipNearestStoresDisplay = _resolveComponent("ZipNearestStoresDisplay")!
  const _component_Flyout = _resolveComponent("Flyout")!

  return (_openBlock(), _createBlock(_component_Flyout, {
    isOpen: _ctx.commonStore.zipFlyout,
    onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.commonStore.setZipFlyout(false)))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "zipLocation_flyout newZipLocation",
          "aria-hidden": "true",
          tabindex: "-1",
          style: _normalizeStyle(_ctx.commonStore.zipFlyout ? 'right: 0' : '')
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_ZipLocationEdit, {
              fields: _ctx.fields,
              showClose: true,
              modelValue: _ctx.zipCode,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.zipCode) = $event))
            }, null, 8, ["fields", "modelValue"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_ZipNearestStoresDisplay, {
              fields: _ctx.fields,
              dealerships: _ctx.commonStore.dealerships,
              closestDealership: _ctx.commonStore.nearestDealership,
              loading: _ctx.loading
            }, null, 8, ["fields", "dealerships", "closestDealership", "loading"])
          ])
        ], 4)
      ])
    ]),
    _: 1
  }, 8, ["isOpen"]))
}
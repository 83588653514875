<template>
  <div class="zipchange-header" data-component-name="zip-location-edit">
    <div class="ziplocation-header-top">
      <template v-if="showClose">
        <span class="zipLocation_flyout_close">
          <img src="@icons/icons-menu-close.svg" alt="Close" @click="setZipFlyout(false)" />
        </span>
      </template>
      <div class="location-info-text mb-2">
        <sc-text :field="fields.titleFlyout" />
        <sc-image
          class="icon-location-header-flyout"
          :media="fields.iconLocationFlyout"
          height="16"
          width="16"
        />
        <div class="zip-styling">{{ zipCode }}</div>
        <div class="small-info-text">
          <sc-text :field="fields.descriptionFlyout" />
        </div>
      </div>
    </div>

    <div class="zipInput-wrapper">
      <input
        v-model="modelValue"
        :placeholder="$t('Zip')"
        autocomplete="postal-code"
        @keyup.enter="changeZip"
        @input="handleInput"
        maxlength="5"
        data-for="new-zip-code"
      />
      <button
        type="button"
        class="change-zip-button"
        role="button"
        @click="changeZip"
        data-action="commit-zip-code-change"
      >
        {{ $t('srp.common.change') }}
      </button>
    </div>

    <div class="zip-flyout-validation">
      <span class="field-validation-error" v-if="zipValidationFailed">
        {{ $t('SRP2.ValidationMessages.ValidZipCode') }}
      </span>
      <span class="field-validation-success" v-if="zipUpdated">
        {{ $t('SRP2.ValidationMessages.ChangeZipSuccessMessage') }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Text, Image } from '@sitecore-jss/sitecore-jss-vue';
import zipService from '@services/zipService';
import { fetchDealerships } from '@services/dealershipSearchService';
import { Dealership } from '@/types/ServiceModels';
import { facetMetaData } from '@util/facetHelper';
import { mapActions, mapState } from 'pinia';
import { useCommonStore } from '@/stores/common';
import { useVehicleSearchStore } from '@/stores/vehicleSearch';
import { FieldsPropType } from '@/lib/FieldTypes';

export default defineComponent({
  name: 'ZipLocationEdit',

  props: {
    fields: {
      type: Object as PropType<FieldsPropType>,
      default: () => ({}),
    },
    showClose: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      zipValidationFailed: false,
      zipUpdated: false,
      modelValue: '',
    };
  },

  components: {
    ScText: Text,
    ScImage: Image,
  },

  computed: {
    ...mapState(useCommonStore, ['zipCode', 'dealerships', 'zipFlyout', 'nearestDealership']),
    ...mapState(useVehicleSearchStore, ['radius']),
  },

  emits: ['update:modelValue'],

  methods: {
    ...mapActions(useCommonStore, ['setZipFlyout', 'setDealerships']),
    ...mapActions(useVehicleSearchStore, ['setRadius']),
    async changeZip() {
      const isValid = await zipService.isValidZip(this.modelValue);
      this.zipValidationFailed = !isValid;
      this.zipUpdated = isValid;

      if (isValid) {
        const dealerships: Dealership[] = await fetchDealerships(this.modelValue);
        this.setDealerships(dealerships); // save into pinia

        //If the nearest store is outside the currently set radius for the new zip - then set it to Nationwide and fetch cars
        // @ts-ignore // TODO-pinia uncovered existing type error
        if (this.radius > 0 && this.nearestDealership.distance > this.radius) {
          this.setRadius(facetMetaData['radius'].radiusMap.nationwide.value);
        }

        this.$emit('update:modelValue', this.modelValue);

        this.emitter.emit('fetch-cars-srp');
      }
    },
    handleInput(event: any) {
      this.modelValue = event.target.value.replace(/\D/g, '');
    },
    resetInput() {
      this.zipUpdated = false;
      this.zipValidationFailed = false;
      this.modelValue = '';
    },
  },
  watch: {
    zipFlyout: {
      handler(_newValue, _oldValue) {
        this.resetInput();
      },
    },
  },
});
</script>

<style lang="scss">
.zipchange-header {
  width: 100%;
  display: inline-block;
  color: var(--park-gray-0);
  font-feature-settings:
    'clig' off,
    'liga' off;
  /* Paragraph / Body LG Light */
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 150% */

  .icon-location-header-flyout {
    height: 16px;
  }
}
.ziplocation-header-top {
  display: flex;
  padding-bottom: 8px;
}
.zip-styling {
  display: inline-block;
  color: var(--park-gray-0);
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-left: 4px;
}
.zipLocation_flyout_close {
  min-width: 24px;
  height: 24px;
  cursor: pointer;
  position: absolute;
  right: 24px;
}
.location-info-text {
  display: inline-block;
  color: var(--park-gray-0);
  font-feature-settings:
    'clig' off,
    'liga' off;
  /* Paragraph / Body LG Light */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 150% */
}
.small-info-text {
  width: 241px;
  color: var(--park-gray-0);
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.5px; /* 170.833% */
}
.zipInput-wrapper {
  position: relative;
  width: 100%;
  margin-bottom: 4px;
  background-color: var(--base-white-100);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: var(--park-gray-20);

  input {
    padding: 4px 150px 4px 15px;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: var(--park-gray-20);
    width: 100%;
    height: 48px;
    background-color: transparent;
    border-radius: 28px;
    border: solid 1px var(--park-gray-80);
  }
}
.change-zip-button {
  border-radius: 28px;
  background-color: var(--green-50);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  color: var(--base-white-100) !important;
  padding: 12px 24px;
  width: 113.615px;
  height: 40px;
  position: absolute;
  right: 4px;
  top: 4px;
  border: 0;

  &:hover {
    background-color: var(--green-40);
    color: var(--base-white-100);
    border-color: transparent;
  }
}
.zip-flyout-validation {
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.57;
  letter-spacing: 0.2px;

  .field-validation-error {
    color: var(--supplementary-red-50);
  }
  .field-validation-success {
    color: var(--green-50);
  }
}
</style>

<template>
  <v-row class="style-guide--spacing-vertical">
    <v-col cols="12">
      <v-row class="align-center">
        <v-col cols="4" sm="3" lg="2" xl="1">
          <div class="box spacing_stack-quark"></div>
        </v-col>
        <v-col cols="8" sm="9" lg="10" xl="11">
          <code>$spacing_stack-quark</code>
        </v-col>
      </v-row>
      <v-row class="align-center">
        <v-col cols="4" sm="3" lg="2" xl="1">
          <div class="box spacing_stack-nano"></div>
        </v-col>
        <v-col cols="8" sm="9" lg="10" xl="11">
          <code>$spacing_stack-nano</code>
        </v-col>
      </v-row>
      <v-row class="align-center">
        <v-col cols="4" sm="3" lg="2" xl="1">
          <div class="box spacing_stack-xxxs"></div>
        </v-col>
        <v-col cols="8" sm="9" lg="10" xl="11">
          <code>$spacing_stack-xxxs</code>
        </v-col>
      </v-row>
      <v-row class="align-center">
        <v-col cols="4" sm="3" lg="2" xl="1">
          <div class="box spacing_stack-xxs"></div>
        </v-col>
        <v-col cols="8" sm="9" lg="10" xl="11">
          <code>$spacing_stack-xxs</code>
        </v-col>
      </v-row>
      <v-row class="align-center">
        <v-col cols="4" sm="3" lg="2" xl="1">
          <div class="box spacing_stack-xs"></div>
        </v-col>
        <v-col cols="8" sm="9" lg="10" xl="11">
          <code>$spacing_stack-xs</code>
        </v-col>
      </v-row>
      <v-row class="align-center">
        <v-col cols="4" sm="3" lg="2" xl="1">
          <div class="box spacing_stack-sm"></div>
        </v-col>
        <v-col cols="8" sm="9" lg="10" xl="11">
          <code>$spacing_stack-sm</code>
        </v-col>
      </v-row>
      <v-row class="align-center">
        <v-col cols="4" sm="3" lg="2" xl="1">
          <div class="box spacing_stack-md"></div>
        </v-col>
        <v-col cols="8" sm="9" lg="10" xl="11">
          <code>$spacing_stack-md</code>
        </v-col>
      </v-row>
      <v-row class="align-center">
        <v-col cols="4" sm="3" lg="2" xl="1">
          <div class="box spacing_stack-lg"></div>
        </v-col>
        <v-col cols="8" sm="9" lg="10" xl="11">
          <code>$spacing_stack-lg</code>
        </v-col>
      </v-row>
      <v-row class="align-center">
        <v-col cols="4" sm="3" lg="2" xl="1">
          <div class="box spacing_stack-xl"></div>
        </v-col>
        <v-col cols="8" sm="9" lg="10" xl="11">
          <code>$spacing_stack-xl</code>
        </v-col>
      </v-row>
      <v-row class="align-center">
        <v-col cols="4" sm="3" lg="2" xl="1">
          <div class="box spacing_stack-xxl"></div>
        </v-col>
        <v-col cols="8" sm="9" lg="10" xl="11">
          <code>$spacing_stack-xxl</code>
        </v-col>
      </v-row>
      <v-row class="align-center">
        <v-col cols="4" sm="3" lg="2" xl="1">
          <div class="box spacing_stack-xxxl"></div>
        </v-col>
        <v-col cols="8" sm="9" lg="10" xl="11">
          <code>$spacing_stack-xxxl</code>
        </v-col>
      </v-row>
      <v-row class="align-center">
        <v-col cols="4" sm="3" lg="2" xl="1">
          <div class="box spacing_stack-huge"></div>
        </v-col>
        <v-col cols="8" sm="9" lg="10" xl="11">
          <code>$spacing_stack-huge</code>
        </v-col>
      </v-row>
      <v-row class="align-center">
        <v-col cols="4" sm="3" lg="2" xl="1">
          <div class="box spacing_stack-giant"></div>
        </v-col>
        <v-col cols="8" sm="9" lg="10" xl="11">
          <code>$spacing_stack-giant</code>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'StyleGuide--SpacingVertical',
  components: {},
  data() {
    return {};
  },
});
</script>

<style lang="scss">
.style-guide--spacing-vertical {
  &.v-row {
    margin: 0 -24px;
  }
  .box {
    width: 100%;
    background: rgba(#ff7101, 0.32);
    border: 1px solid #ff7101;
    margin: 0;

    &.spacing_stack-quark {
      height: $spacing_stack-quarck;
    }
    &.spacing_stack-nano {
      height: $spacing_stack-nano;
    }
    &.spacing_stack-xxxs {
      height: $spacing_stack-xxxs;
    }
    &.spacing_stack-xxs {
      height: $spacing_stack-xxs;
    }
    &.spacing_stack-xs {
      height: $spacing_stack-xs;
    }
    &.spacing_stack-sm {
      height: $spacing_stack-sm;
    }
    &.spacing_stack-md {
      height: $spacing_stack-md;
    }
    &.spacing_stack-lg {
      height: $spacing_stack-lg;
    }
    &.spacing_stack-xl {
      height: $spacing_stack-xl;
    }
    &.spacing_stack-xxl {
      height: $spacing_stack-xxl;
    }
    &.spacing_stack-xxxl {
      height: $spacing_stack-xxxl;
    }
    &.spacing_stack-huge {
      height: $spacing_stack-huge;
    }
    &.spacing_stack-giant {
      height: $spacing_stack-giant;
    }
  }
  code {
    white-space: nowrap;
  }
}
</style>

<template>
  <div class="content">
    <sc-rich-text :field="fields.html" />
    <template v-if="mounted && targetElement != null">
      <Teleport :to="targetElement">
        <NavigationZipCode :zipcode="zipCode" />
      </Teleport>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import RichText from '@components-vue/Foundation/Common/RichText';
import { MvcDatasourceModel } from '@lib/CommonDatasourceModels';
import { HeaderMethods } from './HeaderMethods';
import NavigationZipCode from '@components-vue/Feature/Navigation/NavigationZipCode.vue';
import RegisterSearchEvents from '@assets/js/Search';
import { mapWritableState } from 'pinia';
import { useCommonStore } from '@/stores/common';

export default defineComponent({
  // eslint-disable-next-line vue/no-reserved-component-names
  name: 'Header',

  data() {
    return {
      mounted: false,
      targetElement: '',
    };
  },
  props: {
    fields: {
      type: Object as PropType<MvcDatasourceModel>,
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    ...mapWritableState(useCommonStore, ['zipCode']),
  },
  components: {
    ScRichText: RichText,
    NavigationZipCode,
  },
  mounted() {
    const headerMethods = new HeaderMethods(this.$el, `${this.$.uid}`);
    this.targetElement = headerMethods.setupZipElement();
    headerMethods.setupSubMenu();
    headerMethods.setupMobileMenu();

    RegisterSearchEvents(this.$jss?.sitecoreContext()?.itemPath);

    this.mounted = true;
  },
});
</script>

<style lang="scss">
.justify-content-between {
  justify-content: space-between !important;
}

.header.homePageHeader .guest-user {
  background-image: url(@assets/icons/icons-user-profile-white.svg);
}

.header {
  background-color: var(--base-white-100);
  border-bottom: solid 1px var(--park-gray-90);
  position: relative;
  z-index: 71;
  min-height: 124px;
  display: flex;
  flex-wrap: wrap;

  li,
  p,
  ul {
    margin: 0;
    padding: 0;
  }
}

div[id^='headerZipCodeDisplay'] {
  display: flex;
}

.scrollUp .header .search-bar {
  position: fixed;
  top: 0;
  transition: top 1s linear;
  padding: 8px 0;
  background-color: var(--base-white-100);
  box-shadow: 0 1px 4px 0 rgba(36, 44, 51, 0.1);
  border-bottom: solid 1px var(--park-gray-80);
}

.scrollUp .filter-menu-cta-container,
.scrollUp .sorting-menu-container {
  top: 72px;
}

.scrollDown .filter-menu-cta-container,
.scrollDown .sorting-menu-container {
  top: 0;
}

@media (min-width: 992px) {
  .header {
    min-height: 139px;
  }
}

@media (min-width: 1240px) {
  .header {
    position: relative;
    width: 100%;
  }
}

.header_primary {
  padding: 16px 0;
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: space-between;
}

.header-logos-column {
  display: flex;
  align-items: center;
}

@media (min-width: 992px) {
  .header-logos-column {
    justify-content: center;
  }
}

.header .toggle_menu {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  cursor: pointer;
  background: url(@assets/icons/icons-menu-menu-black.svg) no-repeat;
  background-position: center center;
  background-size: 24px;
  vertical-align: top;
}

.header_logo {
  max-width: 83px;
}

@media (min-width: 992px) {
  .header_logo {
    max-width: initial;
  }
}

@media (max-width: 991px) {
  .header_logo img {
    width: 83px;
  }
}

@media (max-width: 363px) {
  .header_logo img {
    max-width: 100%;
  }
}

.header_logo a {
  display: inline-block;
}

.header_logo a.home-logo {
  display: none;
}

.header_right {
  display: inline-flex;
}

@media (min-width: 992px) {
  .header_right {
    padding-right: 8px;
  }
}

.header_right > div:not(:first-child) {
  margin-left: 16px;
}

@media (min-width: 992px) {
  .header_right > div:not(:first-child) {
    margin-left: 24px;
  }
}

.header_right .zipLocation {
  display: flex;
  align-items: center;
}

.header_right .zipLocation span {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.2px;
  color: var(--park-gray-20);
  display: block;
}

.header_right .zipLocation--code {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: right;
  color: var(--supplementary-blue-60);
  text-decoration: none;
}

@media (max-width: 991px) {
  .header_right .zipLocation--code {
    line-height: 1.71;
  }
}

.header_right .zipLocation--icon {
  width: 24px;
  height: 24px;
  display: inline-block;
  font-size: 0;
  margin-left: 4px;
  background-position: center center;
  background-size: 24px;
  vertical-align: top;
}

.header_right .my_favorates {
  display: flex;
  align-items: center;
}

.header_right .my_favorates a {
  width: 24px;
  height: 24px;
  display: inline-block;
  font-size: 0;
  background: url(@assets/icons/icons-header-user-heart-filled.svg) no-repeat;
  background-position: center center;
  background-size: 24px;
  vertical-align: top;
}

.header_right .user_profile {
  display: flex;
  align-items: center;
}

.header_right .user_profile .registered-user {
  width: 24px;
  height: 24px;
  display: inline-block;
  font-size: 0;
  background: url(@assets/icons/icons-user-profile-black.svg) no-repeat;
  background-position: center center;
  background-size: 24px;
  vertical-align: top;
  color: transparent;
}

@media (max-width: 991px) {
  .header .hamburger_menu {
    width: 100%;
    max-width: 375px;
    height: 100%;
    padding: 24px;
    padding-bottom: 96px;
    background-color: var(--park-gray-10);
    position: fixed;
    top: 0;
    left: -100%;
    z-index: 99;
    transition: all 0.3s ease-in-out;
  }

  .header .hamburger_menu:after {
    content: '';
    width: 100%;
    height: 100px;
    background: var(--park-gray-10);
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
  }
}

.header .hamburger_menu .menu_header {
  margin-bottom: 28px;
}

.header .hamburger_menu .menu_header .menu-logo {
  max-width: 83px;
}

.header .hamburger_menu .menu_header .menu_close {
  width: 40px;
  height: 40px;
  margin: -8px;
  border-radius: 100%;
  background: url(@assets/icons/header-icons-menu-close.svg) no-repeat;
  background-position: center center;
  background-size: 24px;
  cursor: pointer;
}

.header .hamburger_menu .menu_header .menu_close:focus,
.header .hamburger_menu .menu_header .menu_close:hover {
  background-color: var(--park-gray-0);
}

.header .hamburger_menu .welcome_msg {
  margin-bottom: 8px;
}

.header .hamburger_menu .welcome_msg .ParagraphWhite-BodyLG {
  color: var(--park-gray-60);
}

.header .hamburger_menu .menu_footer {
  padding: 16px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.header .hamburger_menu .menu_footer .btn {
  width: 100%;
}

.header .main_navigation {
  height: 100%;
  display: flex;
  flex-direction: column;
}

@media (min-width: 992px) {
  .header .main_navigation {
    height: auto;
    display: inline-block;
    padding-left: 8px;
  }
  .d-flex.d-lg-none,
  .d-block.d-lg-none {
    display: none !important;
  }
  .d-none.d-lg-block {
    display: block !important;
  }
}

@media (max-width: 991px) {
  .header .main_navigation nav {
    max-height: 100%;
    overflow: auto;
    margin: 0 -24px;
  }
}

.header .main_navigation nav ul {
  display: inline-flex;
}

@media (max-width: 991px) {
  .header .main_navigation nav ul {
    display: block;
    padding: 0 8px;
  }
}

.header .main_navigation nav ul li {
  display: block;
}

@media (min-width: 992px) {
  .header .main_navigation nav ul li {
    position: relative;
    display: inline-block;
    vertical-align: top;
  }
}

@media (min-width: 992px) {
  .header .main_navigation nav ul li:not(:last-child) {
    margin-right: 16px;
  }
}

@media (min-width: 1240px) {
  .header .main_navigation nav ul li:not(:last-child) {
    margin-right: 32px;
  }
}

.header .main_navigation nav ul li.more_links > a {
  padding-right: 24px;
  background: url(@assets/icons/icons-arrows-arrow-sm-drop-down.svg) no-repeat;
  background-position: right center;
  background-size: 24px;
}

.header .main_navigation nav ul li a {
  font-family: Roboto, sans-serif;
  font-size: 32px;
  font-weight: 300;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.4px;
  color: var(--base-white-100);
  text-decoration: none;
  display: block;
  padding: 12px 16px;
  border-radius: 8px;
}

@media (min-width: 992px) {
  .header .main_navigation nav ul li a {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--park-gray-0);
    padding: 7px 0;
    display: inline-block;
  }
}

@media (max-width: 991px) {
  .header .main_navigation nav ul li a:focus,
  .header .main_navigation nav ul li a:hover {
    background-color: var(--park-gray-0);
  }
}

@media (max-width: 991px) {
  .header .main_navigation nav ul li ul.submenu {
    margin-top: 32px;
    padding: 0;
  }
}

@media (min-width: 992px) {
  .header .main_navigation nav ul li ul.submenu {
    display: none;
    position: absolute;
    top: calc(100% - 2px);
    left: -6px;
    padding: 12px 0;
    background-color: var(--base-white-100);
    min-width: 200px;
    border-radius: 8px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px var(--park-gray-100);
  }
}

.header .main_navigation nav ul li ul.submenu li {
  margin: 0;
  display: block;
}

.header .main_navigation nav ul li ul.submenu li a {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--base-white-100);
  padding: 8px 16px;
}

@media (min-width: 992px) {
  .header .main_navigation nav ul li ul.submenu li a {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: var(--park-gray-0);
    display: block;
    border-radius: 0;
    padding: 12px 24px;
  }
}

@media (min-width: 992px) {
  .header .main_navigation nav ul li ul.submenu li a:hover {
    background-color: var(--accent-warm-gray-100);
  }
}

.header .main_navigation nav ul li ul.submenu.active {
  display: block;
}

.header .search-bar {
  padding-bottom: 8px;
  position: relative;
}

@media (min-width: 992px) {
  .header .search-bar {
    padding-bottom: 12px;
  }
}

.header:not(.homePageHeader) .search-bar .search-bar-input {
  border: solid 1px var(--park-gray-90);
  background-color: var(--accent-warm-gray-100);
}

.header:not(.homePageHeader) .search-bar .search-bar-input:active,
.header:not(.homePageHeader) .search-bar .search-bar-input:hover {
  border-color: var(--park-gray-70);
}

.header:not(.homePageHeader) .search-bar .search-bar-input:focus {
  border-color: var(--park-gray-70);
}

.header:not(.homePageHeader) .search-bar .clearSearch.btn {
  background-color: var(--accent-warm-gray-100);
}

.header:not(.homePageHeader) .search-bar .clearSearch.btn span {
  width: 20px;
  height: 20px;
}

.header:not(.homePageHeader) .search-bar .searchBtn.btn {
  background: var(--accent-warm-gray-100);
}

.header:not(.homePageHeader) .search-bar .searchBtn.btn span {
  background: url(@assets/icons/icons-system-search-black.svg) no-repeat;
  background-position: center center;
  background-size: 24px;
}

.header:not(.homePageHeader) .search-bar .searchBtn.btn:focus {
  color: var(--base-white-100);
  border-color: var(--supplementary-blue-80);
}

.header:not(.homePageHeader) .search-bar .searchBtn.btn:not(:disabled):not(.disabled).active,
.header:not(.homePageHeader) .search-bar .searchBtn.btn:not(:disabled):not(.disabled):active {
  background-color: transparent;
  color: var(--base-white-100);
  border-color: transparent;
}

.header:not(.homePageHeader) .searchFocused .searchBtn.btn {
  background: var(--green-50);
}

.header:not(.homePageHeader) .searchFocused .searchBtn.btn:hover {
  background-color: var(--green-40);
  color: var(--base-white-100);
  border-color: transparent;
}

.header:not(.homePageHeader) .searchFocused .searchBtn.btn.focus,
.header:not(.homePageHeader) .searchFocused .searchBtn.btn:focus {
  background-color: var(--green-50);
  color: var(--base-white-100);
  border-color: var(--supplementary-blue-80);
}

.header:not(.homePageHeader) .searchFocused .searchBtn.btn.disabled,
.header:not(.homePageHeader) .searchFocused .searchBtn.btn:disabled {
  background-color: var(--accent-warm-gray-90);
  color: var(--park-gray-70);
  pointer-events: none;
  border-color: transparent;
}

.header:not(.homePageHeader) .searchFocused .searchBtn.btn:not(:disabled):not(.disabled).active,
.header:not(.homePageHeader) .searchFocused .searchBtn.btn:not(:disabled):not(.disabled):active {
  background-color: var(--green-60);
  color: var(--base-white-100);
  border-color: transparent;
}

.header:not(.homePageHeader) .searchFocused .searchBtn.btn span {
  background: url(@assets/icons/white-search-icon.svg) no-repeat;
}

.header.homePageHeader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 8;
  background: 0;
  border: 0;
}

.header.homePageHeader:before {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, var(--base-black-100), rgba(0, 0, 0, 0));
}

@media (min-width: 768px) {
  .header.homePageHeader:before {
    height: 170px;
    background-image: linear-gradient(
      to bottom,
      var(--base-black-100),
      rgba(0, 0, 0, 0) 100%,
      rgba(0, 0, 0, 0.8) 100%
    );
  }
}

@media (min-width: 992px) {
  .header.homePageHeader:before {
    height: 320px;
  }
}

.header.homePageHeader .toggle_menu {
  background: url(@assets/icons/icons-menu-menu.svg) no-repeat;
  background-position: center center;
  background-size: 24px;
  vertical-align: top;
}

.header.homePageHeader .header_logo .reguler-logo {
  display: none;
}

.header.homePageHeader .header_logo .home-logo {
  display: inline-block;
}

.header.homePageHeader .header_right .zipLocation span {
  color: var(--base-white-100);
}

.header.homePageHeader .header_right .zipLocation--icon {
  background: url(@assets/icons/icons-map-map-location-white.svg) no-repeat;
  background-position: center center;
  background-size: 24px;
  vertical-align: top;
}

.header.homePageHeader .header_right .my_favorates a {
  background: url(@assets/icons/icons-header-user-heart-filled-white.svg) no-repeat;
  background-position: center center;
  background-size: 24px;
  vertical-align: top;
}

.header.homePageHeader .header_right .user_profile .registered-user {
  background: url(@assets/icons/icons-user-profile-white.svg) no-repeat;
  background-position: center center;
  background-size: 24px;
  vertical-align: top;
  color: transparent;
}

.header.homePageHeader .main_navigation nav ul li.more_links > a {
  padding-right: 24px;
  background: url(@assets/icons/icons-arrows-arrow-sm-drop-down-white.svg) no-repeat;
  background-position: right center;
  background-size: 24px;
}

.header.homePageHeader .main_navigation nav ul li a {
  font-family: Roboto, sans-serif;
  font-size: 32px;
  font-weight: 300;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.4px;
  color: var(--base-white-100);
}

@media (min-width: 992px) {
  .header.homePageHeader .main_navigation nav ul li a {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--base-white-100);
  }
}

.search-bar-input {
  width: 100%;
  height: 56px;
  background: var(--base-white-100);
  border: solid 1px var(--park-gray-park-gray-80);
  display: block;
  border-radius: 28px;
  padding: 20px 24px;
  padding-right: 37px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--park-gray-20);
}

@media (min-width: 992px) {
  .search-bar-input {
    border-radius: 32px;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.06);
  }
}

.search-bar {
  width: 100%;
}

.search-bar form {
  position: relative;
  margin: 0;
}

@media (min-width: 768px) {
  .search-bar form {
    margin: 0;
  }
}

.search-bar .search-button-lg,
.search-bar .search-button-md {
  position: absolute;
  top: 4px;
  right: 4px;
}

.searchBtn.btn {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 48px;
  height: 48px;
  font-size: 0;
  padding: 9px;
  vertical-align: middle;
  background: var(--green-50);
  color: var(--base-white-100);
}

.searchBtn.btn:hover {
  background-color: var(--green-40);
  color: var(--base-white-100);
  border-color: transparent;
}

.searchBtn.btn.focus,
.searchBtn.btn:focus {
  background-color: var(--green-50);
  color: var(--base-white-100);
  border-color: var(--supplementary-blue-80);
}

.searchBtn.btn.disabled,
.searchBtn.btn:disabled {
  background-color: var(--accent-warm-gray-90);
  color: var(--park-gray-70);
  pointer-events: none;
  border-color: transparent;
}

.searchBtn.btn:not(:disabled):not(.disabled).active,
.searchBtn.btn:not(:disabled):not(.disabled):active {
  background-color: var(--green-60);
  color: var(--base-white-100);
  border-color: transparent;
}

.searchBtn.btn span {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  display: inline-block;
  vertical-align: middle;
  background: url(@assets/icons/white-search-icon.svg) no-repeat;
  background-position: center center;
  background-size: 24px;
}

.header.sticky.scrollDown .autocomplete-suggestions {
  display: none;
}

.header.sticky.scrollUp .searchAutoSaggetion--container {
  background-color: var(--base-white-100);
}

.header.sticky.scrollUp .searchAutoSaggetion--container .autocomplete-suggestions {
  box-shadow: none;
  border: 0;
}

.header.sticky.scrollUp .autocomplete-suggestions {
  display: none;
}

.header .searchFocused .search-bar-input {
  padding-right: 142px;
}

@media (max-width: 767px) {
  .header .searchFocused .search-bar-input {
    padding-right: 36px;
  }
}

.header .searchFocused .searchBtn.btn {
  width: auto;
  font-size: inherit;
}

@media (max-width: 767px) {
  .header .searchFocused .searchBtn.btn {
    display: none;
  }
}

.header .searchFocused .clearSearch.btn {
  right: 4px;
  display: block;
}

@media (min-width: 768px) {
  .header .searchFocused .clearSearch.btn {
    right: 112px;
  }
}

@media (max-width: 767px) {
  .header .searchFocused .searchCancelMob {
    display: block;
  }
}

@media (max-width: 767px) {
  .header .searchMob {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: var(--base-white-100);
    height: 100%;
    z-index: 99;
    padding-top: 16px;
  }
}

.searchBarMain {
  display: flex;
  flex-wrap: wrap;
}

.searchBarIn {
  position: relative;
  width: 100%;
  flex: 1;
}

.searchBarIn label {
  display: none;
}

.searchCancelMob {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: var(--supplementary-blue-60);
  background: 0;
  border: 0;
  padding-right: 8px;
  padding-left: 16px;
  text-align: center;
  display: none;
}

.clearSearch.btn {
  width: 48px;
  height: 48px;
  padding: 0;
  background: 0;
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: var(--base-white-100);
  display: none;
}

.clearSearch.btn span {
  background-color: var(--base-white-100);
  background-image: url(@assets/icons/icons-search-remove.svg);
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 100%;
  background-size: 24px;
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
}

.guest-user {
  width: 24px;
  height: 24px;
  display: inline-block;
  font-size: 0;
  background: url(@assets/icons/icons-user-profile-black.svg) no-repeat;
  background-position: center center;
  background-size: 24px;
  vertical-align: top;
  text-decoration: none;
}

@media (min-width: 992px) {
  .guest-user {
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.2px;
    color: var(--base-white-100);
    font-weight: 500;
    width: auto;
    height: 32px;
    padding: 7px;
    padding-right: 9px;
    padding-left: 32px;
    vertical-align: middle;
    border-radius: 28px;
    background-color: var(--green-50);
    background-image: url(@assets/icons/icons-user-profile-white.svg);
    background-repeat: no-repeat;
    background-position: 2px center;
    background-size: 24px;
    border: 2px solid transparent;
  }

  .guest-user:hover {
    background-color: var(--green-40);
    color: var(--base-white-100);
    border-color: transparent;
    text-decoration: none;
  }

  .guest-user.focus,
  .guest-user:focus {
    background-color: var(--green-50);
    color: var(--base-white-100);
  }

  .guest-user.disabled,
  .guest-user:disabled {
    background-color: var(--accent-warm-gray-90);
    color: var(--park-gray-70);
    pointer-events: none;
    border-color: transparent;
  }

  .guest-user:not(:disabled):not(.disabled).active,
  .guest-user:not(:disabled):not(.disabled):active {
    background-color: var(--green-60);
    color: var(--base-white-100);
    border-color: transparent;
  }
}

.zipLocation {
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .spShoppingLocationsLabel.d-lg-block {
    display: block !important;
  }
}

menu-open {
  overflow: hidden;
  position: relative;
  min-height: 100%;
  height: 100vh;
}

.menu-open body {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.menu-open .header .hamburger_menu {
  left: 0;
}

/* Search Bar  ------------------------*/

.search-bar-input {
  width: 100%;
  height: 56px;
  background: var(--base-white-100);
  border: solid 1px var(--park-gray-80);
  display: block;
  border-radius: 28px;
  padding: 20px 24px;
  padding-right: 37px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--park-gray-20);
}

@media (min-width: 992px) {
  .search-bar-input {
    border-radius: 32px;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.06);
  }
}

.search-bar {
  width: 100%;
}

.search-bar form {
  position: relative;
  margin: 0;
}

@media (min-width: 768px) {
  .search-bar form {
    margin: 0;
  }
}

.search-bar .search-button-lg,
.search-bar .search-button-md {
  position: absolute;
  top: 4px;
  right: 4px;
}

.searchBtn.btn {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 48px;
  height: 48px;
  font-size: 0;
  padding: 9px;
  vertical-align: middle;
  background: var(--green-50);
  color: var(--base-white-100);
}

.searchBtn.btn:hover {
  background-color: var(--green-40);
  color: var(--base-white-100);
  border-color: transparent;
}

.searchBtn.btn.focus,
.searchBtn.btn:focus {
  background-color: var(--green-50);
  color: var(--base-white-100);
  border-color: var(--supplementary-blue-80);
}

.searchBtn.btn.disabled,
.searchBtn.btn:disabled {
  background-color: var(--accent-warm-gray-90);
  color: var(--park-gray-70);
  pointer-events: none;
  border-color: transparent;
}

.searchBtn.btn:not(:disabled):not(.disabled).active,
.searchBtn.btn:not(:disabled):not(.disabled):active {
  background-color: var(--green-60);
  color: var(--base-white-100);
  border-color: transparent;
}

.searchBtn.btn span {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  display: inline-block;
  vertical-align: middle;
  /* background: url(../images/white-search-icon.svg) no-repeat; */
  background-position: center center;
  background-size: 24px;
}

.header.sticky.scrollDown .autocomplete-suggestions {
  display: none;
}

.header.sticky.scrollUp .searchAutoSaggetion--container {
  background-color: var(--base-white-100);
}

.header.sticky.scrollUp .searchAutoSaggetion--container .autocomplete-suggestions {
  box-shadow: none;
  border: 0;
}

.header.sticky.scrollUp .autocomplete-suggestions {
  display: none;
}

.header .searchFocused .search-bar-input {
  padding-right: 142px;
}

@media (max-width: 767px) {
  .header .searchFocused .search-bar-input {
    padding-right: 36px;
  }
}

.header .searchFocused .searchBtn.btn {
  width: auto;
  font-size: inherit;
}

@media (max-width: 767px) {
  .header .searchFocused .searchBtn.btn {
    display: none;
  }
}

.header .searchFocused .clearSearch.btn {
  right: 4px;
  display: block;
}

@media (min-width: 768px) {
  .header .searchFocused .clearSearch.btn {
    right: 112px;
  }
}

@media (max-width: 767px) {
  .header .searchFocused .searchCancelMob {
    display: block;
  }
}

@media (max-width: 767px) {
  .header .searchMob {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
    height: 100%;
    z-index: 99;
    padding-top: 16px;
  }
}

.clearSearch.btn {
  width: 48px;
  height: 48px;
  padding: 0;
  background: 0;
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: #fff;
  display: none;
}

.clearSearch.btn span {
  background-color: var(--base-white-100);
  /* background-image: url(../images/icons-search-remove.svg); */
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 100%;
  background-size: 24px;
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
}

.searchCancelMob {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: var(--supplementary-blue-60);
  background: 0;
  border: 0;
  padding-right: 8px;
  padding-left: 16px;
  text-align: center;
  display: none;
}

@media (min-width: 768px) {
  .searchAutoSaggetion--container {
    position: absolute;
    top: calc(100% - 12px);
    width: 100%;
    margin-top: 4px;
  }
}

.searchAutoSaggetion--container .searchAutoSaggetion {
  background-color: var(--base-white-100);
  position: relative;
  width: 100%;
}

@media (min-width: 768px) {
  .searchAutoSaggetion--container .searchAutoSaggetion {
    overflow: hidden;
    border-radius: 24px;
  }
}

@media (max-width: 767px) {
  .searchAutoSaggetion--container.active .searchAutoSaggetion {
    margin-top: 8px;
    margin-left: -16px;
    margin-right: -16px;
    height: calc(100% - 80px);
    position: fixed;
    width: 100%;
    overflow: hidden;
  }
}

.searchAutoSaggetion--container.active .autocomplete-suggestions {
  display: block;
}

.searchBarMain {
  display: flex;
  flex-wrap: wrap;
}

.searchBarIn {
  position: relative;
  width: 100%;
  flex: 1;
}

.searchBarIn label {
  display: none;
}

.autocomplete-suggestions {
  background-color: var(--base-white-100);
  width: calc(100% + 6px) !important;
  padding-right: 8px;
  overflow: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--green-60) var(--accent-warm-gray-80);
}

@media (min-width: 768px) {
  .autocomplete-suggestions {
    border-radius: 24px;
    padding: 8px;
    max-height: 416px;
    vertical-align: top;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.06);
    border: solid 1px var(--park-gray-90);
  }
}

@media (max-width: 767px) {
  .autocomplete-suggestions {
    position: relative !important;
    max-height: 100% !important;
  }
}

.autocomplete-suggestions::-webkit-scrollbar {
  width: 8px;
}

.autocomplete-suggestions::-webkit-scrollbar-track {
  background: var(--accent-warm-gray-80);
}

.autocomplete-suggestions::-webkit-scrollbar-thumb {
  background: var(--green-60);
}

.autocomplete-suggestions::-webkit-scrollbar-thumb:hover {
  background: var(--green-60);
}

.autocomplete-suggestion {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--park-gray-0);
  padding: 8px 40px;
  cursor: pointer;
  margin-left: -16px;
  margin-right: -16px;
  vertical-align: top;
}

@media (min-width: 768px) {
  .autocomplete-suggestion {
    padding-left: 16px;
    padding-right: 16px;
    margin-right: 0;
    margin-left: 0;
    border-radius: 8px;
  }
}

.autocomplete-suggestion:hover {
  background-color: var(--accent-warm-gray-100);
}

.autocomplete-selected {
  background-color: var(--accent-warm-gray-100);
}
</style>

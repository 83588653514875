<template>
  <CtaCarOffer data-testid="cta-banner" :isGrid="false" :fields="fields"></CtaCarOffer>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import CtaCarOffer from '@components/Feature/VehicleSearch/CtaCarOffer.vue';
import { FieldsPropType } from '@/lib/FieldTypes';

export default defineComponent({
  name: 'CtaBanner',
  props: {
    fields: {
      type: Object as PropType<FieldsPropType>,
      default: () => ({}),
    },
  },
  components: {
    CtaCarOffer,
  },
});
</script>

<template>
  <transition name="slide">
    <div class="mileage-filter">
      <div v-show="!mileageFilterSelected && !filterSelected" data-role="facetfilter">
        <FilterMenuLineItem
          @click="expandFilter()"
          :isSelected="!!facetFilters[filterType]"
          :title="$t('Mileage Filter Title')"
        />
      </div>
      <div v-show="mileageFilterSelected">
        <span class="lblTitle"
          ><b>{{ title }}</b></span
        >
        <div class="mileageDropdown" data-action-set="mileage-value">
          <Dropdown :options="mileOptions" @input="handleOptionSelected" v-model="selected" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Dropdown from './Dropdown.vue';
import FilterMenuLineItem from './FilterMenuLineItem.vue';
import { facetMetaData } from '@util/facetHelper';
import { Option } from '@/types/VehicleSearch/Dropdown';
import { mapActions, mapState } from 'pinia';
import { useVehicleSearchStore } from '@/stores/vehicleSearch';

export default defineComponent({
  name: 'MileageFilter',
  props: ['filterName', 'fields'],
  components: {
    Dropdown,
    FilterMenuLineItem,
  },
  data() {
    return {
      title: this.$t('Mileage Filter Title'),
      optionText: this.$t('Mileage Dropdown Text'),
      mileOptions: [] as Option[],
      selected: '200000',
      filterType: facetMetaData?.miles.key,
    };
  },
  computed: {
    ...mapState(useVehicleSearchStore, {
      facetFilters: (state) => state.facetFilters ?? {},
      filterSelected: (state) => state.filterSelected,
      mileageFilterSelected: (state) => state.mileageFilterSelected,
      vehicleData: (state) => state.srpVehiclesData,
      // @ts-ignore // TODO-pinia existing error
      vehicleMileMax: (state) => state.srpVehiclesData?.facets?.miles?.max,
    }),
  },
  created() {
    this.getOptions();
  },
  methods: {
    ...mapActions(useVehicleSearchStore, [
      'setFilterSelected',
      'setMileageFilterSelected',
      'setResetSelectedFacetItems',
      'setNumericFacetFilter',
    ]),
    expandFilter() {
      this.setFilterSelected(true);
      this.setMileageFilterSelected(true);
      this.setResetSelectedFacetItems(true);
    },
    getOptions() {
      let nearestUpperValue = Math.ceil((this.vehicleMileMax ?? 200000) / 10000) * 10000;
      this.mileOptions = [];

      for (let i = nearestUpperValue; i >= 1000; i -= 10000) {
        let value = i.toString();
        let stringValue = i.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        let label = stringValue + ' ' + this.optionText;
        this.mileOptions.push({ value: value, label: label });
      }
      this.selected = String(nearestUpperValue);
    },
    updateStore(data) {
      this.setNumericFacetFilter(data);
      this.emitter.emit('filter-updated-srp');
    },
    handleOptionSelected(event) {
      const updateValue = event.target.value;
      let maxInt = String(updateValue);
      this.selected = maxInt;
      const data = {
        name: this.filterType,
        value: { min: '0', max: maxInt },
      };
      this.updateStore(data);
    },
  },
  watch: {
    facetFilters: {
      handler: async function (newValue) {
        if (!newValue.hasOwnProperty(this.filterType)) {
          this.selected = String(Math.ceil((this.vehicleMileMax ?? 200000) / 10000) * 10000);
        } else {
          this.selected = String(this.facetFilters[this.filterType].max);
        }
      },
      deep: true,
    },
    vehicleMileMax: 'getOptions',
  },
});
</script>

<style lang="scss">
.mileageDropdown {
  padding: 0 24px 16px;
}
</style>

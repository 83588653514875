import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sca-employment mb-6" }
const _hoisted_2 = {
  id: "extra-income-section",
  class: "extra-income-section"
}
const _hoisted_3 = { class: "mt-6 mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EmploymentDetailsForm = _resolveComponent("EmploymentDetailsForm")!
  const _component_FormHeader = _resolveComponent("FormHeader")!
  const _component_v_radio = _resolveComponent("v-radio")!
  const _component_v_radio_group = _resolveComponent("v-radio-group")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_EmploymentDetailsForm, {
      employmentDetails: _ctx.currentEmployment,
      "onUpdate:employmentDetails": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentEmployment) = $event)),
      fields: _ctx.fields,
      fieldsMap: _ctx.fieldsMap,
      isCobuyer: _ctx.isCobuyer,
      hasError: _ctx.hasError,
      buyerEmploymentSchema: _ctx.buyerEmploymentSchema,
      onClearErrors: _ctx.clearErrors
    }, null, 8, ["employmentDetails", "fields", "fieldsMap", "isCobuyer", "hasError", "buyerEmploymentSchema", "onClearErrors"]),
    (_ctx.isPreviousEmploymentRequired || _ctx.isUnemployed)
      ? (_openBlock(), _createBlock(_component_EmploymentDetailsForm, {
          key: 0,
          employmentDetails: _ctx.previousEmployment,
          "onUpdate:employmentDetails": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.previousEmployment) = $event)),
          isPrevious: true,
          fields: _ctx.fields,
          fieldsMap: _ctx.fieldsMap,
          isCobuyer: _ctx.isCobuyer,
          hasError: _ctx.hasError,
          buyerEmploymentSchema: _ctx.buyerEmploymentSchema,
          onClearErrors: _ctx.clearErrors
        }, null, 8, ["employmentDetails", "fields", "fieldsMap", "isCobuyer", "hasError", "buyerEmploymentSchema", "onClearErrors"]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createVNode(_component_FormHeader, {
        title: _ctx.fieldsMap.get(_ctx.coPrefix + 'extra-income.Label')?.value,
        note: _ctx.fieldsMap.get('extra-income.Description')
      }, null, 8, ["title", "note"]),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.fieldsMap.get(_ctx.coPrefix + 'extra-income.other-income-sources.Label')?.value), 1),
      _createVNode(_component_v_radio_group, {
        modelValue: _ctx.other.hasExtraIncome,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.other.hasExtraIncome) = $event)),
        "data-testid": "sca-extra-income",
        "hide-details": "auto"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_radio, {
            value: "No",
            "data-testid": "sca-extra-income-no",
            label: _ctx.fieldsMap.get(_ctx.coPrefix + 'extra-income.other-income-sources.no.Label')?.value,
            "prepend-inner-icon": "mdi-lock",
            "hide-details": "auto"
          }, null, 8, ["label"]),
          _createVNode(_component_v_radio, {
            value: "Yes",
            "data-testid": "sca-extra-income-yes",
            label: _ctx.fieldsMap.get(_ctx.coPrefix + 'extra-income.other-income-sources.yes.Label')?.value,
            "prepend-inner-icon": "mdi-lock"
          }, null, 8, ["label"])
        ]),
        _: 1
      }, 8, ["modelValue"]),
      (_ctx.other.hasExtraIncome === 'Yes')
        ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                lg: "6",
                class: "py-0"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    label: _ctx.buyerEmploymentSchema?.['otherIncomeAmount']?.label,
                    "data-testid": "sca-extra-income-amount",
                    modelValue: _ctx.other.otherIncomeAmountString,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.other.otherIncomeAmountString) = $event)),
                    onInput: _ctx.preventNegativeValues,
                    maxlength: "14",
                    type: "number",
                    "error-messages": _ctx.errorMessages('otherIncomeAmount', 'Enter a monthly amount.')
                  }, null, 8, ["label", "modelValue", "onInput", "error-messages"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                lg: "6",
                class: "py-0"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    label: _ctx.buyerEmploymentSchema?.['otherIncomeSourceDescription']?.label,
                    "data-testid": "sca-extra-income-source",
                    modelValue: _ctx.other.otherIncomeSourceDescription,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.other.otherIncomeSourceDescription) = $event)),
                    "error-messages": 
              _ctx.errorMessages('otherIncomeSourceDescription', 'Enter income source(s).')
            
                  }, null, 8, ["label", "modelValue", "error-messages"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ], 512), [
      [_vShow, _ctx.employmentStatusCodeSelected]
    ])
  ]))
}
<template>
  <div v-if="isVisible" class="no-results-screen-container">
    <FuelAnimatedIcon />

    <sc-text class="no-results-icon-title" :field="fields.IconTitle" />
    <sc-text class="no-results-icon-subtitle" :field="fields.IconSubtitle" />

    <div class="form-group mb-0">
      <Button class="btn-new-search" :text="fields.ButtonLabel.value" @click="triggerNewSearch()" />
    </div>

    <hr class="no-results-screen-divider" />

    <sc-text class="no-results-bottom-title" :field="fields.BottomSectionTitle" />

    <div class="no-results-bottom-links">
      <!-- Call -->
      <a :href="CtaPhoneNumber" class="no-results-icon-link">
        <span class="link-icon" @click="phoneLinkClick">
          <sc-image :media="fields.FirstLinkIcon" />
        </span>
        <span class="link-text">{{ phoneNumber }}</span>
      </a>
      <!-- See FAQs -->
      <a :href="fields.SecondLinkCTA.value" class="no-results-icon-link">
        <span class="link-icon" @click="secondLinkRedirect">
          <sc-image :media="fields.SecondLinkIcon" />
        </span>
        <span class="link-text">{{ fields.SecondLinkLabel.value }}</span>
      </a>
      <!-- Chat -->
      <a href="#" class="no-results-icon-link" @click.prevent="triggerChat">
        <span class="link-icon">
          <sc-image :media="fields.ThirdLinkIcon" />
        </span>
        <span class="link-text">{{ fields.ThirdLinkLabel.value }}</span>
      </a>
      <!-- Update your location -->
      <a href="#" class="no-results-icon-link" @click.prevent="showZipFlyout">
        <span class="link-icon">
          <sc-image :media="fields.FourthLinkIcon" />
        </span>
        <span class="link-text">{{ fields.FourthLinkLabel.value }}</span>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import FuelAnimatedIcon from '@assets/icons/FuelAnimatedIcon/FuelAnimatedIcon.vue';
import Button from '@components-vue/Foundation/Common/Buttons/Button.vue';
import { Text, Image } from '@sitecore-jss/sitecore-jss-vue';
import { defineComponent, PropType } from 'vue';
import * as facetHelper from '@util/facetHelper';
import { mapActions, mapState } from 'pinia';
import { useCommonStore } from '@/stores/common';
import { useVehicleSearchStore } from '@/stores/vehicleSearch';
import { FieldsPropType } from '@/lib/FieldTypes';

export default defineComponent({
  name: 'NoResultsScreen',
  data() {
    return {
      phoneNumber: '',
      CtaPhoneNumber: '',
    };
  },
  props: {
    fields: {
      type: Object as PropType<FieldsPropType>,
      default: () => ({}),
    },
  },
  components: {
    FuelAnimatedIcon,
    ScText: Text,
    ScImage: Image,
    Button, // eslint-disable-line vue/no-reserved-component-names
  },
  methods: {
    ...mapActions(useVehicleSearchStore, [
      'resetStoreDefaults',
      'clearSelectedFacets',
      'setRadius',
    ]),
    ...mapActions(useCommonStore, ['setZipFlyout']),
    showZipFlyout() {
      this.setZipFlyout(true);
    },
    triggerNewSearch() {
      /* As per Jason: It should go back to default (clearing all filters), but then changing the radius to Nationwide */
      const data = {
        radius: this.$jss.sitecoreContext()?.appliedFacetFilters?.radius,
        layoutPath: this.$jss?.sitecoreContext()?.layoutPath,
      };

      this.resetStoreDefaults(data);
      this.clearSelectedFacets();
      this.setRadius(facetHelper.facetMetaData['radius'].radiusMap.nationwide.value);
      this.emitter.emit('filter-updated-srp');
    },
    secondLinkRedirect() {
      let self = this;
      window.location.href = self.fields.SecondLinkCTA.value;
    },
    phoneLinkClick() {
      window.location.href = this.CtaPhoneNumber;
    },
    triggerChat() {
      // @ts-ignore
      if (typeof CNPC !== 'undefined') {
        // @ts-ignore
        CNPC.toggleChat(this);
      }

      return false;
    },
  },
  mounted() {
    this.phoneNumber = this.$jss?.sitecoreContext()?.location?.nearestStore?.phoneNumber;
    if (this.phoneNumber) {
      this.CtaPhoneNumber = 'tel:' + this.phoneNumber.replace(/\D/g, '');
    }
  },
  computed: {
    ...mapState(useVehicleSearchStore, ['srpVehiclesData', 'loading']),
    resultCount() {
      if (!this.srpVehiclesData) return -1;
      return this.srpVehiclesData.resultCount;
    },
    isVisible() {
      return !this.loading && this.resultCount == 0;
    },
  },
});
</script>

<style lang="scss">
.no-results-screen-container {
  min-height: 240px;
  border-radius: 16px;
  border: 1px solid var(--park-gray-80);
  background: var(--base-white-100);
  display: flex;
  padding: 48px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  order: 5;
  margin: 0 24px 48px 24px;

  @media only screen and (min-width: 1441px) {
    margin-bottom: 100px;
  }

  .link-text {
    color: var(--system-alert-blue-60);
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.4px;
    cursor: pointer;

    &:hover:enabled {
      text-decoration-line: underline;
    }
    &:active:enabled {
      color: var(--supplementary-blue-50);
      text-decoration-line: underline;
    }
    &:focus:enabled {
      border-radius: 4px;
      border: 2px solid var(--supplementary-blue-80);
      text-decoration-line: underline;
    }
    &:disabled {
      color: var(--park-gray-70);
    }
  }
  .btn-new-search {
    margin-top: 24px;
  }
  .no-results-icon-title {
    color: var(--park-gray-0);
    font-family: 'Roboto', sans-serif;
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px;

    @media only screen and (min-width: 625px) {
      font-size: 32px;
    }
  }
  .no-results-icon-subtitle {
    color: var(--park-gray-0);
    text-align: center;
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 16px;
  }
  .no-results-screen-divider {
    border-top: solid 1px var(--park-gray-90);
    margin: 40px 0;
    width: 100%;
  }
  .no-results-bottom-title {
    color: var(--park-gray-0);
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px;

    @media only screen and (min-width: 625px) {
      font-size: 32px;
    }
  }
  .no-results-bottom-links {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
    margin: 32px auto auto 0;
    flex-direction: column;

    @media only screen and (min-width: 625px) and (max-width: 979px) {
      display: grid;
      grid-column-gap: 80px;
      grid-row-gap: 40px;
      grid-template-columns: 225px 225px;
      margin: 32px 0 0;
    }
    @media only screen and (min-width: 980px) {
      display: flex;
      flex-direction: row;
      gap: 40px;
      margin: 32px 0 0;
    }
    @media only screen and (min-width: 1441px) {
      gap: 80px;
    }
  }
  .no-results-icon-link {
    display: flex;
    gap: 8px;
    align-items: center;
  }
  .link-icon {
    display: flex;
    width: 40px;
    height: 40px;
    padding: 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 28px;
    border: 0 solid var(--system-alert-blue-60);
    background: var(--system-alert-blue-90);
    cursor: pointer;
  }
}
</style>

export const toCurrencyNoCents = (numberToFormat: number) => {
  return numberToFormat.toLocaleString('en-us', {
    maximumFractionDigits: 0,
    style: 'currency',
    currency: 'USD',
  });
};

export const stringToNumber = (stringToFormat: string) => {
  return Number(stringToFormat.replace(/\D/g, ''));
};

export const toNumberWithCommasNoCents = (stringToFormat: string) => {
  return stringToNumber(stringToFormat).toLocaleString('en-us', {
    maximumFractionDigits: 0,
  });
};

export function numberWithCommas(x: number) {
  return Intl.NumberFormat().format(x); //x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatVehicleName(make, model, trim) {
  return `${make} ${model} ${trim}`;
}

export function formatVehicleInstallmentPrice(low: number, high: number) {
  return '$' + numberWithCommas(low) + '-$' + numberWithCommas(high);
}

export function formatVehicleMileage(miles: number) {
  return miles >= 1000 ? `${Math.floor(miles / 1000)}K` : miles;
}

export function formatVehiclePrice(price) {
  return numberWithCommas(price);
}

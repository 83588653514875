<template>
  <div class="checkout-header">
    <sc-rich-text :field="fields.html" />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import RichText from '@components-vue/Foundation/Common/RichText';
import { MvcDatasourceModel } from '@/lib/CommonDatasourceModels';

export default defineComponent({
  name: 'Checkout Header',
  props: {
    fields: {
      type: Object as PropType<MvcDatasourceModel>,
      default: () => ({}),
    },
  },
  components: {
    ScRichText: RichText,
  },
});
</script>

<style lang="scss">
.checkout-header {
  border-bottom: 1px solid $park-gray-90;
}
</style>

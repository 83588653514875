<template>
  <div class="forgotPasswordOuterContainer d-none">
    <div id="forgotPasswordContainer" class="create_account">
      <sc-rich-text :field="fields.html" />
    </div>
  </div>
</template>

<script lang="ts">
import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import { onLoginMount } from '@assets/js/login';
import { onForgotPasswordMount } from '@assets/js/forgotPassword';
import { FieldsPropType } from '@/lib/FieldTypes';
import { PropType } from 'vue';

export default {
  name: 'ForgotPassword',
  props: {
    fields: {
      type: Object as PropType<FieldsPropType>,
      default: () => ({}),
    },
  },
  components: {
    ScRichText: RichText,
  },
  mounted() {
    onLoginMount();
    onForgotPasswordMount();
  },
  updated() {
    onLoginMount();
    onForgotPasswordMount();

    // NOTE vue3 upgrade: checkValidationAndSubmit is missing from the legacy
    // flow, however it is also missing from the SRP2 implementation, and adding
    // it results in two forgot password emails being fired - so we are not
    // adding it so we match the SRP2 behavior
  },
};
</script>

<style lang="scss">
#forgotPasswordContainer {
  z-index: 81;
}

.forgotPasswordOuterContainer {
  position: fixed;
  z-index: 999;

  .btn {
    background-color: var(--green-50);
    color: var(--base-white-100);
  }
}
</style>

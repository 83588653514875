import { RouteData, Field } from '@sitecore-jss/sitecore-jss-vue';
import { layoutServiceFactory } from '@lib/layout-service-factory';

export interface OverlayRoute extends RouteData {
  fields: {
    ClassName: Field;
    CloseX: Field;
    IsPersistent: Field;
    ModalId: Field;
    SubTitle: Field;
    Title: Field;
  };
}

export async function fetchOverlayRouteData(overlayName: string): Promise<OverlayRoute | null> {
  const sitecoreOverlayPath = `/sitecore/content/Sonic/EchoPark/site-components/Overlays/${overlayName}`;
  const language = 'en';
  const layoutServiceInstance = layoutServiceFactory.create();

  // get the route data for the new route
  try {
    const x = await layoutServiceInstance.fetchLayoutData(sitecoreOverlayPath, language);

    if (!x?.sitecore?.route) {
      console.error(`The overlay named ${overlayName} was not found.`);
      return null;
    }

    return x.sitecore.route as OverlayRoute;
  } catch (error) {
    console.error('Error fetching overlay route data:', error);
    return null;
  }
}

<template>
  <!-- This component should not define any styling beyond the responsive layout -->
  <v-container class="two-col-layout pa-0" fluid>
    <v-row no-gutters>
      <v-col cols="12" md="6" lg="5" :class="layoutClasses[0]" class="left-col">
        <div class="fixed-content">
          <placeholder name="left-content" :rendering="rendering" />
        </div>
      </v-col>
      <v-col cols="12" md="6" lg="7" :class="layoutClasses[1]" class="right-col">
        <placeholder name="right-content" :rendering="rendering" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { ComponentRendering, Placeholder } from '@sitecore-jss/sitecore-jss-vue';

export default defineComponent({
  name: 'TwoColumnLayout',
  props: {
    fields: {
      type: Object as PropType<Object>,
      default: () => ({}),
    },
    params: {
      type: Object,
      default: () => ({}),
    },
    rendering: {
      type: Object as PropType<ComponentRendering>,
      default: () => ({}),
    },
  },
  computed: {
    layoutClasses() {
      const leftCol: string[] = [];
      const rightCol: string[] = [];
      if (this.params['Behavior'] === 'leftDisappear') {
        // leftDisappear - hide the left side for small devices
        leftCol.push('d-none', 'd-md-block');
      } else if (this.params['Behavior'] === 'rightDisappear') {
        // rightDisappear - hide the right side for small devices
        rightCol.push('d-none', 'd-md-block');
      }

      return [leftCol, rightCol];
    },
  },
  components: { Placeholder },
});
</script>

<style lang="scss">
@use '@/assets/styles/variables' as *;
@use 'sass:math';

.sc-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: 100vh;
}

.two-col-layout {
  flex: auto;

  & > .v-row {
    height: 100%;
  }
  .fixed-content {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 50%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 0;
    }
    @include media-breakpoint-up(lg) {
      width: math.div(100%, 12) * 5;
    }
    @include media-breakpoint-up(xl) {
      width: calc(100% - 800px);
    }
  }
  .left-col {
    @include media-breakpoint-up(xl) {
      flex-basis: calc(100% - 800px);
      max-width: unset;
    }
  }
  .right-col {
    position: relative;
    z-index: 1;
    border-left: 1px solid $park-gray-90;
    box-shadow: 1px 4px 4px 0 rgba($base-black-100, 0.25);

    @include media-breakpoint-up(xl) {
      flex-basis: 800px;
      max-width: 800px;
    }
  }
}
</style>

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "sa_modal_exit",
  class: "modal-overlay"
}
const _hoisted_2 = { class: "modal-body" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-icon-wrapper" }
const _hoisted_5 = { class: "modal-text-wrapper" }
const _hoisted_6 = { class: "modal-title" }
const _hoisted_7 = { class: "modal-subtitle" }
const _hoisted_8 = { class: "modal-buttons" }
const _hoisted_9 = ["aria-description"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sc_image = _resolveComponent("sc-image")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_sc_image, {
            media: _ctx.fields.Icon,
            class: "modal-icon"
          }, null, 8, ["media"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.fields.ContentTitle.value), 1),
          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.fields.NotSavedContent.value), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_v_btn, {
          type: "button",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.emitter.emit('exit-overlay:cta-click', _ctx.fields?.['CtaLink']?.value?.['href']))),
          "aria-description": _ctx.ariaAccept
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.confirmCTA), 1)
          ]),
          _: 1
        }, 8, ["aria-description"]),
        _createElementVNode("a", {
          href: "#",
          class: "exit-cta-dismiss",
          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$emit('dismiss') /* OverlayHandler handles all dismiss events */), ["prevent"])),
          "aria-description": _ctx.ariaCancel
        }, _toDisplayString(_ctx.cancelCTA), 9, _hoisted_9)
      ])
    ])
  ]))
}
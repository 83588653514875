import { defineStore } from 'pinia';
import * as Yup from 'yup';
import config from '@/appConfig';
import {
  CreditAppNav,
  CurrentStepDetails,
  FlowOption,
  Buyer,
  DealJacketVehicle,
  Finance,
  ValidationSchema,
  SitecoreSchema,
  ValidationSchemas,
  SchemaDefinitions,
  BuyerIdentity,
  ContactInfo,
  Address,
  PersonalDetails,
  EmploymentDetails,
  AdditionalDetails,
  CreditApplication,
  AbstractSchemaDefinition,
} from '@/types/StandaloneCreditApp/StandaloneCreditAppTypes';
import { Dealership } from '@/types/ServiceModels';
import { createValidationSchemas, createSchemaFields } from '@/util/schema/schemaValidator';
import { useCommonStore } from '@/stores/common';
import {
  getGclidFromCookie,
  getMsclidFromCookie,
  getGaFromCookie,
  getFbclidFromCookie,
} from '@assets/js/capiFunctions';

export interface StandaloneCreditAppState {
  navSteps: CreditAppNav; // all nav options - pulled from the SC layout
  currentStepDetails: CurrentStepDetails | undefined; // current SCA form component details, e.g. StandaloneCreditApp--Start.vue
  currentStepPath: string | undefined; // current router path, e.g. /standalone-credit-app/personal-details
  stepErrors: string[]; // errors shown at the top of the current step
  flow: FlowOption | undefined; // selected flow, e.g. 'single' or 'coApplicant'
  applicationSchema: AbstractSchemaDefinition;
  validationSchemas: ValidationSchemas;
  schemaObject: SchemaDefinitions;
  apiKey: string;
  primaryBuyer: Buyer;
  coBuyer?: Buyer;
  dealJacketVehicles: DealJacketVehicle[];
  finance?: Finance;
  isLoading: boolean;
  routeOneDealerId: String;
  trade: String;
  selectedDealership: Dealership;
  additionalDetails: AdditionalDetails;
  isDebugging: boolean;
}

export const useStandaloneCreditAppStore = defineStore({
  id: 'standaloneCreditApp',
  state: (): StandaloneCreditAppState => ({
    navSteps: {},
    stepErrors: [],
    currentStepDetails: undefined,
    currentStepPath: undefined,
    flow: undefined,
    applicationSchema: {
      properties: {},
      required: [] as string[],
      $defs: {},
    },
    validationSchemas: {},
    schemaObject: {},
    apiKey: config.routeOne.defaultApiKey, // This is default value. See server.ts where we set this value from the environment variable
    dealJacketVehicles: [],
    routeOneDealerId: '',
    selectedDealership: {},
    trade: '', // Trade type- used for eLead only currently, per Jason 2/11/2024 (not validated against a schema or sent to route one), add into comments section
    finance: {
      transactionType: 'RETAIL',
      vehicleCashPrice: 0,
      cashDownPayment: 0,
      cashDownPaymentString: '',
    },
    primaryBuyer: {
      customerTypeCode: 'D',
      identity: {
        firstName: '',
        middleName: '',
        lastName: '',
        suffix: undefined, // enum ['JR', 'SR', 'I', 'II', 'III', 'IV', 'V', ''] // add empty option (its optional)
        displayName: '',
        dateOfBirth: '',
        dateOfBirthDefined: false,
      },
      contactInfo: {
        phone: '',
        phoneType: 'HOME', // not asked for, hardcode for now
        email: '',
        altPhone: '', // not asked for
        altPhoneType: 'MOBILE', // not asked for, hardcode for now
      },
      personalDetails: {
        ssn: '',
        ssnDefined: false,
      },
      address: {
        addressLine1: '',
        addressLine2: '',
        zipCode: '',
        city: '',
        state: '',
        county: '', // not asked for
        countryCode: 'US', // not asked for, hardcode for now
        moveInMonth: undefined, // not on schema
        moveInYear: undefined, // not on schema
        monthsAtAddress: 0,
        residenceTypeCode: '',
        residenceTypeCodeText: '',
        rentMortgagePaymentAmountString: '',
        rentMortgagePaymentAmount: 0,
        previousAddressLine1: '',
        previousAddressLine2: '',
        previousZipCode: '',
        previousCity: '',
        previousState: '',
        previousCounty: '',
        previousCountryCode: '',
        previousMoveInMonth: undefined, // not on schema
        previousMoveInYear: undefined, // not on schema
        previousMonthsAtAddress: 0,
        previousResidenceTypeCode: '',
        previousResidenceTypeCodeText: '',
        previousRentMortgagePaymentAmountString: '', // Not on schema
        previousRentMortgagePaymentAmount: 0, // Not on schema
      },
      employmentDetails: {
        employmentTypeCode: '',
        employmentStatusCode: '',
        employmentTitle: '',
        employerName: '',
        employerPhone: '',
        employerStartMonth: undefined, // Not on schema
        employerStartYear: undefined, // Not on schema
        monthsOnJob: 0,
        incomeTypeCode: '' as 'Hourly' | 'Salary', // not on schema
        hourlyPayRate: 0, // not on schema
        hourlyPayRateString: '', // not on schema
        avgWeeklyHours: 0, // not on schema
        avgWeeklyHoursString: '', // not on schema
        hasBonusPay: '', // not on schema
        monthlyBonusPay: 0, // not on schema
        monthlyBonusPayString: '', // not on schema
        annualOrMonthly: '' as 'Annual' | 'Monthly' | '', // not on schema
        annualIncome: 0, // not on schema
        annualIncomeString: '', // not on schema
        monthlyIncome: 0, // not on schema
        monthlyIncomeString: '', // not on schema
        incomeAmount: 0,
        incomeIntervalCode: '',
        currencyTypeCode: 'USD', // set as default, not asked for
        hasExtraIncome: 'No',
        otherIncomeSourceCode: '',
        otherIncomeSourceDescription: '',
        otherIncomeAmount: 0,
        otherIncomeAmountString: '',
        otherIncomeIntervalCode: '',
        otherCurrencyTypeCode: '',
        educationLevelCode: '',
        previousEmploymentTypeCode: '', // Not on schema
        previousEmploymentStatusCode: '', // Not on schema
        previousEmploymentTitle: '', // Not on schema
        previousEmployerName: '',
        previousEmployerPhone: '', // Not on schema
        previousEmployerStartMonth: undefined, // Not on schema
        previousEmployerStartYear: undefined, // Not on schema
        previousMonthsOnJob: 0,
        previousIncomeTypeCode: '' as 'Hourly' | 'Salary', // not on schema
        previousHourlyPayRate: 0, // not on schema
        previousHourlyPayRateString: '', // not on schema
        previousAvgWeeklyHours: 0, // not on schema
        previousAvgWeeklyHoursString: '', // not on schema
        previousHasBonusPay: '', // not on schema
        previousMonthlyBonusPay: 0,
        previousMonthlyBonusPayString: '',
        previousAnnualOrMonthly: '' as 'Annual' | 'Monthly' | '', // not on schema
        previousAnnualIncome: 0, // not on schema
        previousAnnualIncomeString: '', // not on schema
        previousMonthlyIncome: 0, // not on schema
        previousMonthlyIncomeString: '', // not on schema
        previousIncomeAmount: 0, // Not on schema
        previousIncomeIntervalCode: '', // Not on schema
        previousCurrencyTypeCode: 'USD', // Set as default, not asked for
      },
    },
    coBuyer: {
      customerTypeCode: 'D',
      identity: {
        firstName: '',
        middleName: '',
        lastName: '',
        suffix: undefined, // enum ['JR', 'SR', 'I', 'II', 'III', 'IV', 'V']
        displayName: '',
        dateOfBirth: '',
        dateOfBirthDefined: false,
      },
      contactInfo: {
        phone: '',
        phoneType: 'HOME', // not asked for, hardcode for now
        email: '',
        altPhone: '', // not asked for
        altPhoneType: 'MOBILE', // not asked for, hardcode for now
      },
      personalDetails: {
        ssn: '',
        ssnDefined: false,
      },
      address: {
        addressLine1: '',
        addressLine2: '',
        zipCode: '',
        city: '',
        state: '',
        county: '',
        countryCode: 'US', // not asked for, hardcode for now
        moveInMonth: undefined, // not on schema
        moveInYear: undefined, // not on schema
        monthsAtAddress: 0,
        residenceTypeCode: '',
        residenceTypeCodeText: '',
        rentMortgagePaymentAmountString: '',
        rentMortgagePaymentAmount: 0,
        previousAddressLine1: '',
        previousAddressLine2: '',
        previousZipCode: '',
        previousCity: '',
        previousState: '',
        previousCounty: '',
        previousCountryCode: '',
        previousMoveInMonth: undefined, // not on schema
        previousMoveInYear: undefined, // not on schema
        previousMonthsAtAddress: 0,
        previousResidenceTypeCode: '',
        previousResidenceTypeCodeText: '',
        previousRentMortgagePaymentAmountString: '', // Not on schema
        previousRentMortgagePaymentAmount: 0, // Not on schema
      },
      employmentDetails: {
        employmentTypeCode: '',
        employmentStatusCode: '',
        employmentTitle: '',
        employerName: '',
        employerPhone: '',
        employerStartMonth: undefined, // Not on schema
        employerStartYear: undefined, // Not on schema
        monthsOnJob: 0,
        incomeAmount: 0,
        incomeIntervalCode: '',
        currencyTypeCode: 'USD', // Set as default, not asked for
        hasExtraIncome: 'No',
        otherIncomeSourceCode: '',
        otherIncomeSourceDescription: '',
        otherIncomeAmount: 0,
        otherIncomeIntervalCode: '',
        otherCurrencyTypeCode: 'USD', // Set as default, not asked for
        educationLevelCode: '',
        previousEmploymentTypeCode: '', // Not on schema
        previousEmploymentStatusCode: '', // Not on schema
        previousEmploymentTitle: '', // Not on schema
        previousEmployerName: '',
        previousEmployerPhone: '', // Not on schema
        previousEmployerStartMonth: undefined, // Not on schema
        previousEmployerStartYear: undefined, // Not on schema
        previousMonthsOnJob: 0,
        previousIncomeAmount: 0, // Not on schema
        previousIncomeIntervalCode: '', // Not on schema
        previousCurrencyTypeCode: '', // Not on schema
      },
    },
    additionalDetails: {
      storeId: '',
      source: '',
      comments: [
        {
          GClid: '',
          FBClids: '',
          MSClids: '',
          GAClientID: '',
          timestamp: '',
          nearestLocation: '',
          zipCode: '',
          notes: '',
          trade: '',
        },
      ],
    },
    isLoading: false,
    isDebugging: false, // controls extra btn to allow skipping between steps w/o validation
  }),
  /* https://github.com/vuejs/pinia/issues/58#issuecomment-577074138
     TLDR: actions to mutate the state are no longer required in pinia compared 
     to vuex, but we are keeping them around to ease the transition */
  actions: {
    setSelectedDealership(dealership: Dealership) {
      this.selectedDealership = dealership;
    },
    setIdentity(identity: BuyerIdentity, isCobuyer: boolean = false) {
      if (isCobuyer) {
        this.coBuyer = this.coBuyer ?? ({ identity: {} } as Buyer);
        this.coBuyer.identity = { ...this.coBuyer.identity, ...identity };
      } else {
        this.primaryBuyer.identity = { ...this.primaryBuyer.identity, ...identity };
      }
    },
    setContactInfo(contactInfo: ContactInfo, isCoBuyer: boolean = false) {
      if (isCoBuyer) {
        this.coBuyer = this.coBuyer ?? ({ contactInfo: {} } as Buyer);
        this.coBuyer.contactInfo = { ...this.coBuyer.contactInfo, ...contactInfo };
      } else {
        this.primaryBuyer.contactInfo = { ...this.primaryBuyer.contactInfo, ...contactInfo };
      }
    },
    setAddress(address: Partial<Address>, isCoBuyer: boolean = false) {
      if (isCoBuyer) {
        if (this.coBuyer) {
          this.coBuyer.address = { ...this.coBuyer.address, ...address };
        }
      } else {
        this.primaryBuyer.address = { ...this.primaryBuyer.address, ...address };
      }
    },
    setPersonalDetails(personalDetails: PersonalDetails, isCoBuyer: boolean = false) {
      if (isCoBuyer) {
        if (!this.coBuyer) {
          this.coBuyer = { personalDetails: {} } as Buyer;
        }
        this.coBuyer.personalDetails = { ...this.coBuyer.personalDetails, ...personalDetails };
      } else {
        this.primaryBuyer.personalDetails = {
          ...this.primaryBuyer.personalDetails,
          ...personalDetails,
        };
      }
    },
    setEmployment(employment: Partial<EmploymentDetails>, isCoBuyer: boolean = false) {
      if (isCoBuyer) {
        if (!this.coBuyer) {
          this.coBuyer = { employmentDetails: {} } as Buyer;
        }
        this.coBuyer.employmentDetails = { ...this.coBuyer.employmentDetails, ...employment };
      } else {
        this.primaryBuyer.employmentDetails = {
          ...this.primaryBuyer.employmentDetails,
          ...employment,
        };
      }
    },
    setFinance(finance: Finance) {
      this.finance = { ...this.finance, ...finance };
    },
    setNavSteps(value: CreditAppNav) {
      this.navSteps = value;
    },
    setCurrentStepDetails(value: CurrentStepDetails) {
      this.currentStepDetails = value;
    },
    setCurrentStepPath(value: string) {
      this.currentStepPath = value;
    },
    setFlow(value: FlowOption) {
      this.flow = value;
    },
    setApplicationSchema(value: AbstractSchemaDefinition) {
      this.applicationSchema = value;
    },
    setDealJacketVehicle(vin: string | undefined): void {
      if (!vin) {
        return;
      }

      const newVehicle: DealJacketVehicle = {
        vehicle: {
          vin,
        },
        intendedUse: 'PERSONAL',
      };

      const existingIndex = this.dealJacketVehicles.findIndex((djv) => djv.vehicle.vin === vin);
      if (existingIndex !== -1) {
        this.dealJacketVehicles[existingIndex] = newVehicle;
      } else {
        this.dealJacketVehicles.push(newVehicle);
      }
    },
    generateValidation(
      sitecoreSchema?: Object,
      formOptions?: { subsetName?: string; formName?: string }
    ) {
      this.schemaObject = createSchemaFields(
        this.applicationSchema,
        (sitecoreSchema as SitecoreSchema) || {},
        'SCA',
        formOptions
      );

      if (this.schemaObject) {
        this.validationSchemas = createValidationSchemas(this.schemaObject, 'SCA');
      }
    },
    addError(error: string) {
      this.stepErrors.push(error);
    },
    addErrors(errors: string[]) {
      this.clearErrors();
      errors.forEach((error) => {
        if (!this.stepErrors.includes(error)) {
          this.addError(error);
        }
      });
    },
    clearErrors() {
      this.stepErrors = [];
    },
    formatSsn(ssn: string | undefined): string {
      const ssnStr = String(ssn).trim();
      if (ssnStr.length !== 9 || isNaN(Number(ssnStr))) {
        return String(ssn);
      }

      return `XXX-XX-${ssnStr.slice(-4)}`;
    },
    displayPhoneNumber(number: string | number): string {
      const cleaned = String(number).replace(/\D/g, '');
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      return match ? `${match[1]}-${match[2]}-${match[3]}` : String(number);
    },
    formatCurrency(amount?: number): string {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(amount || 0);
    },
    formattedAddress(isPrevious: boolean, isCobuyer: boolean): string {
      const buyer = isCobuyer ? this.coBuyer : this.primaryBuyer;

      if (!buyer || !('address' in buyer)) {
        return '';
      }

      const { address } = buyer;

      if (!address) return '';

      const addressData = isPrevious
        ? {
            addressLine1: address.previousAddressLine1,
            addressLine2: address.previousAddressLine2,
            city: address.previousCity,
            state: address.previousState,
            zipCode: address.previousZipCode,
          }
        : {
            addressLine1: address.addressLine1,
            addressLine2: address.addressLine2,
            city: address.city,
            state: address.state,
            zipCode: address.zipCode,
          };

      const parts = [
        addressData.addressLine1,
        addressData.addressLine2, // Optional, only included if it exists
        `${addressData.city}, ${addressData.state} ${addressData.zipCode}`,
      ].filter(Boolean); // Remove any undefined or empty values

      return parts.join('<br>');
    },
    formattedMonthAndYear(
      isPrevious: boolean,
      isCobuyer: boolean,
      type: 'employment' | 'address'
    ): string {
      const buyer = isCobuyer ? this.coBuyer : this.primaryBuyer;

      if (!buyer) return '';

      let month, year;

      if (type === 'employment' && buyer.employmentDetails) {
        month = isPrevious
          ? buyer.employmentDetails.previousEmployerStartMonth
          : buyer.employmentDetails.employerStartMonth;
        year = isPrevious
          ? buyer.employmentDetails.previousEmployerStartYear
          : buyer.employmentDetails.employerStartYear;
      } else if (type === 'address' && buyer.address) {
        month = isPrevious ? buyer.address.previousMoveInMonth : buyer.address.moveInMonth;
        year = isPrevious ? buyer.address.previousMoveInYear : buyer.address.moveInYear;
      }

      if (!month || !year) return '';

      const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];

      return `${monthNames[month - 1]}, ${year}`;
    },
    setLoading(loding: boolean) {
      this.isLoading = loding;
    },
  },
  getters: {
    // If these values are not set, we can assume the SCA hasn't been initialized
    isInitialized(): boolean {
      return !!(this.navSteps && this.flow);
    },
    hasApplicationSchema(): boolean {
      return (
        Object.keys(this.applicationSchema.properties || {}).length > 0 ||
        (this.applicationSchema.required?.length ?? 0) > 0 ||
        Object.keys(this.applicationSchema.$defs || {}).length > 0
      );
    },
    buyerIdentitySchema(): Record<string, any> | undefined {
      return this.schemaObject?.['buyer']?.['identity'];
    },
    buyerPersonalDetailsSchema(): Record<string, any> | undefined {
      return this.schemaObject?.['buyer']?.['personalDetails'];
    },
    buyerContactSchema(): Record<string, any> | undefined {
      return this.schemaObject?.['buyer']?.['contactInfo'];
    },
    buyerAddressSchema(): Record<string, any> | undefined {
      return this.schemaObject?.['address'];
    },
    buyerEmploymentSchema(): Record<string, any> | undefined {
      const employmentDetails = this.schemaObject?.['buyer']?.['employmentDetails'] || {};
      const sitecoreFields = this.schemaObject?.['sitecoreFields'] || {};
      return { ...employmentDetails, ...sitecoreFields };
    },
    buyerFinanceSchema(): Record<string, any> | undefined {
      return this.schemaObject?.['finance'];
    },
    buyerIdentityValidator(): ValidationSchema {
      return this.validationSchemas?.['buyer.identity'] || Yup.object().shape({});
    },
    buyerContactValidator(): ValidationSchema {
      return this.validationSchemas?.['buyer.contactInfo'] || Yup.object().shape({});
    },
    buyerAddressValidator(): ValidationSchema {
      return this.validationSchemas?.['address'] || Yup.object().shape({});
    },
    buyerEmploymentValidator(): ValidationSchema {
      return this.validationSchemas?.['buyer.employmentDetails'] || Yup.object().shape({});
    },
    buyerStoreValidator(): ValidationSchema {
      return this.validationSchemas?.['additionalDetails'] || Yup.object().shape({});
    },
    buyerPersonalDetailsValidator(): ValidationSchema {
      return this.validationSchemas?.['buyer.personalDetails'] || Yup.object().shape({});
    },
    buyerFinanceValidator(): ValidationSchema {
      return this.validationSchemas?.['finance'] || Yup.object().shape({});
    },
    routeOneValidator(): ValidationSchema {
      return this.validationSchemas?.['routeOne'] || Yup.object().shape({});
    },
    primaryBuyerAddress(): Address | undefined {
      return this.primaryBuyer?.address;
    },

    isFormStep(): boolean {
      // not the first or last element
      return (
        this.isInitialized &&
        this.totalStepCount !== undefined &&
        this.currentStepCount !== undefined &&
        this.currentStepCount > 0 &&
        this.currentStepCount <= this.totalStepCount
      );
    },
    isStartStep(): boolean {
      // TODO: avoid hard coding this template
      return this.currentStepDetails?.componentName === 'StandaloneCreditApp--Start';
    },
    isStoreStep(): boolean {
      // TODO: avoid hard coding this template
      return this.currentStepDetails?.componentName === 'StandaloneCreditApp--Store';
    },
    // 1-indexed
    currentStepCount(): number | undefined {
      // The first and last pages are assumed to be the start/finish pages and aren't counted as "steps"
      if (this.currentStepPath) {
        return this.currentFlowSteps.indexOf(this.currentStepPath);
      }
    },
    totalStepCount(): number | undefined {
      // The first and last pages are assumed to be the start/finish pages and aren't counted as "steps"
      return this.currentFlowSteps.length - 2;
    },
    stepPercent(): number | undefined {
      if (
        this.totalStepCount === undefined ||
        this.totalStepCount === 0 ||
        this.currentStepCount === undefined
      ) {
        return 0;
      } else {
        // On step 1 of 5, show 0% completed, on 5/5 show 80% completed as per figma
        return Math.ceil(((this.currentStepCount - 1) / this.totalStepCount) * 100);
      }
    },
    nextStep(): string | undefined {
      // if at the last step, no next step
      const isLastStep =
        this.currentFlowSteps[this.currentFlowSteps.length - 1] === this.currentStepPath;
      if (!isLastStep && this.currentStepCount !== undefined && this.currentStepCount >= 0) {
        return this.currentFlowSteps?.at(this.currentStepCount + 1);
      }
    },
    prevStep(): string | undefined {
      // if at the first step, no prev step
      const isFirstStep = this.currentFlowSteps[0] === this.currentStepPath;
      if (!isFirstStep && this.currentStepCount !== undefined) {
        return this.currentFlowSteps?.at(this.currentStepCount - 1);
      }
    },
    singleApplicantNavSteps(): string[] {
      return fetchNavSteps(this.navSteps, 'Single Applicant');
    },
    coApplicantNavSteps(): string[] {
      return fetchNavSteps(this.navSteps, 'Co Applicant');
    },
    // nav steps based on flow the user selected
    currentFlowSteps(): string[] {
      if (this.flow === 'single') {
        return this.singleApplicantNavSteps;
      } else if (this.flow == 'coApplicant') {
        return this.coApplicantNavSteps;
      } else {
        return [];
      }
    },
    routeOneApplication(): CreditApplication {
      return removeEmptyValues({
        routeOneDealerId: this.selectedDealership?.routeOneDealerId,
        primaryBuyer: {
          customerTypeCode: this.primaryBuyer.customerTypeCode,
          identity: { ...(this.primaryBuyer?.identity as BuyerIdentity) },
          contactInfo: { ...(this.primaryBuyer?.contactInfo as ContactInfo) },
          personalDetails: { ...(this.primaryBuyer?.personalDetails as PersonalDetails) },
          address: { ...(this.primaryBuyer.address as Address) },
          employmentDetails: { ...(this.primaryBuyer.employmentDetails as EmploymentDetails) },
        },
        ...(this.flow === 'coApplicant' && {
          coBuyer: {
            customerTypeCode: this.coBuyer?.customerTypeCode,
            identity: { ...(this.coBuyer?.identity as BuyerIdentity) },
            contactInfo: { ...(this.coBuyer?.contactInfo as ContactInfo) },
            personalDetails: { ...(this.coBuyer?.personalDetails as PersonalDetails) },
            address: { ...(this.coBuyer?.address as Address) },
            employmentDetails: { ...(this.coBuyer?.employmentDetails as EmploymentDetails) },
          },
        }),
        dealJacketVehicles: this.dealJacketVehicles as DealJacketVehicle[],
        finance: { ...(this.finance as Finance) },
        additionalDetails: {
          storeId: this.selectedDealership?.fortellisDealerId,
          comments: [
            {
              GClid: getGclidFromCookie(),
              FBClids: getFbclidFromCookie(),
              MSClids: getMsclidFromCookie(),
              GAClientID: getGaFromCookie(),
              timestamp: generateTimestamp(),
              zipCode: useCommonStore().zipCode,
              trade: this.trade, // add here per Jason requirements
              // nearestLocation: 'Dallas Location', // Example- leave out for now
              // notes: 'This is a note from EchoPark!', // Example- leave out for now
              // source: 'Echopark.com Standalone Credit Application', // Being hardcoded on api side
            },
          ],
        } as AdditionalDetails,
      });
    },
  },
});

const fetchNavSteps = (navSteps: CreditAppNav, flowType: string): string[] => {
  return Object.entries(navSteps)
    .filter(([, values]) => values.includes(flowType))
    .map(([key]) => key);
};

const removeEmptyValues = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(removeEmptyValues);
  }

  if (obj !== null && typeof obj === 'object') {
    return Object.entries(obj)
      .filter(([, value]) => value !== '' && value !== undefined)
      .reduce((acc, [key, value]) => {
        acc[key] = removeEmptyValues(value);
        return acc;
      }, {} as any);
  }

  return obj;
};

const generateTimestamp = () => {
  const now = new Date();
  const offset = -now.getTimezoneOffset();
  const sign = offset >= 0 ? '+' : '-';
  const pad = (num) => String(num).padStart(2, '0');

  const year = now.getFullYear();
  const month = pad(now.getMonth() + 1);
  const day = pad(now.getDate());
  const hours = pad(now.getHours());
  const minutes = pad(now.getMinutes());
  const seconds = pad(now.getSeconds());
  const milliseconds = String(now.getMilliseconds()).padStart(3, '0');

  const offsetHours = pad(Math.floor(Math.abs(offset) / 60));
  const offsetMinutes = pad(Math.abs(offset) % 60);

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${sign}${offsetHours}:${offsetMinutes}`;
};

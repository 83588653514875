<template>
  <PersonalDetailsForm
    class="sca-co-applicant-personal-details"
    :fields="fields"
    ref="formComponent"
    :showCoApplicantCheckbox="true"
    :isCobuyer="true"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { useStandaloneCreditAppStore } from '@/stores/standaloneCreditApp';
import { mapActions } from 'pinia';
import { accumulateChildFields } from '@/util/schema/schemaValidatorUtils';
import PersonalDetailsForm from './Forms/PersonalDetailsForm.vue';
import standaloneCreditAppFormStep from '@/mixins/standaloneCreditAppFormStep';
import { FieldsPropType } from '@/lib/FieldTypes';

export default defineComponent({
  name: 'StandaloneCreditApp--CoApplicantPersonalDetails',
  props: {
    fields: {
      type: Object as PropType<FieldsPropType>,
      default: () => ({}),
    },
  },
  mixins: [standaloneCreditAppFormStep],
  components: {
    PersonalDetailsForm,
  },
  methods: {
    ...mapActions(useStandaloneCreditAppStore, ['generateValidation']),
    getSubmitHandler(): () => Promise<boolean> {
      return (this.$refs.formComponent as any).submitHandler;
    },
  },
  created() {
    const addressSubsetName = this.fields?.['children'][1]?.['fields']?.['subset-name']?.value;
    this.generateValidation(accumulateChildFields(this.fields), { subsetName: addressSubsetName });
  },
});
</script>

<style lang="scss">
@use '@/assets/styles/variables' as *;

.sca-co-applicant-personal-details {
  margin-bottom: $spacing_stack-xxxs;
}
</style>

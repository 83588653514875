import Cookies from 'js-cookie';
import { defineStore } from 'pinia';
import { facetMetaData } from '@util/facetHelper';
import { generateCapiEventId } from '@assets/js/capiFunctions';

import emitter from '@util/eventBus';
import { useCommonStore } from '@/stores/common';
import { SearchResultsState, SortType } from '@/types/VehicleSearch/SearchResults';
import { FacetFilters } from '@/types/VehicleSearch/Facets';

export const useVehicleSearchStore = defineStore({
  id: 'vehicleSearch',
  state: (): SearchResultsState => ({
    // TODO-pinia rename SearchResultsState -> VehicleSearchState
    queryParams: {
      page: 0,
    },
    loading: false,
    filterSelected: false,
    distanceShippingFilterSelected: false,
    mpgEngineFilterSelected: false,
    colorFilterSelected: false,
    bodyStyleFilterSelected: false,
    yearFilterSelected: false,
    mileageFilterSelected: false,
    driveTypeFilterSelected: false,
    priceFilterSelected: false,
    makeModelTrimFilterSelected: false,
    extColorToggleSelected: true,
    priceToggleSelected: true,
    shipToStore: true,
    shipToStoreRadius: true,
    shopByStore: false,
    filterZipFlyout: false,
    featureFilterSelected: false,
    radius: -1,
    defaultRadius: -1,
    take: 24,
    srpVehiclesData: null,
    includedDealerships: [],
    facetFilters: {} as FacetFilters,
    selectedFacetFilters: {} as FacetFilters,
    resetSelectedFacetItems: false,
    sortType: {
      sortBy: 'distance',
      sortDirection: 'asc',
      sortName: 'Distance: Nearest',
    },
    askUsModalTitle: null,
    facetChipsExpanded: false,
    hiddenChips: 0,
    facetCounts: {},
    recentSelectedFilter: '',
    remainingParams: {},
    supplementalContent: null,
  }),
  /* https://github.com/vuejs/pinia/issues/58#issuecomment-577074138 */
  actions: {
    setQueryParams(paramObject) {
      if (paramObject?.page) {
        this.queryParams['page'] = paramObject?.page;
      }
    },
    setPage(value) {
      this.queryParams['page'] = parseInt(value);
    },
    setAskUsModalTitle(value) {
      this.askUsModalTitle = value;
    },
    setPageIncrement(value) {
      this.queryParams['page'] = parseInt(this.queryParams['page']) + parseInt(value);
    },
    setFeatureFilterSelected(value) {
      this.featureFilterSelected = value;
    },
    setFilterSelected(value) {
      if (!value) {
        this.distanceShippingFilterSelected = value;
        this.mpgEngineFilterSelected = value;
        this.colorFilterSelected = value;
        this.bodyStyleFilterSelected = value;
        this.yearFilterSelected = value;
        this.mileageFilterSelected = value;
        this.driveTypeFilterSelected = value;
        this.priceFilterSelected = value;
        this.makeModelTrimFilterSelected = value;
        this.featureFilterSelected = value;
      }
      this.filterSelected = value;
    },
    setSpecificFilterSelected(value) {
      const filter = value;

      if (value && value.length > 0) {
        this.filterSelected = true;
        // TODO add better types for facetMetaData here
        if (filter === 'storename' || filter === 'shiptostore') {
          this.distanceShippingFilterSelected = true;
        } else if (filter === facetMetaData['mmt'].key) {
          this.makeModelTrimFilterSelected = true;
        } else if (filter === facetMetaData['bodystyle'].key) {
          this.bodyStyleFilterSelected = true;
        } else if (filter === facetMetaData['price'].key) {
          this.priceFilterSelected = true;
          this.priceToggleSelected = true;
        } else if (filter === facetMetaData['payment'].key) {
          this.priceFilterSelected = true;
          this.priceToggleSelected = false;
        } else if (filter === facetMetaData['miles'].key) {
          this.mileageFilterSelected = true;
        } else if (filter === facetMetaData['year'].key) {
          this.yearFilterSelected = true;
        } else if (filter === facetMetaData['feature'].key) {
          this.featureFilterSelected = true;
        } else if (
          filter === facetMetaData['hmpg'] ||
          filter === facetMetaData['enginedescription'].key
        ) {
          this.mpgEngineFilterSelected = true;
        } else if (
          filter === facetMetaData['drivetype'].key ||
          filter === facetMetaData['transmission'].key
        ) {
          this.driveTypeFilterSelected = true;
        } else if (filter === facetMetaData['extcolor'].key) {
          this.colorFilterSelected = true;
          this.extColorToggleSelected = true;
        } else if (filter === facetMetaData['intcolor'].key) {
          this.colorFilterSelected = true;
          this.extColorToggleSelected = false;
        }
      }
    },
    setDistanceShippingFilterSelected(value) {
      this.distanceShippingFilterSelected = value;
    },
    setMakeModelTrimFilterSelected(value) {
      this.makeModelTrimFilterSelected = value;
    },
    setColorFilterSelected(value) {
      this.colorFilterSelected = value;
    },
    setMpgEngineFilterSelected(value) {
      this.mpgEngineFilterSelected = value;
    },
    setBodyStyleFilterSelected(value) {
      this.bodyStyleFilterSelected = value;
    },
    setYearFilterSelected(value) {
      this.yearFilterSelected = value;
    },
    setMileageFilterSelected(value) {
      this.mileageFilterSelected = value;
    },
    setDriveTypeFilterSelected(value) {
      this.driveTypeFilterSelected = value;
    },
    setPriceFilterSelected(value) {
      this.priceFilterSelected = value;
    },
    setShopByStore(value) {
      this.shopByStore = value;
      setShopLocationCookie({ radius: this.radius });
    },
    setFilterZipFlyout(value) {
      this.filterZipFlyout = value;
    },
    setRecentSelectedFilter(value) {
      this.recentSelectedFilter = value;
    },
    setShipToStore(value) {
      this.shipToStore = value;

      let obj = {
        Removed: `Ship To Store`,
        event: '',
      };

      if (value) {
        obj = {
          Removed: `Ship To Store`,
          event: 'Filter Applied',
        };
      } else if (value) {
        obj = {
          Removed: `Ship To Store`,
          event: 'Filter Removed',
        };
      }

      emitter.emit('analytics', obj);
    },
    setShipToStoreRadius(value) {
      this.shipToStoreRadius = value;
    },
    initializeRadius(value) {
      if (!value || this.radius === value) return;
      this.radius = value;
    },
    setRadius(value) {
      this.radius = value;
      setShopLocationCookie({ radius: this.radius });
    },
    setTake(value) {
      this.take = value;
    },
    setSrpVehiclesData(value) {
      this.srpVehiclesData = value || 0;
      const facetKeys = Object.keys(this.facetFilters);
      const facetValues = Object.values(this.facetFilters);
      const appliedFacets: string[] = [];
      const includedDealershipsString = Object.values(this.includedDealerships);
      const commonStore = useCommonStore();

      facetValues.forEach((nestedObj, index) => {
        // @ts-ignore
        if (nestedObj?.type) {
          if (
            // @ts-ignore
            nestedObj.type === 'numericRange' &&
            // @ts-ignore
            nestedObj.min !== undefined &&
            // @ts-ignore
            nestedObj.max !== undefined
          ) {
            // @ts-ignore
            appliedFacets.push(`${facetKeys[index]}: ${nestedObj['min']}-${nestedObj.max}`);
            // @ts-ignore
          } else if (nestedObj.type === 'string' && nestedObj.facetValue) {
            // @ts-ignore
            appliedFacets.push(`${facetKeys[index]}:${nestedObj.facetValue}`);
            // @ts-ignore
          } else if (nestedObj.type === 'hierarchical' && nestedObj.facetValue) {
            // @ts-ignore
            appliedFacets.push(`${facetKeys[index]}:${getKeys(nestedObj.facetValue)}`);
          }
        }
      });
      // @ts-ignore
      appliedFacets.push(...includedDealershipsString);

      const appliedFacetsFormatted = appliedFacets.join(', ');

      const viewResultsObj = {
        applied: appliedFacetsFormatted ?? '',
        event: 'View Results Clicked',
        nearestStore: commonStore.nearestDealership?.dealerId ?? '',
        resultCount: this.srpVehiclesData?.resultCount ?? 0,
      };

      emitter.emit('analytics', viewResultsObj);

      const capiEventId = generateCapiEventId('top cars Information');
      const obj = {
        event: 'top cars Information',
        CAPI_event_id: capiEventId,
      };
      let locationName, makeName, priceName, modelName, currencyName, vinName, yearName, zipName;
      for (let i = 0; i < 5; i++) {
        locationName = `Location${i + 1}`;
        makeName = `make${i + 1}`;
        priceName = `Price${i + 1}`;
        modelName = `model${i + 1}`;
        currencyName = `currency${i + 1}`;
        vinName = `vin${i + 1}`;
        yearName = `year${i + 1}`;
        zipName = `zip${i + 1}`;

        obj[locationName] = value.items[i]?.dealership || null;
        obj[makeName] = value.items[i]?.make || null;
        obj[priceName] = value.items[i]?.sellingPrice || null;
        obj[modelName] = value.items[i]?.model || null;
        obj[currencyName] = 'USD';
        obj[vinName] = value.items[i]?.vin || null;
        obj[yearName] = value.items[i]?.year || null;
        obj[zipName] = commonStore.zipCode || null;
      }
      emitter.emit('analytics', obj);
    },
    setLoading(value) {
      this.loading = value;
    },
    setIncludedDealerships(value) {
      this.includedDealerships = value ?? [];
      if (this.includedDealerships.length > 0) {
        this.shopByStore = true;
        this.shipToStore = false;
        setShopLocationCookie({ dealer: this.includedDealerships });
      } else {
        this.shopByStore = false; // if 0 selected stores, cannot shop by store
        this.shipToStore = this.shipToStoreRadius;
      }
    },
    setFacetCounts(value) {
      this.facetCounts = value;
    },
    setResetSelectedFacetItems(value) {
      this.resetSelectedFacetItems = value;
    },
    setDefaultRadius(value) {
      this.defaultRadius = value;
    },
    setRemoveFacet: function (value) {
      const facetFilters = this.facetFilters[value];
      const facetFiltersValues = Object.values(facetFilters);
      let obj = {
        Removed: `${value}`,
        event: 'Filter Removed',
      };
      if (
        typeof facetFiltersValues[2] != undefined &&
        facetFiltersValues[2] != null &&
        facetFiltersValues[2] == 'numericRange'
      ) {
        obj = {
          Removed: `${value}:${facetFiltersValues[0]}-${facetFiltersValues[1]}`,
          event: 'Filter Removed',
        };
      }

      delete this.facetFilters[value];
      emitter.emit('analytics', obj);
    },
    setStringFacetFilter: function (value) {
      const facetName = value['name'];
      const facetValue = value['value'];

      if (typeof facetValue === undefined || facetValue === null) {
        // Vue.set(state.facetFilters, facetName, undefined);
        this.facetFilters[facetName] = undefined;
      } else {
        //Vue.set(state.facetFilters, facetName, { facetValue, type: 'string' });
        this.facetFilters[facetName] = { facetValue, type: 'string' };
        emitter.emit('analytics', {
          Applied: `${facetName}:${facetValue}`,
          event: 'Filter Applied',
        });
      }
    },
    setSelectedFacetItems: function (value) {
      const facetName = value['name'];
      const facetValue = value['value'];

      if (typeof facetValue === undefined || facetValue === null) {
        // Vue.set(state.selectedFacetFilters, facetName, undefined); // remove after the vue3 upgrade
        this.selectedFacetFilters[facetName] = undefined;
      } else {
        // Vue.set(state.selectedFacetFilters, facetName, facetValue); // remove after the vue3 upgrade
        this.selectedFacetFilters[facetName] = facetValue;
      }
    },
    setHierarchicalFacetFilter: function (value) {
      const facetName = value['name'];
      const facetValue = value['value'];

      // Create a new object to replace the specific facet filter
      const newFacetFilter = {};

      if (typeof facetValue === undefined || facetValue === null) {
        newFacetFilter[facetName] = undefined;
      } else {
        newFacetFilter[facetName] = { facetValue, type: 'hierarchical' };

        const filterValue = getKeys(facetValue);
        const obj = {
          Applied: `${facetName}:${filterValue}`,
          event: 'Filter Applied',
        };
        emitter.emit('analytics', obj);
      }

      // Replace the facet filter for the specific facet name
      this.facetFilters = { ...this.facetFilters, ...newFacetFilter };
    },
    setNumericFacetFilter: function (value) {
      const facetName = value['name'];
      const facetValue = value['value'];

      if (typeof facetValue === undefined || facetValue === null) {
        // Vue.set(state.facetFilters, facetName, undefined); // vue3 upgrade: see Vue.set note above
        this.facetFilters[facetName] = undefined;
      } else {
        // Vue.set(state.facetFilters, facetName, { ...facetValue, type: 'numericRange' }); // vue3 upgrade: see Vue.set note above
        this.facetFilters[facetName] = { ...facetValue, type: 'numericRange' };
        const obj = {
          Applied: `${facetName}:${facetValue.min} - ${facetValue.max}`,
          event: 'Filter Applied',
        };
        emitter.emit('analytics', obj);
      }
    },
    setSortType(value: SortType) {
      this.sortType = value;
    },
    setResetSelectedFacetItemList(value) {
      const filterType = value;

      if (this.facetFilters[filterType]) {
        let excludedVals: any[] = [];
        if (this.selectedFacetFilters[filterType]) {
          excludedVals = this.selectedFacetFilters[filterType];
        }

        this.facetFilters[filterType].facetValue.forEach((facetValue: any) => {
          if (!this.selectedFacetFilters[filterType]) {
            // Vue.set(state.selectedFacetFilters, filterType, [facetValue]);
            this.selectedFacetFilters[filterType] = [facetValue];
          } else if (
            this.selectedFacetFilters[filterType] &&
            !this.selectedFacetFilters[filterType].hasOwnProperty(facetValue) &&
            !excludedVals.includes(facetValue) // @ts-ignore
          ) {
            const val = [facetValue];
            const includedVals = this.selectedFacetFilters[filterType];
            // Vue.set(state.selectedFacetFilters, filterType, val.concat(includedVals));
            this.selectedFacetFilters[filterType] = val.concat(includedVals);
          }
        });
      }
    },
    clearSelectedFacets() {
      Object.keys(this.facetFilters).forEach((key) => {
        delete this.facetFilters[key];
      });

      Object.keys(this.selectedFacetFilters).forEach((key) => {
        delete this.selectedFacetFilters[key];
      });
    },
    resetStoreDefaults(layoutPath) {
      if (!layoutPath) {
        this.includedDealerships = [];
      }
      this.queryParams['page'] = 0;
      this.shipToStore = true;
      this.shopByStore = false;
      this.filterZipFlyout = false;
      this.radius = this.defaultRadius ?? -1;
      this.take = 24;
      this.sortType = {
        sortBy: 'distance',
        sortDirection: 'asc',
        sortName: 'Distance: Nearest',
      };

      this.facetChipsExpanded = false;
      this.hiddenChips = 0;
      this.recentSelectedFilter = '';
      setShopLocationCookie({ radius: this.radius });
    },
    setFacetChipsExpanded(value) {
      this.facetChipsExpanded = value;
    },
    setHiddenChipCount(value) {
      this.hiddenChips = value;
    },
    setExtColorToggleSelected(value) {
      this.extColorToggleSelected = value;
    },
    setPriceToggleSelected(value) {
      this.priceToggleSelected = value;
    },
    setFacetFilters(value) {
      const newFacetFilters = {};
      if (value) {
        for (const propertyName in value) {
          if (value[propertyName]) {
            newFacetFilters[propertyName] = value[propertyName];
          }
        }
      }
      this.facetFilters = newFacetFilters;
    },
    setRemainingParams(value) {
      const newRemainingParams = {};
      if (value) {
        for (const key in value) {
          if (value[key]) {
            newRemainingParams[key] = value[key];
          }
        }
      }
      this.remainingParams = newRemainingParams;
    },
    setSupplementalContent(value) {
      this.supplementalContent = value;
    },
  },
  getters: {
    page(state) {
      return state.queryParams?.['page'];
    },
    formattedVehicleAmount(state) {
      return (state.srpVehiclesData?.resultCount ?? 0).toLocaleString().replace('.', ',');
    },
    getVehicleByVin: (state) => (selectedVin: string) => {
      return state.srpVehiclesData?.items.find((vehicle) => vehicle?.vin === selectedVin);
    },
    getDynamicLinkData(state) {
      return state.srpVehiclesData?.dynamicLinks;
    },
    vehicleCount(state) {
      return state.srpVehiclesData?.resultCount ?? 0;
    },
    metaDataContent(state) {
      if (Object.keys(state.facetFilters).length > 0) {
        const relevantFilters = ['extcolor', 'year', 'mmt', 'bodystyle'];
        const allFilternames = Object.keys(state.facetFilters);
        const filtersICareAbout = allFilternames.filter((name) => relevantFilters.includes(name));
        //calculate how many total selected filters(facets) there are (that we care about [color, make, model, body style, year])
        const selectedFiltersCount = filtersICareAbout.reduce((counter, current) => {
          //count up how many filters
          switch (current) {
            case 'extcolor':
            case 'bodystyle':
              return counter + state.facetFilters[current].facetValue.length;
            case 'mmt':
              const makes = Object.keys(state.facetFilters[current].facetValue);
              const makesCount = makes.length;
              const modelsCountTotal = makes.reduce((modelsTotal, make) => {
                const models = Object.keys(state.facetFilters[current].facetValue[make]).filter(
                  (model) => model !== 'selected'
                );
                const modelsCount = models.length;
                return modelsTotal + modelsCount;
              }, 0);
              return counter + makesCount + modelsCountTotal;
            case 'year':
              return counter + 2;
            default:
              return counter;
          }
        }, 0);
        //logic for adjusting the title for the specified criteria: https://sonicautomotive.atlassian.net/browse/SAECHO-4788
        if (selectedFiltersCount > 3 || selectedFiltersCount < 1) {
          return null;
        }
        //if less than 3 filters, set the title in priority of facets: Color, year, make, model, body style
        let contentArr: any[] = [];
        if (state.facetFilters['extcolor']) {
          contentArr = [...state.facetFilters['extcolor'].facetValue];
        }
        if (state.facetFilters['year']) {
          contentArr = [
            ...contentArr,
            state.facetFilters['year'].min,
            '-',
            state.facetFilters['year'].max,
          ]; //spread + append new
        }
        if (state.facetFilters['mmt']) {
          //iterate over makes for each model
          const makes = Object.keys(state.facetFilters['mmt'].facetValue);
          makes.forEach((make) => {
            const models = Object.keys(state.facetFilters['mmt'].facetValue[make]).filter(
              (model) => model !== 'selected' //because dataStructure is: {make {model1, model2, selected {true/false} } }
            );
            contentArr = [...contentArr, make, ...models];
          });
        }
        if (state.facetFilters['bodystyle']) {
          contentArr = [...contentArr, ...state.facetFilters['bodystyle'].facetValue];
        }
        return contentArr.join(' ');
      }
    },
    metaDataLocation(state) {
      if (!state.shopByStore || state.includedDealerships.length != 1) {
        return null;
      }
      const dealerId = state.includedDealerships[0];
      const commonStore = useCommonStore();
      const dealer = commonStore.dealerships.find((d) => d.dealerId == dealerId);
      return dealer?.storeName ?? null;
    },
  },
});

// function to recursively get object keys and filter "selected" out
const getKeys = function (obj) {
  return Object.keys(obj)
    .flatMap((k) => (Object(obj[k]) === obj[k] ? [k, ...getKeys(obj[k])] : k))
    .filter((item) => item !== 'selected');
};

const setShopLocationCookie = function (obj: object) {
  if (Cookies.set) {
    if (obj['radius']) {
      Cookies.set('ep_shopLocation', JSON.stringify({ radius: obj['radius'] }), {
        path: '/used-cars',
      });
    } else if (isNaN(obj['radius']) && typeof obj['radius'] !== 'undefined') {
      Cookies.set('ep_shopLocation', JSON.stringify({ radius: -1 }), { path: '/used-cars' });
    } else if (obj.hasOwnProperty('dealer') && obj['dealer']) {
      Cookies.set('ep_shopLocation', JSON.stringify({ dealers: obj['dealer'] }), {
        path: '/used-cars',
      });
    }
  }
};

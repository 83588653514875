<template>
  <Flyout :isOpen="accountFlyout" @close="handleClose">
    <div class="account-flyout">
      <v-overlay :model-value="loading" contained persistent class="align-center justify-center">
        <v-progress-circular color="primary" size="32" indeterminate></v-progress-circular>
      </v-overlay>
      <div class="signIn-content">
        <div class="create_account_title">
          <span class="goback" v-if="hasBackNav" @click="$emit('navBack')">
            <img src="/Assets/images/icons-arrows-left.svg" alt="Back" title=" Back" />
          </span>
          <h6 class="HeadingParkGray-H6-Bold" v-if="heading && !hasBackNav">{{ heading }}</h6>
          <span class="create_account_close" @click="handleClose">
            <img
              src="/-/media/Feature/EchoPark/Account/EchoparkLogin/icons-menu-close.svg?iar=0&amp;hash=71047874FD97363EF7670146DBE51D21"
              alt="Close"
              title="Sonic Close"
            />
          </span>
        </div>
        <slot></slot>
      </div>
    </div>
  </Flyout>
</template>

<script lang="ts">
import Flyout from '@components-vue/Foundation/Common/Overlay/Flyout.vue';

import { mapActions, mapState } from 'pinia';
import { useCommonStore } from '@/stores/common';

export default {
  name: 'AccountFlyout',
  props: {
    heading: {
      type: String,
    },
    hasBackNav: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(useCommonStore, ['accountFlyout']),
  },
  components: {
    Flyout,
  },
  emits: ['navBack'],
  methods: {
    ...mapActions(useCommonStore, ['setAccountFlyout']),
    handleClose() {
      this.setAccountFlyout(false);
    },
  },
};
</script>

<style lang="scss">
.account-flyout {
  .signIn-content {
    display: block;
  }
  .submit-button {
    margin-top: 18px;
    margin-bottom: 24px;
  }
  .create_account_title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    min-height: 24px;
    position: relative;
  }
  .create_account_close {
    min-width: 24px;
    height: 24px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    border: 0;
    background: 0;
  }
  .goback {
    height: 24px;
    cursor: pointer;
    min-width: 24px;
  }
}
</style>

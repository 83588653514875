import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sca-finish" }
const _hoisted_2 = { class: "mx-auto text-center" }
const _hoisted_3 = {
  class: "heading",
  "data-testid": "sca-finish-header"
}
const _hoisted_4 = { class: "helper-text" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "finish-cta" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sc_image = _resolveComponent("sc-image")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_v_row, { "no-gutters": "" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_sc_image, {
                media: _ctx.fieldsMap.get('body-image.pageImage'),
                height: "140",
                width: "140",
                class: ""
              }, null, 8, ["media"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, { class: "my-0" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            class: "py-0"
          }, {
            default: _withCtx(() => [
              _createElementVNode("h5", _hoisted_3, _toDisplayString(_ctx.headerText), 1),
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.fieldsMap.get('body-image.helperText')?.value), 1),
              _createElementVNode("div", {
                class: "content",
                innerHTML: _ctx.mainText
              }, null, 8, _hoisted_5),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_v_btn, {
                  href: _ctx.fieldsMap.get('appointment-cta.cta-link')?.value?.['url'],
                  "data-testid": "sca-appointment-cta"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.fieldsMap.get('appointment-cta.cta-text')?.value), 1)
                  ]),
                  _: 1
                }, 8, ["href"]),
                _createVNode(_component_v_btn, {
                  variant: "plain",
                  "data-testid": "sca-shop-cta",
                  href: _ctx.shopAtStoreHref
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.shopAtStoreCta), 1)
                  ]),
                  _: 1
                }, 8, ["href"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ]))
}
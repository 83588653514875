import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "vehicle-info" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "details" }
const _hoisted_4 = { class: "year-miles-price" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _ctx.vehicle?.images[0] || require('@/assets/images/car-placeholder.png'),
      alt: `${_ctx.vehicle?.make}-${_ctx.vehicle?.model}`
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", _hoisted_4, [
        _createElementVNode("span", null, _toDisplayString(_ctx.vehicle?.year), 1),
        _createElementVNode("span", null, _toDisplayString(_ctx.formatVehicleMileage(_ctx.vehicle?.miles)), 1),
        _createElementVNode("span", null, "$" + _toDisplayString(_ctx.numberWithCommas(_ctx.vehicle?.sellingPrice)), 1)
      ]),
      _createElementVNode("p", null, _toDisplayString(_ctx.vehicle?.make) + " " + _toDisplayString(_ctx.vehicle?.model) + " " + _toDisplayString(_ctx.vehicle?.trim), 1)
    ])
  ]))
}
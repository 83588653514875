import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "panel-content" }
const _hoisted_2 = { class: "small" }
const _hoisted_3 = { class: "small" }
const _hoisted_4 = { class: "small" }
const _hoisted_5 = { class: "small" }
const _hoisted_6 = { class: "large light" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "small" }
const _hoisted_9 = { class: "small" }
const _hoisted_10 = { class: "small" }
const _hoisted_11 = { class: "large light" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = { class: "small" }
const _hoisted_14 = { class: "small" }
const _hoisted_15 = { class: "small" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          cols: "12",
          lg: "3"
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('legal-name-label')), 1),
            _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.buyer?.identity.displayName), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          cols: "12",
          lg: "3"
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('date-of-birth-label')), 1),
            _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.buyer?.identity.dateOfBirth), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          cols: "12",
          lg: "3"
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('mobile-number-label')), 1),
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.displayPhoneNumber(_ctx.buyer?.contactInfo.phone)), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          cols: "12",
          lg: "3"
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('email-address-label')), 1),
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.buyer?.contactInfo.email), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          cols: "12",
          class: "pb-0"
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('current-address-label')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          cols: "12",
          lg: "3"
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('address-label')), 1),
            _createElementVNode("p", {
              class: "small",
              innerHTML: _ctx.formattedAddress
            }, null, 8, _hoisted_7)
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          cols: "12",
          lg: "3"
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('residence-type-label')), 1),
            _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.buyer?.address.residenceTypeCodeText), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          cols: "12",
          lg: "3"
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('move-in-date-label')), 1),
            _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.formattedMonthAndYear), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          cols: "12",
          lg: "3"
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('monthly-payment-label')), 1),
            _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.formatCurrency(_ctx.buyer?.address?.rentMortgagePaymentAmount)), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.buyer?.address.previousResidenceTypeCode)
      ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "12",
              class: "pb-0"
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t('previous-address-label')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              cols: "12",
              lg: "3"
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('address-label')), 1),
                _createElementVNode("p", {
                  class: "small",
                  innerHTML: _ctx.previousFormattedAddress
                }, null, 8, _hoisted_12)
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              cols: "12",
              lg: "3"
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('residence-type-label')), 1),
                _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.buyer?.address.previousResidenceTypeCodeText), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              cols: "12",
              lg: "3"
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('move-in-date-label')), 1),
                _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.previousFormattedMonthAndYear), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              cols: "12",
              lg: "3"
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('monthly-payment-label')), 1),
                _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.formatCurrency(_ctx.buyer?.address?.previousRentMortgagePaymentAmount)), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}
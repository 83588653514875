<template>
  <div class="sca-employment mb-6">
    <EmploymentDetailsForm
      v-model:employmentDetails="currentEmployment"
      :fields="fields"
      :fieldsMap="fieldsMap"
      :isCobuyer="isCobuyer"
      :hasError="hasError"
      :buyerEmploymentSchema="buyerEmploymentSchema"
      @clear-errors="clearErrors"
    />

    <EmploymentDetailsForm
      v-if="isPreviousEmploymentRequired || isUnemployed"
      v-model:employmentDetails="previousEmployment"
      :isPrevious="true"
      :fields="fields"
      :fieldsMap="fieldsMap"
      :isCobuyer="isCobuyer"
      :hasError="hasError"
      :buyerEmploymentSchema="buyerEmploymentSchema"
      @clear-errors="clearErrors"
    />

    <div
      id="extra-income-section"
      v-show="employmentStatusCodeSelected"
      class="extra-income-section"
    >
      <FormHeader
        :title="fieldsMap.get(coPrefix + 'extra-income.Label')?.value"
        :note="fieldsMap.get('extra-income.Description')"
      ></FormHeader>
      <p class="mt-6 mb-4">
        {{ fieldsMap.get(coPrefix + 'extra-income.other-income-sources.Label')?.value }}
      </p>

      <v-radio-group
        v-model="other.hasExtraIncome"
        data-testid="sca-extra-income"
        hide-details="auto"
      >
        <v-radio
          value="No"
          data-testid="sca-extra-income-no"
          :label="fieldsMap.get(coPrefix + 'extra-income.other-income-sources.no.Label')?.value"
          prepend-inner-icon="mdi-lock"
          hide-details="auto"
        >
        </v-radio>
        <v-radio
          value="Yes"
          data-testid="sca-extra-income-yes"
          :label="fieldsMap.get(coPrefix + 'extra-income.other-income-sources.yes.Label')?.value"
          prepend-inner-icon="mdi-lock"
        >
        </v-radio>
      </v-radio-group>

      <v-row v-if="other.hasExtraIncome === 'Yes'">
        <v-col cols="12" lg="6" class="py-0">
          <v-text-field
            :label="buyerEmploymentSchema?.['otherIncomeAmount']?.label"
            data-testid="sca-extra-income-amount"
            v-model="other.otherIncomeAmountString"
            @input="preventNegativeValues"
            maxlength="14"
            type="number"
            :error-messages="errorMessages('otherIncomeAmount', 'Enter a monthly amount.')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="6" class="py-0">
          <v-text-field
            :label="buyerEmploymentSchema?.['otherIncomeSourceDescription']?.label"
            data-testid="sca-extra-income-source"
            v-model="other.otherIncomeSourceDescription"
            :error-messages="
              errorMessages('otherIncomeSourceDescription', 'Enter income source(s).')
            "
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { mapState, mapActions } from 'pinia';
import { useStandaloneCreditAppStore } from '@/stores/standaloneCreditApp';
import { validate } from '@/util/schema/schemaValidator';
import { buildMap } from '@/util/standaloneCreditAppUtils';
import { EmploymentStatuses } from '@/types/StandaloneCreditApp/StandaloneCreditAppTypes';
import { ensureNumericValues, preventNegativeValues } from '@util/commonUtils';
import EmploymentDetailsForm from './EmploymentDetailsForm.vue';
import FormHeader from '../Includes/FormHeader.vue';
import { FieldsPropType } from '@/lib/FieldTypes';

export default defineComponent({
  name: 'EmploymentDetailsFormContainer',
  props: {
    fields: {
      type: Object as PropType<FieldsPropType>,
      default: () => ({}),
    },
    isCobuyer: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    EmploymentDetailsForm,
    FormHeader,
  },
  data() {
    return {
      currentEmployment: {
        employmentTypeCode: '',
        employmentStatusCode: '',
        employmentTitle: '',
        employerName: '',
        employerPhone: '',
        employerStartMonth: undefined as number | undefined,
        employerStartYear: undefined as number | undefined,
        monthsOnJob: 0,
        incomeTypeCode: '' as 'Hourly' | 'Salary' | '',
        hourlyPayRate: 0,
        hourlyPayRateString: '',
        avgWeeklyHours: 0,
        avgWeeklyHoursString: '',
        hasBonusPay: 'No' as 'Yes' | 'No' | '',
        monthlyBonusPay: 0,
        monthlyBonusPayString: '',
        annualOrMonthly: '' as 'Annual' | 'Monthly' | '',
        annualIncome: 0,
        annualIncomeString: '',
        monthlyIncome: 0,
        monthlyIncomeString: '',
        incomeAmount: 0,
        incomeIntervalCode: '' as 'WK' | 'BiWK' | 'SmMO' | 'MO' | 'YR' | '',
        currencyTypeCode: '' as 'USD' | 'CAD' | '',
        educationLevelCode: '',
      },
      previousEmployment: {
        previousEmploymentTypeCode: '',
        previousEmploymentStatusCode: '',
        previousEmploymentTitle: '',
        previousEmployerName: '',
        previousEmployerPhone: '',
        previousEmployerStartMonth: undefined as number | undefined,
        previousEmployerStartYear: undefined as number | undefined,
        previousMonthsOnJob: 0,
        previousIncomeTypeCode: '' as 'Hourly' | 'Salary' | '',
        previousHourlyPayRate: 0,
        previousHourlyPayRateString: '',
        previousAvgWeeklyHours: 0,
        previousAvgWeeklyHoursString: '',
        previousHasBonusPay: 'No' as 'Yes' | 'No' | '',
        previousMonthlyBonusPay: 0,
        previousMonthlyBonusPayString: '',
        previousAnnualOrMonthly: '' as 'Annual' | 'Monthly' | '',
        previousAnnualIncome: 0,
        previousAnnualIncomeString: '',
        previousMonthlyIncome: 0,
        previousMonthlyIncomeString: '',
        previousIncomeAmount: 0,
        previousIncomeIntervalCode: '' as 'WK' | 'BiWK' | 'SmMO' | 'MO' | 'YR' | '',
        previousCurrencyTypeCode: '' as 'USD' | 'CAD' | '',
      },
      other: {
        hasExtraIncome: 'No' as 'Yes' | 'No' | '',
        otherIncomeSourceCode: '',
        otherIncomeSourceDescription: '',
        otherIncomeAmount: 0,
        otherIncomeAmountString: '',
        otherIncomeIntervalCode: 'MO' as 'WK' | 'BiWK' | 'SmMO' | 'MO' | 'YR' | '',
        otherCurrencyTypeCode: 'USD' as 'USD' | 'CAD' | '',
      },
      errors: {} as Record<string, string>,
      fieldsMap: new Map(),
    };
  },
  computed: {
    ...mapState(useStandaloneCreditAppStore, [
      'buyerEmploymentValidator',
      'buyerEmploymentSchema',
      'primaryBuyer',
      'coBuyer',
    ]),
    employmentStatusCodeSelected() {
      return !!this.currentEmployment.employmentStatusCode;
    },
    isPreviousEmploymentRequired() {
      const { employerStartMonth, employerStartYear } = this.currentEmployment;
      if (!employerStartMonth || !employerStartYear) return false;

      const month = Number(employerStartMonth);
      const year = Number(employerStartYear);
      if (isNaN(month) || isNaN(year) || month < 1 || month > 12 || year < 1900) return false;

      const startDate = new Date(year, month - 1);
      const currentDate = new Date();

      return (
        currentDate.getFullYear() - startDate.getFullYear() < 2 ||
        (currentDate.getFullYear() - startDate.getFullYear() === 2 &&
          currentDate.getMonth() <= startDate.getMonth())
      );
    },
    isUnemployed() {
      return (
        this.currentEmployment.employmentStatusCode === EmploymentStatuses.NOT_APPLICABLE || false
      );
    },
    calculateMonthsOnJob() {
      const startMonth = this.currentEmployment.employerStartMonth;
      const startYear = this.currentEmployment.employerStartYear;

      if (!startMonth || !startYear) return 0;

      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;

      const totalMonths = (currentYear - startYear) * 12 + (currentMonth - startMonth);

      return totalMonths >= 0 ? totalMonths : 0;
    },
    calculatePreviousMonthsOnJob() {
      const startMonth = this.previousEmployment.previousEmployerStartMonth;
      const startYear = this.previousEmployment.previousEmployerStartYear;

      if (!startMonth || !startYear) return 0;

      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;

      const totalMonths = (currentYear - startYear) * 12 + (currentMonth - startMonth);

      return totalMonths >= 0 ? totalMonths : 0;
    },
    coPrefix() {
      return this.isCobuyer ? 'co-applicant-' : '';
    },
  },
  methods: {
    ...mapActions(useStandaloneCreditAppStore, ['addErrors', 'setEmployment']),
    preventNegativeValues,
    async submitHandler() {
      this.setMilitaryEmployerName();
      this.setNumericTypes();
      this.setPartTimeEmployment();

      this.errors = (await this.validateForm()) || {};
      if (this.hasErrors()) {
        this.handleErrors();
        return false;
      }

      this.saveEmploymentData();
      return true;
    },
    async validateForm(): Promise<Record<string, string>> {
      this.currentEmployment.monthsOnJob = this.calculateMonthsOnJob;
      this.previousEmployment.previousMonthsOnJob = this.calculatePreviousMonthsOnJob;

      if (!(this.isPreviousEmploymentRequired || this.isUnemployed)) {
        this.previousEmployment.previousEmploymentStatusCode = '';
      }

      if (this.other.otherIncomeAmount) {
        this.other.otherIncomeSourceCode = 'OTHCSTM'; // default per requirements on 3/7/2025 NP
      }

      const errors = await validate([this.buyerEmploymentValidator], {
        ...this.currentEmployment,
        ...this.previousEmployment,
        ...this.other,
      });

      /* Fields that should not show validation errors- they are calculated/set
      behind the scenes NP 3/12/2025 */
      const fieldsToRemove = [
        'incomeAmount',
        'previousIncomeAmount',
        'monthsOnJob',
        'previousMonthsOnJob',
        'incomeIntervalCode',
        'previousIncomeIntervalCode',
      ];

      if (!errors) return {};
      fieldsToRemove.forEach((field) => delete errors[field]);

      return { ...errors };
    },
    hasError(key: string): boolean {
      return !!this.errors[key];
    },
    errorMessages(field, defaultMessage) {
      return this.hasError(field)
        ? [this.buyerEmploymentSchema?.[field]?.validationMessage || defaultMessage]
        : [];
    },
    clearErrors() {
      this.errors = {};
    },
    buildFieldsMap() {
      this.fieldsMap = buildMap(this.fields);
    },
    setMilitaryEmployerName() {
      if (this.currentEmployment.employmentStatusCode === 'Military') {
        this.currentEmployment.employerName = 'Military';
      }

      if (this.previousEmployment.previousEmploymentStatusCode === 'Military') {
        this.previousEmployment.previousEmployerName = 'Military';
      }
    },
    saveEmploymentData() {
      const employmentData = {
        ...this.currentEmployment,
        ...this.previousEmployment,
        ...this.other,
      };

      this.setEmployment(employmentData, this.isCobuyer);
    },
    setPartTimeEmployment() {
      /* If less than 32 hours, set part time per requirements NP 3/13/25
      Average weekly hours only gets set if user selects Employed, then Hourly */
      if (
        this.currentEmployment.employmentStatusCode === EmploymentStatuses.FULL_TIME &&
        this.currentEmployment.incomeTypeCode === 'Hourly' &&
        this.currentEmployment.avgWeeklyHours < 32
      ) {
        this.currentEmployment.employmentStatusCode = EmploymentStatuses.PART_TIME;
      }

      if (
        this.previousEmployment.previousEmploymentStatusCode === EmploymentStatuses.FULL_TIME &&
        this.previousEmployment.previousIncomeTypeCode === 'Hourly' &&
        this.previousEmployment.previousAvgWeeklyHours < 32
      ) {
        this.previousEmployment.previousEmploymentStatusCode = EmploymentStatuses.PART_TIME;
      }
    },
    hasErrors() {
      return Object.keys(this.errors).length > 0;
    },
    handleErrors() {
      this.addErrors(Object.values(this.errors) as string[]);
    },
    setNumericTypes() {
      ensureNumericValues(['hourlyPayRate'], this.currentEmployment);
      ensureNumericValues(['previousHourlyPayRate'], this.previousEmployment);

      ensureNumericValues(['avgWeeklyHours'], this.currentEmployment);
      ensureNumericValues(['previousAvgWeeklyHours'], this.previousEmployment);

      ensureNumericValues(['annualIncome'], this.currentEmployment);
      ensureNumericValues(['previousAnnualIncome'], this.previousEmployment);

      ensureNumericValues(['monthlyIncome'], this.currentEmployment);
      ensureNumericValues(['previousMonthlyIncome'], this.previousEmployment);

      ensureNumericValues(['monthlyBonusPay'], this.currentEmployment);
      ensureNumericValues(['previousMonthlyBonusPay'], this.previousEmployment);

      ensureNumericValues(['otherIncomeAmount'], this.other);
    },
  },
  async mounted() {
    this.buildFieldsMap();
    const buyer = this.isCobuyer ? this.coBuyer : this.primaryBuyer;

    this.currentEmployment = {
      employmentTypeCode: buyer?.employmentDetails?.employmentTypeCode || '',
      employmentStatusCode: buyer?.employmentDetails?.employmentStatusCode || '',
      employmentTitle: buyer?.employmentDetails?.employmentTitle || '',
      employerName: buyer?.employmentDetails?.employerName || '',
      employerPhone: buyer?.employmentDetails?.employerPhone || '',
      employerStartMonth: buyer?.employmentDetails?.employerStartMonth || undefined,
      employerStartYear: buyer?.employmentDetails?.employerStartYear || undefined,
      monthsOnJob: buyer?.employmentDetails?.monthsOnJob || 0,
      incomeTypeCode: buyer?.employmentDetails?.incomeTypeCode || '',
      hourlyPayRate: buyer?.employmentDetails?.hourlyPayRate || 0,
      hourlyPayRateString: buyer?.employmentDetails?.hourlyPayRateString || '',
      avgWeeklyHours: buyer?.employmentDetails?.avgWeeklyHours || 0,
      avgWeeklyHoursString: buyer?.employmentDetails?.avgWeeklyHoursString || '',
      hasBonusPay: buyer?.employmentDetails?.hasBonusPay || 'No',
      monthlyBonusPay: buyer?.employmentDetails?.monthlyBonusPay || 0,
      monthlyBonusPayString: buyer?.employmentDetails?.monthlyBonusPayString || '',
      annualOrMonthly: buyer?.employmentDetails?.annualOrMonthly || '',
      annualIncome: buyer?.employmentDetails?.annualIncome || 0,
      annualIncomeString: buyer?.employmentDetails?.annualIncomeString || '',
      monthlyIncome: buyer?.employmentDetails?.monthlyIncome || 0,
      monthlyIncomeString: buyer?.employmentDetails?.monthlyIncomeString || '',
      incomeAmount: buyer?.employmentDetails?.incomeAmount || 0,
      incomeIntervalCode: buyer?.employmentDetails?.incomeIntervalCode || '',
      currencyTypeCode: buyer?.employmentDetails?.currencyTypeCode || 'USD',
      educationLevelCode: buyer?.employmentDetails?.educationLevelCode || '',
    };

    this.previousEmployment = {
      previousEmploymentTypeCode: buyer?.employmentDetails?.previousEmploymentTypeCode || '',
      previousEmploymentStatusCode: buyer?.employmentDetails?.previousEmploymentStatusCode || '',
      previousEmploymentTitle: buyer?.employmentDetails?.previousEmploymentTitle || '',
      previousEmployerName: buyer?.employmentDetails?.previousEmployerName || '',
      previousEmployerPhone: buyer?.employmentDetails?.previousEmployerPhone || '',
      previousEmployerStartMonth: buyer?.employmentDetails?.previousEmployerStartMonth ?? undefined,
      previousEmployerStartYear: buyer?.employmentDetails?.previousEmployerStartYear ?? undefined,
      previousMonthsOnJob: buyer?.employmentDetails?.previousMonthsOnJob || 0,
      previousIncomeTypeCode: buyer?.employmentDetails?.previousIncomeTypeCode || '',
      previousHourlyPayRate: buyer?.employmentDetails?.previousHourlyPayRate || 0,
      previousHourlyPayRateString: buyer?.employmentDetails?.previousHourlyPayRateString || '',
      previousAvgWeeklyHours: buyer?.employmentDetails?.previousAvgWeeklyHours || 0,
      previousAvgWeeklyHoursString: buyer?.employmentDetails?.previousAvgWeeklyHoursString || '',
      previousHasBonusPay: buyer?.employmentDetails?.previousHasBonusPay || 'No',
      previousMonthlyBonusPay: buyer?.employmentDetails?.previousMonthlyBonusPay || 0,
      previousMonthlyBonusPayString: buyer?.employmentDetails?.previousMonthlyBonusPayString || '',
      previousAnnualOrMonthly: buyer?.employmentDetails?.previousAnnualOrMonthly || '',
      previousAnnualIncome: buyer?.employmentDetails?.previousAnnualIncome || 0,
      previousAnnualIncomeString: buyer?.employmentDetails?.previousAnnualIncomeString || '',
      previousMonthlyIncome: buyer?.employmentDetails?.previousMonthlyIncome || 0,
      previousMonthlyIncomeString: buyer?.employmentDetails?.previousMonthlyIncomeString || '',
      previousIncomeAmount: buyer?.employmentDetails?.previousIncomeAmount || 0,
      previousIncomeIntervalCode: buyer?.employmentDetails?.previousIncomeIntervalCode || '',
      previousCurrencyTypeCode: buyer?.employmentDetails?.previousCurrencyTypeCode || 'USD',
    };

    this.other = {
      hasExtraIncome: buyer?.employmentDetails?.hasExtraIncome || 'No',
      otherIncomeSourceCode: buyer?.employmentDetails?.otherIncomeSourceCode || '',
      otherIncomeSourceDescription: buyer?.employmentDetails?.otherIncomeSourceDescription || '',
      otherIncomeAmount: buyer?.employmentDetails?.otherIncomeAmount || 0,
      otherIncomeAmountString: buyer?.employmentDetails?.otherIncomeAmountString || '',
      otherIncomeIntervalCode: buyer?.employmentDetails?.otherIncomeIntervalCode || 'MO',
      otherCurrencyTypeCode: buyer?.employmentDetails?.otherCurrencyTypeCode || 'USD',
    };
  },
});
</script>

<style lang="scss">
@use '@/assets/styles/variables' as *;

.extra-income-section {
  margin-top: $spacing_stack-md;
}
</style>

export class PipeDelimitedParser {
  private readonly valuesArray: string[];
  private readonly keys: string[];

  constructor(pipeDelimitedString: string = '', keys?: string[]) {
    this.valuesArray = pipeDelimitedString.split('|').map((value) => value.trim());
    this.keys = keys ?? this.generateDynamicKeys(); // Default keys if none provided
  }

  private generateDynamicKeys(): string[] {
    return this.valuesArray.map((_, index) => `Key${index + 1}`);
  }

  public getValues(): string[] {
    return this.valuesArray;
  }

  public getKeyValuePairs(): Record<string, string> {
    const keyValuePairs: Record<string, string> = {};
    this.keys.forEach((key, index) => {
      keyValuePairs[key] = this.valuesArray[index] ?? ''; // Ensure keys exist even if values are missing
    });
    return keyValuePairs;
  }
}

<template>
  <div class="w-100">
    <span :class="shipToStoreContainer">
      <label>{{ $t('Ship to store') }}</label>
      <RoundCheckToggleSwitch
        v-model="shipToStoreToggle"
        :disabled="shopByStore"
        componentName="shipToStoreContainer"
      />
    </span>
    <span class="show-me-subtext">{{ $t('Show me cars that can ship to a nearby store') }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import RoundCheckToggleSwitch from './RoundCheckToggleSwitch.vue';
import { mapActions, mapState } from 'pinia';
import { useVehicleSearchStore } from '@/stores/vehicleSearch';

export default defineComponent({
  name: 'ShipToStoreToggle',
  props: ['filterName', 'fields'],
  data() {
    return {
      shipToStoreContainer: 'd-flex justify-space-between',
    };
  },
  computed: {
    ...mapState(useVehicleSearchStore, ['shopByStore', 'shipToStore']),
    shipToStoreToggle: {
      get() {
        return this.shipToStore;
      },
      set(value) {
        this.setShipToStore(value);
        this.setShipToStoreRadius(value); // preserve state of ship to store on shop by radius when toggling to shop by store which defaults it to off
        this.emitter.emit('filter-updated-srp');
      },
    },
  },
  components: {
    RoundCheckToggleSwitch,
  },
  methods: {
    ...mapActions(useVehicleSearchStore, ['setShipToStoreRadius', 'setShipToStore']),
  },
});
</script>

<style lang="scss">
.show-me-subtext {
  color: $park-gray-20;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
</style>

<template>
  <v-row>
    <v-col cols="12" lg="6" xl="4" class="py-0">
      <v-text-field
        v-model="identityValues.firstName"
        :label="buyerIdentitySchema?.firstName?.label"
        :data-testid="`sca-${isCobuyer ? 'cobuyer' : 'buyer'}-firstname`"
        :error-messages="
          hasError('firstName') ? buyerIdentitySchema?.firstName?.validationMessage : []
        "
      />
    </v-col>
    <v-col cols="12" lg="6" xl="4" class="py-0">
      <v-text-field
        v-model="identityValues.middleName"
        :label="buyerIdentitySchema?.middleName?.label"
        :data-testid="`sca-${isCobuyer ? 'cobuyer' : 'buyer'}-middlename`"
        :error-messages="
          hasError('middleName') ? buyerIdentitySchema?.middleName?.validationMessage : []
        "
      />
    </v-col>
    <v-col cols="12" lg="6" xl="4" class="py-0">
      <v-text-field
        v-model="identityValues.lastName"
        :data-testid="`sca-${isCobuyer ? 'cobuyer' : 'buyer'}-lastname`"
        :label="buyerIdentitySchema?.lastName?.label"
        :error-messages="
          hasError('lastName') ? buyerIdentitySchema?.lastName?.validationMessage : []
        "
      />
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12" xl="4" class="buyerNameForm py-0">
      <!-- TODO: the label should be "inside" the select not above it
       This is happening because of the v-model value (remove it and it works)
       + the same with the "state" select -->
      <v-select
        v-model="identityValues.suffix"
        :label="buyerIdentitySchema?.suffix?.label"
        :data-testid="`sca-${isCobuyer ? 'cobuyer' : 'buyer'}-suffix`"
        :items="suffixOptions"
      />
    </v-col>
    <v-col cols="12" xl="4" class="buyerNameForm py-0">
      <v-text-field
        v-model="formattedDOB"
        @input="handleDOBInput"
        @keydown="onlyAllowNumbers"
        @paste.prevent="handlePaste"
        :label="buyerIdentitySchema?.dateOfBirth?.label"
        :data-testid="`sca-${isCobuyer ? 'cobuyer' : 'buyer'}-birthdate`"
        type="tel"
        maxlength="10"
        :error-messages="
          hasError('dateOfBirth') ? buyerIdentitySchema?.dateOfBirth?.validationMessage : []
        "
      />
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12" lg="6" class="py-0">
      <v-text-field
        v-model="contactInfoValues.email"
        :label="buyerContactSchema?.email?.label"
        :data-testid="`sca-${isCobuyer ? 'cobuyer' : 'buyer'}-email`"
        type="email"
        :error-messages="hasError('email') ? buyerContactSchema?.email?.validationMessage : []"
      />
    </v-col>
    <v-col cols="12" lg="6" class="py-0">
      <v-text-field
        v-model="formattedPhone"
        @input="handlePhoneInput"
        @keydown="onlyAllowNumbers"
        @paste.prevent="handlePaste"
        :label="buyerContactSchema?.phone?.label"
        :data-testid="`sca-${isCobuyer ? 'cobuyer' : 'buyer'}-phone`"
        type="tel"
        maxlength="14"
        :error-messages="hasError('phone') ? buyerContactSchema?.phone?.validationMessage : []"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { validate } from '@/util/schema/schemaValidator';
import { useStandaloneCreditAppStore } from '@/stores/standaloneCreditApp';
import { mapActions, mapState } from 'pinia';
import {
  cleanDOB,
  cleanPhoneNumber,
  formatDOB,
  formatPhoneNumber,
  handlePaste,
  onlyAllowNumbers,
} from '@util/commonUtils';

export default defineComponent({
  name: 'BuyerIdentity',
  props: {
    isCobuyer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      identityValues: {
        firstName: '',
        middleName: '',
        lastName: '',
        suffix: undefined as
          | 'JR'
          | 'SR'
          | 'I'
          | 'II'
          | 'III'
          | 'IV'
          | 'V'
          | 'None'
          | ''
          | undefined,
        dateOfBirth: '',
      },
      contactInfoValues: {
        phone: '',
        phoneType: 'HOME' as 'HOME' | 'WORK' | 'MOBILE', // default per requirements 3/11/25
        email: '',
        altPhone: '', // never asked for, hardcode for now, set as same as phone per requirements 3/11/25
        altPhoneType: 'MOBILE' as 'HOME' | 'WORK' | 'MOBILE', // default per requirements 3/11/25
      },
      errors: {} as Record<string, string>,
    };
  },
  computed: {
    ...mapState(useStandaloneCreditAppStore, [
      'buyerIdentityValidator',
      'buyerContactValidator',
      'buyerIdentitySchema',
      'buyerContactSchema',
      'primaryBuyer',
      'coBuyer',
    ]),
    suffixOptions(): Array<string> {
      const originalOptions = this.buyerIdentitySchema?.suffix?.enum || [];
      return ['None', ...originalOptions]; // todo: possibly content manage extra option, set to None per Jason/Logan 3/10/25
    },
    formattedDOB: {
      get(): string {
        return this.formatDOB(this.identityValues.dateOfBirth);
      },
      set(value: string) {
        this.identityValues.dateOfBirth = this.cleanDOB(value);
      },
    },
    formattedPhone: {
      get(): string {
        return this.formatPhoneNumber(this.contactInfoValues.phone);
      },
      set(value: string) {
        this.contactInfoValues.phone = this.cleanPhoneNumber(value);
      },
    },
  },
  methods: {
    ...mapActions(useStandaloneCreditAppStore, ['setIdentity', 'setContactInfo']),
    async submitHandler(): Promise<Record<string, string>> {
      this.errors = (await this.validateForm()) || {};

      if (Object.keys(this.errors).length === 0) {
        const { firstName, middleName, lastName } = this.identityValues;
        const displayName = `${firstName}${middleName ? ' ' + middleName : ''} ${lastName}${this.identityValues?.suffix ? ', ' + this.identityValues?.suffix : ''}`;
        const dateOfBirthDefined = !!this.identityValues.dateOfBirth;
        this.contactInfoValues.altPhone = this.contactInfoValues.phone;

        const identityData = {
          ...this.identityValues,
          dateOfBirthDefined,
          displayName,
        };

        // If suffix is none, set it to empty so it does not get submitted 3/13/25
        if (this.identityValues.suffix === 'None') {
          this.identityValues.suffix = '';
        }

        if (this.isCobuyer) {
          this.setIdentity(identityData, true);
          this.setContactInfo(this.contactInfoValues, true);
        } else {
          this.setIdentity(identityData);
          this.setContactInfo(this.contactInfoValues);
        }
      }

      return this.errors;
    },
    async validateForm(): Promise<Record<string, string>> {
      let identityErrors = await validate([this.buyerIdentityValidator], {
        ...this.identityValues,
      });

      // if date of birth is not in the past, push date of birth error
      // todo: add this to sitecore validation?
      const now = new Date().getTime();
      const dob = new Date(this.identityValues.dateOfBirth).getTime();
      if (now - dob < 0 && !identityErrors?.dateOfBirth) {
        identityErrors = {
          ...identityErrors,
          dateOfBirth: 'Date of birth (mm/dd/yyyy)',
        };
      }

      const contactErrors = await validate([this.buyerContactValidator], {
        ...this.contactInfoValues,
      });

      return { ...identityErrors, ...contactErrors };
    },
    hasError(key: string): boolean {
      return !!this.errors[key];
    },
    handleDOBInput(event: Event) {
      const target = event.target as HTMLInputElement;
      this.identityValues.dateOfBirth = this.formatDOB(target.value);
    },
    handlePhoneInput(event: Event) {
      const target = event.target as HTMLInputElement;
      this.contactInfoValues.phone = this.cleanPhoneNumber(target.value);
    },
    cleanDOB,
    cleanPhoneNumber,
    formatDOB,
    formatPhoneNumber,
    handlePaste,
    onlyAllowNumbers,
  },
  mounted() {
    const buyer = this.isCobuyer ? this.coBuyer : this.primaryBuyer;

    this.identityValues = {
      firstName: buyer?.identity?.firstName || '',
      middleName: buyer?.identity?.middleName || '',
      lastName: buyer?.identity?.lastName || '',
      suffix:
        (buyer?.identity?.suffix as
          | 'JR'
          | 'SR'
          | 'I'
          | 'II'
          | 'III'
          | 'IV'
          | 'V'
          | 'None'
          | ''
          | undefined) || undefined,
      dateOfBirth: formatDOB(buyer?.identity?.dateOfBirth || ''),
    };

    this.contactInfoValues = {
      phone: buyer?.contactInfo?.phone || '',
      phoneType: (buyer?.contactInfo?.phoneType as 'HOME' | 'WORK' | 'MOBILE') || 'HOME',
      email: buyer?.contactInfo?.email || '',
      altPhone: buyer?.contactInfo?.altPhone || '',
      altPhoneType: (buyer?.contactInfo?.altPhoneType as 'HOME' | 'WORK' | 'MOBILE') || 'MOBILE',
    };
  },
});
</script>

<style lang="scss">
@use '@/assets/styles/variables' as *;

.buyerNameForm .v-field__input {
  height: $spacing_stack-lg;
}
</style>

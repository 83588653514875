<template>
  <sc-rich-text :field="fields.html" />
</template>

<script lang="ts">
/*
TODO-vue3 [JOSH] -- This should be replaced with the /components/Feature/Financing/Calculator.vue.bak
           component after it is fully tested

           Clean up the following files once we move to the native-Vue Calculator:
           - /src/Project/EchoPark/client/public/calculator.js [DELETE]
           - /src/Project/EchoPark/client/public/jquery.min.js [DELETE]
           - /src/Project/EchoPark/client/public/index.html [remove calculator.js]
           - /src/Project/EchoPark/client/public/index.html [remove jquery.min.js]
*/

import { MvcDatasourceModel } from '@/lib/CommonDatasourceModels';
import RichText from '@components-vue/Foundation/Common/RichText';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'Calculator',
  props: {
    fields: {
      type: Object as PropType<MvcDatasourceModel>,
      default: () => ({}),
    },
  },
  components: {
    ScRichText: RichText,
  },
  mounted() {
    // @ts-ignore -- this is loaded via /src/Project/EchoPark/client/public/calculator.js
    window.onCalculatorMount();
  },
});
</script>

<style>
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
:after,
:before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
}

section {
  display: block;
}

p {
  margin: 0;
  padding: 0;
}

li,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ParagraphParkGray-Subhead {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--park-gray-0);
}

[type='button'],
button {
  -webkit-appearance: button;
}

[type='button']:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

.calculator_flyout--footer .btn {
  width: 100%;
}

a {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: default;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tooltip-account {
  cursor: pointer;
  vertical-align: top;
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
}

.tooltip-account:after {
  content: '';
  width: 24px;
  height: 24px;
  background: url(../../assets/icons/icons-information.svg);
  background-position: center center;
  background-size: 24px;
  display: inline-block;
  vertical-align: middle;
}

.calculator_icon {
  background-color: var(--base-white-75);
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  z-index: 9;
  width: 272px;
  height: 124px;
  top: 136px;
  border-radius: 16px;
  display: flex;
  flex-wrap: nowrap;
  align-content: stretch;
  align-items: center;
  justify-content: center;
}

#divCalculatorWait {
  width: 90px;
  height: 90px;
}

.filter_tabs {
  padding: 4px;
  border-radius: 19.5px;
  background-color: var(--accent-warm-gray-90);
  display: inline-flex;
  margin-bottom: 24px;
}

.filter_tabs .filter_tab {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: var(--park-gray-0);
  font-weight: 500;
  display: inline-flex;
  padding: 8px 12px;
  border-radius: 16px;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
  margin-left: 1px;
  margin-right: 1px;
  border: 0;
}

@media (min-width: 992px) {
  .filter_tabs .filter_tab {
    font-size: 13px;
  }
}

@media (max-width: 991px) {
  .filter_tabs .filter_tab {
    padding: 8px 16px;
  }
}

.filter_tabs .filter_tab.active {
  background-color: var(--base-white-100);
}

.calculator_flyout {
  width: 100%;
  height: 100%;
  background: var(--base-white-100);
  padding-bottom: 88px;
  position: fixed;
  top: 0;
  right: -100%;
  z-index: 10007;
  background-color: var(--base-white-100);
  visibility: hidden;
}

@media (min-width: 576px) {
  .calculator_flyout {
    max-width: 375px;
  }
}

.calculator_flyout--container {
  width: calc(100% + 5px);
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  background-color: var(--park-gray-80);
  scrollbar-width: thin;
  scrollbar-color: var(--green-60) var(--accent-warm-gray-80);
}

.calculator_flyout--container::-webkit-scrollbar {
  width: 8px;
}

.calculator_flyout--container::-webkit-scrollbar-track {
  background: var(--accent-warm-gray-80);
}

.calculator_flyout--container::-webkit-scrollbar-thumb {
  background: var(--green-60);
}

.calculator_flyout--container::-webkit-scrollbar-thumb:hover {
  background: var(--green-60);
}

.calculator_flyout_title {
  display: block;
  padding: 24px;
  padding-bottom: 16px;
  position: relative;
  background-color: var(--park-gray-0);
}

.calculator_flyout_close {
  min-width: 24px;
  height: 24px;
  cursor: pointer;
  position: absolute;
  top: 24px;
  right: 24px;
  background: url(../../assets/icons/icons-menu-close-white.svg) no-repeat;
  background-size: 24px;
}

.calculator_flyout--maintitle {
  font-family: Roboto, sans-serif;
  font-size: 22px;
  font-weight: 700;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  color: var(--base-white-100);
}

.calculator_flyout--tabs {
  width: 100%;
  margin-top: 24px;
  text-align: center;
}

.calculator_flyout .filter_tab {
  padding: 8px 16px;
  background: 0;
  border-radius: 16px;
  border: 0;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: var(--park-gray-0);
  font-weight: 500;
  margin: 0 2px;
}

.calculator_flyout .filter_tab.active {
  background: var(--base-white-100);
}

.calculator_flyout .payment_card_sticky {
  min-height: 148px;
  width: 100%;
  display: none;
}

.calculator_flyout .cashPayment,
.calculator_flyout .sellingprice,
.calculator_flyout--tabCont {
  width: 100%;
}

.calculator_flyout--tabCont > div:not(.sellingprice) {
  display: none;
}

.calculator_flyout--allInPrice_details {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.calculator_flyout--allInPrice_details_cash span.seeAllDetails-cash {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 100%;
  color: var(--base-white-100);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  font-weight: 400;
  padding-right: 28px;
  cursor: pointer;
  background: url(../../assets/icons/icons-arrows-down-white.svg);
  background-size: 24px;
  background-position: right center;
  background-repeat: no-repeat;
}

.calculator_flyout--allInPrice_details span {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--park-gray-0);
  color: var(--base-white-100);
}

.calculator_flyout--allInPrice_details span.seeAllDetails {
  font-weight: 400;
  padding-right: 28px;
  cursor: pointer;
  background: url(../../assets/icons/icons-arrows-down-white.svg);
  background-size: 24px;
  background-position: right center;
  background-repeat: no-repeat;
}

.calculator_flyout .calculator_moredetails li {
  padding: 12px 0;
  display: flex;
  justify-content: space-between;
}

.calculator_flyout .calculator_moredetails li:not(:last-child) {
  border-bottom: solid 1px var(--shadow-white);
}

.calculator_flyout .calculator_moredetails li span {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--park-gray-0);
}

.calculator_flyout .calculator_moredetails li span:last-child {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.2px;
  color: var(--park-gray-20);
  padding-left: 8px;
  white-space: nowrap;
}

.calculator_flyout .calculator_moredetails li span:last-child strong {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--park-gray-0);
}

.calculator_flyout--moredetails_breakdown {
  width: 100%;
  margin-top: 24px;
  display: none;
}

.calculator_flyout--moredetails_breakdown .calculator_moredetails_breakdown li {
  display: flex;
  justify-content: space-between;
}

.calculator_flyout--moredetails_breakdown .calculator_moredetails_breakdown li span {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--base-white-100);
}

.calculator_flyout--moredetails_breakdown .calculator_moredetails_breakdown li span:last-child {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--base-white-100);
}

.calculator_flyout--slideBox-vdp-srp {
  padding: 24px;
  border-radius: 8px;
  border: solid 1px var(--park-gray-80);
  background-color: var(--base-white-100);
}

.calculator_flyout--slideBox-vdp-srp:not(:last-child) {
  margin-bottom: 24px;
}

.calculator_flyout--slideBox-vdp-srp .slideBox-title-calculator {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--park-gray-0);
  margin-bottom: 16px;
}

.calculator_flyout--slideBox-vdp-srp .range-slider {
  margin-top: -8px;
}

.calculator_flyout--slideBox-vdp-srp .input-range {
  width: auto;
  padding: 0;
}

.calculator_flyout--slideBox-vdp-srp .input-range input {
  padding-left: 16px;
  width: 80px;
}

.calculator_flyout--slideBox-vdp-srp .input-range:before {
  font-size: 16px;
  margin-top: 1px;
  left: 8px;
}

.calculator_flyout--slideBox-vdp-srp .downpaymentSlider .input-range input {
  text-align: right;
}

.calculator_flyout--slideBox {
  padding: 24px;
  border-radius: 8px;
  border: solid 1px var(--park-gray-80);
  background-color: var(--base-white-100);
}

.calculator_flyout--slideBox:not(:last-child) {
  margin-bottom: 24px;
}

.calculator_flyout--slideBox .slideBox-title {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--park-gray-0);
  margin-bottom: 8px;
}

.calculator_flyout--slideBox .slideBox-title span {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.2px;
  color: var(--park-gray-20);
}

.calculator_flyout--slideBox p {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--park-gray-0);
}

.calculator_flyout--slideBox .slideBox-CTA {
  text-align: center;
  margin: 12px 0 0;
}

.calculator_flyout--slideBox .slideBox-CTA a {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--supplementary-blue-60);
  display: inline-block;
  text-decoration: none;
}

.calculator_flyout--slideBox .apr-rate {
  display: flex;
  justify-content: space-between;
  border-top: solid 1px var(--shadow-white);
  padding-top: 16px;
  margin-top: 16px;
}

.calculator_flyout--slideBox .apr-rate .tooltip {
  width: 16px;
  height: 16px;
  vertical-align: text-top;
}

.calculator_flyout--slideBox .apr-rate .tooltip::after {
  width: 16px;
  height: 16px;
  background-size: 16px;
  vertical-align: top;
}

.calculator_flyout--slideBox .range-slider {
  margin-top: -8px;
}

.calculator_flyout--slideBox .input-range {
  width: auto;
  padding: 0;
}

.calculator_flyout--slideBox .input-range input {
  padding-left: 16px;
  width: 80px;
}

.calculator_flyout--slideBox .input-range:before {
  font-size: 16px;
  margin-top: 1px;
  left: 8px;
}

.calculator-flyout-divider {
  border-top: solid 1px var(--park-gray-90);
  margin-top: 24px;
}

.calculator_flyout--slideBox .termSlider .slideBox-title {
  margin-bottom: -32px;
}

.calculator_flyout--slideBox .creditTierSlider .slideBox-title {
  margin-bottom: -32px;
}

.calculator_flyout--slideBox .cashEstimated .input-range input {
  text-align: right;
}

.calculator_flyout--slideBox .calculator_moredetails li {
  border-color: var(--shadow-white);
}

.calculator_flyout--slideBox--toprow {
  display: flex;
  justify-content: space-between;
}

.calculator_flyout--slideBox--toprow .slideBox-title {
  margin-bottom: 0;
}

.calculator_flyout--slideBox--toprow .slideBox-selectedItem {
  margin-left: auto;
}

.calculator_flyout--body {
  width: calc(100% + 6px);
  background-color: var(--park-gray-80);
  padding: 24px;
  padding-bottom: 32px;
  position: relative;
}

.calculator_flyout--footer {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  padding: 16px 24px;
  box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--base-white-100);
}

@media (min-width: 576px) {
  .calculator_flyout--footer {
    max-width: 375px;
  }
}

.payment_card {
  margin: 0 auto;
  margin-bottom: 24px;
  padding: 12px 0;
  border-radius: 16px;
  background-color: var(--system-green-50);
  text-align: center;
  width: 100%;
  max-width: 272px;
  min-height: 124px;
  min-width: 272px;
}

.payment_card--title {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--base-white-100);
  margin-bottom: 12px;
}

.payment_card--price {
  font-family: Roboto, sans-serif;
  font-size: 64px;
  font-weight: 300;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.8px;
  color: var(--base-white-100);
}

.payment_card--price sub {
  font-family: Roboto, sans-serif;
  font-size: 26px;
  font-weight: 300;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  color: var(--base-white-100);
  vertical-align: baseline;
}

.calculator_flyout_open .calculator_flyout {
  right: 0;
  transition: all 0.3s ease-in-out;
  visibility: visible;
}

.input_check_list {
  border: 1px solid var(--park-gray-80);
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.input_check_list input {
  position: absolute;
  left: -9999px;
  width: 0;
  height: 0;
}

.input_check_list label {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: var(--park-gray-20);
  flex: 1;
  padding: 11px;
  cursor: pointer;
}

@media (min-width: 768px) {
  .input_check_list label {
    padding: 13px;
  }
}

.input_check_list label:not(:last-child) {
  border-right: 1px solid var(--park-gray-80);
}

.input_check_list label:hover {
  background: var(--green-40);
  color: var(--base-white-100);
}

.input_check_list label:active {
  background: var(--system-green-50);
  color: var(--base-white-100);
}

.input_check_list input:checked + label {
  background: var(--park-gray-10);
  color: var(--base-white-100);
}

@media (min-width: 1025px) {
  .input_check_list input:checked + label:hover {
    background: var(--green-40);
    color: var(--base-white-100);
  }
}

@media (min-width: 1025px) {
  .input_check_list input:checked + label:active {
    background: var(--system-green-50);
    color: var(--base-white-100);
  }
}

.downPayment-label {
  margin: auto 0;
}

.range-input-calculator input {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: var(--park-gray-0);
  width: 100%;
  height: 40px;
  padding: 12px 8px;
  border-radius: 8px;
  border: solid 1px var(--park-gray-80);
  background-color: var(--base-white-100);
  text-align: right;
}

.range-input-calculator input:focus {
  outline: 0;
}

.calculator-input-section {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: var(--park-gray-0);
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  position: relative;
}

.calculator-input-section::before {
  content: '$';
  position: absolute;
  right: 66px;
  top: 50%;
  transform: translateY(-50%);
}

#calculator_flyout_total_cost_finance,
#calculator_flyout_total_cost_cash,
#calculator_flyout_total_finance,
#calculator_flyout_total_cash {
  border-top: solid 1px var(--shadow-white);
  padding-top: 12px;
}

#calculator_flyout_footer_finance {
  color: var(--base-white-100);
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 12px;
}

#calculator_flyout_footer_cash {
  color: var(--base-white-100);
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 12px;
}

.calculator-still-owe-section {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: var(--park-gray-20);
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.calculator-still-owe-section p {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--supplementary-blue-60);
  cursor: pointer;
}

.calculator-applied-purchase {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
}

.calculator-applied-purchase p {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--park-gray-0);
}

.slideBox-title-calculator {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--park-gray-0);
  margin-bottom: 16px;
}

.calculator-input-section-payoff {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--park-gray-0);
  transition: opacity 1s ease-out;
  opacity: 0;
  height: 0;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.calculator-input-section-payoff::before {
  content: '$';
  position: absolute;
  right: 66px;
  top: 50%;
  transform: translateY(-50%);
}

#calculator_flyout_taxes_finance,
#calculator_flyout_tag_fee_finance,
#calculator_flyout_doc_fee_finance,
#calculator_flyout_total_finance,
#calculator_flyout_taxes_cash,
#calculator_flyout_total_cost_cash,
#calculator_flyout_total_cost_finance,
#calculator_flyout_tag_fee_cash,
#calculator_flyout_doc_fee_cash,
#calculator_flyout_total_cash {
  padding-top: 12px;
}

#calculator_flyout_downpayment_trade_finance,
#calculator_flyout_downpayment_trade_cash,
#calculator_flyout_shipping_fee_finance,
#calculator_flyout_shipping_fee_cash {
  padding: 12px 0 12px 0;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
:after,
:before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
}

.calculator_overlay {
  width: 100%;
  height: 100%;
  background: var(--base-black-100);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10006;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.calculator_overlay.show_panel {
  opacity: 0.4;
  visibility: visible;
}

.calculator-input-section-payoff {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--park-gray-park-gray-0);
  transition: opacity 1s ease-out;
  opacity: 0;
  height: 0;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.calculator-input-section-payoff::before {
  content: '\0024';
  position: absolute;
  right: 66px;
  top: 50%;
  transform: translateY(-50%);
}

.calculator-input-section-payoff.active {
  opacity: 1;
  height: auto;
}

label {
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .filter_tabs .filter_tab,
  .filter_tabs > div {
    font-size: 13px;
  }
}

@media (max-width: 991px) {
  .filter_tabs .filter_tab,
  .filter_tabs > div {
    padding: 8px 16px;
  }
}

.filter_tabs .filter_tab.active,
.filter_tabs > div.active {
  background-color: var(--base-white-100);
}

.filter_tabs_content > div:not(:first-child) {
  display: none;
}

.calculator_flyout .filter_tab {
  padding: 8px 16px;
  background: 0;
  border-radius: 16px;
  border: 0;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: var(--park-gray-park-gray-0);
  font-weight: 500;
  margin: 0 2px;
}

.calculator_flyout .filter_tab.active {
  background: var(--base-white-100);
}

.calculator_flyout--allInPrice_details_cash span.seeAllDetails-cash.active {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 100%;
  color: var(--base-white-100);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  font-weight: 400;
  padding-right: 28px;
  cursor: pointer;
  background: url(../../assets/icons/icons-arrows-up-white.svg);
  background-size: 24px;
  background-position: right center;
  background-repeat: no-repeat;
}

.calculator_flyout--allInPrice_details span.seeAllDetails.active {
  background: url(../../assets/icons/icons-arrows-up-white.svg);
  background-size: 24px;
  background-position: right center;
  background-repeat: no-repeat;
}
</style>

<template>
  <!-- note .collapsed-container is unused -->
  <div
    class="filter-chip-container"
    ref="divFilterChipContainer"
    :style="cssVars"
    :class="{ 'collapsed-container': !isExpanded && isMobile }"
  >
    <FilterChipItem
      id="btnDistanceShipping"
      ref="btnDistanceShipping"
      @click="setFilter('storename')"
      class="btn-distance-shipping button my-1"
      :isCloseable="false"
    >
      <template #value>
        <span class="location-chip-zip" tabindex="0">
          <img class="mr-1 img-location-icon" src="@icons/locationIcon.svg" alt="location icon" />
          <b>{{ zipCode }}</b>
        </span>
        <span>|</span>
        <span
          id="lblRadius"
          v-if="!shopByStore && radius !== -1"
          class="location-chip-radius"
          tabindex="0"
          >{{ replaceText($t('Cars Within Miles'), ';xMiles;', radius) }}</span
        >
        <span v-if="!shopByStore && radius === -1" class="location-chip-radius" tabindex="0">{{
          $t('Nationwide')
        }}</span>
        <span v-if="shopByStore" class="location-chip-radius" tabindex="0">{{
          $t('Distance Shipping Toggle Text Right')
        }}</span>
      </template>
    </FilterChipItem>

    <FilterChipItem
      v-if="shipToStore && shipToStoreToggleFeatureFlag"
      @click="setFilter('shiptostore')"
      @close="closeShipToStore()"
      id="btnShipToStore"
      class="button filterButton nonDistanceShipButton my-1 flex-container"
      :value="$t('Ship to store')"
    />

    <FilterChipItem
      v-for="item in orderedStoreNames"
      @click="setFilter('storename')"
      @close="removeFromIncludedDealerships(item)"
      class="button filterButton nonDistanceShipButton my-1 flex-container"
      :value="item"
      :key="item"
    />

    <template v-for="(item, facetType) in orderedFacets">
      <FilterChipItem
        v-for="value in filteredStringFacets(facetType, 'string', item)"
        v-show="showButton(`btn${facetType}${value}`) || isExpanded"
        :id="`btn${facetType}${value}`"
        @click="setFilter(facetType)"
        @close="closeStringFacet(facetType, value)"
        class="button filterButton nonDistanceShipButton my-1 flex-container"
        :data-type="item.type"
        :data-should-hide="item.type != 'string'"
        :class="{ btnRemove: item.type != 'string' }"
        :key="`btn${facetType}${value}`"
      >
        <template #value>
          <StringData
            v-if="facetMetaData[facetType]"
            class="btnText"
            tabindex="0"
            :item="value"
            :postfix="facetMetaData[facetType].postfix"
            data-testid="filter-chip-non-distance-ship-button-text"
          ></StringData>
        </template>
      </FilterChipItem>
      <!-- eslint-disable-next-line -->
      <FilterChipItem
        v-show="item.type === 'numericRange' && (showButton(`btn${facetType}`) || isExpanded)"
        :id="`btn${facetType}`"
        :class="{ btnRemove: item.type !== 'numericRange' }"
        @close="closeNumericFacet(facetType)"
        @click="setFilter(facetType)"
        class="button filterButton nonDistanceShipButton my-1 flex-container"
      >
        <template #value>
          <RangeData
            class="btnText"
            tabindex="0"
            v-if="facetMetaData[facetType]"
            :max="
              facetType === 'year'
                ? String(item?.max || 0)
                : String(Number(item?.max || 0).toLocaleString())
            "
            :min="
              facetType === 'year'
                ? String(item?.min || 0)
                : String(Number(item?.min || 0).toLocaleString())
            "
            :prefix="facetMetaData[facetType].prefix"
            :postfix="facetMetaData[facetType].postfix"
            data-testid="filter-chip-non-distance-ship-button-text"
          ></RangeData>
        </template>
      </FilterChipItem>

      <FilterChipItem
        v-for="value in filteredHierarchicalFacets(facetType, item)"
        v-show="showButton(`btn${facetType}${value}`) || isExpanded"
        :id="`btn${facetType}${value}`"
        @close="closeHierarchicalFacet(value)"
        @click="setFilter(facetType)"
        class="button filterButton nonDistanceShipButton my-1 flex-container"
        :value="value"
        :key="`btn${facetType}${value}`"
      />
    </template>
    <!-- note .mobile-only below likely can be removed b/c it doesn't reference anything -->

    <FilterChipItem
      v-show="isMobile && hiddenChips > 0"
      id="btnExpand"
      @click="expandButtons"
      class="button flex-container expandButton mobile-only nonDistanceShipButton my-1"
      :class="{ 'show-less': isExpanded }"
      :isCloseable="false"
    >
      <template #value>
        <span v-show="!isExpanded">+{{ hiddenChips }}</span>
        <span v-show="isExpanded"> {{ $t('srp.common.showLess') }} <ChevronDownIconBlue /> </span>
      </template>
    </FilterChipItem>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import * as facetHelper from '@util/facetHelper';
import RangeData from './RangeData.vue';
import StringData from './StringData.vue';
import FilterChipItem from './FilterChipItem.vue';
import ChevronDownIconBlue from '@assets/icons/ChevronDownIcon/ChevronDownIconBlue.vue';
import { resolveUrl } from '@util/routeBuilder';
import { FacetFilters } from '@/types/VehicleSearch/Facets';
import { mapActions, mapState } from 'pinia';
import { useVehicleSearchStore } from '@/stores/vehicleSearch';
import { useCommonStore } from '@/stores/common';
import { FieldsPropType } from '@/lib/FieldTypes';

export default defineComponent({
  name: 'FilterChipMenu',
  props: {
    hasBorderBottom: { type: Boolean, default: true },
    isInMobileFilterMenu: { type: Boolean, default: true },
    isFilterMenuOpen: { type: Boolean, default: false },
    fields: {
      type: Object as PropType<FieldsPropType>,
      default: () => ({}),
    },
  },
  data() {
    return {
      buttons: {},
      hiddenChips: 0,
      isMobile: false,
      isOverflow: false,
      sortOrder: facetHelper.filterChipSortOrder,
      facetMetaData: facetHelper.facetMetaData,
      shipToStoreToggleFeatureFlag: Boolean(Number(this.$t('Ship To Store Toggle Feature Flag'))),
    };
  },
  components: { ChevronDownIconBlue, RangeData, StringData, FilterChipItem },
  computed: {
    ...mapState(useVehicleSearchStore, {
      facetFilters: (state) => state.facetFilters ?? ({} as FacetFilters),
      isExpanded: (state) => state.facetChipsExpanded,
    }),
    ...mapState(useVehicleSearchStore, [
      'shipToStore',
      'shopByStore',
      'selectedFacetFilters',
      'sortType',
      'queryParams',
      'radius',
      'includedDealerships',
      'remainingParams',
    ]),
    ...mapState(useCommonStore, ['zipCode', 'dealerships']),
    layoutPath() {
      return this.$jss?.sitecoreContext()?.layoutPath;
    },
    orderedFacets() {
      // @ts-ignore // TODO-pinia existing error
      return facetHelper.orderedFacets(this.facetFilters, this.sortOrder) as FacetFilters;
    },
    filteredStoreNames() {
      return (
        this.dealerships
          // @ts-ignore // TODO-pinia existing error
          ?.filter((dealer) => this.includedDealerships?.includes(dealer.dealerId))
          .map((dealer) => dealer.storeName) || []
      );
    },
    orderedStoreNames() {
      return this.filteredStoreNames.sort(); // eslint-disable-line vue/no-side-effects-in-computed-properties
    },
    hierarchicalFacets() {
      return Object.fromEntries(
        Object.entries(this.facetFilters as FacetFilters).filter(
          ([_key, value]) => value.type === 'hierarchical'
        )
      );
    },
    cssVars() {
      return {
        '--border-bottom': this.hasBorderBottom ? '1px solid #E8E9EB' : 'unset',
        '--display-show-less': this.isInMobileFilterMenu ? 'block' : 'none',
      };
    },
  },
  methods: {
    ...mapActions(useVehicleSearchStore, [
      'setStringFacetFilter',
      'setNumericFacetFilter',
      'setRemoveFacet',
      'setShipToStore',
      'setSelectedFacetItems',
      'setHierarchicalFacetFilter',
      'setFacetChipsExpanded',
      'setHiddenChipCount',
      'setSpecificFilterSelected',
      'setFilterSelected',
      'setIncludedDealerships',
      'setRecentSelectedFilter',
      'setShopByStore',
    ]),
    showButton(key) {
      return !this.isMobile || (this.buttons.hasOwnProperty(key) ? this.buttons[key] : false);
    },
    filteredStringFacets(facetType, itemType, item) {
      return item.facetValue && item.facetValue.length > 0
        ? item.facetValue.filter((_value) => {
            return item.type == itemType;
          })
        : [];
    },
    filteredHierarchicalFacets(facetType, item) {
      return facetHelper
        .getMmtKeys(this.hierarchicalFacets)
        .filter((_value) => item.type == 'hierarchical');
    },
    closeStringFacet(filterType, filterValue) {
      this.setRecentSelectedFilter(filterType);
      this.removeFromFacets(filterType, filterValue);
      this.removeFromSelectedFacets(filterType, filterValue);
      this.emitter.emit('filter-updated-srp');
    },
    removeFromIncludedDealerships(storeName) {
      let dealerId = ((storeName) =>
        (this.dealerships.find((dealer) => dealer.storeName === storeName) || {}).dealerId)(
        storeName
      );
      let selectedDealerships = this.includedDealerships;
      // @ts-ignore // TODO-pinia existing error
      if (selectedDealerships && selectedDealerships.includes(dealerId)) {
        // @ts-ignore // TODO-pinia existing error
        const indexToRemove = selectedDealerships.indexOf(dealerId);
        if (indexToRemove !== -1) {
          selectedDealerships.splice(indexToRemove, 1);
        }
      }
      this.setIncludedDealerships(selectedDealerships);
      if (this.layoutPath) {
        const params = {
          facetFilters: this.facetFilters,
          queryParams: this.queryParams,
          includedDealerships: this.includedDealerships,
          ship: this.shipToStore,
          sortType: this.sortType,
          itemPath: this.layoutPath, // set itempath to layout path on reroute
          layoutPath: null,
          radius: this.radius,
          shopByStore: this.shopByStore,
          remainingParams: this.remainingParams,
          root: this.$root,
        };

        resolveUrl(this.$pinia, this.$router, params);
        window.location.reload();
      }

      if (selectedDealerships.length !== 0) {
        // There is a "watch" on shopByStore in ShopByStoreToggle that emits filter-updated-srp - must be emitted explicitly here
        this.emitter.emit('filter-updated-srp');
      }
    },
    removeFromFacets(filterType, filterValue) {
      let items = this.facetFilters[filterType].facetValue || [];
      if (items && items.includes(filterValue)) {
        const indexToRemove = items.indexOf(filterValue);
        if (indexToRemove !== -1) {
          items.splice(indexToRemove, 1);
        }
      }
      if (items.length === 0) {
        this.setRemoveFacet(filterType);
      } else {
        const data = {
          name: filterType,
          value: items,
        };
        this.setStringFacetFilter(data);
      }
    },
    removeFromSelectedFacets(filterType, filterValue) {
      let items = this.selectedFacetFilters[filterType] || [];
      if (items && items.includes(filterValue)) {
        const indexToRemove = items.indexOf(filterValue);
        if (indexToRemove !== -1) {
          items.splice(indexToRemove, 1);
        }
      }
      const data = {
        name: filterType,
        value: items,
      };
      this.setSelectedFacetItems(data);
    },
    closeNumericFacet(filterType) {
      this.setRecentSelectedFilter(filterType);
      this.setRemoveFacet(filterType);
      this.emitter.emit('filter-updated-srp');
    },
    closeShipToStore() {
      this.setShipToStore(false);
      this.emitter.emit('filter-updated-srp');
    },
    closeHierarchicalFacet(filterValue) {
      this.setRecentSelectedFilter(facetHelper.facetMetaData.mmt.key);

      let items = this.facetFilters[this.facetMetaData.mmt.key].facetValue || [];

      for (const make in items) {
        if (filterValue === make) {
          delete items[make];
        }
        const models = items[make];
        for (const model in models) {
          if (filterValue === model) {
            delete models[model];
          }
          items[make] = models;
          const trims = models[model];
          for (const trim in trims) {
            if (filterValue === trim) {
              delete trims[trim];
            }
            models[model] = trims;
            items[make] = models;
          }
        }
      }

      if (Object.keys(items).length === 0) {
        this.setRemoveFacet(this.facetMetaData.mmt.key);
      } else {
        const data = {
          name: this.facetMetaData.mmt.key,
          value: items,
        };
        this.setHierarchicalFacetFilter(data);
      }

      this.emitter.emit('filter-updated-srp');
    },
    updateDimensions() {
      if (!this.isExpanded) {
        this.clearButtons();
        let btnExpandWidth = 46;
        let btnPadding = 12;
        let totalAvailableWidth = this.getFilterContainerWidth() - btnExpandWidth;
        let takenWidth = 0;
        let container = this.$refs.divFilterChipContainer as HTMLDivElement;

        let childElements = container.getElementsByClassName(
          'button'
        ) as HTMLCollectionOf<HTMLButtonElement>;
        let btnRemove = container.getElementsByClassName(
          'btnRemove'
        ) as HTMLCollectionOf<HTMLButtonElement>;
        let btnsToRemove = 0;

        for (let i = 0; i < childElements.length; i++) {
          let element = childElements[i];
          if (element.id === 'btnShipToStore' && !this.shipToStore) {
            btnsToRemove++;
            continue;
          }

          let classes = Array.from(element.classList);
          if (classes.includes('btnRemove')) {
            continue;
          }

          let elementCss = getComputedStyle(element);
          let elementWidth = element.offsetWidth + btnPadding;
          if (elementCss.display === 'none') {
            element.style.display = 'flex';
            elementWidth = element.offsetWidth + btnPadding;
            element.style.display = 'none';
          }

          if (elementWidth > totalAvailableWidth - takenWidth) {
            this.buttons['btnExpanded'] = true;
            break;
          } else {
            takenWidth += elementWidth;
            btnsToRemove++;
            this.buttons[element.id] = true;
          }

          let btnExpandCount = 1;
          let totalButtonsToRemove = btnsToRemove + btnRemove.length + btnExpandCount;
          this.hiddenChips = childElements.length - totalButtonsToRemove;
          this.setHiddenChipCount(this.hiddenChips);
        }
      }
    },
    clearButtons() {
      Object.keys(this.buttons).forEach((key) => {
        delete this.buttons[key];
      });
    },
    getFilterContainerWidth() {
      const container = this.$refs.divFilterChipContainer as HTMLDivElement;
      const containerCss = window.getComputedStyle(container);

      let totalWidth = container.offsetWidth * 2;
      let paddingLeft = parseFloat(containerCss.paddingLeft);
      let paddingRight = parseFloat(containerCss.paddingRight);
      let totalPadding = (paddingLeft + paddingRight) * 2;
      return totalWidth - totalPadding;
    },
    handleResize() {
      this.checkIsMobile();
      this.updateDimensions();
    },
    expandButtons() {
      if (!this.isExpanded) {
        let container = this.$refs.divFilterChipContainer as HTMLDivElement;
        let childElements = container.getElementsByClassName('button');

        for (let i = 0; i < childElements.length; i++) {
          this.buttons[childElements[i].id] = true;
        }
        this.setFacetChipsExpanded(true);
      } else {
        this.setFacetChipsExpanded(false);
        this.updateDimensions();
      }
    },
    checkIsMobile() {
      if (typeof window !== 'undefined') {
        this.isMobile = window.innerWidth < 1240;
      }
    },
    setFilter(index) {
      this.setFilterSelected(false);
      this.setSpecificFilterSelected(index);

      if (this.isMobile && !this.isFilterMenuOpen) {
        this.emitter.emit('toggle-filter-menu');
      }
    },
    replaceText(text, search, replacement) {
      return text.replace(search, replacement);
    },
  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  watch: {
    facetFilters: {
      handler: function () {
        setTimeout(() => {
          if (this.isMobile) {
            this.updateDimensions();
          }
        }, 500);
      },
      deep: true,
    },
    shipToStore: function () {
      this.updateDimensions();
    },
    isExpanded: function () {
      this.updateDimensions();
    },
  },
});
</script>

<style lang="scss">
.filter-chip-container {
  order: 3;
  padding: 0 24px;
  width: 100%;
  background: #fff;
  height: auto !important;
  border-bottom: var(--border-bottom);
  display: flex;
  flex-wrap: wrap;
  z-index: 2;
  position: sticky;
  top: 52px;

  @media only screen and (min-width: 1240px) {
    order: 1;
    height: 76px;
    top: 0;
  }

  .filter-chip-main & {
    padding: 14px 24px;
  }
  .filterButton {
    background: linear-gradient(0deg, #e6f1f5, #e6f1f5), linear-gradient(0deg, #006ea6, #006ea6);
    padding: 0;

    &:last-of-type {
      margin-right: 16px;
    }
  }
  .expandButton {
    background: white;
    padding: 12px;

    &.show-less {
      font-weight: 700;
      border: none;
      line-height: 1;
      display: var(--display-show-less);
    }
    &.show-less svg {
      transform: rotate(180deg);
    }
  }
  .nonDistanceShipButton {
    line-height: 9px;
  }
  .button {
    width: auto;
    height: 36px;
    border-radius: 36px;
    border: 1px solid #006ea6;
    display: inline-block;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: left;
    color: $system-alert-blue-60;
    margin-right: 12px;
    vertical-align: middle;

    &.btn-distance-shipping {
      background: linear-gradient(0deg, #006fa6, #006fa6), linear-gradient(0deg, #006ea6, #006ea6);
      border: 1px solid #006ea6;
      width: auto;
      height: 36px;
      border-radius: 36px;
      display: inline;
      color: #ffffff;
      font-family: Roboto, sans-serif;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0;
      text-align: left;
      text-wrap: nowrap;

      @media (min-width: 364px) and (max-width: 991px) {
        padding-top: 1px;
      }
    }
  }
  .flex-container {
    display: flex;
    align-items: center;
  }
}
.img-location-icon {
  position: relative;
  top: 3px;
  width: 13.25px;
  height: 16px;
}
.location-chip-zip {
  margin-left: -1px;
  padding: 10px 7px 9px 10px;
  border-radius: 36px;

  &:active {
    background: #047fbd;
  }
  &:focus {
    background: #047fbd;
  }
  &:focus,
  &:focus-visible {
    outline-offset: -3px !important;
  }
  &:hover {
    background: #0084c7;
  }
}
.location-chip-radius {
  padding: 10px 10px 9px 7px;
  margin-right: -1px;
  border-radius: 0 18px 18px 0;

  &:focus,
  &:focus-visible {
    outline-offset: -3px !important;
  }
  &:active,
  &:focus,
  &:hover {
    text-decoration: underline;
  }
}
</style>

import Cookies from 'js-cookie';
// array of event.key values that can be safely ignored when analyzing a 'keydown' event
// https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values
export const nonCharacterKeys = [
  // whitespace keys
  'Tab',
  // navigation keys
  'ArrowDown',
  'ArrowLeft',
  'ArrowRight',
  'ArrowUp',
  'End',
  'Home',
  // editing keys
  'Backspace',
  'Del', // older versions of firefox report 'Delete' as 'Del'
  'Delete',
];

export function isNullOrEmpty(value: string) {
  return value == null || value == undefined || value.trim().length <= 0;
}

/* https://stackoverflow.com/a/34749873 */
function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

/* https://stackoverflow.com/a/34749873 */
export function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

export function arraysEqualShallow(arr1, arr2) {
  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }
  return true;
}

export function deepEqual(obj1: any, obj2: any) {
  if (obj1 === obj2) return true;
  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
    return false;
  }
  if (Array.isArray(obj1) !== Array.isArray(obj2)) return false;
  if (obj1 instanceof RegExp && obj2 instanceof RegExp) {
    return obj1.toString() === obj2.toString();
  }
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) return false;
  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

export function ensureNumericValues(fields: string[], target: Record<string, any>): void {
  fields.forEach((field) => {
    if (target[field + 'String'] !== undefined) {
      // Convert the corresponding string field to a number
      target[field] = Number(target[field + 'String']) || 0;
    } else if (target[field] !== undefined) {
      // Ensure existing numeric field is a valid number
      target[field] = Number(target[field]) || 0;
    }
  });
}

export function onlyAllowNumbers(event: KeyboardEvent): void {
  // prevent non-numeric characters from being typed
  // use with a 'keydown' listener, 'keypress' is deprecated
  const key = event.key;
  if (!nonCharacterKeys.includes(key) && !/^\d$/.test(key)) {
    event.preventDefault();
  }
}

export function handlePaste(event: ClipboardEvent): void {
  const pastedText = event.clipboardData?.getData('text') || '';
  if (!/^\d+$/.test(pastedText)) {
    event.preventDefault(); // Prevents pasting anything that isn't all numbers
  }
}

export function preventNegativeValues(event): void {
  const input = event.target;
  if (parseFloat(input.value) < 0) {
    input.value = ''; // Clear the input if negative
  }
}

export function formatPhoneNumber(phone: string): string {
  if (!phone) return '';

  const cleaned = phone.replace(/\D/g, ''); // Remove non-numeric characters
  if (cleaned.length <= 3) return `(${cleaned}`;
  if (cleaned.length <= 6) return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3)}`;
  return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
}

export function cleanPhoneNumber(phone: string): string {
  return phone.replace(/\D/g, '').slice(0, 10); // Remove non-numeric characters and limit to 10 digits
}

export function cleanDOB(dob: string): string {
  return dob.replace(/\D/g, '').slice(0, 8); // Remove non-numeric characters and limit to 8 digits
}

export function formatDOB(dob: string): string {
  if (!dob) return '';

  const cleaned = cleanDOB(dob);

  if (cleaned.length < 2) return `${cleaned}`;
  if (cleaned.length === 2) return `${cleaned}/`;
  if (cleaned.length === 3) return `${cleaned.slice(0, 2)}/${cleaned.slice(2, 3)}`;
  if (cleaned.length === 4) return `${cleaned.slice(0, 2)}/${cleaned.slice(2, 4)}/`;
  return `${cleaned.slice(0, 2)}/${cleaned.slice(2, 4)}/${cleaned.slice(4, 8)}`;
}

export function removeCookie(cookieName: string): void {
  Cookies.remove(cookieName);
}

import axios from 'axios';
import conf from '@/appConfig';
import { createRouteOneClient } from '@/clients/routeOneClient';
import { useStandaloneCreditAppStore } from '@/stores/standaloneCreditApp';

export async function fetchCreditAppSchema(dealerId: string) {
  const client = createClient();
  return await client.get(`/schema/${dealerId}`);
}

export async function submitCreditApp(payload: object) {
  const client = createClient();
  // POST to the base URL (no extra path required)
  return await client.post('', payload);
}

function createClient() {
  const store = useStandaloneCreditAppStore();
  const apiKey = store.apiKey || conf.routeOne.defaultApiKey;
  return createRouteOneClient(axios, conf.routeOne.baseEndpoint, apiKey);
}

<template>
  <v-container fluid class="sca-additional-info pa-0">
    <v-form ref="form">
      <v-row>
        <v-col cols="12" class="pb-6">
          <FormHeader
            :title="fieldsMap.get('down-payment.Label')?.value"
            :note="fieldsMap.get('down-payment.Description')"
          ></FormHeader>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" class="py-0">
          <v-text-field
            v-model="financeValues.cashDownPaymentString"
            :label="buyerFinanceSchema?.cashDownPayment?.label"
            data-testid="sca-finance-down-payment"
            type="number"
            :error-messages="
              hasError('cashDownPayment')
                ? [
                    buyerFinanceSchema?.cashDownPayment?.validationMessage ||
                      'Enter a valid down payment',
                  ]
                : []
            "
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pb-6">
          <FormHeader :title="fieldsMap.get('trade.Label')?.value" class="trade-header">
            <template #note>
              <!-- "all the questions above inputs (radio buttons, box selectors, etc.) are 16px BUT subcopy underneath sections are 14px" -->
              <div class="trade-note">
                <sc-rich-text
                  :field="fieldsMap.get('trade.Description')"
                  data-testid="sca-trade-description"
                  class="sca-trade-description"
                />
                <div class="tooltip-wrapper">
                  <v-tooltip
                    :text="fieldsMap.get('trade.trade-selection.optional-text')?.value"
                    location="top"
                    class="top"
                    width="170"
                  >
                    <template v-slot:activator="{ props }">
                      <sc-image
                        :media="fieldsMap.get('trade.trade-selection.option-icon')"
                        tabindex="0"
                        v-bind="props"
                        width="20"
                        height="20"
                        class="ms-2 align-self-center"
                      />
                    </template>
                  </v-tooltip>
                </div>
              </div>
            </template>
          </FormHeader>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-radio-group v-model="trade" data-testid="sca-trade">
            <v-radio
              v-for="(option, index) in tradeOptions"
              :data-testid="`sca-trade-${index}`"
              :key="index"
              :value="option"
              :label="option"
            />
          </v-radio-group>
        </v-col>
      </v-row>
      <SocialSecurity
        :fields="fields"
        :title="fieldsMap.get('verify-identity.Label')?.value"
        :subtitle="fieldsMap.get('verify-identity.Description')"
        :footnote="fieldsMap.get('verify-identity.ssn.notes')"
        :hasError="hasError"
        :personalDetailsValues="personalDetailsValues"
        :buyerPersonalDetailsSchema="buyerPersonalDetailsSchema"
      />
    </v-form>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { useStandaloneCreditAppStore } from '@/stores/standaloneCreditApp';
import { mapState, mapActions, mapWritableState } from 'pinia';
import { validate } from '@/util/schema/schemaValidator';
import { buildMap } from '@/util/standaloneCreditAppUtils';
import { accumulateChildFields } from '@/util/schema/schemaValidatorUtils';
import SocialSecurity from './Forms/SocialSecurity.vue';
import RichText from '@components-vue/Foundation/Common/RichText';
import FormHeader from './Includes/FormHeader.vue';
import { Image } from '@sitecore-jss/sitecore-jss-vue';
import { ensureNumericValues } from '@util/commonUtils';
import standaloneCreditAppFormStep from '@/mixins/standaloneCreditAppFormStep';
import { FieldsPropType } from '@/lib/FieldTypes';

export default defineComponent({
  name: 'StandaloneCreditApp--AdditionalInfo',
  props: {
    fields: {
      type: Object as PropType<FieldsPropType>,
      default: () => ({}),
    },
  },
  components: {
    ScImage: Image,
    ScRichText: RichText,
    SocialSecurity,
    FormHeader,
  },
  data() {
    return {
      personalDetailsValues: {
        ssn: '',
        ssnDefined: false,
      },
      financeValues: {
        transactionType: 'RETAIL' as 'RETAIL' | 'LEASE' | 'BALLOON' | 'CASH' | 'PAYMENT_CALL', // not asked for, hardcode for now per Jason 2/11/24 (NP)
        vehicleCashPrice: undefined as number | undefined,
        cashDownPayment: undefined as number | undefined,
        cashDownPaymentString: '',
      },
      errors: {} as Record<string, string>,
      fieldsMap: new Map(),
    };
  },
  mixins: [standaloneCreditAppFormStep],
  computed: {
    ...mapState(useStandaloneCreditAppStore, [
      'buyerPersonalDetailsValidator',
      'buyerFinanceValidator',
      'buyerPersonalDetailsSchema',
      'buyerFinanceSchema',
      'primaryBuyer',
      'finance',
      'trade',
    ]),
    ...mapWritableState(useStandaloneCreditAppStore, ['trade']),
    tradeOptions(): string[] {
      return [
        this.fieldsMap.get('trade.trade-selection.no.Label')?.value,
        this.fieldsMap.get('trade.trade-selection.yes.Label')?.value,
        this.fieldsMap.get('trade.trade-selection.not-sure.Label')?.value,
      ];
    },
  },
  methods: {
    ...mapActions(useStandaloneCreditAppStore, [
      'generateValidation',
      'setPersonalDetails',
      'setFinance',
      'addErrors',
    ]),
    ensureNumericValues,
    getSubmitHandler(): () => Promise<boolean> {
      this.ensureNumericValues(['cashDownPayment'], this.financeValues);

      const isValid: () => Promise<boolean> = async () => {
        this.errors = (await this.validateForm()) || {};
        if (Object.keys(this.errors).length) {
          this.addErrors(Object.values(this.errors) as string[]);
          return false;
        } else {
          this.setPersonalDetails({
            ...this.personalDetailsValues,
            ssnDefined: !!this.personalDetailsValues.ssn.trim(),
          });

          this.setFinance(this.financeValues);
          return true;
        }
      };

      return isValid.bind(this);
    },
    async validateForm(): Promise<Record<string, string>> {
      const [personalDetailsErrors, financeErrors] = await Promise.all([
        validate([this.buyerPersonalDetailsValidator], {
          ...this.personalDetailsValues,
          ssnDefined: !!this.personalDetailsValues.ssn.trim(),
        }),
        validate([this.buyerFinanceValidator], this.financeValues),
      ]);
      return { ...personalDetailsErrors, ...financeErrors };
    },
    hasError(key: string): boolean {
      return !!this.errors[key];
    },
    buildFieldsMap() {
      this.fieldsMap = buildMap(this.fields);
    },
  },
  created() {
    this.generateValidation(accumulateChildFields(this.fields));
  },
  mounted() {
    this.buildFieldsMap();

    // Primary buyer social only here
    this.personalDetailsValues = {
      ssn: this.primaryBuyer?.personalDetails?.ssn || '',
      ssnDefined: !!this.primaryBuyer?.personalDetails?.ssn?.trim(),
    };

    this.financeValues = {
      transactionType: this.finance?.transactionType || 'RETAIL',
      vehicleCashPrice: this.finance?.vehicleCashPrice ?? 0,
      cashDownPayment: this.finance?.cashDownPayment ?? 0,
      cashDownPaymentString: this.finance?.cashDownPaymentString ?? '',
    };

    this.trade = this.trade || this.fieldsMap.get('trade.trade-selection.no.Label')?.value || '';
  },
});
</script>
<style lang="scss">
@use '@/assets/styles/variables' as *;

.sca-additional-info {
  .trade-header {
    margin-bottom: -20px;
  }
  .trade-note {
    display: block;
    margin-top: $spacing_stack-nano;
  }
  .sca-trade-description {
    font-size: $font-size-xxs;
    display: inline;
  }
  .tooltip-wrapper {
    display: inline;

    img {
      position: relative;
      top: $spacing_stack-quarck;
    }
  }
  .v-icon {
    /* only for ssn icon - need to adjust */
    color: $park-gray-60;
  }
}
</style>

import { aliases, mdi } from 'vuetify/iconsets/mdi';

const config = {
  ssr: true,
  defaults: {
    global: {
      ripple: false,
      elevation: 0,
    },
    VBtn: {
      flat: true,
      opacity: 0,
      rounded: 'pill',
    },
    VCheckbox: {
      hideDetails: true,
    },
    VSelect: {
      variant: 'underlined',
    },
    VSwitch: {
      inset: true,
      hideDetails: true,
    },
    VTextField: {
      variant: 'underlined',
    },
    VTextarea: {
      variant: 'underlined',
      rows: '4',
    },
  },
  display: {
    mobileBreakpoint: 'md',
    // the [xs, sm, md, lg, xl, xxl] keys are required for the vuetify grid. if you change them you
    // will break the vuetify grid. you can add new breakpoints by adding a key/value pair, but you
    // can only use the [xs, sm, md, lg, xl, xxl] breakpoints when using <v-col>
    // (ie - <v-col cols="12" md="6">)
    thresholds: {
      xs: 0,
      375: 375,
      sm: 576,
      625: 625,
      md: 768,
      lg: 1024,
      xl: 1240,
      xxl: 1440,
      1920: 1920,
    },
  },
  icons: {
    defaultSet: 'mdi',
    aliases: {
      ...aliases,
      checkboxOn: 'ep-icon-checkbox',
      checkboxOff: 'ep-icon-checkbox-empty',
      dropdown: 'mdi-chevron-down',
      radioOn: 'ep-icon-radio',
      radioOff: 'ep-icon-radio-empty',
    },
    sets: {
      mdi,
    },
  },
  theme: {
    defaultTheme: 'echoParkTheme',
    themes: {
      echoParkTheme: {
        dark: false,
        colors: {
          error: '#b30715', // $feedback-color-warning-dark, $supplementary-red-50
        },
        variables: {
          'disabled-opacity': 1,
          'field-border-opacity': 1,
          'focus-opacity': 0,
          'hover-opacity': 0,
          'medium-emphasis-opacity': 1,
        },
      },
    },
  },
};

export default config;

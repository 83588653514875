<template>
  <div class="modal show ask-us-modal" id="AskUsModal" v-if="isOpen">
    <div class="askUsModalContent">
      <AskUsForm :askUsVehicle="getVehicleByVin(selectedVin)" />
    </div>
  </div>
</template>

<script lang="ts">
import AskUsForm from '@components/Feature/VehicleSearch/Forms/AskUsForm.vue';
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { useVehicleSearchStore } from '@/stores/vehicleSearch';

export default defineComponent({
  name: 'AskUsModal',
  props: {
    isOpen: Boolean,
    selectedVin: String,
  },
  computed: {
    ...mapState(useVehicleSearchStore, ['getVehicleByVin']),
  },
  components: {
    AskUsForm,
  },
});
</script>

<style lang="scss">
.ask-us-modal {
  display: flex;
  min-height: calc(100% - 80px);
  background-color: var(--park-gray-0-op50);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  outline: 0;
  scrollbar-width: thin;

  .askUsModalContent {
    display: flex;
    background-color: var(--base-white-100);
    padding: 24px;
    margin: auto auto;
    min-width: 328px;
    max-width: 500px;
    border-radius: 16px;
  }
}

/* TODO: coerce into using a standard breakpoint - this is legacy */
@include media-breakpoint-down(625) {
  .ask-us-modal {
    width: 100%;
    height: 100%;
    border-radius: 0;
    overflow-y: auto;

    .askUsModalContent {
      width: 100%;
      height: auto;
      border-radius: 0;
    }
  }
}

@include media-breakpoint-down(375) {
  .ask-us-modal {
    width: 100%;
    border-radius: 0;

    .askUsModalContent {
      width: 100%;
      border-radius: 0;
      height: auto;
    }
  }
}
</style>

<template>
  <!--
    There is a 'strange' Figma requirement, that on selection of a particular option in a filter:

    The option should be 'InSelection' (checkbox will appear by option, BUT option will not move to the top of the list)
    When going out of the filter, and stepping back in, that option that was previously 'InSelection', moves to 'Selected' state.
    Basically, we have these two similar but different states of an option : (InSelection & Selected)
        InSelection - marks the option as selected in current interaction with filter.
        Selected - once you finish interaction with particular filter, and then you step back into the filter, InSelection options move to Selected state.
      -->
  <div class="facet-list">
    <span v-if="title" class="lblTitle">{{ title }}</span>
    <ul class="divFacetList" v-bind="$attrs">
      <li
        v-for="(item, index) in selectedValues"
        :key="`${item}-${index}`"
        :class="{ selected: isSelected(item.name), 'border-top': index === 0 }"
        @click="handleDeselect(item.name)"
        @keyup.enter="handleDeselect(item.name)"
        tabindex="0"
      >
        <FacetListItem
          :filterType="filterType"
          :item="item"
          :isSelected="true"
          :isInSelection="isInSelection(item.name)"
        />
      </li>
      <li
        v-for="(item, index) in notSelectedValues"
        :key="`${item.name}${item.value}-${index}`"
        :class="{ selected: isSelected(item.name), 'border-top': index === 0 }"
        @click="handleSelect(item.name)"
        @keyup.enter="handleSelect(item.name)"
        tabindex="0"
      >
        <FacetListItem
          :filterType="filterType"
          :item="item"
          :isSelected="false"
          :isInSelection="isInSelection(item.name)"
        />
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { FacetListItemValue } from '@/types/VehicleSearch/Facets';
import FacetListItem from '@components/Feature/VehicleSearch/FacetListItem.vue';
import { mapActions, mapState } from 'pinia';
import { useVehicleSearchStore } from '@/stores/vehicleSearch';

export default defineComponent({
  name: 'FacetList',
  components: { FacetListItem },
  props: {
    title: {
      type: String,
    },
    data: {
      type: Array as PropType<Array<FacetListItemValue>>,
    },
    filterType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      items: [],
    };
  },
  computed: {
    ...mapState(useVehicleSearchStore, {
      facetFilters: (state) => state.facetFilters ?? {},
      selectedFacetFilters: (state) => state.selectedFacetFilters,
      reset: (state) => state.resetSelectedFacetItems,
    }),
    filteredInSelectionItems() {
      return this.facetFilters[this.filterType]
        ? this.facetFilters[this.filterType].facetValue
        : [];
    },
    filteredSelectedItems() {
      return this.selectedFacetFilters?.[this.filterType]
        ? this.selectedFacetFilters?.[this.filterType].filter((element) =>
            this.facetFilters[this.filterType] && this.facetFilters[this.filterType].facetValue
              ? this.facetFilters[this.filterType].facetValue.includes(element)
              : false
          )
        : [];
    },
    isSelected() {
      return (key) =>
        this.filteredSelectedItems ? this.filteredSelectedItems.includes(key) : false;
    },
    isInSelection() {
      return (key) =>
        this.filteredInSelectionItems ? this.filteredInSelectionItems.includes(key) : false;
    },
    isNotSelected() {
      return (key) =>
        this.filteredSelectedItems ? !this.filteredSelectedItems.includes(key) : true;
    },
    selectedValues() {
      return this.data?.filter(
        (item: FacetListItemValue) => this.isSelected(item?.name) && item?.value
      );
    },
    notSelectedValues() {
      return this?.data?.filter(
        (item: FacetListItemValue) => this.isNotSelected(item?.name) && item?.value
      );
    },
  },
  methods: {
    ...mapActions(useVehicleSearchStore, [
      'setStringFacetFilter',
      'setRemoveFacet',
      'setResetSelectedFacetItemList',
      'setSelectedFacetItems',
      'setRecentSelectedFilter',
    ]),
    handleSelect(key) {
      this.setRecentSelectedFilter(this.filterType);

      let items = this.filteredInSelectionItems;
      if (items && items.includes(key)) {
        const indexToRemove = items.indexOf(key);
        if (indexToRemove !== -1) {
          items.splice(indexToRemove, 1);
        }
      } else {
        items.push(key);
      }

      if (items.length === 0) {
        this.setRemoveFacet(this.filterType);
      } else {
        const data = {
          name: this.filterType,
          value: items,
        };
        this.setStringFacetFilter(data);
      }

      this.emitter.emit('filter-updated-srp');
    },
    handleDeselect(key) {
      this.setRecentSelectedFilter(this.filterType);

      const items = this.filteredSelectedItems;
      if (items && items.includes(key)) {
        const indexToRemove = items.indexOf(key);
        if (indexToRemove !== -1) {
          items.splice(indexToRemove, 1);
        }
      }

      const data = {
        name: this.filterType,
        value: items,
      };

      this.setSelectedFacetItems(data);

      let selectedItems = this.filteredInSelectionItems;
      if (selectedItems && selectedItems.includes(key)) {
        const indexToRemove = selectedItems.indexOf(key);
        if (indexToRemove !== -1) {
          selectedItems.splice(indexToRemove, 1);
        }
      }

      if (selectedItems.length === 0) {
        this.setRemoveFacet(this.filterType);
      } else {
        const data = {
          name: this.filterType,
          value: selectedItems,
        };
        this.setStringFacetFilter(data);
      }

      this.emitter.emit('filter-updated-srp');
    },
  },
  watch: {
    reset(value) {
      if (value) {
        this.setResetSelectedFacetItemList(this.filterType);
      }
    },
  },
});
</script>

<style lang="scss">
.facet-list {
  .border-top {
    border-top: 1px solid #dee2e6 !important;
  }
  .divFacetList {
    overflow-y: auto;
    overflow-x: hidden;

    & > li:focus-visible {
      outline-offset: -3px;
    }

    & > li:first-of-type {
      border-top: 1px solid var(--shadow-white);
    }
  }
  .selected {
    background-color: var(--green-90);

    .facetIcon {
      filter: invert(34%) sepia(92%) saturate(1674%) hue-rotate(65deg) brightness(90%)
        contrast(103%);
    }
  }
}
</style>

import * as Yup from 'yup';

import {
  EmploymentStatuses,
  EmploymentStatus,
} from '@/types/StandaloneCreditApp/StandaloneCreditAppTypes';

/**
 * Enhances the employment validation schema by conditionally requiring fields
 * based on employmentStatusCode using `.test()`, and ensures valid date selections.
 *
 * @param {ValidationSchemas} validationSchemas - The existing validation schemas.
 * @param {Object} schemaObject - The schema object containing field metadata.
 */
export const enhanceEmploymentValidator = (validationSchemas, schemaObject) => {
  if (!validationSchemas['buyer.employmentDetails']) return;

  const existingSchema = validationSchemas['buyer.employmentDetails'];

  validationSchemas['buyer.employmentDetails'] = existingSchema.shape({
    ...addCurrentFields(existingSchema, schemaObject),
    ...addPreviousFields(existingSchema, schemaObject),
  });
};

const addCurrentFields = (existingSchema, schemaObject) => ({
  ...mergeWithExistingSchema(existingSchema, employmentCurrentSchemaFields(schemaObject)),
  ...employmentCurrentCustomFields(schemaObject),
});

const addPreviousFields = (existingSchema, schemaObject) => ({
  ...mergeWithExistingSchema(existingSchema, employmentPreviousSchemaFields(schemaObject)),
  ...employmentPreviousCustomFields(schemaObject),
});

const mergeWithExistingSchema = (existingSchema, fields) => {
  return Object.keys(fields).reduce((acc, key) => {
    acc[key] = existingSchema.fields[key]
      ? existingSchema.fields[key].concat(fields[key])
      : fields[key];
    return acc;
  }, {});
};

const VALID_DECIMAL = (schemaObject, fieldName) =>
  Yup.number()
    .typeError(`${getFieldLabel(schemaObject, fieldName)} must be a valid number`)
    .min(0, `${getFieldLabel(schemaObject, fieldName)} must be 0 or greater`)
    .test(
      'valid-decimal',
      `${getFieldLabel(schemaObject, fieldName)} must be a valid number with up to two decimal places`,
      (value) => value === undefined || /^\d+(\.\d{1,2})?$/.test(String(value))
    );

const EMPLOYMENT_STATUSES_ALL = Object.values(EmploymentStatuses);
const EMPLOYMENT_STATUSES_ALL_EXCLUDE_UNEMPLOYED = [
  EmploymentStatuses.FULL_TIME,
  EmploymentStatuses.MILITARY,
  EmploymentStatuses.RETIRED,
  EmploymentStatuses.SELF_EMPLOYED,
];

const EMPLOYMENT_STATUSES_EXCLUDE_RETIRED_UNEMPLOYED = [
  EmploymentStatuses.FULL_TIME,
  EmploymentStatuses.MILITARY,
  EmploymentStatuses.SELF_EMPLOYED,
];

const EMPLOYMENT_STATUSES_FULL_TIME_OR_OTHER = [
  EmploymentStatuses.FULL_TIME,
  EmploymentStatuses.SELF_EMPLOYED,
];

const EMPLOYMENT_STATUSES_RETIRED_OR_MILITARY = [
  EmploymentStatuses.MILITARY,
  EmploymentStatuses.RETIRED,
];

const REQUIRED_IF_NOT_UNEMPLOYED = (schemaObject, field, defaultLabel, isPrevious = false) =>
  Yup.string().when(isPrevious ? 'previousEmploymentStatusCode' : 'employmentStatusCode', {
    is: (statusCode: unknown): statusCode is EmploymentStatus => {
      if (typeof statusCode !== 'string') return false;
      if (statusCode === 'Not Applicable') return false;
      return (Object.values(EmploymentStatuses) as string[]).includes(statusCode);
    },
    then: (schema) => schema.required(`${getSafeFieldLabel(schemaObject, field, defaultLabel)}`),
    otherwise: (schema) => schema,
  });

const REQUIRED_IF_MONTHS_ON_JOB_LESS_THAN_24 = (schema, field, schemaObject) =>
  schema.when('monthsOnJob', (monthsOnJob, schema) =>
    monthsOnJob > 0 && monthsOnJob <= 24
      ? schema.required(getSafeFieldLabel(schemaObject, field, 'Previous employment status'))
      : schema
  );

const REQUIRED_IF_HAS_EXTRA_INCOME = (fieldName, schemaObject, defaultLabel) =>
  Yup.string()
    .typeError(`${getSafeFieldLabel(schemaObject, fieldName, defaultLabel)} must be a string`)
    .test(
      'required-if-extra-income',
      getSafeFieldLabel(schemaObject, fieldName, defaultLabel),
      (value, context) => {
        return context.parent?.hasExtraIncome === 'Yes' ? !!value : true;
      }
    );
/**
 * Retrieves the label for a field from schemaObject.
 */
const getFieldLabel = (schemaObject, fieldName) => {
  return schemaObject?.buyer?.employmentDetails?.[fieldName]?.label;
};

const getSafeFieldLabel = (schemaObject, fieldName, defaultLabel) => {
  const label = getFieldLabel(schemaObject, fieldName);
  return typeof label === 'string' && label.trim().length > 0 ? label.trim() : defaultLabel;
};

const employmentCurrentSchemaFields = (schemaObject) => ({
  employmentStatusCode: Yup.string()
    .oneOf(EMPLOYMENT_STATUSES_ALL)
    .required(getSafeFieldLabel(schemaObject, 'employmentStatusCode', 'Employment status')),

  employerName: Yup.string()
    .typeError(
      `${getSafeFieldLabel(schemaObject, 'employerName', 'Employer name')} must be a string`
    )
    .test(
      'required-if-full-time-or-other',
      getSafeFieldLabel(schemaObject, 'employerName', 'Employer name'),
      (value, context) => {
        const status = context.parent.employmentStatusCode;
        return EMPLOYMENT_STATUSES_FULL_TIME_OR_OTHER.includes(status) ? !!value : true;
      }
    ),

  employerPhone: Yup.string()
    .typeError(
      `${getSafeFieldLabel(schemaObject, 'employerPhone', 'Employer phone')} must be a string`
    )
    .test(
      'required-if-employed-military-other',
      getSafeFieldLabel(schemaObject, 'employerPhone', 'Employer phone'),
      (value, context) => {
        const status = context.parent.employmentStatusCode;
        return EMPLOYMENT_STATUSES_EXCLUDE_RETIRED_UNEMPLOYED.includes(status) ? !!value : true;
      }
    ),

  employmentTitle: Yup.string()
    .typeError(
      `${getSafeFieldLabel(schemaObject, 'employmentTitle', 'Employment title')} must be a string`
    )
    .test(
      'required-if-employed-military-other',
      getSafeFieldLabel(schemaObject, 'employmentTitle', 'Employment title'),
      (value, context) => {
        const status = context.parent.employmentStatusCode;
        return EMPLOYMENT_STATUSES_EXCLUDE_RETIRED_UNEMPLOYED.includes(status) ? !!value : true;
      }
    ),

  incomeIntervalCode: REQUIRED_IF_NOT_UNEMPLOYED(
    schemaObject,
    'incomeIntervalCode',
    'Income interval'
  ),

  annualOrMonthly: Yup.string()
    .typeError(
      `${getSafeFieldLabel(schemaObject, 'annualOrMonthly', 'Annual or monthly')} must be a string`
    )
    .test(
      'required-if-salary',
      getSafeFieldLabel(schemaObject, 'annualOrMonthly', 'Annual or monthly'),
      (value, context) => {
        const incomeType = context.parent.incomeTypeCode;
        return incomeType === 'Salary' ? !!value : true;
      }
    )
    .test(
      'required-if-military-or-retired',
      getSafeFieldLabel(schemaObject, 'annualOrMonthly', 'Annual or monthly'),
      (value, context) => {
        const status = context.parent.employmentStatusCode;
        return EMPLOYMENT_STATUSES_RETIRED_OR_MILITARY.includes(status) ? !!value : true;
      }
    ),

  otherIncomeSourceDescription: REQUIRED_IF_HAS_EXTRA_INCOME(
    'otherIncomeSourceDescription',
    schemaObject,
    'Other income description'
  ),

  otherIncomeAmount: Yup.number()
    .typeError(
      `${getSafeFieldLabel(schemaObject, 'otherIncomeAmount', 'Other income amount')} must be a valid number`
    )
    .test(
      'valid-number',
      `${getSafeFieldLabel(schemaObject, 'otherIncomeAmount', 'Other income amount')} must be a valid number (1-22 digits)`,
      (value) => value === undefined || /^[0-9]{1,22}$/.test(String(value))
    )
    .test(
      'required-if-extra-income',
      getSafeFieldLabel(schemaObject, 'otherIncomeAmount', 'Other income amount'),
      (value, context) => (context.parent?.hasExtraIncome === 'Yes' ? !!value : true)
    ),
});

const employmentCurrentCustomFields = (schemaObject) => ({
  // this is on the schema, but overriding it to enforce validation rules within type constraints
  monthsOnJob: Yup.number()
    .typeError(
      `${getSafeFieldLabel(schemaObject, 'monthsOnJob', 'Months on job')} must be a valid number`
    )
    .min(
      0,
      `${getSafeFieldLabel(schemaObject, 'monthsOnJob', 'Months on job')} must be 0 or greater`
    )
    .test(
      'required-if-employed',
      getSafeFieldLabel(schemaObject, 'monthsOnJob', 'Months on job'),
      (value, context) => {
        const status = context.parent.employmentStatusCode;
        return EMPLOYMENT_STATUSES_ALL.includes(status) ? !!value : true;
      }
    ),

  // this is on the schema, but overriding it to enforce validation rules within type constraints
  incomeAmount: Yup.number()
    .typeError(
      `${getSafeFieldLabel(schemaObject, 'incomeAmount', 'Income amount')} must be a valid number`
    )
    .test(
      'valid-number',
      `${getSafeFieldLabel(schemaObject, 'incomeAmount', 'Income amount')} must be a valid number (1-22 digits)`,
      (value) => /^[0-9]{1,22}$/.test(String(value))
    )
    .test(
      'required-if-employed',
      getSafeFieldLabel(schemaObject, 'incomeAmount', 'Income amount'),
      (value, context) => {
        const status = context.parent.employmentStatusCode;
        return EMPLOYMENT_STATUSES_ALL_EXCLUDE_UNEMPLOYED.includes(status) ? !!value : true;
      }
    ),

  incomeTypeCode: Yup.string()
    .oneOf(
      ['Hourly', 'Salary'],
      `${getSafeFieldLabel(schemaObject, 'incomeTypeCode', 'Income type')} must be either Hourly or Salary`
    )
    .test(
      'required-if-full-time-or-other',
      getSafeFieldLabel(schemaObject, 'incomeTypeCode', 'Income type'),
      (value, context) => {
        const status = context.parent.employmentStatusCode;
        return EMPLOYMENT_STATUSES_FULL_TIME_OR_OTHER.includes(status) ? !!value : true;
      }
    ),

  employerStartMonth: Yup.number()
    .typeError(
      `${getSafeFieldLabel(schemaObject, 'employerStartMonth', 'Employer start month')} must be a valid number`
    )
    .min(
      1,
      `${getSafeFieldLabel(schemaObject, 'employerStartMonth', 'Employer start month')} must be between 1 and 12`
    )
    .max(
      12,
      `${getSafeFieldLabel(schemaObject, 'employerStartMonth', 'Employer start month')} must be between 1 and 12`
    )
    .test(
      'required-if-employed',
      getSafeFieldLabel(schemaObject, 'employerStartMonth', 'Employer start month'),
      (value, context) => {
        const status = context.parent.employmentStatusCode;
        return EMPLOYMENT_STATUSES_ALL.includes(status) ? !!value : true;
      }
    )
    .test('valid-month', 'Future months are not allowed', (value, context) => {
      if (!value) return true;
      const currentDate = new Date();
      const selectedYear = context.parent.employerStartYear;
      return selectedYear < currentDate.getFullYear() || value <= currentDate.getMonth() + 1;
    }),

  employerStartYear: Yup.number()
    .typeError(
      `${getSafeFieldLabel(schemaObject, 'employerStartYear', 'Employer start year')} must be a valid number`
    )
    .min(
      1900,
      `${getSafeFieldLabel(schemaObject, 'employerStartYear', 'Employer start year')} must be a valid year`
    )
    .max(
      new Date().getFullYear(),
      `${getSafeFieldLabel(schemaObject, 'employerStartYear', 'Employer start year')} cannot be in the future`
    )
    .test(
      'required-if-employed',
      getSafeFieldLabel(schemaObject, 'employerStartYear', 'Employer start year'),
      (value, context) => {
        const status = context.parent.employmentStatusCode;
        return EMPLOYMENT_STATUSES_ALL.includes(status) ? !!value : true;
      }
    )
    .test('valid-year', 'Future years are not allowed', (value, context) => {
      if (!value) return true;
      const currentDate = new Date();
      const selectedMonth = context.parent.employerStartMonth;
      return value < currentDate.getFullYear() || selectedMonth <= currentDate.getMonth() + 1;
    }),

  hourlyPayRate: VALID_DECIMAL(schemaObject, 'hourlyPayRate').test(
    'required-if-hourly',
    getSafeFieldLabel(schemaObject, 'hourlyPayRate', 'Hourly pay rate'),
    (value, context) => (context.parent.incomeTypeCode === 'Hourly' ? !!value : true)
  ),

  avgWeeklyHours: VALID_DECIMAL(schemaObject, 'avgWeeklyHours')
    .max(
      168,
      `${getSafeFieldLabel(schemaObject, 'avgWeeklyHours', 'Average weekly hours')} cannot exceed 168`
    )
    .test(
      'required-if-hourly',
      getSafeFieldLabel(schemaObject, 'avgWeeklyHours', 'Average weekly hours'),
      (value, context) => (context.parent.incomeTypeCode === 'Hourly' ? !!value : true)
    ),

  monthlyBonusPay: VALID_DECIMAL(schemaObject, 'monthlyBonusPay').test(
    'required-if-bonus',
    getSafeFieldLabel(schemaObject, 'monthlyBonusPay', 'Monthly bonus pay'),
    (value, context) => (context.parent.hasBonusPay === 'Yes' ? !!value : true)
  ),

  annualIncome: VALID_DECIMAL(schemaObject, 'annualIncome').test(
    'required-if-annual',
    getSafeFieldLabel(schemaObject, 'annualIncome', 'Annual income'),
    (value, context) => (context.parent.annualOrMonthly === 'Annual' ? !!value : true)
  ),

  monthlyIncome: VALID_DECIMAL(schemaObject, 'monthlyIncome').test(
    'required-if-monthly',
    getSafeFieldLabel(schemaObject, 'monthlyIncome', 'Monthly income'),
    (value, context) => (context.parent.annualOrMonthly === 'Monthly' ? !!value : true)
  ),

  hasExtraIncome: Yup.string()
    .oneOf(['Yes', 'No'])
    .required(getSafeFieldLabel(schemaObject, 'hasExtraIncome', 'Extra income')),
});

const employmentPreviousSchemaFields = (schemaObject) => ({
  previousEmploymentStatusCode: Yup.string()
    .oneOf(EMPLOYMENT_STATUSES_ALL)
    .required(
      getSafeFieldLabel(schemaObject, 'previousEmploymentStatusCode', 'Previous employment status')
    )
    .concat(
      REQUIRED_IF_MONTHS_ON_JOB_LESS_THAN_24(
        Yup.string().oneOf(EMPLOYMENT_STATUSES_ALL),
        'previousEmploymentStatusCode',
        schemaObject
      )
    )
    .when('employmentStatusCode', {
      is: EmploymentStatuses.NOT_APPLICABLE,
      then: (schema) =>
        schema.required(
          getSafeFieldLabel(
            schemaObject,
            'previousEmploymentStatusCode',
            'Previous employment status'
          )
        ),
    }),

  previousEmployerName: Yup.string()
    .typeError(
      `${getSafeFieldLabel(schemaObject, 'previousEmployerName', 'Previous employer name')} must be a string`
    )
    .test(
      'required-if-full-time-or-other',
      getSafeFieldLabel(schemaObject, 'previousEmployerName', 'Previous employer name'),
      (value, context) => {
        const status = context.parent.previousEmploymentStatusCode;
        return EMPLOYMENT_STATUSES_FULL_TIME_OR_OTHER.includes(status) ? !!value : true;
      }
    ),

  previousEmployerPhone: Yup.string()
    .typeError(
      `${getSafeFieldLabel(schemaObject, 'previousEmployerPhone', 'Previous employer phone')} must be a string`
    )
    .test(
      'required-if-employed-military-other',
      getSafeFieldLabel(schemaObject, 'previousEmployerPhone', 'Previous employer phone'),
      (value, context) => {
        const status = context.parent.previousEmploymentStatusCode;
        return EMPLOYMENT_STATUSES_EXCLUDE_RETIRED_UNEMPLOYED.includes(status) ? !!value : true;
      }
    ),

  previousEmploymentTitle: Yup.string()
    .typeError(
      `${getSafeFieldLabel(schemaObject, 'previousEmploymentTitle', 'Previous employer title')} must be a string`
    )
    .test(
      'required-if-employed-military-other',
      getSafeFieldLabel(schemaObject, 'previousEmploymentTitle', 'Previous employer title'),
      (value, context) => {
        const status = context.parent.previousEmploymentStatusCode;
        return EMPLOYMENT_STATUSES_EXCLUDE_RETIRED_UNEMPLOYED.includes(status) ? !!value : true;
      }
    ),

  previousIncomeIntervalCode: REQUIRED_IF_NOT_UNEMPLOYED(
    schemaObject,
    'previousIncomeIntervalCode',
    'Previous income interval',
    true
  ),

  previousAnnualOrMonthly: Yup.string()
    .typeError(
      `${getSafeFieldLabel(schemaObject, 'previousAnnualOrMonthly', 'Previous annual or monthly')} must be a string`
    )
    .test(
      'required-if-salary',
      getSafeFieldLabel(schemaObject, 'previousAnnualOrMonthly', 'Previous annual or monthly'),
      (value, context) => {
        const previousIncomeType = context.parent.previousIncomeTypeCode;
        return previousIncomeType === 'Salary' ? !!value : true;
      }
    )
    .test(
      'required-if-military-or-retired',
      getSafeFieldLabel(schemaObject, 'previousAnnualOrMonthly', 'Previous annual or monthly'),
      (value, context) => {
        const status = context.parent.previousEmploymentStatusCode;
        return EMPLOYMENT_STATUSES_RETIRED_OR_MILITARY.includes(status) ? !!value : true;
      }
    ),
});

const employmentPreviousCustomFields = (schemaObject) => ({
  // this is on the schema, but overriding it to enforce validation rules within type constraints
  previousMonthsOnJob: Yup.number()
    .typeError(
      `${getSafeFieldLabel(schemaObject, 'previousMonthsOnJob', 'Previous months on job')} must be a valid number`
    )
    .min(
      0,
      `${getSafeFieldLabel(schemaObject, 'previousMonthsOnJob', 'Previous months on job')} must be 0 or greater`
    )
    .test(
      'required-if-previously-employed',
      getSafeFieldLabel(schemaObject, 'previousMonthsOnJob', 'Previous months on job'),
      (value, context) => {
        const status = context.parent.previousEmploymentStatusCode;
        return EMPLOYMENT_STATUSES_ALL.includes(status) ? !!value : true;
      }
    ),

  previousIncomeAmount: Yup.number()
    .typeError(
      `${getSafeFieldLabel(schemaObject, 'previousIncomeAmount', 'Previous income amount')} must be a valid number`
    )
    .test(
      'valid-number',
      `${getSafeFieldLabel(schemaObject, 'previousIncomeAmount', 'Previous income amount')} must be a valid number (1-22 digits)`,
      (value) => /^[0-9]{1,22}$/.test(String(value))
    )
    .test(
      'required-if-previously-employed',
      getSafeFieldLabel(schemaObject, 'previousIncomeAmount', 'Previous income amount'),
      (value, context) => {
        const status = context.parent.previousEmploymentStatusCode;
        return EMPLOYMENT_STATUSES_ALL_EXCLUDE_UNEMPLOYED.includes(status) ? !!value : true;
      }
    ),

  previousIncomeTypeCode: Yup.string()
    .oneOf(
      ['Hourly', 'Salary'],
      `${getSafeFieldLabel(schemaObject, 'previousIncomeTypeCode', 'Previous income type')} must be either Hourly or Salary`
    )
    .test(
      'required-if-full-time-or-other',
      getSafeFieldLabel(schemaObject, 'previousIncomeTypeCode', 'Previous income type'),
      (value, context) => {
        const status = context.parent.previousEmploymentStatusCode;
        return EMPLOYMENT_STATUSES_FULL_TIME_OR_OTHER.includes(status) ? !!value : true;
      }
    ),

  previousEmployerStartMonth: Yup.number()
    .typeError(
      `${getSafeFieldLabel(schemaObject, 'previousEmployerStartMonth', 'Previous employer start month')} must be a valid number`
    )
    .min(
      1,
      `${getSafeFieldLabel(schemaObject, 'previousEmployerStartMonth', 'Previous employer start month')} must be between 1 and 12`
    )
    .max(
      12,
      `${getSafeFieldLabel(schemaObject, 'previousEmployerStartMonth', 'Previous employer start month')} must be between 1 and 12`
    )
    .test(
      'required-if-previously-employed',
      getSafeFieldLabel(
        schemaObject,
        'previousEmployerStartMonth',
        'Previous employer start month'
      ),
      (value, context) => {
        const status = context.parent.previousEmploymentStatusCode;
        return EMPLOYMENT_STATUSES_ALL.includes(status) ? !!value : true;
      }
    )
    .test('valid-month', 'Future months are not allowed', (value, context) => {
      if (!value) return true;
      const currentDate = new Date();
      const selectedYear = context.parent.previousEmployerStartYear;
      return selectedYear < currentDate.getFullYear() || value <= currentDate.getMonth() + 1;
    }),

  previousEmployerStartYear: Yup.number()
    .typeError(
      `${getSafeFieldLabel(schemaObject, 'previousEmployerStartYear', 'Previous employer start year')} must be a valid number`
    )
    .min(
      1900,
      `${getSafeFieldLabel(schemaObject, 'previousEmployerStartYear', 'Previous employer start year')} must be a valid year`
    )
    .max(
      new Date().getFullYear(),
      `${getSafeFieldLabel(schemaObject, 'previousEmployerStartYear', 'Previous employer start year')} cannot be in the future`
    )
    .test(
      'required-if-previously-employed',
      getSafeFieldLabel(schemaObject, 'previousEmployerStartYear', 'Previous employer start year'),
      (value, context) => {
        const status = context.parent.previousEmploymentStatusCode;
        return EMPLOYMENT_STATUSES_ALL.includes(status) ? !!value : true;
      }
    )
    .test('valid-year', 'Future years are not allowed', (value, context) => {
      if (!value) return true;
      const currentDate = new Date();
      const selectedMonth = context.parent.previousEmployerStartMonth;
      return value < currentDate.getFullYear() || selectedMonth <= currentDate.getMonth() + 1;
    }),

  previousHourlyPayRate: VALID_DECIMAL(schemaObject, 'previousHourlyPayRate').test(
    'required-if-hourly',
    getSafeFieldLabel(schemaObject, 'previousHourlyPayRate', 'Previous hourly pay rate'),
    (value, context) => (context.parent.previousIncomeTypeCode === 'Hourly' ? !!value : true)
  ),

  previousAvgWeeklyHours: VALID_DECIMAL(schemaObject, 'previousAvgWeeklyHours')
    .max(
      168,
      `${getSafeFieldLabel(schemaObject, 'previousAvgWeeklyHours', 'Previous average weekly hours')} cannot exceed 168`
    )
    .test(
      'required-if-hourly',
      getSafeFieldLabel(schemaObject, 'previousAvgWeeklyHours', 'Previous average weekly hours'),
      (value, context) => (context.parent.previousIncomeTypeCode === 'Hourly' ? !!value : true)
    ),

  previousMonthlyBonusPay: VALID_DECIMAL(schemaObject, 'previousMonthlyBonusPay').test(
    'required-if-bonus',
    getSafeFieldLabel(schemaObject, 'previousMonthlyBonusPay', 'Previous monthly bonus pay'),
    (value, context) => (context.parent.previousHasBonusPay === 'Yes' ? !!value : true)
  ),

  previousAnnualIncome: VALID_DECIMAL(schemaObject, 'previousAnnualIncome').test(
    'required-if-annual',
    getSafeFieldLabel(schemaObject, 'previousAnnualIncome', 'Previous annual income'),
    (value, context) => (context.parent.previousAnnualOrMonthly === 'Annual' ? !!value : true)
  ),

  previousMonthlyIncome: VALID_DECIMAL(schemaObject, 'previousMonthlyIncome').test(
    'required-if-monthly',
    getSafeFieldLabel(schemaObject, 'previousMonthlyIncome', 'Previous monthly income'),
    (value, context) => (context.parent.previousAnnualOrMonthly === 'Monthly' ? !!value : true)
  ),
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "logic-selector-label" }
const _hoisted_2 = {
  key: 0,
  class: "error-text"
}
const _hoisted_3 = {
  key: 0,
  id: "employed-section",
  class: "employed-section mt-6"
}
const _hoisted_4 = {
  key: 0,
  id: "pay-type-toggle",
  class: "mb-8"
}
const _hoisted_5 = { class: "logic-selector-label" }
const _hoisted_6 = {
  key: 0,
  class: "error-text"
}
const _hoisted_7 = {
  key: 1,
  id: "hourly-section",
  class: "hourly-section"
}
const _hoisted_8 = { class: "mb-4" }
const _hoisted_9 = {
  key: 2,
  id: "salary-section",
  class: "salary-section"
}
const _hoisted_10 = { class: "logic-selector-label" }
const _hoisted_11 = {
  key: 0,
  class: "error-text"
}
const _hoisted_12 = {
  key: 3,
  id: "bonus-pay"
}
const _hoisted_13 = {
  key: 0,
  class: "my-4"
}
const _hoisted_14 = {
  key: 1,
  class: "my-4"
}
const _hoisted_15 = {
  key: 2,
  class: "my-4"
}
const _hoisted_16 = {
  key: 4,
  id: "total-income",
  class: "total-income"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormHeader = _resolveComponent("FormHeader")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_btn_toggle = _resolveComponent("v-btn-toggle")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_radio = _resolveComponent("v-radio")!
  const _component_v_radio_group = _resolveComponent("v-radio-group")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(
      _ctx.isPrevious ? 'previous-employment employment-status-section' : 'employment-status-section'
    )
    }, [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_FormHeader, { title: _ctx.getEmploymentTitle }, null, 8, ["title"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "12",
            class: "toggle-buttons"
          }, {
            default: _withCtx(() => [
              _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.fieldsMap.get(_ctx.coPrefix + 'employment.primary-job-income.Label')?.value), 1),
              _createVNode(_component_v_btn_toggle, {
                modelValue: _ctx.selectedEmploymentStatusCode,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedEmploymentStatusCode) = $event)),
                class: "logic-selector",
                mandatory: ""
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.isPrevious
              ? _ctx.employmentStatusMapperPrevious
              : _ctx.employmentStatusMapper, (value, key, index) => {
                    return (_openBlock(), _createBlock(_component_v_btn, {
                      key: key,
                      value: value,
                      disabled: _ctx.selectedEmploymentStatusCode === value,
                      "data-testid": _ctx.getDataTestId(`employmentStatusCode-${index}`),
                      onClick: ($event: any) => (_ctx.handleToggle('employmentType', value))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(key), 1)
                      ]),
                      _: 2
                    }, 1032, ["value", "disabled", "data-testid", "onClick"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue"]),
              (_ctx.errorMessages('employmentStatusCode', 'Select an employment status.').length)
                ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.errorMessages('employmentStatusCode', 'Select an employment status.')[0]), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ], 2),
    (
      _ctx.showEmployerName || _ctx.showEmployerPhone || _ctx.showEmploymentTitle || _ctx.employmentStatusCodeSelected
    )
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              (_ctx.showEmployerName)
                ? (_openBlock(), _createBlock(_component_v_col, {
                    key: 0,
                    cols: "12",
                    lg: "6",
                    class: "py-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        modelValue: _ctx.localEmploymentDetails[_ctx.getFieldKey('employerName')],
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localEmploymentDetails[_ctx.getFieldKey('employerName')]) = $event)),
                        label: _ctx.buyerEmploymentSchema?.[_ctx.getFieldKey('employerName')]?.label,
                        "data-testid": `sca-${_ctx.getFieldKey('employerName')}`,
                        "error-messages": _ctx.errorMessages('employerName', 'Enter a name.')
                      }, null, 8, ["modelValue", "label", "data-testid", "error-messages"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.showEmployerPhone)
                ? (_openBlock(), _createBlock(_component_v_col, {
                    key: 1,
                    cols: "12",
                    lg: _ctx.showEmployerName ? 6 : 3,
                    class: "py-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        modelValue: _ctx.formattedEmployerPhone,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formattedEmployerPhone) = $event)),
                        label: _ctx.buyerEmploymentSchema?.[_ctx.getFieldKey('employerPhone')]?.label,
                        maxlength: 14,
                        type: "tel",
                        "data-testid": `sca-${_ctx.getFieldKey('employerPhone')}`,
                        "error-messages": _ctx.errorMessages('employerPhone', 'Enter a valid 10-digit phone number.'),
                        onKeydown: _ctx.onlyAllowNumbers,
                        onPaste: _withModifiers(_ctx.handlePaste, ["prevent"])
                      }, null, 8, ["modelValue", "label", "data-testid", "error-messages", "onKeydown", "onPaste"])
                    ]),
                    _: 1
                  }, 8, ["lg"]))
                : _createCommentVNode("", true),
              (_ctx.showEmploymentTitle && !_ctx.showEmployerName)
                ? (_openBlock(), _createBlock(_component_v_col, {
                    key: 2,
                    cols: "12",
                    lg: "6",
                    class: "py-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        modelValue: _ctx.localEmploymentDetails[_ctx.getFieldKey('employmentTitle')],
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localEmploymentDetails[_ctx.getFieldKey('employmentTitle')]) = $event)),
                        label: _ctx.buyerEmploymentSchema?.[_ctx.getFieldKey('employmentTitle')]?.label,
                        "data-testid": `sca-${_ctx.getFieldKey('employmentTitle')}-1`,
                        "error-messages": _ctx.errorMessages('employmentTitle', 'Enter a title.')
                      }, null, 8, ["modelValue", "label", "data-testid", "error-messages"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              (_ctx.showEmploymentTitle && _ctx.showEmployerName)
                ? (_openBlock(), _createBlock(_component_v_col, {
                    key: 0,
                    cols: "12",
                    lg: "6",
                    class: "py-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        modelValue: _ctx.localEmploymentDetails[_ctx.getFieldKey('employmentTitle')],
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localEmploymentDetails[_ctx.getFieldKey('employmentTitle')]) = $event)),
                        label: _ctx.buyerEmploymentSchema?.[_ctx.getFieldKey('employmentTitle')]?.label,
                        "data-testid": `sca-${_ctx.getFieldKey('employmentTitle')}`,
                        "error-messages": _ctx.errorMessages('employmentTitle', 'Enter a title.'),
                        maxlength: 20
                      }, null, 8, ["modelValue", "label", "data-testid", "error-messages"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.employmentStatusCodeSelected)
                ? (_openBlock(), _createBlock(_component_v_col, {
                    key: 1,
                    cols: "6",
                    lg: "3",
                    class: "py-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_select, {
                        label: _ctx.fieldsMap.get(_ctx.coPrefix + 'employment.employment-start-month.Label')?.value,
                        modelValue: _ctx.localEmploymentDetails[_ctx.getFieldKey('employerStartMonth')],
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.localEmploymentDetails[_ctx.getFieldKey('employerStartMonth')]) = $event)),
                        items: _ctx.validMonthOptions,
                        "item-title": "text",
                        "item-value": "value",
                        "data-testid": `sca-${_ctx.getFieldKey('employerStartMonth')}`,
                        "error-messages": _ctx.errorMessages('employerStartMonth', 'Select a month.')
                      }, null, 8, ["label", "modelValue", "items", "data-testid", "error-messages"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.employmentStatusCodeSelected)
                ? (_openBlock(), _createBlock(_component_v_col, {
                    key: 2,
                    cols: "6",
                    lg: "3",
                    class: "py-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_select, {
                        label: _ctx.fieldsMap.get(_ctx.coPrefix + 'employment.employment-start-year.Label')?.value,
                        modelValue: _ctx.localEmploymentDetails[_ctx.getFieldKey('employerStartYear')],
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.localEmploymentDetails[_ctx.getFieldKey('employerStartYear')]) = $event)),
                        items: _ctx.validYearOptions,
                        variant: "underlined",
                        "data-testid": `sca-${_ctx.getFieldKey('employerStartYear')}`,
                        "error-messages": _ctx.errorMessages('employerStartYear', 'Select a year.')
                      }, null, 8, ["label", "modelValue", "items", "data-testid", "error-messages"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.showIncome)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(_ctx.isPrevious ? 'previous-employment pay-section' : 'pay-section')
        }, [
          (_ctx.showEmployerName)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "12",
                      class: "toggle-buttons"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.fieldsMap.get(_ctx.coPrefix + 'employment.incomeIntervalCode.Label')?.value), 1),
                        _createVNode(_component_v_btn_toggle, {
                          modelValue: _ctx.localEmploymentDetails[_ctx.getFieldKey('incomeTypeCode')],
                          "onUpdate:modelValue": [
                            _cache[9] || (_cache[9] = ($event: any) => ((_ctx.localEmploymentDetails[_ctx.getFieldKey('incomeTypeCode')]) = $event)),
                            _ctx.updateIncomeType
                          ],
                          class: "logic-selector mt-1",
                          mandatory: ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_btn, {
                              value: _ctx.fieldsMap.get(_ctx.coPrefix + 'employment.incomeIntervalCode.hourly.Label')?.value,
                              disabled: 
                _ctx.isSelectedIncomeType(
                  _ctx.fieldsMap.get(_ctx.coPrefix + 'employment.incomeIntervalCode.hourly.Label')?.value
                )
              ,
                              onClick: _cache[7] || (_cache[7] = ($event: any) => (
                _ctx.handleToggle(
                  'incomeType',
                  _ctx.fieldsMap.get(_ctx.coPrefix + 'employment.incomeIntervalCode.hourly.Label')?.value
                )
              )),
                              "data-testid": 
                _ctx.getToggleTypeTestId(
                  'incomeTypeCode',
                  _ctx.fieldsMap.get(_ctx.coPrefix + 'employment.incomeIntervalCode.hourly.Label')?.value
                )
              
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.fieldsMap.get(_ctx.coPrefix + 'employment.incomeIntervalCode.hourly.Label')?.value), 1)
                              ]),
                              _: 1
                            }, 8, ["value", "disabled", "data-testid"]),
                            _createVNode(_component_v_btn, {
                              value: _ctx.fieldsMap.get(_ctx.coPrefix + 'employment.incomeIntervalCode.salary.Label')?.value,
                              disabled: 
                _ctx.isSelectedIncomeType(
                  _ctx.fieldsMap.get(_ctx.coPrefix + 'employment.incomeIntervalCode.salary.Label')?.value
                )
              ,
                              onClick: _cache[8] || (_cache[8] = ($event: any) => (
                _ctx.handleToggle(
                  'incomeType',
                  _ctx.fieldsMap.get(_ctx.coPrefix + 'employment.incomeIntervalCode.salary.Label')?.value
                )
              )),
                              "data-testid": 
                _ctx.getToggleTypeTestId(
                  'incomeTypeCode',
                  _ctx.fieldsMap.get(_ctx.coPrefix + 'employment.incomeIntervalCode.salary.Label')?.value
                )
              
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.fieldsMap.get(_ctx.coPrefix + 'employment.incomeIntervalCode.salary.Label')?.value), 1)
                              ]),
                              _: 1
                            }, 8, ["value", "disabled", "data-testid"])
                          ]),
                          _: 1
                        }, 8, ["modelValue", "onUpdate:modelValue"]),
                        (_ctx.hasError(_ctx.getFieldKey('incomeTypeCode')))
                          ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.errorMessages('incomeTypeCode', 'Select a pay type.')[0]), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          (_ctx.isHourlyWithEmployer)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.fieldsMap.get(_ctx.coPrefix + 'employment.hourly-pay-notes.fieldNotes')?.value), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      lg: "6",
                      xl: "4",
                      class: "py-0"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          label: _ctx.fieldsMap.get(_ctx.coPrefix + 'employment.hourly-pay-rate.Label')?.value,
                          modelValue: _ctx.localEmploymentDetails[_ctx.getFieldKey('hourlyPayRateString')],
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.localEmploymentDetails[_ctx.getFieldKey('hourlyPayRateString')]) = $event)),
                          onInput: _ctx.preventNegativeValues,
                          maxlength: "14",
                          type: "number",
                          "error-messages": _ctx.errorMessages('hourlyPayRate', 'Enter a pay rate.'),
                          "data-testid": _ctx.getDataTestId('hourlyPayRate')
                        }, null, 8, ["label", "modelValue", "onInput", "error-messages", "data-testid"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      lg: "6",
                      xl: "4",
                      class: "py-0"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          label: _ctx.fieldsMap.get(_ctx.coPrefix + 'employment.average-weekly-hours.Label')?.value,
                          modelValue: _ctx.localEmploymentDetails[_ctx.getFieldKey('avgWeeklyHoursString')],
                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.localEmploymentDetails[_ctx.getFieldKey('avgWeeklyHoursString')]) = $event)),
                          onInput: _ctx.preventNegativeValues,
                          maxlength: "14",
                          type: "number",
                          "error-messages": _ctx.errorMessages('avgWeeklyHours', 'Enter a number of hours.'),
                          "data-testid": _ctx.getDataTestId('avgWeeklyHours')
                        }, null, 8, ["label", "modelValue", "onInput", "error-messages", "data-testid"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          (_ctx.shouldShowSalarySection)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "12",
                      class: "toggle-buttons"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.fieldsMap.get(_ctx.coPrefix + 'employment.income-frequency.Label')?.value), 1),
                        _createVNode(_component_v_btn_toggle, {
                          modelValue: _ctx.localEmploymentDetails[_ctx.getFieldKey('annualOrMonthly')],
                          "onUpdate:modelValue": [
                            _cache[14] || (_cache[14] = ($event: any) => ((_ctx.localEmploymentDetails[_ctx.getFieldKey('annualOrMonthly')]) = $event)),
                            _ctx.updateIncomeInterval
                          ],
                          class: "logic-selector mt-1",
                          mandatory: ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_btn, {
                              value: _ctx.fieldsMap.get(_ctx.coPrefix + 'employment.income-frequency.annual.Label')?.value,
                              onClick: _cache[12] || (_cache[12] = ($event: any) => (
                _ctx.handleToggle(
                  'salaryType',
                  _ctx.fieldsMap.get(_ctx.coPrefix + 'employment.income-frequency.annual.Label')?.value
                )
              )),
                              disabled: 
                _ctx.localEmploymentDetails[_ctx.getFieldKey('incomeIntervalCode')] ===
                _ctx.fieldsMap.get(_ctx.coPrefix + 'employment.income-frequency.annual.Label')?.value
              ,
                              "data-testid": `sca-${_ctx.getFieldKey('incomeIntervalCode')}-${_ctx.fieldsMap.get(_ctx.coPrefix + 'employment.income-frequency.annual.Label')?.value.toLowerCase()}`
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.fieldsMap.get(_ctx.coPrefix + 'employment.income-frequency.annual.Label')?.value), 1)
                              ]),
                              _: 1
                            }, 8, ["value", "disabled", "data-testid"]),
                            _createVNode(_component_v_btn, {
                              value: _ctx.fieldsMap.get(_ctx.coPrefix + 'employment.income-frequency.monthly.Label')?.value,
                              onClick: _cache[13] || (_cache[13] = ($event: any) => (
                _ctx.handleToggle(
                  'salaryType',
                  _ctx.fieldsMap.get(_ctx.coPrefix + 'employment.income-frequency.monthly.Label')?.value
                )
              )),
                              disabled: 
                _ctx.localEmploymentDetails[_ctx.getFieldKey('incomeIntervalCode')] ===
                _ctx.fieldsMap.get(_ctx.coPrefix + 'employment.income-frequency.monthly.Label')?.value
              ,
                              "data-testid": `sca-${_ctx.getFieldKey('incomeIntervalCode')}-${_ctx.fieldsMap.get(_ctx.coPrefix + 'employment.income-frequency.monthly.Label')?.value.toLowerCase()}`
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.fieldsMap.get(_ctx.coPrefix + 'employment.income-frequency.monthly.Label')?.value), 1)
                              ]),
                              _: 1
                            }, 8, ["value", "disabled", "data-testid"])
                          ]),
                          _: 1
                        }, 8, ["modelValue", "onUpdate:modelValue"]),
                        (_ctx.hasError(_ctx.getFieldKey('annualOrMonthly')))
                          ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(_ctx.errorMessages('annualOrMonthly', 'Select an option.')[0]), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    (_ctx.isAnnualIncome)
                      ? (_openBlock(), _createBlock(_component_v_col, {
                          key: 0,
                          cols: "12",
                          lg: "6",
                          class: "py-0 mt-3"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_text_field, {
                              label: _ctx.fieldsMap.get(_ctx.coPrefix + 'employment.annualIncomeAmount.Label')?.value,
                              modelValue: _ctx.localEmploymentDetails[_ctx.getFieldKey('annualIncomeString')],
                              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.localEmploymentDetails[_ctx.getFieldKey('annualIncomeString')]) = $event)),
                              name: "annualIncome",
                              onInput: _ctx.preventNegativeValues,
                              maxlength: "14",
                              type: "number",
                              "error-messages": _ctx.errorMessages('annualIncome', 'Enter annual income.'),
                              "data-testid": `sca-${_ctx.getFieldKey('annualIncome')}`
                            }, null, 8, ["label", "modelValue", "onInput", "error-messages", "data-testid"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.isMonthlyIncome)
                      ? (_openBlock(), _createBlock(_component_v_col, {
                          key: 1,
                          cols: "12",
                          lg: "6",
                          class: "py-0 mt-3"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_text_field, {
                              label: _ctx.fieldsMap.get(_ctx.coPrefix + 'employment.monthlyIncomeAmount.Label')?.value,
                              modelValue: _ctx.localEmploymentDetails[_ctx.getFieldKey('monthlyIncomeString')],
                              "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.localEmploymentDetails[_ctx.getFieldKey('monthlyIncomeString')]) = $event)),
                              name: "monthlyIncome",
                              onInput: _ctx.preventNegativeValues,
                              maxlength: "14",
                              type: "number",
                              "error-messages": _ctx.errorMessages('monthlyIncome', 'Enter monthly amount.'),
                              "data-testid": `sca-${_ctx.getFieldKey('monthlyIncome')}`
                            }, null, 8, ["label", "modelValue", "onInput", "error-messages", "data-testid"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          (_ctx.shouldShowBonusPay)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                (_ctx.isHourlyWithEmployer)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(_ctx.fieldsMap.get(_ctx.coPrefix + 'employment.hourly-bonus-tips-commission.Label')?.value), 1))
                  : (_ctx.isAnnualPay)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_14, _toDisplayString(_ctx.fieldsMap.get(_ctx.coPrefix + 'employment.annual-bonus-tips-commission.Label')?.value), 1))
                    : (_ctx.isMonthlyPay)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_15, _toDisplayString(_ctx.fieldsMap.get(_ctx.coPrefix + 'employment.monthly-bonus-tips-commission.Label')?.value), 1))
                      : _createCommentVNode("", true),
                (_ctx.shouldShowBonusPayRadio)
                  ? (_openBlock(), _createBlock(_component_v_radio_group, {
                      key: 3,
                      modelValue: _ctx.localEmploymentDetails[_ctx.getFieldKey('hasBonusPay')],
                      "onUpdate:modelValue": [
                        _cache[17] || (_cache[17] = ($event: any) => ((_ctx.localEmploymentDetails[_ctx.getFieldKey('hasBonusPay')]) = $event)),
                        _cache[18] || (_cache[18] = (selectedValue) => _ctx.handleToggle('bonusPay', selectedValue))
                      ],
                      "hide-details": "auto"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_radio, {
                          value: "No",
                          label: 
            _ctx.fieldsMap.get(_ctx.coPrefix + 'employment.annual-bonus-tips-commission.no.Label')?.value
          ,
                          "prepend-inner-icon": "mdi-lock",
                          "data-testid": `sca-${_ctx.getFieldKey('hasBonusPay')}-no`
                        }, null, 8, ["label", "data-testid"]),
                        _createVNode(_component_v_radio, {
                          value: "Yes",
                          label: 
            _ctx.fieldsMap.get(_ctx.coPrefix + 'employment.annual-bonus-tips-commission.yes.Label')?.value
          ,
                          "prepend-inner-icon": "mdi-lock",
                          "data-testid": `sca-${_ctx.getFieldKey('hasBonusPay')}-yes`
                        }, null, 8, ["label", "data-testid"])
                      ]),
                      _: 1
                    }, 8, ["modelValue"]))
                  : _createCommentVNode("", true),
                (_ctx.localEmploymentDetails[_ctx.getFieldKey('hasBonusPay')] === 'Yes')
                  ? (_openBlock(), _createBlock(_component_v_row, { key: 4 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: "12",
                          lg: "6"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_text_field, {
                              label: _ctx.fieldsMap.get(_ctx.coPrefix + 'employment.additional-pay-amount.Label')?.value,
                              modelValue: _ctx.localEmploymentDetails[_ctx.getFieldKey('monthlyBonusPayString')],
                              "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.localEmploymentDetails[_ctx.getFieldKey('monthlyBonusPayString')]) = $event)),
                              onInput: _ctx.preventNegativeValues,
                              maxlength: "14",
                              type: "number",
                              "error-messages": _ctx.errorMessages('monthlyBonusPay', 'Enter a monthly amount.'),
                              "data-testid": `sca-${_ctx.getFieldKey('monthlyBonusPay')}`
                            }, null, 8, ["label", "modelValue", "onInput", "error-messages", "data-testid"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (_ctx.totalIncome && _ctx.showTotalIncome)
            ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                _createTextVNode(" Total income (pre-tax) = "),
                _createElementVNode("strong", null, _toDisplayString(_ctx.totalIncome), 1)
              ]))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true)
  ], 64))
}
<template>
  <EmploymentDetailsFormContainer :fields="fields" ref="formComponent" :isCobuyer="true" />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { useStandaloneCreditAppStore } from '@/stores/standaloneCreditApp';
import { mapActions } from 'pinia';
import { accumulateChildFields } from '@/util/schema/schemaValidatorUtils';
import EmploymentDetailsFormContainer from './Forms/EmploymentDetailsFormContainer.vue';
import standaloneCreditAppFormStep from '@/mixins/standaloneCreditAppFormStep';
import { FieldsPropType } from '@/lib/FieldTypes';

export default defineComponent({
  name: 'StandaloneCreditApp--CoApplicantEmployment',
  data() {
    return {};
  },
  mixins: [standaloneCreditAppFormStep],
  props: {
    fields: {
      type: Object as PropType<FieldsPropType>,
      default: () => ({}),
    },
  },
  components: {
    EmploymentDetailsFormContainer,
  },
  methods: {
    ...mapActions(useStandaloneCreditAppStore, ['generateValidation']),
    getSubmitHandler(): () => Promise<boolean> {
      return (this.$refs.formComponent as any).submitHandler;
    },
  },
  created() {
    this.generateValidation(accumulateChildFields(this.fields), {
      formName: this.fields?.['FormName']?.value,
    });
  },
});
</script>
<style lang="scss">
@use '@/assets/styles/variables' as *;

.sca-employment {
  .sca-employment-cta-title {
    font-size: $font-size-sm;
  }

  &:not(.v-btn--icon) .v-btn__content {
    font-size: $font-size-xxxs;
  }
}
</style>

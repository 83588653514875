<template>
  <transition name="slide">
    <div
      class="price-and-payment-filter"
      data-component-name="price-and-payment-filter"
      data-testid="price-payment-filter"
    >
      <div v-show="!priceFilterSelected && !filterSelected" data-role="facet-filter">
        <FilterMenuLineItem
          @click="expandFilter()"
          :isSelected="!!(facetFilters[filterTypePrice] || facetFilters[filterTypePayment])"
          :title="title"
        />
      </div>
      <div v-show="priceFilterSelected">
        <span class="lblTitle">{{ title }}</span>
        <div class="text-toggle d-flex justify-center">
          <TextToggle
            class="price-payment-toggle"
            :options="toggleOptions"
            v-model="priceToggleSelectedValue"
          />
          <!-- data-testid="price-payment-toggle" -->
        </div>
        <div
          class="pp-range-input"
          data-for="price-payment"
          v-show="priceToggleSelected"
          data-testid="range-input"
        >
          <RangeInput
            :range="priceRange"
            @inputValueSet="inputValueSetPrice"
            @focus="setUpdateStorePrice = true"
            :step="priceIncrement"
            :currency="true"
            :minLabel="minLabel"
            :maxLabel="maxLabel"
            :currencyIcon="currencyIcon"
            component-name="PriceFilterPrice"
          ></RangeInput>
        </div>
        <div class="pp-range-input" v-show="!priceToggleSelected" data-testid="range-input">
          <RangeInput
            :range="paymentRange"
            @inputValueSet="inputValueSetPayment"
            @focus="setUpdateStorePayment = true"
            :step="paymentIncrement"
            :currency="true"
            :minLabel="minLabel"
            :maxLabel="maxLabel"
            :currencyIcon="currencyIcon"
            component-name="PriceFilterPayment"
          ></RangeInput>
        </div>
        <div class="pp-range-slider" v-show="priceToggleSelected">
          <RangeSlider
            :step="priceIncrement"
            :range="priceRange"
            :setRange="setPriceRange"
            v-model="priceModel"
            @sliderValueUpdated="updatePriceRange"
            @sliderValueSet="updateStorePrice"
          ></RangeSlider>
        </div>
        <div class="pp-range-slider" v-show="!priceToggleSelected">
          <RangeSlider
            :step="paymentIncrement"
            :range="paymentRange"
            :setRange="setPaymentRange"
            v-model="paymentModel"
            @sliderValueUpdated="updatePaymentRange"
            @sliderValueSet="updateStorePayment"
          >
          </RangeSlider>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import RangeInput from './RangeInput.vue';
import RangeSlider from './RangeSlider.vue';
import TextToggle from './TextToggle.vue';
import FilterMenuLineItem from './FilterMenuLineItem.vue';
import { facetMetaData } from '@util/facetHelper';
import { mapActions, mapState } from 'pinia';
import { useVehicleSearchStore } from '@/stores/vehicleSearch';
import { FieldsPropType } from '@/lib/FieldTypes';

export default defineComponent({
  name: 'PriceFilter',
  props: {
    fields: {
      type: Object as PropType<FieldsPropType>,
      default: () => ({}),
    },
    rendering: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    RangeInput,
    RangeSlider,
    TextToggle,
    FilterMenuLineItem,
  },
  data() {
    return {
      priceRange: [] as number[],
      paymentRange: [] as number[],
      setPriceRange: [] as number[],
      setPaymentRange: [] as number[],
      priceIncrement: 1000,
      paymentIncrement: 50,
      title: this.$t('Price Payment Filter Title'),
      minLabel: this.$t('Min Label'),
      maxLabel: this.$t('Max Label'),
      currencyIcon: this.$t('Currency Icon'),
      priceToggle: this.$t('Price Payment Toggle Text Left'),
      paymentToggle: this.$t('Price Payment Toggle Text Right'),
      filterTypePrice: facetMetaData.price.key,
      filterTypePayment: facetMetaData.payment.key,
      setUpdateStorePrice: true,
      setUpdateStorePayment: true,
    };
  },
  computed: {
    ...mapState(useVehicleSearchStore, {
      vehicleData: (state) => state.srpVehiclesData,
      facetFilters: (state) => state.facetFilters ?? {},
      // @ts-ignore TODO-pinia existing error
      vehiclePriceMax: (state) => state.srpVehiclesData?.facets?.price?.max,
      // @ts-ignore TODO-pinia existing error
      vehiclePriceMin: (state) => state.srpVehiclesData?.facets?.price?.min,
      // @ts-ignore TODO-pinia existing error
      vehiclePaymentMax: (state) => state.srpVehiclesData?.facets?.payment?.max,
      // @ts-ignore TODO-pinia existing error
      vehiclePaymentMin: (state) => state.srpVehiclesData?.facets?.payment?.min,
    }),
    ...mapState(useVehicleSearchStore, [
      'priceToggleSelected',
      'filterSelected',
      'priceFilterSelected',
    ]),
    toggleOptions: function () {
      return [
        { text: 'Price', value: true },
        { text: 'Payment', value: false },
      ];
    },
    priceModel: {
      get() {
        return this.priceRange;
      },
      set(value) {
        this.updateStorePrice(value);
      },
    },
    paymentModel: {
      get() {
        return this.paymentRange;
      },
      set(value) {
        this.updateStorePayment(value);
      },
    },
    priceToggleSelectedValue: {
      get() {
        return this.priceToggleSelected;
      },
      set(value) {
        this.setPriceToggleSelected(value);
      },
    },
  },
  created() {
    this.setUpdateStorePrice = false;
    this.setUpdateStorePayment = false;
    this.setInitialPriceRanges();
    this.setInitialPaymentRanges();
  },
  methods: {
    ...mapActions(useVehicleSearchStore, [
      'setPriceToggleSelected',
      'setFilterSelected',
      'setPriceFilterSelected',
      'setResetSelectedFacetItems',
      'setNumericFacetFilter',
    ]),
    expandFilter() {
      this.setFilterSelected(true);
      this.setPriceFilterSelected(true);
      this.setResetSelectedFacetItems(true);
    },
    inputValueSetPrice(values) {
      this.setUpdateStorePrice = true;
      this.updateStorePrice(values);
    },
    inputValueSetPayment(values) {
      this.setUpdateStorePayment = true;
      this.updateStorePayment(values);
    },
    updatePriceRange(values) {
      this.setUpdateStorePrice = true;
      let minInt = parseInt(values[0]);
      let maxInt = parseInt(values[1]);
      this.priceRange = [minInt, maxInt];
    },
    updatePaymentRange(values) {
      this.setUpdateStorePayment = true;
      let minInt = parseInt(values[0]);
      let maxInt = parseInt(values[1]);
      this.paymentRange = [minInt, maxInt];
    },
    updateStorePrice(values) {
      if (this.setUpdateStorePrice) {
        const minInt = parseInt(values[0]);
        const maxInt = parseInt(values[1]);
        const data = {
          name: this.filterTypePrice,
          value: { min: minInt, max: maxInt },
        };

        this.priceRange = [minInt, maxInt];
        this.setNumericFacetFilter(data);
        this.emitter.emit('filter-updated-srp');
      }
    },
    updateStorePayment(values) {
      if (this.setUpdateStorePayment) {
        const minInt = parseInt(values[0]);
        const maxInt = parseInt(values[1]);
        const data = {
          name: this.filterTypePayment,
          value: { min: minInt, max: maxInt },
        };

        this.paymentRange = [minInt, maxInt];
        this.setNumericFacetFilter(data);
        this.emitter.emit('filter-updated-srp');
      }
    },
    setInitialPriceRanges() {
      let lowestMinPrice = parseInt(
        String(this.roundNumberToLowestThousand(this.vehiclePriceMin ?? 5000))
      );
      let highestMaxPrice = parseInt(
        String(this.roundNumberToHighestTenThousand(this.vehiclePriceMax ?? 100000))
      );

      this.setPriceRange = [lowestMinPrice, highestMaxPrice];
      this.priceRange = [lowestMinPrice, highestMaxPrice];
    },
    setInitialPaymentRanges() {
      let lowestMinPayment = parseInt(
        String(this.roundNumberToLowestFifty(this.vehiclePaymentMin ?? 50))
      );
      let highestMaxPayment = parseInt(
        String(this.roundNumberToHighestOneHundred(this.vehiclePaymentMax ?? 750))
      );

      this.setPaymentRange = [lowestMinPayment, highestMaxPayment];
      this.paymentRange = [lowestMinPayment, highestMaxPayment];
    },
    roundNumberToLowestThousand(number) {
      return Math.floor(number / 1000) * 1000;
    },
    roundNumberToHighestTenThousand(number) {
      return Math.ceil(number / 10000) * 10000;
    },
    roundNumberToLowestFifty(number) {
      return Math.floor(number / 50) * 50;
    },
    roundNumberToHighestOneHundred(number) {
      return Math.ceil(number / 100) * 100;
    },
  },
  watch: {
    facetFilters: {
      handler: async function (newValue) {
        if (!newValue.hasOwnProperty(this.filterTypePrice)) {
          this.priceRange = [this.setPriceRange[0], this.setPriceRange[1]];
          this.setUpdateStorePrice = false;
        } else {
          this.priceRange = [
            this.facetFilters[this.filterTypePrice].min,
            this.facetFilters[this.filterTypePrice].max,
          ];
        }

        if (!newValue.hasOwnProperty(this.filterTypePayment)) {
          this.paymentRange = [this.setPaymentRange[0], this.setPaymentRange[1]];
          this.setUpdateStorePayment = false;
        } else {
          this.paymentRange = [
            this.facetFilters[this.filterTypePayment].min,
            this.facetFilters[this.filterTypePayment].max,
          ];
        }
      },
      deep: true,
    },
    vehiclePriceMax: 'setInitialPriceRanges',
    vehiclePaymentMax: 'setInitialPaymentRanges',
  },
});
</script>

<style lang="scss">
.price-and-payment-filter {
  .slide-enter {
    transform: translateX(-100%);
  }
  .slide-enter-active {
    transition: all 0.3s ease-in;
  }
  .slide-leave-active {
    transition: all 0.1s ease-in;
  }
  .slide-leave-to {
    transform: translateX(-100%);
  }
  .lblTitle {
    padding-bottom: 0;
  }
  .text-toggle {
    padding-bottom: 16px;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
  }
  .price-payment-toggle .toggleBtn {
    width: 88px;
  }
  .pp-range-input {
    .lblTitle {
      display: none;
    }
    .inputMaxLbl {
      padding-right: 73px;
    }
  }
  .pp-range-slider .divRangeSlider {
    width: 100%;
    padding-left: 24px;
    padding-right: 20px;
  }
}
</style>

<template>
  <transition name="slide">
    <div v-show="isFilterMenuOpen" class="filter-menu-container" data-component-name="facet-menu">
      <!-- Special menu for the DistanceAndShippingFilter factet when selected -->
      <div v-if="filterZipFlyout">
        <div class="filter-menu-header">
          <div
            class="filter-back align-center"
            v-if="filterSelected"
            @click="setFilterZipFlyout(false)"
            @keyup.enter="setFilterZipFlyout(false)"
            tabindex="0"
          >
            <img src="@icons/Path_filtermain.svg" class="arrow-back-icon" />
            <span class="ml-2">Distance &amp; shipping</span>
          </div>
          <div v-show="!filterSelected" class="filter-label">
            <sc-image :media="fields.icon"></sc-image>
            <sc-text :field="fields.label" class="filter-text" data-testid="filter-text"></sc-text>
          </div>
          <span v-on:click="toggleFilterMenu()" class="close-button">
            <CloseIcon />
          </span>
          <span
            class="desktop-reset-button"
            @click="resetFacets()"
            @keyup.enter="resetFacets()"
            tabindex="0"
            ><sc-text :field="fields.button" data-testid="reset-button-text-desktop"></sc-text
          ></span>
        </div>
      </div>
      <div v-else class="filter-menu-header-outer">
        <div class="filter-menu-header">
          <div
            class="filter-back align-center"
            v-if="filterSelected"
            @click="resetFilters()"
            @keyup.enter="resetFilters()"
            tabindex="0"
            data-action="return-to-all-filters"
          >
            <img src="@icons/Path_filtermain.svg" class="arrow-back-icon" />
            <span class="ml-2">All Filters</span>
          </div>
          <div v-show="!filterSelected" class="filter-label">
            <sc-image :media="fields.icon"></sc-image>
            <sc-text :field="fields.label" class="filter-text" data-testid="filter-text"></sc-text>
          </div>
          <span v-on:click="toggleFilterMenu()" class="close-button">
            <CloseIcon />
          </span>
          <span
            class="desktop-reset-button"
            @click="resetFacets()"
            @keyup.enter="resetFacets()"
            tabindex="0"
            data-action="reset-facets"
            ><sc-text :field="fields.button" data-testid="reset-button-text-desktop"></sc-text
          ></span>
        </div>
        <div class="filter-chips-mobile" v-if="!filterSelected">
          <!-- TODO: some of these properties might need to get moved up to the .filter-chips-mobile parent div -->
          <sc-placeholder
            name="filter-chip"
            :rendering="rendering"
            :hasBorderBottom="false"
            :isInMobileFilterMenu="false"
            :isFilterMenuOpen="isFilterMenuOpen"
          />
        </div>
        <div class="facet-action-links" v-if="!filterSelected">
          <span class="mobile-reset-link" @click="resetFacets()">
            <sc-text :field="fields.button" data-testid="reset-button-text-mobile"></sc-text>
          </span>
          <span
            v-if="facetChipsExpanded && isMobile && hiddenChips > 0"
            class="mobile-collapse-link"
            @click="hideFilterChips"
          >
            {{ $t('srp.common.showLess') }} <ChevronDownIconBlue />
          </span>
        </div>
      </div>
      <div class="filter-list" :class="{ 'mt-0': filterZipFlyout }" data-menu-type="filter-list">
        <div
          class="border-top"
          :class="{
            'border-top-0': filterZipFlyout,
            'filter-selected': filterSelected,
          }"
        >
          <sc-placeholder :rendering="rendering" name="filter-category" data-role="container" />
        </div>
      </div>
      <div class="filter-footer">
        <Button :text="`View ${formattedVehicleAmount} results`" @click="toggleFilterMenu" />
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Text, Image, Placeholder } from '@sitecore-jss/sitecore-jss-vue';
import CloseIcon from '@assets/icons/CloseIcon/CloseIcon.vue';
import Button from '@components-vue/Foundation/Common/Buttons/Button.vue';
import ChevronDownIconBlue from '@assets/icons/ChevronDownIcon/ChevronDownIconBlue.vue';
import { mapActions, mapState } from 'pinia';
import { useVehicleSearchStore } from '@/stores/vehicleSearch';
import { FieldsPropType } from '@/lib/FieldTypes';

export default defineComponent({
  name: 'FilterMenu',
  props: {
    fields: {
      type: Object as PropType<FieldsPropType>,
      default: () => ({}),
    },
    rendering: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    ChevronDownIconBlue,
    CloseIcon,
    ScText: Text,
    ScImage: Image,
    ScPlaceholder: Placeholder,
    Button, // eslint-disable-line vue/no-reserved-component-names
  },
  data() {
    return {
      isFilterMenuOpen: false,
      isMobile: false,
    };
  },
  computed: {
    ...mapState(useVehicleSearchStore, [
      'facetChipsExpanded',
      'filterSelected',
      'filterZipFlyout',
      'facetFilters',
      'formattedVehicleAmount',
      'hiddenChips',
      'radius',
    ]),
  },

  mounted() {
    this.checkIsMobile();

    this.emitter.on('toggle-filter-menu', () => {
      this.isFilterMenuOpen = !this.isFilterMenuOpen;
    });

    window.addEventListener('resize', this.handleResize);
    if (this.isMobile) {
      this.isFilterMenuOpen = false;
    }
  },
  created() {},
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },

  methods: {
    ...mapActions(useVehicleSearchStore, [
      'setFilterSelected',
      'clearSelectedFacets',
      'setFacetChipsExpanded',
      'resetStoreDefaults',
      'setFilterZipFlyout',
    ]),
    checkIsMobile() {
      if (typeof window !== 'undefined') {
        this.isMobile = window.innerWidth < 1240;
      }
    },
    toggleFilterMenu() {
      this.setFilterZipFlyout(false);
      this.setFilterSelected(false);
      this.emitter.emit('toggle-filter-menu');
    },
    resetFacets() {
      this.resetFilters();
      this.clearSelectedFacets();
      this.resetStoreDefaults(this.$jss?.sitecoreContext()?.layoutPath);
      this.emitter.emit('filter-updated-srp');
    },
    handleResize() {
      this.checkIsMobile();

      if (!this.isMobile) {
        this.isFilterMenuOpen = false;
      }
    },
    resetFilters() {
      this.setFilterSelected(false);
    },
    hideFilterChips() {
      this.setFacetChipsExpanded(false);
    },
  },
});
</script>

<style lang="scss">
.filter-menu-container {
  display: none;
  flex-direction: column;
  height: 100%;
  z-index: 70;
  background: var(--base-white-100);

  &::-webkit-scrollbar {
    width: 0;
  }

  p {
    color: var(--park-gray-0);
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  .filterExpanded & {
    display: flex;
  }
  .border-top {
    border-top: 1px solid #dee2e6 !important;
  }
  .border-top-0 {
    border-top: 0 !important;
  }
  .filter-footer {
    z-index: 150;
    padding: 16px;
    box-shadow: 0 0 5px 0 #00000024;
    border: 1px solid var(--park-gray-90);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .filter-list {
    flex: 1;
    overflow-y: auto;

    .lblTitle {
      display: block;
      font-family: Roboto, sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
      height: auto;
      color: var(--park-gray);
      padding: 16px 24px;
    }
  }
  .filter-selected {
    border-top: 0;
  }
  .filter-back {
    display: flex;
    align-self: center;
    font-size: 16px;
  }
  .filter-label {
    display: flex;
    align-self: center;
    font-weight: 700;
    align-items: center;
    gap: 8px;
    margin-bottom: 0;
  }
  .arrow-back-icon {
    filter: brightness(0) saturate(100%) invert(18%) sepia(91%) saturate(3047%) hue-rotate(184deg)
      brightness(99%) contrast(102%);
    height: 14px;
    transform: rotate(180deg);
    margin-top: -2px;
  }
  .filter-menu-header-outer {
    position: sticky;
    top: 0;
  }
  .filter-menu-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 24px;
    align-items: end;

    span {
      color: var(--system-alert-blue-60);
      font-feature-settings:
        'clig' off,
        'liga' off;
      font-family: Roboto, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      cursor: pointer;
    }
  }
  .desktop-reset-button {
    display: none;
  }
  .facet-action-links {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
  }
  .mobile-collapse-link svg {
    transform: rotate(180deg);
  }
  .filter-chip {
    position: unset;
  }
  .slide-enter {
    transform: translateX(-100%);
  }
  .slide-enter-active {
    transition: all 0.3s ease-in;
  }
  .slide-leave-active {
    transition: all 0.1s ease-in;
  }
  .slide-leave-to {
    transform: translateX(-100%);
  }
  .filter-chips-mobile {
    margin: -4px auto;
  }

  .mobile-reset-link {
    margin-left: 24px;
    margin-bottom: 16px;
    color: var(--system-alert-blue-60);
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    cursor: pointer;
  }

  /*Tablet style */
  @media only screen and (min-width: 625px) and (max-width: 1240px) {
    width: 375px;
  }

  /* Large devices (laptops/desktops, 1240px and up) */
  @media only screen and (min-width: 1240px) {
    display: flex !important;
    width: 306px;
    max-width: 306px;
    position: sticky;
    top: 0;
    border-right: 1px solid var(--park-gray-90);
    height: 100vh;

    .filter-chips-mobile {
      display: none;
    }
    .filter-footer {
      display: none;
    }
    .scrollUp & {
      top: 72px;
    }
    .filter-menu-header {
      display: flex;
      justify-content: space-between;
      align-self: stretch;
      background: var(--base-white-100);

      .close-button {
        display: none;
      }
    }
    .filter-label img {
      width: 24px;
      height: 24px;
    }
    .facet-action-links {
      display: none;
    }
    .desktop-reset-button {
      display: block;
      width: 36px;
      height: 24px;
      line-height: 24px !important;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
      span {
        font-size: 14px;
        font-weight: 400;
      }
    }
    .filter-chip {
      display: none;
    }
    .filter-size-mock {
      position: sticky;
      top: 76px;
    }
  }
  span.filter-text {
    color: $park-gray-0;
    cursor: default;
  }
}
</style>

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EmploymentDetailsFormContainer = _resolveComponent("EmploymentDetailsFormContainer")!

  return (_openBlock(), _createBlock(_component_EmploymentDetailsFormContainer, {
    fields: _ctx.fields,
    ref: "formComponent",
    isCobuyer: true
  }, null, 8, ["fields"]))
}
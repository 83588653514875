<template>
  <div class="panel-content">
    <v-row>
      <v-col cols="12" lg="3">
        <p>{{ $t('legal-name-label') }}</p>
        <p class="small">{{ buyer?.identity.displayName }}</p>
      </v-col>
      <v-col cols="12" lg="3">
        <p>{{ $t('date-of-birth-label') }}</p>
        <p class="small">{{ buyer?.identity.dateOfBirth }}</p>
      </v-col>
      <v-col cols="12" lg="3">
        <p>{{ $t('mobile-number-label') }}</p>
        <p class="small">{{ displayPhoneNumber(buyer?.contactInfo.phone) }}</p>
      </v-col>
      <v-col cols="12" lg="3">
        <p>{{ $t('email-address-label') }}</p>
        <p class="small">{{ buyer?.contactInfo.email }}</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pb-0">
        <p class="large light">{{ $t('current-address-label') }}</p>
      </v-col>
      <v-col cols="12" lg="3">
        <p>{{ $t('address-label') }}</p>
        <p class="small" v-html="formattedAddress"></p>
      </v-col>
      <v-col cols="12" lg="3">
        <p>{{ $t('residence-type-label') }}</p>
        <p class="small">{{ buyer?.address.residenceTypeCodeText }}</p>
      </v-col>
      <v-col cols="12" lg="3">
        <p>{{ $t('move-in-date-label') }}</p>
        <p class="small">
          {{ formattedMonthAndYear }}
        </p>
      </v-col>
      <v-col cols="12" lg="3">
        <p>{{ $t('monthly-payment-label') }}</p>
        <p class="small">{{ formatCurrency(buyer?.address?.rentMortgagePaymentAmount) }}</p>
      </v-col>
    </v-row>
    <v-row v-if="buyer?.address.previousResidenceTypeCode">
      <v-col cols="12" class="pb-0">
        <p class="large light">{{ $t('previous-address-label') }}</p>
      </v-col>
      <v-col cols="12" lg="3">
        <p>{{ $t('address-label') }}</p>
        <p class="small" v-html="previousFormattedAddress"></p>
      </v-col>
      <v-col cols="12" lg="3">
        <p>{{ $t('residence-type-label') }}</p>
        <p class="small">{{ buyer?.address.previousResidenceTypeCodeText }}</p>
      </v-col>
      <v-col cols="12" lg="3">
        <p>{{ $t('move-in-date-label') }}</p>
        <p class="small">
          {{ previousFormattedMonthAndYear }}
        </p>
      </v-col>
      <v-col cols="12" lg="3">
        <p>{{ $t('monthly-payment-label') }}</p>
        <p class="small">{{ formatCurrency(buyer?.address?.previousRentMortgagePaymentAmount) }}</p>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { mapActions } from 'pinia';
import { useStandaloneCreditAppStore } from '@/stores/standaloneCreditApp';

export default defineComponent({
  name: 'Submit--ApplicantDetails',
  props: {
    buyer: {
      type: Object as PropType<Record<string, any> | undefined>,
      required: true,
    },
    formattedAddress: {
      type: String as PropType<string | undefined>,
      required: true,
    },
    previousFormattedAddress: {
      type: String as PropType<string | undefined>,
      required: true,
    },
    formattedMonthAndYear: {
      type: String as PropType<string | undefined>,
      required: true,
    },
    previousFormattedMonthAndYear: {
      type: String as PropType<string | undefined>,
      required: true,
    },
  },
  components: {},
  methods: {
    ...mapActions(useStandaloneCreditAppStore, ['formatCurrency', 'displayPhoneNumber']),
  },
});
</script>

<style lang="scss"></style>

import { defineStore } from 'pinia';
import { Vehicle, Dealership } from '@/types/ServiceModels';

export interface ScheduleTestDriveContact {
  first: string;
  last: string;
  email: string;
  mobile: string;
}

type TestDriveModalView = 'dayAndTime' | 'contactInfo' | 'confirmation';

export interface ScheduleTestDriveState {
  day: string;
  time: string;
  contact: ScheduleTestDriveContact;
  view: TestDriveModalView;
  vehicle: Vehicle;
  dealership: Dealership;
}

export const useScheduleTestDriveStore = defineStore({
  id: 'scheduleTestDrive',
  state: (): ScheduleTestDriveState => ({
    day: '',
    time: '',
    contact: {
      first: '',
      last: '',
      email: '',
      mobile: '',
    },
    view: 'dayAndTime',
    vehicle: {
      year: 0,
      make: '',
      model: '',
      trim: '',
      modelNumber: '',
      vin: '',
      miles: 0,
      stockNumber: '',
      dealership: '',
      sellingPrice: 0,
      images: [],
      lowRezImage: '',
      estimatedPayLow: 0,
      estimatedPayHigh: 0,
      url: '',
      shippable: false,
    },
    dealership: {
      distance: 0,
      zip: '',
      storeName: '',
      storeId: '',
      dealerId: '',
      phone: '',
      address: '',
      city: '',
      state: '',
      url: '',
      storeHours: '',
      timeZone: '',
    },
  }),
  /* https://github.com/vuejs/pinia/issues/58#issuecomment-577074138 */
  actions: {
    setTestDriveContact(value: ScheduleTestDriveContact) {
      this.contact = value;
    },
    setTestDriveDay(value: string) {
      this.day = value;
    },
    setTestDriveTime(value: string) {
      this.time = value;
    },
    setTestDriveView(value: TestDriveModalView) {
      this.view = value;
    },
    setTestDriveVehicle(value: Vehicle) {
      this.vehicle = value;
    },
    setTestDriveDealership(value: Dealership) {
      this.dealership = value;
    },
    resetStoreDefaults() {
      this.$reset();
    },
  },
  getters: {},
});

<template>
  <div class="panel-section">
    <div class="panel-heading">
      <p class="sub-head">{{ $t(labelKey) }}</p>
      <a href="#" title="Edit" v-if="enableEdit">
        <v-icon icon="ep-icon-edit" />
        {{ $t('edit-label') }}
      </a>
    </div>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PanelSection',
  props: {
    labelKey: {
      type: String,
      required: true,
    },
    enableEdit: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss">
/* Add relevant styles if necessary */
</style>

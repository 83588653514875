import * as Yup from 'yup';

/**
 * Enhances the address validation schema by conditionally requiring fields
 * based on the user's residency duration and ensures valid date selections.
 *
 * @param {ValidationSchemas} validationSchemas - The existing validation schemas.
 * @param {Object} schemaObject - The schema object containing field metadata.
 */
export const enhanceAddressValidator = (validationSchemas, schemaObject) => {
  if (!validationSchemas.address) return;

  const existingSchema = validationSchemas.address;

  validationSchemas.address = existingSchema.shape({
    ...addCurrentAddressFields(existingSchema, schemaObject),
    ...addPreviousAddressFields(existingSchema, schemaObject),
  });
};

const addCurrentAddressFields = (existingSchema, schemaObject) => ({
  ...mergeWithExistingSchema(existingSchema, currentAddressFields(schemaObject)),
});

const addPreviousAddressFields = (existingSchema, schemaObject) => ({
  ...mergeWithExistingSchema(existingSchema, previousAddressFields(schemaObject)),
});

/**
 * Merges additional schema fields into the existing schema.
 */
const mergeWithExistingSchema = (existingSchema, fields) => {
  return Object.keys(fields).reduce((acc, key) => {
    acc[key] = existingSchema.fields[key]
      ? existingSchema.fields[key].concat(fields[key])
      : fields[key];
    return acc;
  }, {});
};

/**
 * Retrieves the label for a field from schemaObject.
 */
const getFieldLabel = (schemaObject, fieldName) => {
  return schemaObject?.address?.[fieldName]?.label;
};

/**
 * Ensures safe fallback to a default label if the dynamic label is missing.
 */
const getSafeFieldLabel = (schemaObject, fieldName, defaultLabel) => {
  const label = getFieldLabel(schemaObject, fieldName);
  return typeof label === 'string' && label.trim() ? label : defaultLabel;
};

/**
 * Schema fields for the current address.
 */
const currentAddressFields = (schemaObject) => ({
  monthsAtAddress: Yup.number()
    .typeError(
      `${getSafeFieldLabel(schemaObject, 'monthsAtAddress', 'Months at address')} must be a number`
    )
    .required(getSafeFieldLabel(schemaObject, 'monthsAtAddress', 'Months at address')),

  moveInMonth: Yup.number()
    .typeError(
      `${getSafeFieldLabel(schemaObject, 'moveInMonth', 'Move-in month')} must be a number`
    )
    .required(getSafeFieldLabel(schemaObject, 'moveInMonth', 'Move-in month'))
    .min(
      1,
      `${getSafeFieldLabel(schemaObject, 'moveInMonth', 'Move-in month')} must be between 1 and 12`
    )
    .max(
      12,
      `${getSafeFieldLabel(schemaObject, 'moveInMonth', 'Move-in month')} must be between 1 and 12`
    ),

  moveInYear: Yup.number()
    .typeError(`${getSafeFieldLabel(schemaObject, 'moveInYear', 'Move-in year')} must be a number`)
    .required(getSafeFieldLabel(schemaObject, 'moveInYear', 'Move-in year'))
    .min(
      1900,
      `${getSafeFieldLabel(schemaObject, 'moveInYear', 'Move-in year')} must be a valid year`
    )
    .max(
      new Date().getFullYear(),
      `${getSafeFieldLabel(schemaObject, 'moveInYear', 'Move-in year')} cannot be in the future`
    ),

  rentMortgagePaymentAmount: Yup.number()
    .typeError(
      `${getSafeFieldLabel(schemaObject, 'rentMortgagePaymentAmount', 'Rent/Mortgage payment amount')} must be a number`
    )
    .test(
      'required-if-mortgage-or-rent',
      getSafeFieldLabel(schemaObject, 'rentMortgagePaymentAmount', 'Rent/Mortgage payment amount'),
      (value, context) => {
        const residenceTypeCode = context.parent?.residenceTypeCode;
        const isRequired = ['2', '4'].includes(residenceTypeCode); // required for mortgage & lease/rent
        return isRequired ? value !== undefined && value !== null : true;
      }
    )
    .test(
      'valid-number',
      `${getSafeFieldLabel(schemaObject, 'rentMortgagePaymentAmount', 'Rent/Mortgage payment amount')} must be a valid number`,
      (value, context) => {
        const residenceTypeCode = context.parent?.residenceTypeCode;
        const isRequired = ['2', '4'].includes(residenceTypeCode); // required for mortgage & lease/rent

        if (!isRequired && (value === undefined || value === null)) {
          return true;
        }

        if (isRequired && value === 0) {
          return false; // Explicitly reject 0 for residenceTypeCode 2 or 4
        }

        return /^[0-9]{1,22}$/.test(String(value ?? ''));
      }
    ),
});

/**
 * Schema fields for the previous address.
 */
const previousFields = (schemaObject) => {
  const fieldsToUpdate = {
    previousAddressLine1: Yup.string()
      .typeError(
        `${getSafeFieldLabel(schemaObject, 'previousAddressLine1', 'Previous address line 1')} must be a string`
      )
      .notRequired(),

    previousZipCode: Yup.string()
      .typeError(
        `${getSafeFieldLabel(schemaObject, 'previousZipCode', 'Previous ZIP code')} must be a string`
      )
      .notRequired(),

    previousCity: Yup.string()
      .typeError(
        `${getSafeFieldLabel(schemaObject, 'previousCity', 'Previous city')} must be a string`
      )
      .notRequired(),

    previousState: Yup.string()
      .typeError(
        `${getSafeFieldLabel(schemaObject, 'previousState', 'Previous state')} must be a string`
      )
      .notRequired(),

    previousResidenceTypeCode: Yup.string()
      .typeError(
        `${getSafeFieldLabel(schemaObject, 'previousResidenceTypeCode', 'Previous residence type')} must be a string`
      )
      .notRequired(),

    previousMoveInMonth: Yup.number()
      .typeError(
        `${getSafeFieldLabel(schemaObject, 'previousMoveInMonth', 'Previous move-in month')} must be a number`
      )
      .min(
        1,
        `${getSafeFieldLabel(schemaObject, 'previousMoveInMonth', 'Previous move-in month')} must be between 1 and 12`
      )
      .max(
        12,
        `${getSafeFieldLabel(schemaObject, 'previousMoveInMonth', 'Previous move-in month')} must be between 1 and 12`
      )
      .notRequired(),

    previousMoveInYear: Yup.number()
      .typeError(
        `${getSafeFieldLabel(schemaObject, 'previousMoveInYear', 'Previous move-in year')} must be a number`
      )
      .min(
        1900,
        `${getSafeFieldLabel(schemaObject, 'previousMoveInYear', 'Previous move-in year')} must be a valid year`
      )
      .max(
        new Date().getFullYear(),
        `${getSafeFieldLabel(schemaObject, 'previousMoveInYear', 'Previous move-in year')} cannot be in the future`
      )
      .notRequired(),

    previousMonthsAtAddress: Yup.number()
      .typeError(
        `${getSafeFieldLabel(schemaObject, 'previousMonthsAtAddress', 'Previous months at address')} must be a number`
      )
      .required(
        getSafeFieldLabel(schemaObject, 'previousMonthsAtAddress', 'Previous months at address')
      ),
  };

  Object.keys(fieldsToUpdate).forEach((field) => {
    fieldsToUpdate[field] = fieldsToUpdate[field].test(
      'required-if-valid-and-less-than-24-months',
      getSafeFieldLabel(schemaObject, field, 'Missing label'),
      (value, context) => {
        const { monthsAtAddress, moveInMonth, moveInYear } = context.parent || {};

        const requiresPreviousAddress = monthsAtAddress !== undefined && monthsAtAddress < 24;
        const isValidMoveInMonth = moveInMonth >= 1 && moveInMonth <= 12;
        const isValidMoveInYear = moveInYear >= 1900 && moveInYear <= new Date().getFullYear();

        return requiresPreviousAddress && isValidMoveInMonth && isValidMoveInYear ? !!value : true;
      }
    );
  });

  return fieldsToUpdate;
};

/**
 * Final schema fields for the previous address.
 */
const previousAddressFields = (schemaObject) => ({
  ...previousFields(schemaObject),
  previousRentMortgagePaymentAmount: Yup.number()
    .typeError(
      `${getSafeFieldLabel(schemaObject, 'previousRentMortgagePaymentAmount', 'Previous rent/mortgage payment amount')} must be a number`
    )
    .test(
      'required-if-previous-mortgage-or-rent',
      getSafeFieldLabel(
        schemaObject,
        'previousRentMortgagePaymentAmount',
        'Previous rent/mortgage payment amount'
      ),
      (value, context) => {
        const previousResidenceTypeCode = context.parent?.previousResidenceTypeCode;
        const isRequired = ['2', '4'].includes(previousResidenceTypeCode); // required for mortgage & lease/rent
        if (!isRequired) {
          return true; // If not required, allow it to be empty
        }

        return value !== undefined && value !== null; // Ensures a valid value when required
      }
    )
    .test(
      'valid-number',
      `${getSafeFieldLabel(schemaObject, 'previousRentMortgagePaymentAmount', 'Previous rent/mortgage payment amount')} must be a valid number (1-22 digits)`,
      (value, context) => {
        const previousResidenceTypeCode = context.parent?.previousResidenceTypeCode;
        const isRequired =
          ['2', '4'].includes(previousResidenceTypeCode) && context.parent?.monthsAtAddress < 24; // required for mortgage & lease/rent

        if (!isRequired && (value === undefined || value === null)) {
          return true;
        }

        if (isRequired) {
          return value !== 0 && /^[0-9]{1,22}$/.test(String(value ?? ''));
        }

        return true;
      }
    ),
});

<template>
  <transition name="slide">
    <div class="mpg-engine-type-filter" data-testid="mpg-engine-type-filter">
      <div v-show="!mpgEngineFilterSelected && !filterSelected" data-role="facet-filter">
        <FilterMenuLineItem
          @click="expandFilter()"
          :isSelected="!!(facetFilters[filterTypeMpg] || facetFilters[filterTypeEngine])"
          :title="$t('MPG Engine Filter Title')"
        />
      </div>
      <div v-show="mpgEngineFilterSelected">
        <div class="mpgRangeFrameInput" data-testid="range-input">
          <RangeInput
            :title="titleMpg"
            :range="mpgRange"
            @inputValueSet="updateMpgStore"
            @focus="setUpdateStoreMpg = true"
            :setRange="setRange"
            :minLabel="minLabel"
            :maxLabel="maxLabel"
            data-action="range-input"
            component-name="MpgEngineTypeFilter"
          ></RangeInput>
        </div>
        <div class="mpgRangeFrameSlider">
          <RangeSlider
            style="width: 80%"
            :range="mpgRange"
            :setRange="setRange"
            v-model="mpgModel"
            @sliderValueUpdated="updateMpgRange"
            @sliderValueSet="updateMpgStore"
            data-action="range-slider"
          ></RangeSlider>
        </div>
        <div class="engineTypeFilter" style="max-height: 50%">
          <FacetList
            v-model="engineModel"
            :title="titleEngineType"
            :data="facetList"
            :filterType="filterTypeEngine"
          ></FacetList>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import RangeSlider from './RangeSlider.vue';
import RangeInput from './RangeInput.vue';
import FacetList from './FacetList.vue';
import FilterMenuLineItem from './FilterMenuLineItem.vue';
import { facetMetaData } from '@util/facetHelper';
import { mapActions, mapState } from 'pinia';
import { useVehicleSearchStore } from '@/stores/vehicleSearch';

export default defineComponent({
  name: 'MpgEngineTypeFilter',
  props: ['filterName', 'fields'],
  components: {
    RangeSlider,
    RangeInput,
    FacetList,
    FilterMenuLineItem,
  },
  data() {
    return {
      mpgRange: [] as number[],
      setRange: [] as number[],
      titleMpg: this.$t('MPG Filter Title'),
      titleEngineType: this.$t('Engine Filter Title'),
      minLabel: this.$t('Min Label'),
      maxLabel: this.$t('Max Label'),
      filterTypeEngine: facetMetaData.enginedescription.key,
      filterTypeMpg: facetMetaData.hmpg.key,
      setUpdateStoreMpg: true,
      cachedCount: {},
      updateCachedCount: true,
    };
  },
  computed: {
    ...mapState(useVehicleSearchStore, {
      filterSelected: (state) => state.filterSelected,
      mpgEngineFilterSelected: (state) => state.mpgEngineFilterSelected,
      vehicleData: (state) => state.srpVehiclesData,
      facetFilters: (state) => state.facetFilters ?? {},
      // @ts-ignore TODO-pinia existing error uncovered
      vehicleHmpgMin: (state) => state.srpVehiclesData?.facets?.hmpg?.min,
      // @ts-ignore TODO-pinia existing error uncovered
      vehicleHmpgMax: (state) => state.srpVehiclesData?.facets?.hmpg?.max,
      // @ts-ignore TODO-pinia existing error uncovered
      engineTypeCount: (state) => state.facetCounts?.enginedescription || {},
      recentSelectedFilter: (state) => state.recentSelectedFilter,
    }),
    facetList() {
      return this.fields?.EngineType?.map((x) => ({
        name: x.fields?.EngineTypeCode?.value,
        value: this.getCount(x),
      }));
    },
    mpgModel: {
      get() {
        return this.mpgRange;
      },
      set(value) {
        this.updateMpgStore(value);
      },
    },
    engineModel() {
      return this.facetList;
    },
    getCount() {
      return (x) =>
        this.cachedCount.hasOwnProperty(x.fields?.EngineTypeCode?.value)
          ? this.cachedCount[x.fields?.EngineTypeCode?.value]
          : 0;
    },
  },
  created() {
    this.setUpdateStoreMpg = false;
    this.setInitialHmpgValues();
  },
  methods: {
    ...mapActions(useVehicleSearchStore, [
      'setFilterSelected',
      'setMpgEngineFilterSelected',
      'setResetSelectedFacetItems',
      'setNumericFacetFilter',
    ]),
    setInitialHmpgValues() {
      let lowestMinInt = parseInt(this.vehicleHmpgMin ?? 0);
      let highestMaxInt = parseInt(this.vehicleHmpgMax ?? 100);
      this.setRange = [lowestMinInt, highestMaxInt];
      this.mpgRange = [lowestMinInt, highestMaxInt];
    },
    expandFilter() {
      this.setFilterSelected(true);
      this.setMpgEngineFilterSelected(true);
      this.setResetSelectedFacetItems(true);
    },
    updateMpgRange(values) {
      this.setUpdateStoreMpg = true;
      let minInt = parseInt(values[0]);
      let maxInt = parseInt(values[1]);
      this.mpgRange = [minInt, maxInt];
    },
    updateMpgStore(values) {
      if (this.setUpdateStoreMpg) {
        const minInt = parseInt(values[0]);
        const maxInt = parseInt(values[1]);
        const data = {
          name: this.filterTypeMpg,
          value: { min: minInt, max: maxInt },
        };

        this.mpgRange = [minInt, maxInt];
        this.setNumericFacetFilter(data);
        this.emitter.emit('filter-updated-srp');
      }
    },
  },
  watch: {
    facetFilters: {
      handler: async function (newValue) {
        if (!newValue.hasOwnProperty(this.filterTypeMpg)) {
          this.mpgRange = [this.setRange[0], this.setRange[1]];
          this.setUpdateStoreMpg = false;
        } else {
          this.mpgRange = [
            this.facetFilters[this.filterTypeMpg].min,
            this.facetFilters[this.filterTypeMpg].max,
          ];
        }

        if (this.updateCachedCount && this.mpgEngineFilterSelected) {
          this.emitter.emit('fetch-facet-count', this.filterTypeEngine);
          this.cachedCount = this.engineTypeCount;
        }
      },
      deep: true,
    },
    vehicleHmpgMax: 'setInitialHmpgValues',
    recentSelectedFilter(newValue, oldValue) {
      this.updateCachedCount = !(
        newValue === this.filterTypeEngine && oldValue !== this.filterTypeEngine
      );
    },
    engineTypeCount() {
      this.cachedCount = this.engineTypeCount;
    },
    mpgEngineFilterSelected(isSelected) {
      if (isSelected) {
        this.emitter.emit('fetch-facet-count', this.filterTypeEngine);
      }
    },
  },
});
</script>

<style lang="scss">
.mpg-engine-type-filter {
  .facet-list {
    width: 100%;
    height: 100vh;
  }
}
.mpgRangeFrameSlider {
  padding: 0 0 30px !important;
}
.engineTypeFilter {
  padding-top: 0;
  padding-bottom: 20px;
}
</style>

<template>
  <div class="h-list-container">
    <span v-if="title" class="h-list-title">{{ title }}</span>
    <ul v-if="data && data.length > 0">
      <li
        v-for="item in selectedFacets"
        class="h-list-element"
        :class="{ selected: isSelected(`${item.fullName}~${id}`) }"
        :key="`${item.fullName}~${id}`"
      >
        <div
          class="divItemInfo"
          :class="{
            'hover-underline': isSelected(`${item.fullName}~${id}`),
          }"
          :style="{
            width: isSelected(`${item.fullName}~${id}`) && subMenu ? '80%' : '100%',
          }"
          @click="deselect(`${item.fullName}~${id}`)"
          @keyup.enter="deselect(`${item.fullName}~${id}`)"
          tabindex="0"
        >
          <div class="image-icon">
            <img v-if="item.descendant" src="@icons/ArrowPath.svg" alt="Option" class="imgArrow" />
            <img
              v-if="isSelected(`${item.fullName}~${id}`)"
              src="@icons/check.png"
              alt="Check"
              class="imgCheck"
            />
            <span>
              <span class="lblName">{{ item.name }}</span>
              <span class="lblCount ml-1">({{ item.value.toLocaleString() }})</span>
            </span>
          </div>
        </div>
        <div
          v-if="!item.lastChild && subMenu && isSelected(`${item.fullName}~${id}`)"
          class="h-sub-menu d-flex align-center"
          @click="onSelect(item.name)"
          @keyup.enter="onSelect(item.name)"
          tabindex="0"
        >
          {{ item.subMenu ? item.subMenu : subMenu
          }}<img
            src="@icons/Path_MMT.svg"
            class="arrow-icon ml-1"
            :alt="$t('srp.common.rightArrowIcon')"
          />
        </div>
      </li>
      <li
        v-for="item in notSelectedFacets"
        class="h-list-element"
        :class="{ selected: isSelected(`${item.fullName}~${id}`) }"
        :key="`${item.fullName}~${id}`"
      >
        <div
          class="divItemInfo"
          :class="{
            'hover-underline': isInSelection(`${item.fullName}~${id}`),
          }"
          :style="{
            width: isInSelection(`${item.fullName}~${id}`) && subMenu ? '80%' : '100%',
          }"
          @click="select(`${item.fullName}~${id}`)"
          @keyup.enter="select(`${item.fullName}~${id}`)"
          tabindex="0"
        >
          <div class="image-icon">
            <img v-if="item.descendant" src="@icons/ArrowPath.svg" alt="Option" class="imgArrow" />
            <img
              v-if="isInSelection(`${item.fullName}~${id}`)"
              src="@icons/check.png"
              alt="Check"
              class="imgCheck"
            />
            <span>
              <span class="lblName">{{ item.name }}</span>
              <span class="lblCount ml-1">({{ item.value.toLocaleString() }})</span>
            </span>
          </div>
        </div>
        <div
          v-if="!item.lastChild && subMenu && isInSelection(`${item.fullName}~${id}`)"
          class="h-sub-menu d-flex align-center"
          @click="onSelect(item.name)"
          @keyup.enter="onSelect(item.name)"
          tabindex="0"
        >
          {{ item.subMenu ? item.subMenu : subMenu
          }}<img
            src="@icons/Path_MMT.svg"
            class="arrow-icon ml-1"
            :alt="$t('srp.common.rightArrowIcon')"
          />
        </div>
      </li>
    </ul>
    <div v-else class="h-no-items">No items to display</div>
    <div
      v-if="data && !data.some((x) => !(x as MMTFilterSelection).hidden)"
      class="h-no-items d-flex justify-center"
    >
      No Results
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { facetMetaData } from '@util/facetHelper';
import { MMTFilterSelection, MMTModel } from '@/types/VehicleSearch/Facets';
import { mapActions, mapState } from 'pinia';
import { useVehicleSearchStore } from '@/stores/vehicleSearch';

export default defineComponent({
  name: 'HierarchicalFacetList',
  props: {
    title: {
      type: String,
    },
    data: {
      type: Array,
    },
    subMenu: {
      type: String,
    },
    modelValue: {
      type: Array,
    },
    onSelect: {
      type: Function,
      default: () => {},
    },
  },
  components: {},
  data() {
    return {
      selected: {},
      inSelection: {},
      id: this._uid,
    };
  },
  computed: {
    ...mapState(useVehicleSearchStore, {
      reset: (state) => state.resetSelectedFacetItems,
    }),
    isSelected() {
      return (key) => this.selected[key];
    },
    isInSelection() {
      return (key) => this.inSelection[key];
    },
    isNotSelected() {
      return (key) => !this.selected[key];
    },
    selectedFacets() {
      return this.data && this.data.length > 0
        ? (this.data as MMTFilterSelection[]).filter(
            (item) => this.isSelected(`${item.fullName}~${this.id}`) && !item.hidden && item.value
          )
        : [];
    },
    notSelectedFacets() {
      return this.data && this.data.length > 0
        ? (this.data as MMTFilterSelection[]).filter(
            (item) =>
              this.isNotSelected(`${item.fullName}~${this.id}`) && !item.hidden && item.value
          )
        : [];
    },
  },
  emits: ['update:modelValue'],
  methods: {
    ...mapActions(useVehicleSearchStore, [
      'setRemoveFacet',
      'setRecentSelectedFilter',
      'setResetSelectedFacetItems',
    ]),
    select(key) {
      if (!this.inSelection.hasOwnProperty(key)) {
        // this.$set(this.inSelection, key, true); // Remove after vue3 upgrade
        this.inSelection[key] = true;
      } else {
        // this.$set(this.inSelection, key, !this.inSelection[key]); // Remove after vue3 upgrade
        this.inSelection[key] = !this.inSelection[key];
      }

      let selectedItems = Object.assign({}, this.selected, this.inSelection);
      this.setResetSelectedFacetItems(false);

      let output = {
        internalUpdate: true,
        items: Object.keys(selectedItems)
          .filter((key) => selectedItems[key] === true)
          .map((x) => x.split('~')[0]),
      } as MMTModel;

      this.setRecentSelectedFilter(facetMetaData.mmt.key);
      // this.$emit('input', output);
      this.$emit('update:modelValue', output); // <-- added in the vue3 upgrade - in general the v-model here is very non-standard and should be fixed
    },
    deselect(key) {
      // this.$set(this.selected, key, false);  // Remove after vue3 upgrade
      this.selected[key] = false;
      if (this.inSelection.hasOwnProperty(key)) {
        // this.$set(this.inSelection, key, false); // Remove after vue3 upgrade
        this.inSelection[key] = false;
      }
      let selectedItems = Object.assign({}, this.selected, this.inSelection);
      this.setResetSelectedFacetItems(false);
      let output = {
        internalUpdate: true,
        items: Object.keys(selectedItems)
          .filter((key) => selectedItems[key] === true)
          .map((x) => x.split('~')[0]),
      } as MMTModel;
      this.setRecentSelectedFilter(facetMetaData.mmt.key);
      // this.$emit('input', output);
      this.$emit('update:modelValue', output); // <-- added in the vue3 upgrade - in general the v-model here is very non-standard and should be fixed
    },
    deselectFromExterior(key) {
      key += `~${this.id}`;
      // this.$set(this.selected, key, false); // Remove after vue3 upgrade
      this.selected[key] = false;
      // this.$set(this.inSelection, key, false); // Remove after vue3 upgrade
      this.inSelection[key] = false;
    },
    resetLists() {
      this.selected = {};
      this.inSelection = {};
    },
  },
  watch: {
    reset(value) {
      if (value) {
        for (let key in this.inSelection) {
          // this.$set(this.selected, key, this.inSelection[key]); // Remove after vue3 upgrade
          this.selected[key] = this.inSelection[key];
        }
        this.inSelection = {};
      }
    },
    data(newValue, _oldValue) {
      this.resetLists();
      newValue.forEach((x: MMTFilterSelection) => {
        if (x.selected) {
          let key = x.fullName + '~' + this.id;
          if (!this.inSelection.hasOwnProperty(key)) {
            // this.$set(this.inSelection, key, true); // Remove after vue3 upgrade
            this.inSelection[key] = true;
          } else {
            // this.$set(this.inSelection, key, !this.inSelection[key]); // Remove after vue3 upgrade
            this.inSelection[key] = !this.inSelection[key];
          }
        }
      });
      let selectedItems = Object.assign({}, this.selected, this.inSelection);
      this.setResetSelectedFacetItems(false);
      let output = {
        internalUpdate: false,
        items: Object.keys(selectedItems).map((x) => x.split('~')[0]),
      } as MMTModel;
      // this.$emit('input', output);
      this.$emit('update:modelValue', output); // <-- added in the vue3 upgrade - in general the v-model here is very non-standard and should be fixed
      for (let key in this.inSelection) {
        const subOption = newValue.some(
          (x: MMTFilterSelection) => x.fullName === key.split('~')[0] && x.descendant
        );
        const parentOption = newValue.some(
          (x: MMTFilterSelection) => x.fullName === key.split('~')[0] && x.parent
        );
        if (!subOption && !parentOption) {
          // this.$set(this.selected, key, this.inSelection[key]); // Remove after vue3 upgrade
          this.selected[key] = this.inSelection[key];
        }
      }
    },
  },
});
</script>

<style lang="scss">
.h-list-container {
  display: inline-block;
  overflow-y: auto;
  overflow-x: hidden;
  cursor: default;

  .h-no-items {
    padding: 20px;
    cursor: default;
  }
  .h-sub-menu {
    color: $system-alert-blue-60;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    cursor: pointer;
    padding: 16px 24px;

    &:hover {
      text-decoration: underline;

      .facetIcon {
        filter: invert(34%) sepia(92%) saturate(1674%) hue-rotate(65deg) brightness(90%)
          contrast(103%);
      }
    }
    &:focus,
    &:focus-visible {
      outline-offset: -3px;
    }
  }
  .h-list-title {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
    height: 24px;
    color: $park-gray-0;
    padding-bottom: 50px;
    padding-left: 20px;
    display: inline-block;
  }
  .h-list-element {
    width: 100%;
    border-bottom: 1px solid #e8e9eb;
    align-self: stretch;
    color: var(--park-gray-0);
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-right: -7px;
    display: flex;
    justify-content: space-between;
    cursor: default !important;

    &:not(.selected) .divItemInfo:active,
    &:not(.selected) .h-sub-menu:active {
      background-color: #e8e9eb;
    }
    &:not(.selected):hover .divItemInfo,
    &:not(.selected):hover .h-sub-menu {
      background-color: var(--park-gray-100);
    }
  }
  .arrow-icon {
    fill: var(--system-alert-blue-60);
    height: 14px;
  }
  .hover-underline:hover {
    text-decoration: underline;
    text-decoration-color: $park-gray-0;
  }
  .image-icon {
    display: flex;
    align-items: center;
  }
  .imgCheck {
    padding-right: 8px;
    height: 100%;
  }
  .imgArrow {
    padding-right: 8px;
    margin-top: -16px;
  }
  .selected {
    background-color: #eaf5dc;

    .facetIcon {
      filter: invert(34%) sepia(92%) saturate(1674%) hue-rotate(65deg) brightness(90%)
        contrast(103%);
    }
  }
  .sc-image {
    margin-top: -6px;
    margin-bottom: -6px;
  }
  .inSelection {
    filter: invert(34%) sepia(92%) saturate(1674%) hue-rotate(65deg) brightness(90%) contrast(103%);
  }
  .lblCount {
    color: $park-gray-20;
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
  }
  .divItemInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 80%;
    min-width: 175px;
    padding: 16px 24px;

    &:focus,
    &:focus-visible {
      outline-offset: -3px;
    }
    &:hover .facetIcon {
      filter: invert(34%) sepia(92%) saturate(1674%) hue-rotate(65deg) brightness(90%)
        contrast(103%);
    }
  }
}
</style>

import Cookies from 'js-cookie';
import { defineStore } from 'pinia';
import { Dealership } from '@/types/ServiceModels';
import emitter from '@util/eventBus';

type AccountView = 'login' | 'registration' | 'forgotPassword';

export interface CommonState {
  zipCode: string;
  accountFlyout: boolean;
  accountView: AccountView;
  zipFlyout: boolean;
  dealerships: Dealership[];
  urlCount: number;
  headerData: any; // TODO-ts type here
}

export const useCommonStore = defineStore({
  id: 'common',
  state: (): CommonState => ({
    zipCode: '28205' /* TODO: grab default zip */,
    accountFlyout: false,
    accountView: 'login',
    zipFlyout: false,
    dealerships: [],
    urlCount: 0,
    headerData: {},
  }),
  /* https://github.com/vuejs/pinia/issues/58#issuecomment-577074138 */
  actions: {
    setHeaderData(value: any) {
      // TODO-ts set type
      this.headerData = value;
    },
    decrementUrlCount() {
      this.urlCount--;
    },
    incrementUrlCount() {
      this.urlCount++;
    },
    setZipCode(value: string) {
      if (!value || this.zipCode === value) return;
      if (Cookies.set) {
        Cookies.set('ep_selectedZipcode', value, { expires: 3650 });
        Cookies.set('ep_zipstatus', 'ManualEntry', { expires: 3650 });
      }
      this.zipCode = value;
      const obj = {
        event: 'zip code selected',
        entered: value,
      };

      emitter.emit('analytics', obj);
    },
    setAccountFlyout(value: boolean) {
      this.accountFlyout = value;
    },
    setAccountView(value: AccountView) {
      this.accountView = value;
    },
    setZipFlyout(value: boolean) {
      this.zipFlyout = value;
    },
    setDealerships(value: Dealership[]) {
      if (!this.dealerships) this.dealerships = [];
      this.dealerships.length = 0;
      value.forEach((dealer) => {
        this.dealerships.push(dealer);
      });
    },
    initializeZip(value: string) {
      if (!value || this.zipCode === value) return;
      this.zipCode = value;
    },
  },
  getters: {
    nearestDealership(state): Dealership {
      return state.dealerships[0];
    },
  },
});

<template>
  <transition name="slide">
    <div class="color-filter" data-testid="color-filter">
      <div v-show="!colorFilterSelected && !filterSelected" data-role="filter-toggle">
        <FilterMenuLineItem
          @click="expandFilter()"
          :isSelected="
            facetFilters[extColorFilterType] || facetFilters[intColorFilterType] ? true : false
          "
          :title="title"
          :data-for="title"
        />
      </div>
      <div v-show="colorFilterSelected">
        <span class="lblTitle">{{ title }}</span>
        <div class="text-toggle d-flex justify-center">
          <TextToggle :options="toggleOptions" v-model="exteriorSelected" />
        </div>
        <div v-show="exteriorSelected" class="colorFilter" data-testid="exterior-colors">
          <FacetList
            v-model="facetModelExt"
            :data="facetListExtColor"
            :filterType="extColorFilterType"
          ></FacetList>
        </div>
        <div v-show="!exteriorSelected" class="colorFilter" data-testid="interior-colors">
          <FacetList
            v-model="facetModelInt"
            :data="facetListIntColor"
            :filterType="intColorFilterType"
          ></FacetList>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import FacetList from './FacetList.vue';
import TextToggle from './TextToggle.vue';
import FilterMenuLineItem from './FilterMenuLineItem.vue';
import { facetMetaData } from '@util/facetHelper';
import { FacetListItemValue } from '@/types/VehicleSearch/Facets';
import { useVehicleSearchStore } from '@/stores/vehicleSearch';
import { mapActions, mapState, mapStores } from 'pinia';
import { FieldsPropType } from '@/lib/FieldTypes';

export default defineComponent({
  name: 'ColorFilter',
  props: {
    fields: {
      type: Object as PropType<FieldsPropType>,
      default: () => ({}),
    },
    rendering: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    TextToggle,
    FacetList,
    FilterMenuLineItem,
  },
  data() {
    return {
      title: this.$t('Color Filter Title'),
      exteriorToggleText: this.$t('Color Filter Toggle Text Left'),
      interiorToggleText: this.$t('Color Filter Toggle Text Right'),
      extColorFilterType: facetMetaData.extcolor.key,
      intColorFilterType: facetMetaData.intcolor.key,
      cachedCountExtColor: {},
      cachedCountIntColor: {},
      updateCachedCountExt: true,
      updateCachedCountInt: true,
    };
  },
  computed: {
    ...mapStores(useVehicleSearchStore),
    ...mapState(useVehicleSearchStore, {
      facetFilters: (state) => state.facetFilters ?? {},
      filterSelected: (state) => state.filterSelected,
      colorFilterSelected: (state) => state.colorFilterSelected,
      vehicleData: (state) => state.srpVehiclesData,
      exteriorSelected: (state) => state.extColorToggleSelected,
      // @ts-ignore // TODO-pinia uncovered existing error
      extColorCount: (state) => state.facetCounts?.extcolor || {},
      // @ts-ignore // TODO-pinia uncovered existing error
      intColorCount: (state) => state.facetCounts?.intcolor || {},
      recentSelectedFilter: (state) => state.recentSelectedFilter,
    }),
    toggleOptions: function () {
      return [
        { text: this.exteriorToggleText, value: true },
        { text: this.interiorToggleText, value: false },
      ];
    },
    facetListExtColor(): FacetListItemValue[] {
      // @ts-ignore TS migration - fix when possible
      return this.fields.ExteriorColors.map((x) => ({
        name: x.displayName,
        value: this.getExtCount(x),
        scImage: x.fields?.ColorIcon,
      }));
    },
    facetListIntColor() {
      // @ts-ignore TS migration - fix when possible
      return this.fields?.InteriorColors?.map((x) => ({
        name: x.displayName,
        value: this.getIntCount(x),
        scImage: x.fields?.ColorIcon,
      }));
    },
    facetModelExt() {
      return this.facetListExtColor;
    },
    facetModelInt() {
      return this.facetListIntColor;
    },
    getIntCount() {
      return (x) =>
        this.cachedCountIntColor.hasOwnProperty(x.displayName)
          ? this.cachedCountIntColor[x.displayName]
          : 0;
    },
    getExtCount() {
      return (x) =>
        this.cachedCountExtColor.hasOwnProperty(x.displayName)
          ? this.cachedCountExtColor[x.displayName]
          : 0;
    },
    exteriorSelected: {
      get() {
        return this.vehicleSearchStore.extColorToggleSelected;
        // return this.$store.state['searchResults'].extColorToggleSelected;
      },
      set(value) {
        this.setExtColorToggleSelected(value);
      },
    },
  },
  mounted() {},
  methods: {
    ...mapActions(useVehicleSearchStore, [
      'setExtColorToggleSelected',
      'setFilterSelected',
      'setColorFilterSelected',
      'setResetSelectedFacetItems',
      'setStringFacetFilter',
    ]),
    updateStore(data) {
      this.setStringFacetFilter(data);
      this.emitter.emit('filter-updated-srp');
    },
    expandFilter() {
      this.setFilterSelected(true);
      this.setColorFilterSelected(true);
      this.setResetSelectedFacetItems(true);
    },
  },
  watch: {
    extColorCount() {
      this.cachedCountExtColor = this.extColorCount;
    },
    intColorCount() {
      this.cachedCountIntColor = this.intColorCount;
    },
    exteriorSelected(isSelected) {
      if (this.colorFilterSelected && isSelected) {
        this.emitter.emit('fetch-facet-count', this.extColorFilterType);
      } else if (this.colorFilterSelected && !isSelected) {
        this.emitter.emit('fetch-facet-count', this.intColorFilterType);
      }
    },
    colorFilterSelected(isSelected) {
      if (isSelected && this.exteriorSelected) {
        this.emitter.emit('fetch-facet-count', this.extColorFilterType);
      } else if (isSelected && !this.exteriorSelected) {
        this.emitter.emit('fetch-facet-count', this.intColorFilterType);
      }
    },

    recentSelectedFilter: {
      handler: async function (newValue, oldValue) {
        if (newValue === this.extColorFilterType && oldValue !== this.extColorFilterType) {
          this.updateCachedCountExt = false;
        } else {
          this.updateCachedCountExt = true;
        }

        if (newValue === this.intColorFilterType && oldValue !== this.intColorFilterType) {
          this.updateCachedCountInt = false;
        } else {
          this.updateCachedCountInt = true;
        }
      },
      deep: true,
    },
    facetFilters: {
      handler: async function () {
        if (this.colorFilterSelected && this.updateCachedCountExt && this.exteriorSelected) {
          // if color filter is open
          this.emitter.emit('fetch-facet-count', this.extColorFilterType);
          this.cachedCountExtColor = this.extColorCount;
        } else if (
          this.colorFilterSelected &&
          this.updateCachedCountInt &&
          !this.exteriorSelected
        ) {
          this.emitter.emit('fetch-facet-count', this.intColorFilterType);
          this.cachedCountIntColor = this.intColorCount;
        }
      },
      deep: true,
    },
  },
});
</script>

<style lang="scss">
.color-filter {
  .text-toggle {
    padding: 8px 0 16px;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
  }

  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #d9d9d9 #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 7px;
  }
  *::-webkit-scrollbar-track {
    background: #ffffff;
  }
  *::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 8px;
    border: 1px solid #ffffff;
  }
}

.colorFilter {
  margin-top: 24px;
  padding-bottom: 40px;

  .facet-list {
    width: 100%;
    max-height: calc(100vh - 215px);
  }
}
</style>

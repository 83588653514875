import axios from 'axios';
import { Dealership } from '@/types/ServiceModels';
import DealershipSearchClient from '@/clients/dealershipSearchClient';
import conf from '@/appConfig';

export async function fetchDealerships(zipCode: string): Promise<Dealership[]> {
  const client = DealershipSearchClient(axios, conf.dealershipApiEndpoint, zipCode);
  const response = await client.execute();
  return response.data?.items || [];
}

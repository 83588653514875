<template>
  <div class="testDriveModal--cont ask-us-form" v-if="FirstModal">
    <div class="modal-ask-us-header">
      <h4 class="modal-title">{{ $t('srp2.askUsModal.modalTitle') }} {{ this.askUsModalTitle }}</h4>
      <button type="button" class="close" @click="closeAskAboutUsModal()">
        <v-icon icon="ep-icon-close" />
      </button>
    </div>
    <input type="hidden" :value="askUsVehicle?.vin" id="selectedVehicleVin" />
    <input type="hidden" :value="askUsVehicle?.make" id="selectedVehicleMake" />
    <input type="hidden" :value="askUsVehicle?.model" id="selectedVehicleModel" />
    <input type="hidden" :value="askUsVehicle?.year" id="selectedVehicleYear" />
    <input type="hidden" id="isEmailPhone" v-model="isBoth" />
    <div class="Paragraphs">
      <p class="ParagraphParkGray-Subhead">
        {{ $t('srp2.askUsModal.bodyTitle') }}
      </p>
      <p class="ParagraphParkGray-BodyLG">
        {{ $t('srp2.askUsModal.bodyDescription') }}
      </p>
    </div>
    <form action="" role="form" id="form">
      <div class="testDriveModal">
        <div class="form-group-nested">
          <div class="form-group">
            <label
              class="form-label"
              for="inputFirstnameAboutThisCar"
              role="presentation"
              id="labelForFirstNameAboutCar"
              >{{ $t('srp2.askUsModal.firstNameLabel') }}</label
            >
            <input
              @focus="onInputFocus"
              @blur="onInputBlur"
              @input="validateFirstName"
              type="text"
              aria-label="First name"
              class="form-control"
              id="inputFirstNameAboutThisCar"
              placeholder=""
              required
              aria-labelledby="labelForFirstNameAboutCar"
              autocomplete="given-name"
            />
            <span class="error_message hide-text" id="error_FirstName">
              {{ $t('srp2.askUsModal.firstNameValidation') }}
            </span>
          </div>

          <div class="form-group">
            <label class="form-label" for="inputLastNameAboutThisCar">{{
              $t('srp2.askUsModal.lastNameLabel')
            }}</label>
            <input
              @focus="onInputFocus"
              @blur="onInputBlur"
              @input="validateLastName"
              type="text"
              aria-label="Last name"
              class="form-control"
              id="inputLastNameAboutThisCar"
              placeholder=""
              required
              autocomplete="family-name"
            />
            <span class="error_message hide-text" id="error_LastName"
              >{{ $t('srp2.askUsModal.lastNameValidation') }}
            </span>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label" for="inputEmailAskAboutThisCar"
          >{{ $t('srp2.askUsModal.emailLabel') }}
        </label>
        <input
          @focus="onInputFocus"
          @blur="onInputBlur"
          type="email"
          aria-label="Ask About This Car Email"
          class="form-control"
          id="inputEmailAskAboutThisCar"
          placeholder=""
          required
          autocomplete="email"
        />
        <span class="error_message hide-text" id="error_Email"
          >{{ $t('srp2.askUsModal.emailValidation') }}
        </span>
      </div>
      <div class="form-group-nested">
        <div class="form-group">
          <label class="form-label" for="inputPhoneNumberAskAboutThisCar"
            >{{ $t('srp2.askUsModal.phoneNumberLabel') }}
          </label>
          <input
            @focus="onInputFocus"
            @blur="onInputBlur"
            @input="validatePhoneNumber"
            type="text"
            aria-label="Phone Number"
            inputmode="numeric"
            class="form-control PhoneNumberFormat"
            id="inputPhoneNumberAskAboutThisCar"
            placeholder=""
            maxlength="14"
            autocomplete="tel"
          />
          <span class="error_message hide-text" id="error_PhoneNumber"
            >{{ $t('srp2.askUsModal.phoneNumberValidation') }}
          </span>
        </div>
        <div class="form-group">
          <label class="form-label" for="inputZipCodeLabelAboutThisCar" id="labelForZipCodeAboutCar"
            >{{ $t('srp2.askUsModal.zipLabel') }}
          </label>
          <input
            @focus="onInputFocus"
            @blur="onInputBlur"
            @input="validateZipCode"
            type="text"
            :aria-label="$t('srp2.askUsModal.zipLabel')"
            inputmode="numeric"
            class="form-control"
            id="inputZipCodeAboutThisCar"
            required
            placeholder=""
            maxlength="5"
            aria-labelledby="labelForZipCodeAboutCar"
            autocomplete="postal-code"
          />
          <span id="error-zip" class="error_message hide-text"
            >{{ $t('srp2.askUsModal.zipValidation') }}
          </span>
        </div>
      </div>

      <div class="form-group">
        <span class="hintTextArea hide-text" id="hintTextArea"
          >{{ $t('srp2.askUsModal.optionalMessage') }}
        </span>
        <textarea
          @focus="TextAreaFocus"
          @blur="TextAreaFocusOut"
          type="text"
          id="comments"
          name="comments"
          class="form-control textarea"
          :placeholder="$t('srp2.askUsModal.optionalMessage')"
          maxlength="1200"
          :aria-label="$t('srp2.askUsModal.optionalMessage')"
        ></textarea>
      </div>

      <div class="form-group Footer">
        <div class="form-group mb-0">
          <v-btn id="btn-form" text="Send my question" @click="handleSecondModal" />
        </div>
        <div>
          <span v-if="isBoth === '1'" class="error_message hide-text" id="Error_btn"
            >{{ $t('srp2.askUsModal.validationBothRequired') }}
          </span>
          <span v-else class="error_message hide-text" id="Error_btn"
            >{{ $t('srp2.askUsModal.formValidation') }}
          </span>
        </div>
      </div>

      <div class="Footer text-center">
        <p class="ParagraphParkGray-Light-BodySM">
          {{ $t('Submitting') }}
          <a
            role="link"
            href="/terms-conditions"
            target="_blank"
            aria-describedby="audioeye_new_window_message"
            >{{ $t('TermsOfUse') }}</a
          >
          and
          <a
            role="link"
            href="/privacy-policy"
            target="_blank"
            aria-describedby="audioeye_new_window_message"
            >{{ $t('PrivacyPolicy') }}</a
          >
        </p>
      </div>
    </form>
  </div>
  <div class="ask-us-form" v-else>
    <div class="popup-con-wrap">
      <div class="popup-content">
        <div class="modal-ask-us-header">
          <h4 class="modal-title">{{ $t('srp2.askUsModal.successModalTitle') }}</h4>
          <button type="button" class="close" @click="closeAskAboutUsModal()">
            <v-icon icon="ep-icon-close" />
          </button>
        </div>
        <div class="text-center">
          <img
            src="@assets/images/Modal_Success.png"
            class="modal_success_icon"
            alt="illustration Send info Success"
          />
        </div>
        <p class="modal_success_message">
          {{ $t('srp2.askUsModal.successMessage') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  onInputFocus,
  onInputBlur,
  TextAreaFocus,
  TextAreaFocusOut,
  validateFormAskAboutThisCar,
  CheckAvailability,
} from './AskUsFormMethods';
import { mapState } from 'pinia';
import { defineComponent } from 'vue';
import { useVehicleSearchStore } from '@/stores/vehicleSearch';

export default defineComponent({
  name: 'AskUsForm',
  data() {
    return {
      FirstModal: true,
      isBoth: this.$t('srp2.askUsModal.fieldValidations'),
    };
  },
  computed: {
    ...mapState(useVehicleSearchStore, ['askUsModalTitle']),
  },
  created() {
    this.isBoth = this.$t('srp2.askUsModal.fieldValidations');
  },
  props: {
    askUsVehicle: Object,
  },
  methods: {
    closeAskAboutUsModal() {
      this.FirstModal = true;
      this.emitter.emit('ask-about-us-modal:open', { vin: '', isOpen: false });
    },
    onInputFocus,
    onInputBlur,
    TextAreaFocus,
    TextAreaFocusOut,
    async handleSecondModal() {
      if (await validateFormAskAboutThisCar()) {
        CheckAvailability();
        this.FirstModal = !this.FirstModal;
      }
    },
    validatePhoneNumber(e) {
      const x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
    validateFirstName(e) {
      e.target.value = e.target.value.replace(/[^A-Za-z. ]/g, '');
    },
    validateLastName(e) {
      e.target.value = e.target.value.replace(/[^A-Za-z. ]/g, '');
    },
    validateZipCode(e) {
      e.target.value = e.target.value.replace(/\D/g, '');
    },
  },
});
</script>

<style lang="scss">
.ask-us-form {
  .form-control {
    width: 100%;
    background: 0;
    height: 50px;
    border: 0 none;
    border-bottom: solid 2px var(--park-gray-70);
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: var(--park-gray-0);
    padding-top: 0;
    padding-bottom: 8px;
    padding-left: 0;
    border-radius: 0;
    box-sizing: border-box;

    &:active {
      border-bottom-color: var(--supplementary-blue-50);
    }

    &:focus {
      box-shadow: none;
      height: 34px;
      margin-top: 16px;
    }

    &:focus-visible {
      outline: 3px solid var(--supplementary-blue-80);
      outline-offset: 1px;
    }

    &:focus,
    &:focus-visible {
      border-bottom-color: var(--supplementary-blue-80);
    }

    &.textarea {
      resize: none;
      padding: 16px;
      gap: 10px;
      border: 2px solid var(--park-gray-80);
      border-radius: 8px;
      flex: none;
      order: 1;
      flex-grow: 0;
      height: 120px !important;
      margin-top: 8px;
      position: relative;

      &:focus,
      &:focus-visible {
        outline-offset: -2px;
      }
    }
  }

  .form-group {
    position: relative;
    margin-bottom: 8px;

    .form-label {
      position: absolute;
      top: 18px;
      left: 0;
      transition: all 0.2s ease-in-out;
    }

    &.focused .form-label,
    &.filled .form-label {
      opacity: 1;
      left: 0;
      font-family: Roboto, sans-serif;
      font-size: 12px;
      font-weight: 400;
      font-style: normal;
      line-height: 16px;
      letter-spacing: 0.2px;
    }

    &.focused .form-label {
      top: -20px;
      color: var(--supplementary-blue-50);
    }

    &.filled .form-label {
      top: -4px;
    }

    .error_message {
      margin-top: 4px;
      font-family: Roboto, sans-serif;
      font-size: 12px;
      font-weight: 400;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: 0.2px;
      color: var(--supplementary-red-50);
    }
  }

  .form-group-nested {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .form-group {
      width: calc(50% - 12px);

      &.focused .form-label {
        top: -4px;
      }
    }
  }

  .form-label {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: var(--park-gray-60);
    pointer-events: none;

    .filled & {
      opacity: 1;
      top: 0;
      left: 0;
      font-family: Roboto, sans-serif;
      font-size: 12px;
      font-weight: 400;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: 0.2px;
      color: var(--park-gray-60);
    }
  }

  .hide-text {
    color: transparent !important;
  }

  .hintTextArea {
    display: flex;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: var(--park-gray-20);
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-bottom: 8px;
    transition: all 0.2s;
    position: relative;
    top: 0;
    left: 0;

    &.hide-text {
      font-size: 16px;
      position: relative;
      top: 42px;
      left: 18px;
      letter-spacing: unset;
    }
  }

  .ParagraphParkGray-BodyLG {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--park-gray-0);
    padding: 0;
    margin: 0;
  }

  .Paragraphs {
    margin-bottom: 16px;
  }

  .ParagraphParkGray-Subhead {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 700;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--park-gray-0);
  }

  .ParagraphParkGray-Light-BodySM {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: var(--park-gray-20);
  }

  &.testDriveModal--cont {
    width: 500px;

    .ParagraphParkGray-Light-BodySM a {
      font-weight: 500;
      line-height: 1.57;
      text-decoration: none;
      color: var(--supplementary-blue-60);
    }
  }

  .Footer {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    flex-direction: column;
  }

  .text-center {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
  }

  .popup-content {
    display: flex;
    flex-direction: column;
  }

  .modal_success_message {
    display: flex;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--park-gray-0);
  }

  .modal_success_icon {
    margin: 8px 0 24px 0;
    height: 125px;
    max-width: 100%;
    vertical-align: top;
    border: 0;
  }

  .popup-con-wrap {
    max-height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .modal-ask-us-header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-content: space-between;
    align-items: flex-start;
    margin-bottom: 24px;
  }

  a[target='_blank']:after {
    content: none;
  }
}
</style>

<template>
  <transition name="slide">
    <div class="distance-and-shipping-filter" data-component-name="distance-and-shipping-filter">
      <div v-show="!distanceShippingFilterSelected && !filterSelected">
        <FilterMenuLineItem
          @click="expandFilter()"
          :isSelected="true"
          :title="pageTitle"
          data-role="facet-filter"
        />
      </div>
      <div v-if="distanceShippingFilterSelected" class="">
        <!-- Default View -->
        <div v-if="!filterZipFlyout">
          <div class="distance-shipping-title px-6 py-4">
            {{ pageTitle }}
          </div>
          <div class="divShipToStoreContainer px-6 py-4 d-flex" v-if="shipToStoreToggleFeatureFlag">
            <ShipToStoreToggle />
          </div>

          <!-- Clickable ZipCode -->
          <div class="d-flex flex-column align-center px-6 py-4">
            <span class="your-location">Your location:</span>
            <div>
              <span
                class="clickable-zip"
                @click="setFilterZipFlyout(true)"
                @keyup.enter="setFilterZipFlyout(true)"
                tabindex="0"
                data-action-toggle-filter="zip-code"
              >
                <sc-image
                  class="icon-location-header-flyout"
                  :media="fields.iconLocationFlyout"
                  height="24"
                  width="24"
                />
                {{ zipCode }}
              </span>
            </div>
          </div>

          <div class="shopByStoreToggle">
            <ShopByStoreToggle :fields="fields" />
          </div>
        </div>
        <!-- Zip Edit "Nested" View -->
        <div v-else class="zipLocation">
          <transition>
            <ZipLocation :fields="fields" />
          </transition>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Image } from '@sitecore-jss/sitecore-jss-vue';
import ShipToStoreToggle from './ShipToStoreToggle.vue';
import ZipLocation from './ZipLocation.vue';
import ShopByStoreToggle from './ShopByStoreToggle.vue';
import FilterMenuLineItem from './FilterMenuLineItem.vue';
import { mapActions, mapState } from 'pinia';
import { useVehicleSearchStore } from '@/stores/vehicleSearch';
import { useCommonStore } from '@/stores/common';
import { FieldsPropType } from '@/lib/FieldTypes';

export default defineComponent({
  props: {
    fields: {
      type: Object as PropType<FieldsPropType>,
      default: () => {},
    },
  },
  data() {
    return {
      shopBy: 0,
      isDistanceAndShippingFilterOpen: true,
      shipToStoreToggleFeatureFlag: Boolean(Number(this.$t('Ship To Store Toggle Feature Flag'))),
    };
  },
  computed: {
    ...mapState(useVehicleSearchStore, [
      'filterSelected',
      'distanceShippingFilterSelected',
      'filterZipFlyout',
    ]),
    ...mapState(useCommonStore, ['zipCode']),
    pageTitle() {
      return this.shipToStoreToggleFeatureFlag
        ? this.$t('Distance Shipping Filter Title')
        : this.$t('Distance stores Filter Title');
    },
  },
  name: 'DistanceAndShippingFilter',
  components: {
    ScImage: Image,
    ShipToStoreToggle,
    ZipLocation,
    ShopByStoreToggle,
    FilterMenuLineItem,
  },
  methods: {
    ...mapActions(useVehicleSearchStore, [
      'setFilterSelected',
      'setDistanceShippingFilterSelected',
      'setResetSelectedFacetItems',
      'setFilterZipFlyout',
      'setRadius',
    ]),
    expandFilter() {
      this.setFilterSelected(true);
      this.setDistanceShippingFilterSelected(true);
      this.setResetSelectedFacetItems(true);
    },
  },
});
</script>

<style lang="scss">
.distance-and-shipping-filter {
  .shopping-near {
    color: var(--park-gray-0);
    font-family: Roboto, serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.2px;
  }

  /* Add this style to .clickable-zip to keep it aligned properly */
  .clickable-zip {
    cursor: pointer;
    color: $system-alert-blue-60;
    font-family: Roboto, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: $font-weight-medium;
    line-height: 24px; /* 150% */

    img {
      position: relative;
      top: 6px;
    }
  }
  .your-location {
    color: var(--park-gray-0);
    font-family: Roboto, serif;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 16px;
  }
  .divShipToStoreContainer {
    background-color: #f7f7f7;
  }
  .distance-shipping-title {
    font-family: Roboto, serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
  }
}
</style>

<template>
  <div class="panel-content">
    <v-row>
      <v-col cols="12" lg="3">
        <p>{{ $t('down-payment-label') }}</p>
        <p class="small">{{ formatCurrency(additionalDetails?.downPayment) }}</p>
      </v-col>
      <v-col cols="12" lg="3">
        <p>{{ $t('have-trade-label') }}</p>
        <p class="small">{{ additionalDetails?.trade }}</p>
      </v-col>
      <v-col cols="12" lg="3">
        <p>{{ $t('ssn-label') }}</p>
        <p class="small">{{ additionalDetails?.ssn }}</p>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { useStandaloneCreditAppStore } from '@/stores/standaloneCreditApp';
import { mapActions } from 'pinia';

export default defineComponent({
  name: 'Submit--AdditionalDetails',
  props: {
    additionalDetails: {
      type: Object as PropType<Record<string, any> | undefined>,
      required: true,
    },
  },
  methods: {
    ...mapActions(useStandaloneCreditAppStore, ['formatCurrency']),
  },
});
</script>

<style lang="scss">
/* Add relevant styles if necessary */
</style>

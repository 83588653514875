import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withKeys as _withKeys, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "sca-store" }
const _hoisted_2 = { class: "sca-store-inner" }
const _hoisted_3 = { class: "sca-store-header" }
const _hoisted_4 = { class: "d-flex flex-column align-end" }
const _hoisted_5 = { class: "sca-store-tip-content" }
const _hoisted_6 = { class: "d-flex align-end" }
const _hoisted_7 = { class: "d-flex align-center" }
const _hoisted_8 = { class: "sca-store-zip" }
const _hoisted_9 = { class: "label-start" }
const _hoisted_10 = { class: "label-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sc_image = _resolveComponent("sc-image")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_sc_rich_text = _resolveComponent("sc-rich-text")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_btn_toggle = _resolveComponent("v-btn-toggle")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_v_row, {
        justify: "space-between",
        align: "center",
        dense: "",
        class: "header-wrap flex-nowrap"
      }, {
        default: _withCtx(() => [
          _createElementVNode("h6", _hoisted_3, _toDisplayString(_ctx.fieldsMap.get('Select Location.Label')?.value), 1),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.fieldsMap.get('nearest-to.fieldNotes')?.value), 1),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_sc_image, {
                  class: "sca-store-location-icon mr-1",
                  media: _ctx.fieldsMap.get('Select Location.option-icon')?.value
                }, null, 8, ["media"]),
                _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.zipCode), 1)
              ])
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, { dense: "" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_sc_rich_text, {
                class: "sca-store-body-text",
                field: _ctx.fieldsMap.get('Select Location.notes')
              }, null, 8, ["field"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            class: "sca-store-selector"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dealerships, (dealership, index) => {
                return (_openBlock(), _createBlock(_component_v_btn_toggle, {
                  modelValue: _ctx.selectedDealership,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedDealership) = $event)),
                  key: index,
                  class: "box-input box-input-md"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      onClick: ($event: any) => (_ctx.setDealership(dealership)),
                      onKeyup: _withKeys(($event: any) => (_ctx.setDealership(dealership)), ["enter"]),
                      class: _normalizeClass(_ctx.selectedDealership === dealership ? 'active' : ''),
                      "data-testid": `sca-dealership-${index}`
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_9, _toDisplayString(dealership.storeName), 1),
                        _createElementVNode("div", _hoisted_10, _toDisplayString(Math.round(dealership.distance || 0).toLocaleString()) + " mi ", 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick", "onKeyup", "class", "data-testid"])
                  ]),
                  _: 2
                }, 1032, ["modelValue"]))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ]))
}
import {
  SitecoreSchema,
  AbstractSchemaField,
  SchemaFieldDefinition,
  ValidationSchemas,
} from '@/types/StandaloneCreditApp/StandaloneCreditAppTypes';
import * as Yup from 'yup';

/**
 * Maps Sitecore fields into an easily accessible dictionary.
 * @param {Object} sitecoreSchema - The Sitecore schema object.
 * @returns {Object} - A dictionary of Sitecore fields with field names as keys.
 */
export const mapSitecoreFields = (sitecoreSchema: SitecoreSchema): Record<string, any> => {
  const fieldsMap: Record<string, any> = {};
  const subsetName = sitecoreSchema?.fields?.['subset-name']?.value;

  sitecoreSchema?.fields?.children?.forEach(({ fields }) => {
    const fieldName = fields?.FieldName?.value;
    if (fieldName) {
      fieldsMap[fieldName] = {
        label: fields?.Label?.value || null,
        required: fields?.Required?.value || false,
        validationMessage: fields?.ValidationMessage?.value || '',
        subset: subsetName,
      };
    }
  });
  return fieldsMap;
};

/**
 * Processes schema properties and generates field definitions.
 *
 * - Iterates through schema properties and combines them with Sitecore field mappings.
 * - Calls `getFieldData` to construct each field's metadata.
 * - Determines if a field is required based on the schema definition.
 *
 * @param {Record<string, AbstractSchemaField>} properties - The schema properties object.
 * @param {Record<string, any>} sitecoreFieldsMap - The mapped Sitecore fields.
 * @param {string[]} requiredFields - A list of required fields from the schema definition.
 * @returns {Record<string, any>} - A dictionary of processed schema fields with validation details.
 */
export const processSchemaProperties = (
  properties: Record<string, AbstractSchemaField>,
  sitecoreFieldsMap: Record<string, any>,
  requiredFields: string[]
) => {
  const processedFields: Record<string, any> = {};
  const requiredFieldsSet = new Set(requiredFields);

  Object.entries(properties).forEach(([fieldName, fieldDef]) => {
    const sitecoreField = sitecoreFieldsMap[fieldName];
    const fieldData = getFieldData(fieldName, fieldDef, sitecoreField, requiredFieldsSet);
    processedFields[fieldName] = fieldData;
  });

  return processedFields;
};

/**
 * Constructs the metadata object for a field.
 *
 * - Determines the field's label, preferring Sitecore label > schema description > field name.
 * - Extracts the field type, format, and any applicable enumerations.
 * - Identifies whether the field is required based on Sitecore settings or schema definition.
 * - Includes validation messages and patterns if available.
 *
 * @param {string} fieldName - The name of the field.
 * @param {AbstractSchemaField} fieldDef - The field definition from the schema.
 * @param {any} sitecoreField - The field data from the Sitecore schema.
 * @param {Set<string>} requiredFieldsSet - A set of required field names.
 * @returns {Record<string, any>} - The constructed field data object with metadata.
 */
export const getFieldData = (
  fieldName: string,
  fieldDef: AbstractSchemaField,
  sitecoreField: any,
  requiredFieldsSet: Set<string>
) => {
  return {
    label: sitecoreField?.label || fieldDef.description || fieldName,
    type: typeof fieldDef?.$ref === 'string' ? 'object' : fieldDef.type || 'string',
    format: fieldDef.format || null,
    // enum: Array.isArray(fieldDef.enum) ? ['', ...fieldDef.enum] : [],  // put back if we want empty option
    enum: Array.isArray(fieldDef.enum) ? [...fieldDef.enum] : [],
    required: sitecoreField?.required || requiredFieldsSet.has(fieldName),
    validationMessage: sitecoreField?.validationMessage || '',
    pattern: fieldDef?.pattern || null,
    minLength: fieldDef?.minLength || null,
    maxLength: fieldDef?.maxLength || null,
  };
};

/**
 * Filter out empty or undefined values from the provided object.
 * @param {Record<string, any>} values - The form values to filter.
 * @returns {Record<string, any>} - An object with only non-empty values.
 */
export const filterNonEmptyValues = (values: Record<string, any>): Record<string, any> => {
  return Object.fromEntries(
    Object.entries(values).filter(([_key, value]) => {
      return value !== null && value !== undefined && value !== '';
    })
  );
};

/**
 * Extract validation errors from a Yup validation error.
 * @param {Yup.ValidationError} error - The Yup validation error.
 * @returns {Record<string, string>} - A map of field names to error messages.
 */
export const extractValidationErrors = (error: Yup.ValidationError): Record<string, string> => {
  const errorsMap: Record<string, string> = {};

  if (error.inner) {
    error.inner.forEach((err) => {
      if (err.path) {
        errorsMap[err.path] = err.message;
      }
    });
  }

  return errorsMap;
};

/**
 * Create a Yup validator based on field definition.
 * @param {string} fieldName - The field name.
 * @param {SchemaFieldDefinition} fieldDefinition - The field definition.
 * @returns {Yup.Schema<any>} - A Yup schema for the field.
 */
export const createFieldValidator = (
  fieldName: string,
  fieldDefinition: SchemaFieldDefinition
): Yup.Schema<any> => {
  const label = fieldDefinition?.label || fieldName;
  const validationMessage = fieldDefinition?.validationMessage;

  let validator: Yup.Schema<any>;

  switch (fieldDefinition.type) {
    case 'string': {
      let stringValidator: Yup.StringSchema = Yup.string().typeError(`${label} must be a string`);

      if (fieldDefinition.format === 'email') {
        // enforce email pattern using custom regex (yup built in email validator not enough NP 3/4/2025)
        stringValidator = stringValidator.matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          `${label} must be a valid email address`
        );
      } else if (fieldDefinition.pattern) {
        stringValidator = stringValidator.matches(
          new RegExp(fieldDefinition.pattern),
          `${label} format is invalid`
        );
      }

      // Add minLength and maxLength validation
      if (typeof fieldDefinition.minLength === 'number') {
        stringValidator = stringValidator.min(
          fieldDefinition.minLength,
          `${label} must be at least ${fieldDefinition.minLength} characters`
        );
      }
      if (typeof fieldDefinition.maxLength === 'number') {
        stringValidator = stringValidator.max(
          fieldDefinition.maxLength,
          `${label} must be at most ${fieldDefinition.maxLength} characters`
        );
      }

      validator = stringValidator;
      break;
    }

    case 'integer':
    case 'number': {
      let numberValidator: Yup.NumberSchema = Yup.number().typeError(`${label} must be a number`);

      if (fieldDefinition.rules?.minimum !== undefined) {
        numberValidator = numberValidator.min(
          fieldDefinition.rules.minimum,
          `${label} is too small`
        );
      }

      if (fieldDefinition.rules?.maximum !== undefined) {
        numberValidator = numberValidator.max(
          fieldDefinition.rules.maximum,
          `${label} is too large`
        );
      }

      if (fieldDefinition.rules?.pattern) {
        const patternRegex = new RegExp(fieldDefinition.rules.pattern);
        numberValidator = numberValidator.test('pattern', `${label} format is invalid`, (value) => {
          return value === undefined || value === null || patternRegex.test(String(value));
        });
      }

      validator = numberValidator;
      break;
    }

    case 'boolean': {
      const booleanValidator: Yup.BooleanSchema = Yup.boolean().typeError(
        `${label} must be true or false`
      );
      validator = booleanValidator;
      break;
    }

    case 'array': {
      const arrayValidator: Yup.ArraySchema<any, Yup.AnyObject> = Yup.array().typeError(
        `${label} must be an array`
      );
      validator = arrayValidator;
      break;
    }

    case 'object': {
      const objectValidator: Yup.ObjectSchema<any, Yup.AnyObject> = Yup.object().typeError(
        `${label} must be an object`
      );
      validator = objectValidator;
      break;
    }

    default: {
      const mixedValidator: Yup.MixedSchema = Yup.mixed().strict();
      validator = mixedValidator;
      break;
    }
  }

  /* 
    Custom for Standalone credit app- for store, use the validation message, otherwise use the label
    per jason 3/11/2025
   */
  if (fieldName === 'storeId') {
    return fieldDefinition.required
      ? validator.required(`${validationMessage}`)
      : validator.notRequired();
  }

  return fieldDefinition.required ? validator.required(`${label}`) : validator.notRequired();
};

export const isValidFieldDefinition = (fieldDefinition: any): boolean => {
  return (
    fieldDefinition &&
    typeof fieldDefinition === 'object' &&
    ('required' in fieldDefinition || // Check if the field has validation rules
      'enum' in fieldDefinition || // Check if it's an enum field
      'rules' in fieldDefinition || // Check for additional rules
      'format' in fieldDefinition || // Optional format, e.g., email format
      'pattern' in fieldDefinition) // Regex validation pattern
  );
};

/**
 * Recursively processes schema properties and extracts validation schemas for nested structures.
 *
 * - Iterates through an object containing field definitions and nested objects.
 * - Generates Yup validation schemas for individual fields based on their definition.
 * - When encountering a nested object, it builds a new Yup object schema recursively.
 * - Stores extracted nested schemas in `validationSchemas` using dot-separated paths.
 *
 * @param {Record<string, any>} obj - The object containing schema definitions.
 * @param {ValidationSchemas} validationSchemas - The object to store extracted sub-schemas.
 * @param {string} [prefix] - The prefix for nested schema names (used for proper key naming).
 * @returns {Record<string, Yup.Schema<any>>} - The generated shape for Yup validation.
 *
 * Example:
 * Input object:
 * {
 *   name: {
 *     firstName: { type: 'string', required: true },
 *     contact: {
 *       phone: { type: 'string', required: true },
 *       email: { type: 'string', required: true }
 *     }
 *   },
 *   address: {
 *     street: { type: 'string', required: true },
 *     city: { type: 'string', required: true }
 *   }
 * }
 *
 * Output validationSchemas:
 * {
 *   name: Yup.object().shape({
 *     firstName: Yup.string().required()
 *   }),
 *   'name.contact': Yup.object().shape({
 *     phone: Yup.string().required(),
 *     email: Yup.string().required()
 *   }),
 *   address: Yup.object().shape({
 *     street: Yup.string().required(),
 *     city: Yup.string().required()
 *   })
 * }
 */
export const buildAndExtractSubSchemas = (
  obj: Record<string, any>,
  validationSchemas: ValidationSchemas,
  prefix: string = ''
): Record<string, Yup.Schema<any>> => {
  return Object.entries(obj).reduce<Record<string, Yup.Schema<any>>>((shape, [key, value]) => {
    const fullKey = prefix ? `${prefix}.${key}` : key; // Generate the full key name
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      if (isValidFieldDefinition(value)) {
        shape[key] = createFieldValidator(key, value);
      } else {
        validationSchemas[fullKey] = Yup.object().shape(
          buildAndExtractSubSchemas(value, validationSchemas, fullKey)
        );
        shape[key] = validationSchemas[fullKey];
      }
    } else if (isValidFieldDefinition(value)) {
      shape[key] = createFieldValidator(key, value);
    }

    return shape;
  }, {});
};

/**
 * Recursively process properties, including nested objects.
 * Tracks which sitecoreFields have been matched & removes them from sitecoreFieldsMap.
 * @param {Record<string, AbstractSchemaField>} properties - Schema properties.
 * @param {Set<string>} requiredFieldsSet - Set of required field names.
 * @param {Record<string, any>} sitecoreFieldsMap - Dictionary of mapped sitecore fields.
 * @param {Set<string>} matchedFields - Tracks matched fields.
 * @returns {Record<string, any>} - Processed fields, including nested objects.
 */
export const processNestedFields = (
  properties: Record<string, AbstractSchemaField>,
  requiredFieldsSet: Set<string>,
  sitecoreFieldsMap: Record<string, any>,
  matchedFields: Set<string> = new Set() // Track matched fields
): Record<string, any> => {
  const processedFields: Record<string, any> = {};

  Object.entries(properties).forEach(([fieldName, fieldDef]) => {
    if (fieldDef.type === 'object' && fieldDef.properties) {
      processedFields[fieldName] = processNestedFields(
        fieldDef.properties,
        new Set(fieldDef.required || []),
        sitecoreFieldsMap,
        matchedFields
      );
    } else {
      const sitecoreField = sitecoreFieldsMap[fieldName];
      if (sitecoreField) {
        matchedFields.add(fieldName); // Track this field as matched
        delete sitecoreFieldsMap[fieldName]; // Remove from sitecoreFieldsMap
      }
      processedFields[fieldName] = getFieldData(
        fieldName,
        fieldDef,
        sitecoreField,
        requiredFieldsSet
      );
    }
  });

  return processedFields;
};

/**
 * Aggregates and merges fields from child objects into a single fields object.
 *
 * This function processes a parent object that contains child objects with nested fields.
 * It accumulates field values from all children into a unified fields object.
 * - If a field appears in multiple children as an array, it merges and deduplicates the values.
 * - If a field appears in one child as an array and in another as a single value, it merges them.
 * - If a field appears as a single value in multiple children, it keeps the latest occurrence.
 *
 * @param {Object} parentObject - The parent object containing child objects.
 * @returns {Object} - An object with merged child fields.
 */
export const accumulateChildFields = (parentObject: any) => {
  return {
    fields: {
      fields: parentObject?.children?.reduce((acc: any, child: any) => {
        Object.keys(child.fields || {}).forEach((key) => {
          if (Array.isArray(acc[key]) && Array.isArray(child.fields[key])) {
            acc[key] = [...new Set([...acc[key], ...child.fields[key]])];
          } else if (Array.isArray(acc[key])) {
            acc[key] = [...acc[key], child.fields[key]];
          } else if (Array.isArray(child.fields[key])) {
            acc[key] = [acc[key], ...child.fields[key]].filter((v) => v !== undefined);
          } else {
            acc[key] = child.fields[key];
          }
        });
        return acc;
      }, {}),
    }?.fields,
  };
};

<template>
  <!-- There are two variations of this page, the intro or the form variation
   The intro has a single image centered at the bottom. The form variation has an image and text centered x and y -->
  <div class="sca-text-illustration">
    <div class="inner">
      <sc-image :media="fields.image_file" height="320" width="320" />
      <sc-rich-text :field="imageText" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { StartImage } from '@/lib/CommonDatasourceModels';
import { Image } from '@sitecore-jss/sitecore-jss-vue';
import RichText from '@components-vue/Foundation/Common/RichText';

export default defineComponent({
  name: 'TextIllustration',
  props: {
    fields: {
      type: Object as PropType<StartImage>,
      default: () => ({}),
    },
  },
  components: {
    ScImage: Image,
    ScRichText: RichText,
  },
  computed: {
    imageText() {
      return this.fields?.['tip-content'];
    },
  },
});
</script>

<style lang="scss">
.sca-text-illustration {
  background-color: $park-gray-100;
  width: 100%;
  text-align: center;
  height: 100vh;

  .inner {
    width: 386px;
    margin-left: auto;
    margin-right: auto;
    padding: 235px $spacing_inline-xxs $spacing_stack-xs;

    @include media-breakpoint-up(lg) {
      width: 428px;
      padding-left: $spacing_inline-sm;
      padding-right: $spacing_inline-sm;
    }
    @include media-breakpoint-up(xl) {
      width: 440px;
    }
  }
}
</style>

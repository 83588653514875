import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "panel-section" }
const _hoisted_2 = { class: "panel-heading" }
const _hoisted_3 = { class: "sub-head" }
const _hoisted_4 = {
  key: 0,
  href: "#",
  title: "Edit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t(_ctx.labelKey)), 1),
      (_ctx.enableEdit)
        ? (_openBlock(), _createElementBlock("a", _hoisted_4, [
            _createVNode(_component_v_icon, { icon: "ep-icon-edit" }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('edit-label')), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _renderSlot(_ctx.$slots, "default")
  ]))
}